import styled from "styled-components"
import Tag, { TTagType } from "../../Tag"
import { SvgIconComponent } from "@mui/icons-material"
import Icon from "../../Icon"
import Tooltip, { TTooltipPosition } from "../../Tooltip/Tooltip"
import OptionsCard from "./OptionsCard"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { useSearchParams } from "react-router-dom";
import { dsVariables } from "../../../variables"
import Label from "../../Text/Label"
import { useSelector } from "react-redux"
import { IStore } from "../../../../interfaces/store.interface"
import Switch, { TSwitchState } from "../../Switch"
import useCanPerformAction from "../../../hooks/CanPerformAction.hook"
import { ActionEnum } from "../../../constants/user-action-permissions.constant"

export interface IIcon {
    Icon: SvgIconComponent
    color?: string
    onClick: any
    tooltipText?: string
    tooltipPosition?: TTooltipPosition;
    tableIndex?: number;
    width?:number
    height?:number
}

export interface ICellProps {
    label?: string
    Icons?: IIcon[]
    showCheckbox?: boolean
    isTag?: boolean
    tagType?: TTagType
    onChangeCheckbox?: any
    checkboxDisabled?: boolean
    checked?: boolean
    isSwitch?: boolean
    switchState?: TSwitchState
    switchIsOn?: boolean
    onChangeSwitch?: any
    isLink?: boolean
    color?: string
    labels?: any[]
    onClick?: any
}

export default function Cell(
    {
        Icons, label, showCheckbox, isTag = false, onChangeCheckbox,
        checked = false, tagType, isSwitch, switchState, switchIsOn,
        onChangeSwitch, checkboxDisabled, isLink, color, labels, onClick
    }: ICellProps) {

    const iconOptionsCardRef = useRef<HTMLDivElement | null>(null);
    const optionsCardRef = useRef<HTMLDivElement | null>(null);

    const [showOptionsCard, setShowOptionsCard] = useState(false);

    const location = useLocation()

    const stringCutter = (str: string, maxLength: number) => {
        return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
    };

    useEffect(() => {
        setShowOptionsCard(false)
    }, [location]);

    const isMoreOptionsCell = (icon: IIcon) => icon.tooltipText == 'Mais opções'

    function handleIconClick(icon: IIcon) {
        isMoreOptionsCell(icon)
            ? setShowOptionsCard(!showOptionsCard)
            : icon.onClick()
    }

    useEffect(() => {
        function closeOptionsCard(event: MouseEvent) {
            if ((iconOptionsCardRef.current && !iconOptionsCardRef.current.contains(event.target as Node))
                && (optionsCardRef.current && !optionsCardRef.current.contains(event.target as Node))
            ) {
                setShowOptionsCard(false);
            }
        }

        document.addEventListener('mousedown', closeOptionsCard)

        return () => {
            document.removeEventListener('mousedown', closeOptionsCard)
        }
    }, [showOptionsCard]);

    const canChangeThreatStatus = useCanPerformAction(ActionEnum.change_threat_url_status)

    return (
        <Td onClick={onClick}>
            <div className="d-flex gap-2 align-items-center">
                {showCheckbox && <input disabled={checkboxDisabled} checked={checked} type="checkbox" onChange={e => onChangeCheckbox(e.target.checked)} />}
                {
                    Icons &&
                    <div className="d-flex gap-2 justify-content-center">
                        {Icons.map((icon, index) =>
                            icon.tooltipText ?
                                <div className="position-relative" key={index} >
                                    <Tooltip text={icon.tooltipText} >
                                        <div ref={iconOptionsCardRef}>
                                            <Icon width={24} height={24} IconComponent={icon.Icon} color={icon.color} onClick={() => handleIconClick(icon)} />
                                        </div>
                                    </Tooltip>
                                    {(showOptionsCard && isMoreOptionsCell(icon)) &&
                                        <div ref={optionsCardRef}>
                                            <OptionsCard tableIndex={icon.tableIndex} onClick={icon.onClick} />
                                        </div>
                                    }
                                </div>
                                :
                                <Icon width={icon.width} height={icon.height} key={index} IconComponent={icon.Icon} color={icon.color} onClick={icon.onClick} />
                        )}
                    </div>
                }
                {label ?
                    isTag
                        ? <Tag type={tagType} label={label} />
                        : <div >
                            {
                                isLink ?
                                    <Link href={label} target="_blank">
                                        <Label color={color}>{label}</Label>
                                    </Link>
                                    : <Label color={color}>{label}</Label>
                            }
                        </div>
                    : null
                }

                {
                    isSwitch
                        ? <Switch onChange={onChangeSwitch} state={!canChangeThreatStatus ? 'disabled' : switchState} isOn={switchIsOn} />
                        : null
                }
                {
                    labels && labels.length > 0 && labels.map((el, index) =>
                        el?.label ? (
                            <Tooltip key={index} text={el.label}>
                                <Tag 
                                    label={el.label} 
                                    backgroundColor={el.backgroundColor} 
                                    textColor={el.textColor} 
                                />
                            </Tooltip>
                        ) : null
                    )
                }
            </div>
        </Td>
    )
}

const Link = styled.a`
    label {
        cursor:pointer;
    }
`

const Td = styled.td`
    height: Fixed (44px)px;
    padding: 8px 12px;
    gap: 8px;
    border: 0px 0px 1px 0px;
    border-bottom: 1px solid var(--Colors-Gray-200, #E6E6E6);
    vertical-align:middle;
    white-space: nowrap;

    input:disabled{
        cursor: not-allowed;
    }
`