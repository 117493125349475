import { all, call, put, takeLatest } from "redux-saga/effects";
import { ActionTypesEnum } from "./types.enum";
import { cancelUserInviteHelper, changeUserPasswordAdminHelper, changeUserPermissionsHelper, changeUserStatusHelper, fetchDatabaseByTypeHelper, getAllCompaniesToAdminHelper, getAllUsersAndInvitedsHelper, getAllUsersHelper, getCompaniesGroupHelper, getUserByEmailHelper, inviteUserHelper, registerDatabaseInTypeHelper, resendUserInviteHelper, updateDatabaseHelper } from "../../helpers/backend_helper";
import { changeAdminCompaniesGroupList, changeAdminCompaniesList, changeDatabaseByType, changeErrorMessage, changeSuccessMessage, changeUser, changeUsers, changeUsersAndInviteds } from "./action";
import { setLoading } from "../threats/actions";

function* getCompaniesSaga() {
  try {
    const companies: any[] = yield call(getAllCompaniesToAdminHelper)
    yield put(changeAdminCompaniesList(companies))
  } catch {
    // 
  }
}

function* getCompaniesGroupSaga({ payload }: any) {
  try {
    const companies: any[] = yield call(getCompaniesGroupHelper, { companyId: payload })
    yield put(changeAdminCompaniesGroupList(companies))
  } catch {
    // 
  }
}

function* inviteUserSaga({ payload }: any) {
  try {
    const userInvite: any[] = yield call(inviteUserHelper, {
      ...payload, type: 'CREATE_USER'
    })
    yield put(setLoading(false))
    alert('Usuário convidado com sucesso')
    window.location.reload()
  } catch (e: any) {
    alert('Usuário já cadastrado')
  }
}

function* changeUserPermissionsSaga({ payload }: any) {
  try {
    yield call(changeUserPermissionsHelper, { ...payload })
    yield put(setLoading(false))
    alert('Permissões atualizadas com sucesso')
    window.location.reload()
  } catch (e: any) {
    alert('Usuário já cadastrado')
    yield put(setLoading(false))
  }
}

function* getUserByEmailSaga({ payload }: any) {
  try {
    // @ts-ignore
    const user = yield call(getUserByEmailHelper, { email: payload })

    if (user?.data == '') return alert('Usuário não encontrado')

    yield put(setLoading(false))
    yield put(changeUser(user))
  } catch (e: any) {
    alert('Usuário não encontrado')
    yield put(setLoading(false))
  }
}

function* getAllUsersSaga() {
  try {
    // @ts-ignore
    const users = yield call(getAllUsersHelper)

    if (users?.data == '') return alert('Nenhum usuário não encontrado')

    yield put(setLoading(false))
    yield put(changeUsers(users))
  } catch (e: any) {
    alert('Usuário não encontrado')
    yield put(setLoading(false))
  }
}

function* getAllUsersAndInvitedsSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    const users = yield call(getAllUsersAndInvitedsHelper, {
      page: payload.page, quantity: payload.quantity,
      registered: payload.registered, status: payload.status,
      text: payload.text
    })

    yield put(setLoading(false))
    yield put(changeUsersAndInviteds(users))
  } catch (e: any) {
    yield put(changeErrorMessage('Falha ao lista usuários'))
    yield put(setLoading(false))
  }
}

function* cancelUserInviteSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    const user = yield call(cancelUserInviteHelper, { email: payload })

    yield put(setLoading(false))
    yield put(changeSuccessMessage(`Convite cancelado para ${user.email}`))
  } catch (e: any) {
    yield put(changeErrorMessage('Falha ao realizar ação'))
    yield put(setLoading(false))
  }
}

function* resendUserInviteSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    const user = yield call(resendUserInviteHelper, { email: payload })

    yield put(setLoading(false))
    yield put(changeSuccessMessage(`Convite reenviado para ${user.email}`))
  } catch (e: any) {
    yield put(changeErrorMessage('Falha ao realizar ação'))
    yield put(setLoading(false))
  }
}

function* changeUserStatusSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    const user = yield call(changeUserStatusHelper, { email: payload })

    yield put(setLoading(false))
    yield put(changeSuccessMessage(`Status do usuário ${user.email} alterado`))
  } catch (e: any) {
    yield put(changeErrorMessage('Falha ao realizar ação'))
    yield put(setLoading(false))
  }
}

function* changeUserPasswordSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    const user = yield call(changeUserPasswordAdminHelper, { userId: payload.userId, password: payload.password })

    yield put(setLoading(false))
    yield put(changeSuccessMessage(`Senha do usuário ${user.email} alterada`))
  } catch (e: any) {
    yield put(changeErrorMessage('Falha ao realizar ação'))
    yield put(setLoading(false))
  }
}

function* registerRegistrarSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    yield call(registerDatabaseInTypeHelper, payload)

    yield put(setLoading(false))
  } catch (e: any) {
    console.error('Erro ao adicionar database:', e)
    yield put(setLoading(false))
  }
}

function* registerDomainSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    yield call(registerDatabaseInTypeHelper, payload)

    yield put(setLoading(false))
  } catch (e: any) {
    console.error('Erro ao adicionar database:', e)
    yield put(setLoading(false))
  }
}

function* registerHostSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    yield call(registerDatabaseInTypeHelper, payload)

    yield put(setLoading(false))
  } catch (e: any) {
    console.error('Erro ao adicionar database:', e)
    yield put(setLoading(false))
  }
}

function* updateDatabaseSaga({ payload }: any) {
  try {
    yield put(setLoading(true))

    // @ts-ignore
    yield call(updateDatabaseHelper, payload)

    yield put(setLoading(false))
    alert('Database atualizado!')

  } catch (e: any) {
    console.error('Erro ao adicionar database:', e)
    yield put(setLoading(false))
  }
}

function* fetchDatabaseByTypeSaga({ payload }: any) {
  try {
    yield put(setLoading(true))
    // @ts-ignore
    const data = yield call(fetchDatabaseByTypeHelper, { type: payload })
    yield put(changeDatabaseByType(data))
    yield put(setLoading(false))
  } catch (e: any) {
    console.error('Erro ao adicionar database:', e)
    yield put(setLoading(false))
  }
}


export function* watchAll() {
  yield takeLatest(ActionTypesEnum.FETCH_COMPANIES, getCompaniesSaga);
  yield takeLatest(ActionTypesEnum.FETCH_COMPANIES_GROUP, getCompaniesGroupSaga);
  yield takeLatest(ActionTypesEnum.INVITE_USER, inviteUserSaga);
  yield takeLatest(ActionTypesEnum.CHANGE_USER_PERMISSIONS, changeUserPermissionsSaga);
  yield takeLatest(ActionTypesEnum.FETCH_USER_TO_CHANGE, getUserByEmailSaga);
  yield takeLatest(ActionTypesEnum.FETCH_USERS, getAllUsersSaga);
  yield takeLatest(ActionTypesEnum.FETCH_USERS_AND_INVITEDS, getAllUsersAndInvitedsSaga);
  yield takeLatest(ActionTypesEnum.CANCEL_USER_INVITE, cancelUserInviteSaga);
  yield takeLatest(ActionTypesEnum.RESEND_USER_INVITE, resendUserInviteSaga);
  yield takeLatest(ActionTypesEnum.CHANGE_USER_STATUS, changeUserStatusSaga);
  yield takeLatest(ActionTypesEnum.CHANGE_USER_PASSWORD, changeUserPasswordSaga);
  yield takeLatest(ActionTypesEnum.REGISTER_REGISTRAR, registerRegistrarSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_DATABASE, updateDatabaseSaga);
  yield takeLatest(ActionTypesEnum.REGISTER_DOMAIN, registerDomainSaga);
  yield takeLatest(ActionTypesEnum.REGISTER_HOST, registerHostSaga);
  yield takeLatest(ActionTypesEnum.FETCH_DATABASE_BY_TYPE, fetchDatabaseByTypeSaga);
}

function* adminSaga() {
  yield all([
    call(watchAll)
  ]);
}
export default adminSaga 