interface IPinProps {
    style?: React.CSSProperties;
    value: number;
}

export default function Pin({ style, value }: IPinProps) {
    return (
        <div 
            style={{ 
                position: 'absolute',
                transform: 'translateX(-50%)',
                width: '33px',
                height: '38px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                top: '20px',
                color: '#FFFFFF',
                fontSize: '12px',
                fontWeight: 500,
                backgroundImage: `url("data:image/svg+xml,%3Csvg width='33' height='38' viewBox='0 0 33 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.1863 10.3137C34.4347 16.5621 34.4347 26.6928 28.1863 32.9411C21.9379 39.1895 11.8073 39.1895 5.55887 32.9411C-0.689514 26.6928 -0.689513 16.5621 5.55888 10.3137L14.7513 1.12134C15.9228 -0.0502297 17.8223 -0.0502298 18.9939 1.12134L28.1863 10.3137Z' fill='%23001F35'/%3E%3C/svg%3E")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                ...style 
            }}
        >
            {value}
        </div>
    );
} 