import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input/Input";
import Label from "../../../components/Text/Label";
import { dsVariables } from "../../../variables";
import Select from "../../../components/Select";
import Accordion from "../../../components/Accordion";
import InputEvidence from "../../../components/Input/InputEvidence";
import { IStore } from "../../../../interfaces/store.interface";
import { changePixFormCheckoutUrl, changePixFormCpf, changePixFormEvidence, changePixFormEvidenceLink, changePixFormFinancialInstitution, changePixFormPaymentGateway, changePixFormPix, registerPix, setLoading, updatePix } from "../../../../store/threats/actions";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import ButtonSecondary from "../../../components/Button/ButtonSecondary";
import Body from "../../../components/Text/Body";
import styled from "styled-components";
import { useState } from "react";
import { CheckCircleOutline, ErrorOutline, ModeEditOutline } from "@mui/icons-material";
import Icon from "../../../components/Icon";
import { PaymentsGatewayEnum } from "../../../../enums/payments-gateway.enum";
import { FinancialInstitutionEnum } from "../../../../enums/financial-institution.enum";
import { Bounce, toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

interface IProps {
    onCancel?: any
    editFlow?: boolean
    pix?: any
}

export default function RegisterPixForm({ onCancel, editFlow = false, pix }: IProps) {

    const { forms: { registerPixForm }, currentTakedown } = useSelector((store: IStore) => store.Threats)
    const dispatch = useDispatch()

    const [canEdit, setCanEdit] = useState(editFlow);

    const searchParams = useSearchParams()[0]

    const tab = searchParams.get('tab')

    const isDetailView = tab != '0' && tab != '1'

    async function savePix(e: any) {
        e.preventDefault()
        if (registerPixForm.paymentGateway == PaymentsGatewayEnum.NONE) {
            return toast.error(
                <div className="d-flex flex-column">
                    <Label style="medium">
                        Erro
                    </Label>
                    <Label>Gateway de pagamento inválido</Label>
                </div>
                , {
                    position: "bottom-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    transition: Bounce,
                    style: {
                        backgroundColor: dsVariables.colors.Red[100],
                        color: dsVariables.colors.Red[800],
                        borderColor: dsVariables.colors.Red[200],
                        borderWidth: 1,
                        borderStyle: 'solid'
                    },
                    progressStyle: {
                        height: 2,
                        backgroundColor: dsVariables.colors.Red[800],
                    },
                    icon: () => <ErrorOutline />
                })
        }

        if (registerPixForm.financialInstitution == FinancialInstitutionEnum.NONE) {
            return toast.error(
                <div className="d-flex flex-column">
                    <Label style="medium">
                        Erro
                    </Label>
                    <Label>Instituição financeira inválida</Label>
                </div>
                , {
                    position: "bottom-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    transition: Bounce,
                    style: {
                        backgroundColor: dsVariables.colors.Red[100],
                        color: dsVariables.colors.Red[800],
                        borderColor: dsVariables.colors.Red[200],
                        borderWidth: 1,
                        borderStyle: 'solid'
                    },
                    progressStyle: {
                        height: 2,
                        backgroundColor: dsVariables.colors.Red[800],
                    },
                    icon: () => <ErrorOutline />
                })
        }

        if (!registerPixForm?.evidence?.link && !registerPixForm?.evidence?.files?.length) {
            return toast.error(
                <div className="d-flex flex-column">
                    <Label style="medium">
                        Erro
                    </Label>
                    <Label>Insira ao menos uma evidência</Label>
                </div>
                , {
                    position: "bottom-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    transition: Bounce,
                    style: {
                        backgroundColor: dsVariables.colors.Red[100],
                        color: dsVariables.colors.Red[800],
                        borderColor: dsVariables.colors.Red[200],
                        borderWidth: 1,
                        borderStyle: 'solid'
                    },
                    progressStyle: {
                        height: 2,
                        backgroundColor: dsVariables.colors.Red[800],
                    },
                    icon: () => <ErrorOutline />
                })
        }

        dispatch(setLoading(true))
        if (editFlow) dispatch(updatePix({ ...registerPixForm, threatId: currentTakedown?.threat?._id, _id: pix._id }))
        else dispatch(registerPix({ ...registerPixForm, threatId: currentTakedown?.threat?._id }))
        onCancel()

        return toast.success(
            <div className="d-flex flex-column">
                <Label style="medium">
                    Evidência pix registrada!
                </Label>
            </div>
            , {
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                transition: Bounce,
                style: {
                    backgroundColor: dsVariables.colors.Green[100],
                    color: dsVariables.colors.Green[800],
                    borderColor: dsVariables.colors.Green[200],
                    borderWidth: 1,
                    borderStyle: 'solid'
                },
                progressStyle: {
                    height: 2,
                    backgroundColor: dsVariables.colors.Green[800],
                },
                icon: () => <CheckCircleOutline />
            })
    }

    function formatCpf(cpf: string) {
        cpf = cpf.replace(/\D/g, '');
        if (cpf.length <= 3) return dispatch(changePixFormCpf(cpf))
        if (cpf.length <= 6) return dispatch(changePixFormCpf(cpf.slice(0, 3) + "." + cpf.slice(3)))
        if (cpf.length <= 9) return dispatch(changePixFormCpf(cpf.slice(0, 3) + "." + cpf.slice(3, 6) + "." + cpf.slice(6)))
        return dispatch(changePixFormCpf(cpf.slice(0, 3) + "." + cpf.slice(3, 6) + "." + cpf.slice(6, 9) + "-" + cpf.slice(9, 11)))
    }

    return (
        <form style={{
            borderColor: canEdit ? dsVariables.colors.Gray[200] : dsVariables.colors.Blue[500],
            borderWidth: 1, borderStyle: 'solid',
            boxShadow: canEdit ? '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)' : 'unset'
        }} onSubmit={savePix}
            className="rounded d-flex flex-column gap-2 position-relative pt-3">
            {(editFlow && !isDetailView && canEdit) && <div role="button" className="position-absolute top-0 end-0 m-3">
                <Icon IconComponent={ModeEditOutline} width={16} height={16} onClick={() => {
                    setCanEdit(!canEdit)
                    dispatch(changePixFormEvidenceLink(pix.evidence?.link || ''))
                    dispatch(changePixFormEvidence(pix.evidence?.files || []))
                    dispatch(changePixFormCheckoutUrl(pix.checkoutUrl))
                    dispatch(changePixFormCpf(pix.cpf))
                    dispatch(changePixFormFinancialInstitution(pix.financialInstitution))
                    dispatch(changePixFormPaymentGateway(pix.paymentGateway))
                    dispatch(changePixFormPix(pix.pix))
                }} />
            </div>}
            <div className="d-flex gap-4 mt-2 px-3">
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column w-50">
                    <Label color={dsVariables.colors.Gray[600]}>Chave Pix*</Label>
                    {
                        canEdit
                            ? <Body color={dsVariables.colors.Gray[800]}>{pix.pix}</Body>
                            : <Input placeholder="Insira a chave pix" required={true} value={registerPixForm.pix} onChange={(e: any) => dispatch(changePixFormPix(e.target.value))} />
                    }
                </div>
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column w-50">
                    <Label color={dsVariables.colors.Gray[600]}>Gateway de Pagamento*</Label>
                    {
                        canEdit
                            ? <Body color={dsVariables.colors.Gray[800]}>{pix.paymentGateway}</Body>
                            : <Select required={true} selected={registerPixForm.paymentGateway} onChange={(e: any) => dispatch(changePixFormPaymentGateway(e.target.value))} options={Object.values(PaymentsGatewayEnum)} />
                    }
                </div>
            </div>
            <div className="d-flex gap-4 mt-2 px-3">
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column w-50">
                    <Label color={dsVariables.colors.Gray[600]}>Instituição Financeira*</Label>
                    {
                        canEdit
                            ? <Body color={dsVariables.colors.Gray[800]}>{pix.financialInstitution}</Body>
                            : <Select required={true} selected={registerPixForm.financialInstitution} onChange={(e: any) => dispatch(changePixFormFinancialInstitution(e.target.value))} options={Object.values(FinancialInstitutionEnum)} />
                    }
                </div>
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column w-50">
                    <Label color={dsVariables.colors.Gray[600]}>CPF*</Label>
                    {
                        canEdit
                            ? <Body color={dsVariables.colors.Gray[800]}>{pix.cpf}</Body>
                            : <Input placeholder="000.000.000-00" required={true} value={registerPixForm.cpf} onChange={(e: any) => formatCpf(e.target.value)} />
                    }
                </div>
            </div>
            <div className="d-flex gap-4 mt-2 px-3 pb-3">
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column flex-grow-1">
                    <Label color={dsVariables.colors.Gray[600]}>URL de checkout*</Label>
                    {
                        canEdit
                            ? <Body color={dsVariables.colors.Gray[800]}>{pix.checkoutUrl}</Body>
                            : <Input placeholder="Insira a url" required={true} value={registerPixForm.checkoutUrl} onChange={(e: any) => dispatch(changePixFormCheckoutUrl(e.target.value))} />
                    }
                </div>
            </div>
            <Accordion withBorder title="Comprovante de denúncias">
                {canEdit ?
                    pix?.evidence?.link
                        ? <Body color={dsVariables.colors.Gray[800]}>{pix?.evidence?.link}</Body>
                        : <div className="d-flex flex-column gap-2">
                            {pix?.evidence?.files?.map((file: any, index: number) => (
                                <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                    <div className="d-flex gap-2 align-items-center">
                                        {/* @ts-ignore */}
                                        <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                        <div className="d-flex flex-column gap-1">
                                            <Label>{file.name || `Evidência ${++index}`}</Label>
                                            {file.size && <Label>{file.size / 1024} Kb</Label>}
                                        </div>
                                    </div>
                                </FilesCard>
                            ))}
                        </div>
                    : <InputEvidence
                        files={registerPixForm.evidence?.files}
                        onChangeLink={(e: any) => dispatch(changePixFormEvidenceLink(e.target.value))}
                        linkValue={registerPixForm.evidence?.link}
                        onDropFile={(e: any) => {
                            dispatch(changePixFormEvidence([...registerPixForm.evidence?.files || [], e[0]]))
                        }}
                        onRemoveFile={(file: File) => dispatch(changePixFormEvidence(registerPixForm.evidence?.files.filter((el: any) => el != file)))}
                    />}
            </Accordion>
            {
                !canEdit &&
                <div className="d-flex justify-content-center gap-3 pb-3 pt-2">
                    <ButtonSecondary isSubmit={false} onClick={onCancel} type="destructive" label="Cancelar" />
                    <ButtonPrimary state={Object.values(registerPixForm).every(el => el) ? 'default' : 'disabled'} isSubmit={true} label="Salvar" />
                </div>
            }
        </form>
    )
}

const FilesCard = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
`