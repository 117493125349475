// src/components/CompanyFilters.jsx
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components';

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const FilterInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FilterSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Filter = () => {
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();
  const location = useLocation();

  return (
    <FiltersContainer>
      <FilterInput onChange={(e: any) => {
        setTimeout(() => {
          searchParams.set('text', e.target.value)
          const newSearch = searchParams.toString();
          if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }, 1000);
      }} className="flex-grow-1" type="text" placeholder="Pesquisa por empresa, agência ou grupo" />
      <FilterSelect onChange={(e: any) => {
        searchParams.set('status', e.target.value)
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
      }}>
        <option value="">Status</option>
        <option value={"true"}>Ativas</option>
        <option value={"false"}>Inativas</option>
      </FilterSelect>
      <FilterSelect onChange={(e: any) => {
        searchParams.set('category', e.target.value)
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
      }}>
        <option selected value="">Todas as categorias</option>
        <option value="pending">Pendente</option>
        <option value="saas">SAAS</option>
        <option value="gold">Gold</option>
        <option value="Platinum 1">Platinum 1</option>
        <option value="Platinum 2">Platinum 2</option>
        <option value="Black 1">Black 1</option>
        <option value="Black 2">Black 2</option>
        <option value="enterprise">Enterprise</option>
        <option value="Titanium 1">Titanium 1</option>
        <option value="Titanium 2">Titanium 2</option>
      </FilterSelect>
      <FilterSelect onChange={(e: any) => {
        searchParams.set('company_type', e.target.value)
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
      }}>
        <option value="">Tipo</option>
        <option value="customer">Cliente</option>
        <option value="prospect">Prospect</option>
        <option value="ADVERTISER">Anunciante</option>
        <option value="churn">Churn</option>
      </FilterSelect>
    </FiltersContainer>
  );
};

export default Filter;