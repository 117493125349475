import Cell from "./Cell"
import Column from "./Column"
import styled from "styled-components"
import { dsVariables } from "../../../variables"
import { useSearchParams } from "react-router-dom"
import { AutoAwesome, AutoAwesomeOutlined, InfoOutlined, MoreVertRounded, WebAssetOff } from "@mui/icons-material"
import { ThreatOptionsCardEnum } from "../../../../enums/threat-options-card.enum"
import EmptyState from "./EmptyState"
import { useDispatch, useSelector } from "react-redux"
import { IStore } from "../../../../interfaces/store.interface"
import { changeThreatsCurrent, changeThreatsCurrentIds, changeThreatsTable, updateFraud } from "../../../../store/threats/actions"
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum"
import { getThreatTypeText } from "../../../utils/get-threat-type-text.util"
import Paginator from "./Paginator"
import useCanPerformAction from "../../../hooks/CanPerformAction.hook"
import { ActionEnum } from "../../../constants/user-action-permissions.constant"
import useIsEnabledOrTrial from "../../../hooks/IsEnabledOrTrial.hook"
import { useState } from "react"
import showToast from "../../../utils/show-toast.util"
import Label from "../../Text/Label"

interface IProps {
    showDetails: any
    showOptions: any
    showCommercialModal: any
    showAIAnalysis: any
    onExport: () => Promise<void>;
}


export default function TableThreats({ showDetails, showOptions, showAIAnalysis,    showCommercialModal, onExport }: IProps) {
    const { isTrial } = useIsEnabledOrTrial("fraud")

    const searchParams = useSearchParams()[0]
    const isTabOne = searchParams.get('tab') == '2'
    const tabActive = searchParams.get('tab')

    const { threatsTableData, currentIds, threatsList, isCustomer, isFreemium } = useSelector((store: IStore) => {
        return {
            threatsTableData: store.Threats.threatsTableData,
            currentIds: store.Threats.currentIds,
            threatsList: store.Threats.threatsList,
            isCustomer: store.Profile.user.isCustomer,
            isFreemium: store.Profile.user.isFreemium
        }
    })

    const showApproveTakedown = useCanPerformAction(ActionEnum.approve_takedown)
    const showDiscardThreat = useCanPerformAction(ActionEnum.discard_threat)
    const showQuarantine = useCanPerformAction(ActionEnum.move_threat_to_quarantine)
    const showResolved = useCanPerformAction(ActionEnum.mark_threat_as_resolved)
    const showRestore = useCanPerformAction(ActionEnum.restore_threat_from_discarded)
    const showEdit = useCanPerformAction(ActionEnum.edit_threat)
    const showCancelApproval = useCanPerformAction(ActionEnum.cancel_approval_request)

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const dispatch = useDispatch()

    function handleCheckboxColumn(checked: boolean) {
        if (checked) {
            setIsCheckboxChecked(true)
            dispatch(changeThreatsCurrentIds(threatsTableData.map((el: any) => el._id)))
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => ({ ...threat, checked: true }))))
        }
        else {
            setIsCheckboxChecked(false)
            dispatch(changeThreatsCurrentIds([]))
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => ({ ...threat, checked: false }))))
        }
    }

    function handleCheckboxRow(checked: boolean, id: string) {
        if (checked) {
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => {
                if (threat._id == id) return { ...threat, checked: true }
                else return threat
            })))
            dispatch(changeThreatsCurrentIds([...currentIds, id]))
            dispatch(changeThreatsCurrent(threatsList.find(el => el._id == id)!))
        } else {
            dispatch(changeThreatsTable(threatsTableData.map((threat: any) => {
                if (threat._id == id) return { ...threat, checked: false }
                else return threat
            })))
            const updatedArray = currentIds.filter(el => el != id)

            dispatch(changeThreatsCurrentIds(updatedArray))
        }
    }

    function cantShowOptions() {
        if (isCustomer) return !showApproveTakedown && !showDiscardThreat && !showQuarantine && !isTrial

        return tabActive == '3'
            ? !showRestore
            : !showRestore &&
            !showDiscardThreat &&
            !showQuarantine &&
            !showResolved &&
            !showRestore &&
            !showEdit &&
            !showCancelApproval
    }

    return (
        <>
            <Div isEmpty={threatsTableData.length == 0} className="table-responsive h-100">
                <table className="table table-striped table-hover table-striped text-nowrap">
                    <thead>
                        <TR>
                            {!isFreemium && <Column checkboxChecked={isCheckboxChecked} checkboxDisabled={(isCustomer || isFreemium) ? tabActive != '2' : tabActive == '5'} showCheckbox={true} onChangeCheckbox={(checked: boolean) => handleCheckboxColumn(checked)} />}
                            <Column label="Ameaça" />
                            <Column label="Identificado" />
                            <Column label="Última análise" />
                            <Column label="Plataforma" />
                            <Column label="Tipos de Ameaça" />
                            <Column label="Status" />
                            <Column />
                            {(isFreemium && tabActive === '2') && <Column />}
                        </TR>
                    </thead>
                    <Tbody isTabOne={isTabOne}>
                        {threatsTableData?.map((threat, index) =>
                            <tr role="button" key={threat._id}>
                                {!isFreemium && <Cell checkboxDisabled={(isCustomer || isFreemium) ? tabActive != '2' : tabActive == '4' || tabActive == '5'} checked={threat.checked || Boolean(currentIds.find(el => el == threat._id))} showCheckbox={true} onChangeCheckbox={(checked: boolean) => handleCheckboxRow(checked, threat._id!)} />}
                                <Cell onClick={() => showDetails(threat._id)} labels={(threat?.labels || [])?.concat(threat.labelsOccurrences)} label={threat.sourceThreat?.searchText} />
                                <Cell onClick={() => showDetails(threat._id)} label={new Date(threat.createdAt).toLocaleDateString('pt-BR')} />
                                <Cell onClick={() => showDetails(threat._id)} label={threat.updatedAt ? new Date(threat.updatedAt).toLocaleDateString('pt-BR') : '—'} />
                                <Cell onClick={() => showDetails(threat._id)} isTag label={threat.sourceThreat?.platform} />
                                <Cell onClick={() => showDetails(threat._id)} label={getThreatTypeText(threat.type)} />
                                {
                                    (isCustomer || isFreemium)
                                        ? <Cell tagType={threat.sourceThreat?.isActive ? "success" : "negative"} isTag label={threat.sourceThreat?.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE} />

                                        : <Cell onChangeSwitch={(checked: boolean) => {
                                            if (!checked) {
                                                if (tabActive == '2') return dispatch(updateFraud({ ...threat, isActive: checked }))
                                                if (tabActive == '4') return dispatch(updateFraud({ ...threat, isActive: checked }))
                                            }
                                            dispatch(updateFraud({ ...threat, isActive: checked }))
                                            showToast({ children: <Label>Status da ameaça atualizado!</Label>, type: 'info' })
                                        }} isSwitch switchState={tabActive == '5' ? 'disabled' : 'default'} switchIsOn={threat.sourceThreat?.isActive} />
                                }
                                {
                                    (isFreemium) ?
                                        <>
                                            {tabActive === '2' &&
                                                <Cell Icons={[
                                                    {
                                                        width: 16, height: 16, Icon: WebAssetOff,
                                                        color: dsVariables.colors.Red[700], onClick:showCommercialModal
                                                    }]}
                                                    label={"Aprovar takedown"}
                                                    color={dsVariables.colors.Red[700]}
                                                    onClick={showCommercialModal}
                                                />}

                                            <Cell Icons={[{ Icon: AutoAwesome, color: dsVariables.colors.Astronaut[900], onClick: () => showAIAnalysis(threat._id), tooltipText: 'Resumo da IA' }]} />
                                        </>
                                        :
                                        <Cell Icons={
                                            tabActive != '4' && tabActive != '5' && !cantShowOptions() ?
                                                [
                                                    { Icon: AutoAwesome, color: dsVariables.colors.Astronaut[900], onClick: () => showDetails(threat._id), tooltipText: 'Ver detalhes' },
                                                    { Icon: MoreVertRounded, color: dsVariables.colors.Astronaut[900], onClick: (option: ThreatOptionsCardEnum) => showOptions(threat._id, option), tooltipText: 'Mais opções', tableIndex: index },
                                                ]
                                                : 
                                                 [
                                                    { Icon: AutoAwesome, color: dsVariables.colors.Astronaut[900], onClick: () => showDetails(threat._id), tooltipText: 'Ver detalhes' },
                                                ]
                                        } />
                                }
                            </tr>
                        )}
                    </Tbody>
                </table>
            </Div>

            {threatsTableData?.length == 0 && <EmptyState text="Nenhuma ameaça listada" />}

            <Paginator onExport={onExport} />
        </>
    )
}

const Div = styled.div<{ isEmpty: boolean }>`
    &::-webkit-scrollbar {
        display:none;
    }
    min-height:${props => props.isEmpty ? 0 : '500px'};
    overflow-y:hidden;
`

const TR = styled.tr`
    background: ${dsVariables.colors.Whitesmoke[50]};
    font-family: ${dsVariables.fontFamilies.Inter};
    font-size:12px;

    th{
        color: ${dsVariables.colors.Gray[900]};
    }

    th:first-child {
    width: 32px;
    position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
  border-right: 1px solid #ddd;
  }
  th:last-child {
    position: sticky;
  right: 0;
  background-color: #fff;
  z-index: 2;
  border-left: 1px solid #ddd;
  }
`

const Tbody = styled.tbody<{ isTabOne: boolean }>`
    tr {
        background: ${dsVariables.colors.Gray[50]};
        font-family: ${dsVariables.fontFamilies.Inter};

        &:hover {
            background-color: ${dsVariables.colors.Gray[100]};
        }

        td {
            color: ${dsVariables.colors.Gray[800]};
        }

        td:first-child {
    width: 32px;
    position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
  border-right: 1px solid #ddd;
  }

  td:last-child {
    position: sticky;
  right: 0;
  background-color: #fff;
  z-index: 2;
  border-left: 1px solid #ddd;
  }
    }
`