import Cell from "./Cell";
import Column from "../TableThreats/Column";
import styled from "styled-components";
import { dsVariables } from "../../../variables";
import { useSearchParams } from "react-router-dom";
import { InfoOutlined, MoreVertRounded } from "@mui/icons-material";
import { ThreatOptionsCardEnum } from "../../../../enums/threat-options-card.enum";
import EmptyState from "../TableThreats/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import {
  changeTakedownsCurrent,
  changeTakedownsCurrentIds,
  changeTakedownsTable,
  updateTakedown,
} from "../../../../store/threats/actions";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import { getThreatTypeText } from "../../../utils/get-threat-type-text.util";
import Paginator from "./Paginator";
import useCanPerformAction from "../../../hooks/CanPerformAction.hook";
import { ActionEnum } from "../../../constants/user-action-permissions.constant";
import useIsEnabledOrTrial from "../../../hooks/IsEnabledOrTrial.hook";
import { useState } from "react";

interface IProps {
  showDetails: any;
  showOptions: any;
}

export default function TableTakedowns({ showDetails, showOptions }: IProps) {
  const { isTrial } = useIsEnabledOrTrial("fraud");

  const searchParams = useSearchParams()[0];
  const isTabOne = searchParams.get("tab") == "2";
  const tabActive = searchParams.get("tab");

  let { currentIds, takedownsList, isCustomer, takedownsTableData } =
    useSelector((store: IStore) => {
      return {
        takedownsTableData: store.Threats.takedownsTableData,
        currentIds: store.Threats.currentIds,
        takedownsList: store.Threats.takedownsList,
        isCustomer: store.Profile.user.isCustomer,
      };
    });

  const showActTakedown = useCanPerformAction(ActionEnum.act_takedown);
  const canActTakedown = useCanPerformAction(ActionEnum.act_takedown)

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const dispatch = useDispatch();

  function handleCheckboxColumn(checked: boolean) {
    if (checked) {
      setIsCheckboxChecked(true);
      dispatch(
        changeTakedownsCurrentIds(takedownsTableData.map((el: any) => el._id))
      );
      dispatch(
        changeTakedownsTable(
          takedownsTableData.map((takedown: any) => ({
            ...takedown,
            checked: true,
          }))
        )
      );
    } else {
      setIsCheckboxChecked(false);
      dispatch(changeTakedownsCurrentIds([]));
      dispatch(
        changeTakedownsTable(
          takedownsTableData.map((takedown: any) => ({
            ...takedown,
            checked: false,
          }))
        )
      );
    }
  }

  function handleCheckboxRow(checked: boolean, id: string) {
    if (checked) {
      dispatch(
        changeTakedownsTable(
          takedownsTableData.map((takedown: any) => {
            if (takedown._id == id) return { ...takedown, checked: true };
            else return takedown;
          })
        )
      );
      dispatch(changeTakedownsCurrentIds([...currentIds, id]));
      dispatch(
        changeTakedownsCurrent(takedownsList.find((el) => el._id == id)!)
      );
    } else {
      dispatch(
        changeTakedownsTable(
          takedownsTableData.map((takedown: any) => {
            if (takedown._id == id) return { ...takedown, checked: false };
            else return takedown;
          })
        )
      );
      const updatedArray = currentIds.filter((el) => el != id);

      dispatch(changeTakedownsCurrentIds(updatedArray));
    }
  }

  function canShowOptions() {
    return showActTakedown
  }

  const stringCutter = (str: string, maxLength: number) => {
    return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
  };

  return (
    <>
      <Div
        isEmpty={takedownsTableData.length == 0}
        className="table-responsive h-100"
      >
        <table className="table  table-hover table-striped text-nowrap">
          <thead>
            <TR>
              <Column
                checkboxChecked={isCheckboxChecked}
                checkboxDisabled={
                  tabActive == "3"
                }
                showCheckbox={true}
                onChangeCheckbox={(checked: boolean) =>
                  handleCheckboxColumn(checked)
                }
              />
              <Column label="ID" />
              <Column label="Marcas (Clientes)" />
              <Column label="Responsável" />
              <Column label="Ameaça" />
              <Column label="Criado" />
              <Column label="Atualizado" />
              <Column label="Plataforma" />
              <Column label="Tipo de ameaça" />
              <Column label="Status" />
              <Column label="Notificações" />
              <Column label="Prazo SLA" />
              <Column label="Identificado há" />
              <Column label="Tempo na fase" />
              <Column />
            </TR>
          </thead>
          <Tbody isTabOne={isTabOne}>
            {takedownsTableData?.map((takedown, index) => (
              <tr role="button" key={takedown._id}>
                <Cell
                  checkboxDisabled={
                    tabActive == "3"
                  }
                  checked={
                    takedown.checked ||
                    Boolean(currentIds.find((el) => el == takedown._id))
                  }
                  showCheckbox={true}
                  onChangeCheckbox={(checked: boolean) =>
                    handleCheckboxRow(checked, takedown._id!)
                  }
                />
                <Cell label={'#' + takedown.identifier} onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} />
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} label={takedown.companyName} />
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} label={takedown.responsibleName} />
                <Cell
                  onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)}
                  label={stringCutter(takedown.sourceThreat?.searchText, 25)}
                  labels={[
                    ...(takedown.labels || []),
                    ...(takedown.occurrences?.flatMap((occurrence: { labels: any; }) => occurrence.labels || []) || [])
                  ]}
                />
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)}
                  label={new Date(takedown.createdAt).toLocaleDateString(
                    "pt-BR"
                  )}
                />
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)}
                  label={
                    takedown.updatedAt
                      ? new Date(takedown.updatedAt).toLocaleDateString("pt-BR")
                      : "—"
                  }
                />
                {/* @ts-ignore */}
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} isTag label={takedown.sourceThreat.platform} />
                {/* @ts-ignore */}
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} label={getThreatTypeText(takedown.threat.type)} />
                {isCustomer ? (
                  <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)}
                    tagType={takedown.isActive ? "success" : "negative"}
                    isTag
                    label={
                      takedown.isActive
                        ? ThreatStatusActiveEnum.ACTIVE
                        : ThreatStatusActiveEnum.INACTIVE
                    }
                  />
                ) : (
                  <Cell
                    onChangeSwitch={(checked: boolean) => {
                      if (!checked) {
                        if (tabActive == "3") return dispatch(updateTakedown({ ...takedown, isActive: checked }));
                      }
                      dispatch(updateTakedown({ ...takedown, isActive: checked }));
                    }}
                    isSwitch
                    switchState={!canActTakedown || tabActive == "3" ? "disabled" : "default"}
                    switchIsOn={takedown.isActive}
                  />
                )}
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} isLink label={takedown.notifications?.toString() || '0'} />
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} label={takedown.SLA} color={takedown.SLA?.startsWith("-") ? dsVariables.colors.Red[700] : dsVariables.colors.Gray[800]} />
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} label={takedown.timeIdentified} color={takedown.timeIdentified?.startsWith("-") ? dsVariables.colors.Red[700] : dsVariables.colors.Gray[800]} />
                <Cell onClick={() => showOptions(takedown._id, ThreatOptionsCardEnum.REGISTER_NOTIFICATION)} label={takedown.timeOnPhase} color={takedown.timeOnPhase?.startsWith("-") ? dsVariables.colors.Red[700] : dsVariables.colors.Gray[800]} />
                {isCustomer && tabActive != "2" ? (
                  <Cell label="" />
                ) : (
                  <Cell
                    Icons={
                      // canShowOptions() ?
                      [
                        {
                          Icon: MoreVertRounded,
                          color: dsVariables.colors.Astronaut[900],
                          onClick: (option: ThreatOptionsCardEnum) =>
                            showOptions(takedown._id, option),
                          tooltipText: "Mais opções",
                          tableIndex: index,
                        },
                      ]
                      // : []
                    }
                  />
                )}
              </tr>
            ))}
          </Tbody>
        </table>
      </Div>

      {takedownsTableData?.length == 0 && (
        <EmptyState text="Nenhuma ameaça listada" />
      )}

      <Paginator />
    </>
  );
}

const Div = styled.div<{ isEmpty: boolean }>`
  &::-webkit-scrollbar {
    display: none;
  }
  min-height: ${(props) => (props.isEmpty ? 0 : "500px")};
  overflow-y: hidden;
`;

const TR = styled.tr`
  background: ${dsVariables.colors.Whitesmoke[50]};
  font-family: ${dsVariables.fontFamilies.Inter};
  font-size: 12px;

  th {
    color: ${dsVariables.colors.Gray[900]};
  }
  th:first-child {
    width: 32px;
    position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
  border-right: 1px solid #ddd;
  }
  th:last-child {
    position: sticky;
  right: 0;
  background-color: #fff;
  z-index: 2;
  border-left: 1px solid #ddd;
  }
`;

const Tbody = styled.tbody<{ isTabOne: boolean }>`
  tr {
    background: ${dsVariables.colors.Gray[50]};
    font-family: ${dsVariables.fontFamilies.Inter};

    &:hover {
      background-color: ${dsVariables.colors.Gray[100]};
    }

    td:first-child {
    width: 32px;
    position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
  border-right: 1px solid #ddd;
  }

  td:last-child {
    position: sticky;
  right: 0;
  background-color: #fff;
  z-index: 2;
  border-left: 1px solid #ddd;
  }

    td {
      color: ${dsVariables.colors.Gray[800]};
    }
  }
`;
