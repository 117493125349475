import styled from "styled-components";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import Accordion from "../../../components/Accordion";
import Label from "../../../components/Text/Label";
import Icon from "../../../components/Icon";
import useCanPerformAction from "../../../hooks/CanPerformAction.hook";
import Modal, { IFooterButton } from "../../../components/Modal";
import { AccessTime, Cancel, Check, EditOutlined, WebAssetOff, InfoOutlined, AutoAwesome, WarningAmber } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { dsVariables } from "../../../variables";
import { getThreatTypeText } from "../../../../DesignSystem/utils/get-threat-type-text.util";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import { ActionEnum } from "../../../constants/user-action-permissions.constant";
import useIsEnabledOrTrial from "../../../hooks/IsEnabledOrTrial.hook";
import Body from "../../../components/Text/Body";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import Tag from "../../../components/Tag";
import Tabs, { ITabs } from "../../../components/Tabs";
import { useState, useEffect } from "react";
import Caption from "../../../components/Text/Caption";
import Sub5 from "../../../components/Text/Sub5";
import Sub6 from "../../../components/Text/Sub6";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import Pin from "../../../components/Tooltip/Pin";
// import { Info } from "feather-icons-react/build/IconComponents";
import { UncontrolledTooltip } from "reactstrap";
import ButtonSecondary from "../../../components/Button/ButtonSecondary";

interface IProps {
    isOpen: boolean;
    onClose: any;
    tabActive: number;
    ModalDiscard: () => void;
    ModalEdit: () => void;
    ModalQuarentine: () => void;
    ModalApproval: () => void;
    ModalCancelApproval: () => void;
    ModalTakedown: () => void;
    ModalRestore: () => void;
    ModalResolved: () => void;
}

function getAccordionBorderType(array: any[], index: number): 'top' | 'bottom' | 'top-bottom' | undefined {
    if (array.length === 1 || array.length === 0) return undefined;
    if (array.length === 2) return index === 0 ? 'bottom' : 'top';

    if (index === 0) return 'bottom';
    if (index === array.length - 1) return 'top';
    return 'top-bottom';
}

const FilesCard = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
`;

const ThermometerBar = styled.div`
    width: 100%;
    height: 20px;
    background: linear-gradient(90deg, #7D9EF0 0%, #FF9494 100%);
    border-radius: ${dsVariables.cornerRadius[4]};
    position: relative;
    margin-top: ${dsVariables.spacing[8]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${dsVariables.spacing[8]};
`;

const ThermometerContainer = styled.div`
    padding: ${dsVariables.spacing[4]} ${dsVariables.spacing[8]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
`;

const Indicator = styled.div`
    position: absolute;
    transform: translateX(-50%);
    background: ${dsVariables.colors.BlueStone[1000]};
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -8px;
`;

const DecisionContainer = styled.div`
    background: ${dsVariables.colors.Gray[50]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
    border-radius: ${dsVariables.cornerRadius[4]};
    padding: ${dsVariables.spacing[16]};
`;

const Divider = styled.hr`
    width: 100%;
    height: 0;
    border: none;
    border-top: 1px solid ${dsVariables.colors.Gray[200]};
    margin: ${dsVariables.spacing[16]} 0;
`;

export default function ModalDetailsThreat({
    isOpen,
    onClose,
    tabActive,
    ModalDiscard,
    ModalResolved,
    ModalEdit,
    ModalQuarentine,
    ModalApproval,
    ModalCancelApproval,
    ModalTakedown,
    ModalRestore
}: IProps) {
    const [activeTab, setActiveTab] = useState(0);

    const { threatsTableData, current, isCustomer } = useSelector((store: IStore) => ({
        threatsTableData: store.Threats.threatsTableData,
        current: store.Threats.current,
        isCustomer: store.Profile.user.isCustomer,
    }))

    const hasAIAnalysis = () => current?.AIAnalysis?.takedownDecision.decision

    const tabs: ITabs[] = [
        {
            label: 'Análise IA Branddi',
            onClick: () => { setActiveTab(0) },
            tooltip: hasAIAnalysis() ? '' : 'Análise da IA não disponível',
            disabled: !hasAIAnalysis()
        },
        {
            label: 'Sobre',
            onClick: () => {
                {
                    setActiveTab(1)
                    setShowAIDetails(false)
                }
            }
        }
    ];

    const showApproveTakedown = useCanPerformAction(ActionEnum.approve_takedown)
    const showDiscardThreat = useCanPerformAction(ActionEnum.discard_threat)
    const showQuarantine = useCanPerformAction(ActionEnum.move_threat_to_quarantine)
    const showResolved = useCanPerformAction(ActionEnum.mark_threat_as_resolved)
    const showRestore = useCanPerformAction(ActionEnum.restore_threat_from_discarded)
    const showEdit = useCanPerformAction(ActionEnum.edit_threat)
    const showCancelApproval = useCanPerformAction(ActionEnum.cancel_approval_request)
    const showRequestTakedown = useCanPerformAction(ActionEnum.request_takedown_approval)

    const [ShowAIDetails, setShowAIDetails] = useState(false);

    const getFooterButtons = (): IFooterButton[] => {
        const actions: IFooterButton[] = []
        if (tabActive == 4 || tabActive == 5) return actions

        if (isCustomer) {
            if (tabActive != 1 && showQuarantine) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalQuarentine, label: 'Quarentena', iconLeft: WarningAmberOutlinedIcon, iconLeftColor: dsVariables.colors.Astronaut[900] })
            }
            if (tabActive != 3 && showDiscardThreat) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalDiscard, label: 'Descartar', iconLeft: DeleteOutlinedIcon, iconLeftColor: dsVariables.colors.Red[400], type: 'destructiveBorderless' })
            }
            if (tabActive === 2 && showApproveTakedown) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalTakedown, label: 'Aprovar Takedown', iconLeft: WebAssetOff, iconLeftColor: 'white' })
            }
            if (tabActive != 2 && tabActive != 4 && showRequestTakedown) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalTakedown, label: 'Solicitar Takedown', iconLeft: WebAssetOff, iconLeftColor: 'white' })
            }
        } else {
            if ((tabActive == 0 || !tabActive) && showQuarantine) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalQuarentine, label: 'Quarentena', iconLeft: AccessTime, iconLeftColor: dsVariables.colors.Astronaut[900], type: 'normal' })
            }
            if ((tabActive == 0 || !tabActive || tabActive == 1) && showRequestTakedown) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalTakedown, label: 'Solicitar Aprovaçao', iconLeft: WebAssetOff, iconLeftColor: 'white' })
            }
            if (tabActive === 2 && showApproveTakedown) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalApproval, label: 'Aprovar takedown', iconLeft: WebAssetOff, iconLeftColor: 'white' })
            }
            if (tabActive == 2 && showCancelApproval) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalCancelApproval, label: 'Cancelar solicitação de aprovação', iconLeft: Cancel, iconLeftColor: dsVariables.colors.Astronaut[900], type: 'normal' })
            }
            if (tabActive != 3 && showDiscardThreat) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalDiscard, label: 'Descartar', iconLeft: DeleteOutlinedIcon, iconLeftColor: dsVariables.colors.Red[400], type: 'destructiveBorderless' })
            }
            if (tabActive == 3 && showRestore) {
                actions.push({ buttonStyle: 'tertiary', onClick: ModalRestore, label: 'Restaurar', iconLeft: HistoryOutlinedIcon, iconLeftColor: 'white' })
            }
            if (tabActive == 4 && showResolved) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalResolved, label: 'Marcar como resolvida', iconLeft: Check, iconLeftColor: dsVariables.colors.Astronaut[900], type: 'normal' })
            }
            if ((tabActive == 0 || !tabActive || tabActive == 1 || tabActive == 4) && showEdit) {
                actions.push({ buttonStyle: 'secondary', onClick: ModalEdit, label: 'Editar', iconLeft: EditOutlined, iconLeftColor: dsVariables.colors.Astronaut[900], type: 'normal' })
            }

        }
        return actions;
    };


    const renderAIAnalysis = () => {
        const pinPosition = (value: number) => {
            if (value <= 0) return '8px';
            if (value >= 100) return 'calc(100% - 8px)';
            return `${value}%`;
        };

        const capitalizeFirstLetter = (string: string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        return (
            <div className="d-flex flex-column gap-3">
                <DecisionContainer>
                    <div className="d-flex align-items-start gap-3">
                        <BoxAction>
                            <Caption
                                style="medium"
                                color={dsVariables.colors.Cyan[700]}
                            >
                                Ação recomendada
                            </Caption>

                            <Sub5
                                style="medium"
                                color={dsVariables.colors.BlueStone[1000]}
                            >
                                {capitalizeFirstLetter(current?.AIAnalysis?.takedownDecision?.decision || "quarentena")}
                            </Sub5>

                            <div className="d-flex align-items-center gap-1">
                                <Caption
                                    style="medium"
                                    color={dsVariables.colors.Cyan[600]}
                                >
                                    Última análise:{' '}
                                    {current?.AIAnalysis?.lastAnalysis ? (
                                        <span style={{ fontWeight: 400 }}>
                                            {new Date(current?.AIAnalysis?.lastAnalysis).toLocaleString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}
                                        </span>
                                    ) : (
                                        <span style={{ fontWeight: 400 }}>—</span>
                                    )}
                                </Caption>
                            </div>
                        </BoxAction>
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-center align-items-center gap-2">
                                <Label style="medium">Índice de Probabilidade de Fraude</Label>
                                <InfoOutlined
                                    id="IPFTooltip"
                                    sx={{
                                        fontSize: 16,
                                        color: dsVariables.colors.Cyan[900]
                                    }}
                                />
                                <UncontrolledTooltip
                                    placement="top"
                                    target="IPFTooltip"
                                    className="text-left"
                                    container="body"
                                    style={{ zIndex: 99999999 }}
                                >
                                    O índice representa a probabilidade de uma ameaça ser fraudulenta.
                                    Um valor de 100 indica alta probabilidade de fraude, enquanto 0 indica baixa probabilidade.
                                </UncontrolledTooltip>
                            </div>
                            <ThermometerBar>
                                <Caption
                                    style="medium"
                                    color="#FFFFFF"
                                >
                                    0
                                </Caption>
                                <Pin
                                    style={{
                                        left: pinPosition(current?.AIAnalysis?.takedownDecision?.fraudIndex || 0)
                                    }}
                                    value={current?.AIAnalysis?.takedownDecision?.fraudIndex || 0}
                                />
                                <Caption
                                    style="medium"
                                    color="#FFFFFF"
                                >
                                    100
                                </Caption>
                            </ThermometerBar>
                        </div>
                    </div>

                    <Divider />

                    <div className="position-relative pb-5">
                        <ReasonsBox>
                            <div className="d-flex align-items-center gap-2">
                                <GppMaybeIcon
                                    sx={{
                                        fontSize: 24,
                                        color: dsVariables.colors.Cyan[900]
                                    }}
                                />
                                <Sub6
                                    style="medium"
                                    color={dsVariables.colors.Cyan[900]}
                                >
                                    Principais motivos
                                </Sub6>
                            </div>
                            <ReasonsList>
                                {current?.AIAnalysis?.takedownDecision?.reasoning?.split(';').map((reason: string, index: number) => (
                                    <li key={index}>
                                        <Body
                                            style="regular"
                                            color={dsVariables.colors.Cyan[900]}
                                        >
                                            {reason.trim()}
                                        </Body>
                                    </li>
                                ))}
                            </ReasonsList>
                        </ReasonsBox>
                        {!ShowAIDetails &&
                            <>
                                <div className="position-absolute top-0 start-0 w-100 h-100" style={{ background: 'linear-gradient(180deg, rgba(239, 250, 255, 0.20) 0%, #DEF3FF 59.83%)' }}></div>
                                <div className="position-absolute bottom-0 w-100 d-flex justify-content-center mb-4">
                                    <ButtonSecondary onClick={() => setShowAIDetails(true)} iconLeftColor={dsVariables.colors.Astronaut[900]} label="Ver detalhes" iconLeft={AutoAwesome} />
                                </div>
                            </>
                        }

                        {
                            ShowAIDetails && <div className="ms-2 mt-4 d-flex flex-column gap-1">
                                <Sub6 color={dsVariables.colors.Cyan[1000]} style="medium">Análise do conteúdo</Sub6>

                                <div className="d-flex  mt-2">
                                    <div className="" style={{ width: 180 }}>
                                        <Body style="medium" color={dsVariables.colors.Cyan[900]}>Marcas relacionadas:</Body>
                                    </div>
                                    <Body color={dsVariables.colors.Cyan[900]}>{current?.AIAnalysis?.contentAnalysis?.brandRelateds}</Body>
                                </div>
                                <div className="d-flex ">
                                    <div className="" style={{ width: 180 }}>
                                        <Body style="medium" color={dsVariables.colors.Cyan[900]}>Marca do site</Body>
                                    </div>
                                    <Body color={dsVariables.colors.Cyan[900]}>{current?.AIAnalysis?.contentAnalysis?.siteBrand}</Body>
                                </div>
                                <div className="d-flex ">
                                    <div className="" style={{ width: 180 }}>
                                        <Body style="medium" color={dsVariables.colors.Cyan[900]}>Site em construção:</Body>
                                    </div>
                                    <Body color={dsVariables.colors.Cyan[900]}>{current?.AIAnalysis?.takedownDecision?.constructionSite ? 'Sim' : 'Não'}</Body>
                                </div>
                                <div className="d-flex ">
                                    <div className="" style={{ width: 180 }}>
                                        <Body style="medium" color={dsVariables.colors.Cyan[900]}>Categoria do site:</Body>
                                    </div>
                                    <Body color={dsVariables.colors.Cyan[900]}>{current?.AIAnalysis?.contentAnalysis?.siteCategory}</Body>
                                </div>
                                <div className="d-flex ">
                                    <div className="" style={{ width: 180 }}>
                                        <Body style="medium" color={dsVariables.colors.Cyan[900]}>Conteúdo do site:</Body>
                                    </div>
                                    <Body color={dsVariables.colors.Cyan[900]}>{current?.AIAnalysis?.contentAnalysis?.siteContentActive}</Body>
                                </div>
                                <div className="d-flex ">
                                    <div className="" style={{ width: 180 }}>
                                        <Body style="medium" color={dsVariables.colors.Cyan[900]}>Moeda do site:</Body>
                                    </div>
                                    <Body color={dsVariables.colors.Cyan[900]}>{current?.AIAnalysis?.contentAnalysis?.siteCurrency}</Body>
                                </div>
                                <div className="d-flex ">
                                    <div className="" style={{ width: 180 }}>
                                        <Body style="medium" color={dsVariables.colors.Cyan[900]}>Descrição do site:</Body>
                                    </div>
                                    <Body color={dsVariables.colors.Cyan[900]}>{current?.AIAnalysis?.contentAnalysis?.siteDescription}</Body>
                                </div>
                                <div className="d-flex ">
                                    <div className="" style={{ width: 180 }}>
                                        <Body style="medium" color={dsVariables.colors.Cyan[900]}>Idioma do site:</Body>
                                    </div>
                                    <Body color={dsVariables.colors.Cyan[900]}>{current?.AIAnalysis?.contentAnalysis?.siteLanguage}</Body>
                                </div>
                            </div>
                        }

                    </div>
                </DecisionContainer>
                <div className="d-flex gap-3 align-items-center" style={{
                    borderRadius: 4,
                    padding: 16,
                    border: '1px solid var(--Snackbar-Warning-Stroke, #FFEB85)',
                    background: 'var(--Snackbar-Warning-Fill-BG, #FFF5C5)'
                }}>
                    <Icon IconComponent={WarningAmber} color="#984C18" />
                    <Label color="#984C18" style="medium">Atenção - A análise da IA da Branddi da ameaça, monitora e realiza movimentações automaticamente.</Label>
                </div>
            </div>
        );
    };

    const renderAboutContent = () => (
        // Conteúdo atual do modal
        <div className="d-flex gap-3 mt-3">
            <div className="d-flex flex-column gap-2 rounded border border-gray p-3">
                <div style={{ display: "flex", justifyContent: "flex-start", }}>
                    <div style={{ width: 180 }}><Body style="medium">Tipo de Ameaça:&nbsp;</Body></div>
                    <Body>{getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE)}</Body>
                </div>
                {getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE) === "Site falso" ? "" :
                    <div style={{ display: "flex", justifyContent: "flex-start", }}>
                        <div style={{ width: 180 }}><Body style="medium">Plataforma:&nbsp;</Body></div>
                        <Body>{current?.sourceThreat.platform}</Body>
                    </div>
                }
                <div style={{ display: "flex", justifyContent: "flex-start", }}>
                    <div style={{ width: 180 }}><Body style="medium">Status:&nbsp;</Body></div>
                    <Body>{current?.sourceThreat.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE}</Body>
                </div>
                {getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE) === "Anúncio falso" ?
                    <div style={{ display: "flex", justifyContent: "flex-start", }}>
                        <div style={{ width: 180 }}><Body style="medium">Veiculação iniciada em:&nbsp;</Body></div>
                        <Body>—</Body>
                    </div>
                    : ""}
                {getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE) === "Site falso" || getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE) === "Conteúdo falso" ? "" :
                    <div style={{ display: "flex", justifyContent: "flex-start", }}>
                        <div style={{ width: 180 }}><Body style="medium">Data da criação:&nbsp;</Body></div>
                        <Body>{new Date(current?.createdAt || new Date()).toLocaleDateString("pt-BR")}</Body>
                    </div>
                }
                <div style={{ display: "flex", justifyContent: "flex-start", }}>
                    <div style={{ width: 180 }}><Body style="medium">Data de identificação:&nbsp;</Body></div>
                    <Body>{new Date(current?.createdAt || new Date()).toLocaleDateString("pt-BR")}</Body>
                </div>
                {getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE) === "Anúncio falso" ?
                    <div style={{ display: "flex", justifyContent: "flex-start", }}>
                        <div style={{ width: 180 }}><Body style="medium">ID do anúncio:&nbsp;</Body></div>
                        <Body>{current?.sourceThreat.advertiserId ?? "—"}</Body>
                    </div>
                    : ""}
                {getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE) === "Site falso" ? "" :
                    <div style={{ display: "flex", justifyContent: "flex-start", }}>
                        <div style={{ width: 180 }}><Body style="medium">Perfil:&nbsp;</Body></div>
                        <Body>{current?.sourceThreat.profile}</Body>
                    </div>
                }
            </div>

            <div className="d-flex flex-column flex-grow-1">
                {/* accordeon de anúncio falso */}
                {getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE) === "Anúncio falso" ?
                    <Accordion withBorder title="Informação do Anunciante">
                        <div style={{ display: "flex", justifyContent: "flex-start", }}>
                            <div style={{ width: 180 }}><Body style="medium">Perfil Anunciante:&nbsp;</Body></div>
                            <Body>{current?.sourceThreat.advertiserName ?? "—"}</Body>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start", }}>
                            <div style={{ width: 180 }}><Body style="medium">ID Perfil Anunciante:&nbsp;</Body></div>
                            <Body>{current?.sourceThreat.advertiserId ?? "—"}</Body>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start", }}>
                            <div style={{ width: 180 }}><Body style="medium">Perfil Instagram:&nbsp;</Body></div>
                            <Body>{current?.sourceThreat.instagramProfile ?? "—"}</Body>
                        </div>
                    </Accordion>
                    : ""}

                {/* accordeon de site falso */}

                {getThreatTypeText(current?.type || ThreatTypeEnum.FAKE_SITE) === "Site falso" ?
                    <>
                        <Accordion withBorder title="Informações do Domínio">
                            <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                <div style={{ width: 180 }}><Body style="medium">Domain Name:&nbsp;</Body></div>
                                <Body>{current?.sourceThreat?.domainDatabase?.domainName}</Body>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                <div style={{ width: 180 }}><Body style="medium">Register Date UTC:&nbsp;</Body></div>
                                <Body>
                                    {current?.sourceThreat?.domainDatabase?.createDdateInTime ? new Date(current?.sourceThreat?.domainDatabase?.createDdateInTime).toLocaleDateString("pt-BR") : "—"}
                                </Body>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                <div style={{ width: 180 }}><Body style="medium">Register Domain ID:&nbsp;</Body></div>
                                <Body>{current?.sourceThreat?.domainDatabase?.ticket ?? "—"}</Body>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                <div style={{ width: 180 }}><Body style="medium">Status:&nbsp;</Body></div>
                                <Body>{current?.sourceThreat?.domainDatabase?.status?.length > 0 ?
                                    current?.sourceThreat?.domainDatabase?.status.map((status: any, index: any) => (
                                        <>
                                            <span key={index}>{status}</span><br />
                                        </>
                                    )) : "—"
                                }
                                </Body>
                            </div>
                        </Accordion>
                        <Accordion withBorder title="Informações do Registrar">
                            <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                <div style={{ width: 180 }}><Body style="medium">Net Range:&nbsp;</Body></div>
                                <Body>{current?.sourceThreat?.registrarDatabase?.netRange ?? "—"}</Body>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                <div style={{ width: 180 }}><Body style="medium">Net Name:&nbsp;</Body></div>
                                <Body>{current?.sourceThreat?.registrarDatabase?.netName ?? "—"}</Body>
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", }}>
                                <div style={{ width: 180 }}><Body style="medium">Abuse Contact Email:&nbsp;</Body></div>
                                <Body>{current?.sourceThreat?.registrarDatabase?.abuseEmail ?? "—"}</Body>
                            </div>
                        </Accordion>
                        <Accordion withBorder title="Hospedagens por ocorrência">
                            {current?.occurrence?.map((occurrence: any, index: number) =>
                                <Accordion key={index}
                                    isChild
                                    borderType={getAccordionBorderType(current?.occurrence, index)}
                                    withBorder
                                    title={`${index + 1}. ${occurrence.url}`}
                                    headerLabels={occurrence.labels}
                                >
                                    {occurrence?.hostDatabase && occurrence?.hostDatabase?.map((el: any, index: number) => <div key={index} className="d-flex flex-column gap-2 p-3 border border-gray border-2 position-relative">
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Net Range:</Label>
                                            <Label>{el?.netRange ?? '-'}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Net Name:</Label>
                                            <Label>{el?.netName ?? '-'}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Abuse Contact Email:</Label>
                                            <Label>{el?.abuseEmail ?? '-'}</Label>
                                        </div>
                                    </div>)}
                                    {(!occurrence?.hostDatabase || occurrence?.hostDatabase?.length == 0) && 'Hospedagem não encontrada'}
                                </Accordion>
                            )}
                        </Accordion>
                    </>
                    : ""}

                {/* accordeon de site falso */}

                <Accordion withBorder title="Ocorrências">
                    <div className="d-flex flex-column">
                        {
                            current?.occurrence?.map((occurrence: any, index: number) =>
                                <Accordion withBorder key={index}
                                    isChild
                                    borderType={getAccordionBorderType(current?.occurrence, index)}
                                    title={`${index + 1}. ${occurrence.url}`}
                                    headerLabels={(occurrence.labels)?.concat([{label:new Date(occurrence?.createdAt).toLocaleDateString('pt-BR')}])}
                                >
                                    <div key={index} className="d-flex flex-column gap-2">
                                        <div className="d-flex gap-4">
                                            <Label>Evidência(s):</Label>
                                            <div className="flex-grow-1 gap-3 d-flex flex-column">{occurrence.evidence?.link
                                                ? occurrence.evidence?.link
                                                : occurrence.evidence?.files?.map((file: string, index: number) => {
                                                    return (
                                                        <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                            <div className="d-flex gap-2 align-items-center">
                                                                {/* @ts-ignore */}
                                                                <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                                <div className="d-flex flex-column gap-1">
                                                                    <Label>{`Evidência ${++index}`}</Label>
                                                                </div>
                                                            </div>
                                                            <button className="border-0 bg-transparent">
                                                                <a href={file} target="blank" >
                                                                    <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                                </a>
                                                            </button>
                                                        </FilesCard>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Accordion>)
                        }
                        {
                            current?.pixOccurrences?.map((pixOccurrence: any, index: number) =>
                                <Accordion withBorder key={index}
                                    isChild
                                    borderType={getAccordionBorderType(current?.pixOccurrences!, index)}
                                    title={`${current?.occurrence?.length + (index + 1)}. ${pixOccurrence.pix}`}>
                                    <div key={index} className="d-flex flex-column gap-2">
                                        <div className="d-flex gap-4">
                                            <div className="w-50">
                                                <Label style="medium">CPF:</Label>
                                            </div>
                                            <Label>{pixOccurrence.cpf}</Label>
                                        </div>

                                        <div className="d-flex gap-4">
                                            <div className="w-50">
                                                <Label style="medium">Instituição financeira:</Label>
                                            </div>
                                            <Label>{pixOccurrence.financialInstitution}</Label>
                                        </div>

                                        <div className="d-flex gap-4">
                                            <div className="w-50">
                                                <Label style="medium">Url de checkout:</Label>
                                            </div>
                                            <Label>{pixOccurrence.checkoutUrl}</Label>
                                        </div>

                                        <div className="d-flex gap-4">
                                            <div className="w-50">
                                                <Label style="medium">Gateway de pagamento:</Label>
                                            </div>
                                            <Label>{pixOccurrence.paymentGateway}</Label>
                                        </div>

                                        <div className="d-flex gap-4">
                                            <div className="w-50">
                                                <Label style="medium">Evidência(s):</Label>
                                            </div>
                                            <div className="flex-grow-1 gap-3 d-flex flex-column">{pixOccurrence.evidence?.link
                                                ? pixOccurrence.evidence?.link
                                                : pixOccurrence.evidence?.files?.map((file: string, index: number) => {
                                                    return (
                                                        <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                            <div className="d-flex gap-2 align-items-center">
                                                                {/* @ts-ignore */}
                                                                <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                                <div className="d-flex flex-column gap-1">
                                                                    <Label>{`Evidência ${++index}`}</Label>
                                                                </div>
                                                            </div>
                                                            <button className="border-0 bg-transparent">
                                                                <a href={file} target="blank" >
                                                                    <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                                </a>
                                                            </button>
                                                        </FilesCard>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </Accordion>
                            )
                        }
                    </div>

                </Accordion>
            </div>
        </div>
    );

    const renderSubtitle = () => (
        <div className="d-flex align-items-center gap-2">
            <Label
                style="medium"
                color={dsVariables.colors.Blue[500]}
            >
                <u>{current?.sourceThreat?.searchText}</u>
            </Label>

            {current?.labels?.concat(current?.labelsOccurrences || current?.occurrences)?.map((label: any, index: number) => (
                <Tag
                    key={index}
                    label={label.label || label}
                    backgroundColor={label.backgroundColor || dsVariables.colors.Gray[100]}
                    textColor={label.textColor || dsVariables.colors.Gray[700]}
                />
            ))}
        </div>
    );

    useEffect(() => {
        if (!hasAIAnalysis()) setActiveTab(1)
    }, [, current]);

    return (
        <Modal
            title="Detalhes da ameaça"
            subtitle={renderSubtitle()}
            isOpen={isOpen}
            onClose={() => {
                onClose()
                setShowAIDetails(false)
                setActiveTab(0)
            }}
            width={980}
            height={540}
            footerButtons={getFooterButtons()}
        >
            <Tabs
                tabs={tabs}
                indexActive={activeTab}
            />

            <div className="mt-4">
                {activeTab === 0 ? renderAIAnalysis() : renderAboutContent()}
            </div>
        </Modal>
    );
}

const ReasonsBox = styled.div`
background: ${dsVariables.colors.Cyan[0]};
border: 1px solid ${dsVariables.colors.Cyan[200]};
border-radius: ${dsVariables.cornerRadius[4]};
padding: ${dsVariables.spacing[16]};
`;

const BoxAction = styled.div`
width: 228px;
height: 110px;
padding: ${dsVariables.spacing[16]};
gap: ${dsVariables.spacing[8]};
border-radius: ${dsVariables.cornerRadius[4]};
background: ${dsVariables.colors.Cyan[0]};
border: 1px solid ${dsVariables.colors.Cyan[200]};
box-shadow: 0px 0px ${dsVariables.cornerRadius[4]} ${dsVariables.colors.Gray[200]};

display: flex;
flex-direction: column;
align-items: center;
text-align: center;
`;

const ReasonsList = styled.ul`
    list-style-type: none;
    padding-left: 0;
    margin-top: ${dsVariables.spacing[8]};

    li {
        position: relative;
        padding-left: 20px;
        margin-bottom: ${dsVariables.spacing[8]};
        font-family: Inter;
        font-size: 16px;
        line-height: 19.2px;

        &:before {
            content: "•";
            position: absolute;
            left: 0;
            color: ${dsVariables.colors.Cyan[900]};
        }
    }
`;
