export enum ActionTypesEnum {
    SUBMIT_FORM = "SUBMIT_FORM",
    SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS",
    SUBMIT_FORM_ERROR = "SUBMIT_FORM_ERROR",

    CHANGE_SITE_FORM_TYPE='CHANGE_SITE_FORM_TYPE',
    CHANGE_SITE_FORM_STATUS='CHANGE_SITE_FORM_STATUS',
    CHANGE_SITE_FORM_URL='CHANGE_SITE_FORM_URL',
    CHANGE_SITE_FORM_SEARCH_DATE='CHANGE_SITE_FORM_SEARCH_DATE',
    CHANGE_SITE_FORM_EVIDENCE_LINK='CHANGE_SITE_FORM_EVIDENCE_LINK',
    CHANGE_SITE_FORM_DOMAIN='CHANGE_SITE_FORM_DOMAIN',
    CHANGE_SITE_FORM_EVIDENCE_FILES='CHANGE_SITE_FORM_EVIDENCE_FILES',
    CHANGE_SITE_FORM_IS_ACTIVE='CHANGE_SITE_FORM_IS_ACTIVE',
    CHANGE_SITE_FORM='CHANGE_SITE_FORM',

    CHANGE_PROFILE_FORM_TYPE='CHANGE_PROFILE_FORM_TYPE',
    CHANGE_PROFILE_FORM_STATUS='CHANGE_PROFILE_FORM_STATUS',
    CHANGE_PROFILE_FORM_URL='CHANGE_PROFILE_FORM_URL',
    CHANGE_PROFILE_FORM_SEARCH_DATE='CHANGE_PROFILE_FORM_SEARCH_DATE',
    CHANGE_PROFILE_FORM_PROFILE='CHANGE_PROFILE_FORM_PROFILE',
    CHANGE_PROFILE_FORM_PLATFORM='CHANGE_PROFILE_FORM_PLATFORM',
    CHANGE_PROFILE_FORM_EVIDENCE_LINK='CHANGE_PROFILE_FORM_EVIDENCE_LINK',
    CHANGE_PROFILE_FORM_EVIDENCE_FILES='CHANGE_PROFILE_FORM_EVIDENCE_FILES',
    CHANGE_PROFILE_FORM_IS_ACTIVE='CHANGE_PROFILE_FORM_IS_ACTIVE',
    CHANGE_PROFILE_FORM='CHANGE_PROFILE_FORM',

    CHANGE_CONTENT_FORM_TYPE='CHANGE_CONTENT_FORM_TYPE',
    CHANGE_CONTENT_FORM_STATUS='CHANGE_CONTENT_FORM_STATUS',
    CHANGE_CONTENT_FORM_URL='CHANGE_CONTENT_FORM_URL',
    CHANGE_CONTENT_FORM_CONTENT='CHANGE_CONTENT_FORM_CONTENT',
    CHANGE_CONTENT_FORM_PLATFORM='CHANGE_CONTENT_FORM_PLATFORM',
    CHANGE_CONTENT_FORM_EVIDENCE_LINK='CHANGE_CONTENT_FORM_EVIDENCE_LINK',
    CHANGE_CONTENT_FORM_EVIDENCE_FILES='CHANGE_CONTENT_FORM_EVIDENCE_FILES',
    CHANGE_CONTENT_FORM_IS_ACTIVE='CHANGE_CONTENT_FORM_IS_ACTIVE',
    CHANGE_CONTENT_FORM='CHANGE_CONTENT_FORM',

    CHANGE_ADS_FORM_TYPE='CHANGE_ADS_FORM_TYPE',
    CHANGE_ADS_FORM_STATUS='CHANGE_ADS_FORM_STATUS',
    CHANGE_ADS_FORM_START_DATE='CHANGE_ADS_FORM_START_DATE',
    CHANGE_ADS_FORM_SEARCH_DATE='CHANGE_ADS_FORM_SEARCH_DATE',
    CHANGE_ADS_FORM_ID='CHANGE_ADS_FORM_ID',
    CHANGE_ADS_FORM_PLATFORM='CHANGE_ADS_FORM_PLATFORM',
    CHANGE_ADS_FORM_URL='CHANGE_ADS_FORM_URL',
    CHANGE_ADS_FORM_INSTAGRAM_PROFILE='CHANGE_ADS_FORM_INSTAGRAM_PROFILE',
    CHANGE_ADS_FORM_ADVERTISER_NAME='CHANGE_ADS_FORM_ADVERTISER_NAME',
    CHANGE_ADS_FORM_ADVERTISER_ID='CHANGE_ADS_FORM_ADVERTISER_ID',
    CHANGE_ADS_FORM_EVIDENCE_LINK='CHANGE_ADS_FORM_EVIDENCE_LINK',
    CHANGE_ADS_FORM_EVIDENCE_FILES='CHANGE_ADS_FORM_EVIDENCE_FILES',
    CHANGE_ADS_FORM_IS_ACTIVE='CHANGE_ADS_FORM_IS_ACTIVE',
    CHANGE_ADS_FORM='CHANGE_ADS_FORM',

    CHANGE_FILTER_TEXT = "CHANGE_FILTER_TEXT",
    CHANGE_FILTER_TYPE = "CHANGE_FILTER_TYPE",
    CHANGE_FILTER_PLATFORM = "CHANGE_FILTER_PLATFORM",
    CHANGE_STATUS_PLATFORM = "CHANGE_STATUS_PLATFORM",
    CHANGE_FILTER_IPF = "CHANGE_FILTER_IPF",
    CHANGE_FILTER_DATE_RANGE_START = "CHANGE_FILTER_DATE_RANGE_START",
    CHANGE_FILTER_DATE_RANGE_END = "CHANGE_FILTER_DATE_RANGE_END",
    CHANGE_FILTER_DAYS = "CHANGE_FILTER_DAYS",

    CHANGE_LIST = "CHANGE_LIST",
    CHANGE_TABLE = "CHANGE_TABLE",
    CHANGE_CURRENT_IDS = "CHANGE_CURRENT_IDS",
    CHANGE_CURRENT = "CHANGE_CURRENT",

    FETCH_FRAUDS = "FETCH_FRAUDS",

    GET_FRAUDS_BY_COMPANY= "GET_FRAUDS_BY_COMPANY",
    REGISTER_FRAUD = "REGISTER_FRAUD",
    REGISTER_FRAUD_FAILURE = "REGISTER_FRAUD_FAILURE",
    UPDATE_FRAUD = "UPDATE_FRAUD",
    RESTORE_FRAUD = "RESTORE_FRAUD",
    UPDATE_MULTIPLE_FRAUDS = "UPDATE_MULTIPLE_FRAUDS",
    RESTORE_MULTIPLE_FRAUDS = "RESTORE_MULTIPLE_FRAUDS",

    ADD_FRAUD = "ADD_FRAUD",
    UPDATE_FRAUD_FROM_SAGA = "UPDATE_FRAUD_FROM_SAGA",
    UPDATE_MULTIPLE_FRAUDS_FROM_SAGA = "UPDATE_MULTIPLE_FRAUDS_FROM_SAGA",

    CLEAN_FORMS = "CLEAN_FORMS",

    IS_LOADING = "IS_LOADING",

    CHANGE_LAST_LIST = "CHANGE_LAST_LIST",

    CHANGE_FRAUDS_QUANTITY_BY_STATE= "CHANGE_FRAUDS_QUANTITY_BY_STATE",
    CHANGE_FRAUDS_FILTERED_QUANTITY_BY_STATE= "CHANGE_FRAUDS_FILTERED_QUANTITY_BY_STATE",
    CHANGE_FRAUDS_TOTAL_PAGES_BY_STATE= "CHANGE_FRAUDS_TOTAL_PAGES_BY_STATE",

    CHANGE_FORM_ERROR= "CHANGE_FORM_ERROR",

    GET_TAKEDOWNS= "GET_TAKEDOWNS",
    CHANGE_TAKEDOWN_LIST = "CHANGE_TAKEDOWN_LIST",
    CHANGE_TABLE_TAKEDOWNS = "CHANGE_TABLE_TAKEDOWNS",
    CHANGE_CURRENT_IDS_TAKEDOWNS = "CHANGE_CURRENT_IDS_TAKEDOWNS",
    CHANGE_CURRENT_TAKEDOWN = "CHANGE_CURRENT_TAKEDOWN",
    UPDATE_TAKEDOWN = "UPDATE_TAKEDOWN",
    CHANGE_TAKEDOWNS_FILTERED_QUANTITY_BY_STATE = "CHANGE_TAKEDOWNS_FILTERED_QUANTITY_BY_STATE",
    CHANGE_TAKEDOWNS_FILTER = "CHANGE_TAKEDOWNS_FILTER",
    CHANGE_TAKEDOWNS_QUANTITY_BY_PHASE = "CHANGE_TAKEDOWNS_QUANTITY_BY_PHASE",
    CHANGE_TAKEDOWNS_FILTERED_QUANTITY_BY_PHASE = "CHANGE_TAKEDOWNS_FILTERED_QUANTITY_BY_PHASE",
    CHANGE_TANKEDOWNS_TOTAL_PAGES_BY_PHASE = "CHANGE_TANKEDOWNS_TOTAL_PAGES_BY_PHASE",
    UPDATE_TAKEDOWN_FROM_SAGA = "UPDATE_TAKEDOWN_FROM_SAGA",
    CHANGE_TAKEDOWNS_FROM_SAGA = "CHANGE_TAKEDOWNS_FROM_SAGA",
    CHANGE_FILTER_TEXT_TAKEDOWN = "CHANGE_FILTER_TEXT_TAKEDOWN",
    CHANGE_FILTER_COMPANY_ID_TAKEDOWN = "CHANGE_FILTER_COMPANY_ID_TAKEDOWN",
    CHANGE_FILTER_TYPE_TAKEDOWN = "CHANGE_FILTER_TYPE_TAKEDOWN",
    CHANGE_FILTER_PLATFORM_TAKEDOWN = "CHANGE_FILTER_PLATFORM_TAKEDOWN",
    CHANGE_FILTER_STATUS_TAKEDOWN = "CHANGE_FILTER_STATUS_TAKEDOWN",
    CHANGE_TAKEDOWN_FILTER_DATE_RANGE_START = "CHANGE_TAKEDOWN_FILTER_DATE_RANGE_START",
    CHANGE_TAKEDOWN_FILTER_DATE_RANGE_END = "CHANGE_TAKEDOWN_FILTER_DATE_RANGE_END",
    CHANGE_TAKEDOWNS_FILTER_DAYS = "CHANGE_TAKEDOWNS_FILTER_DAYS",
    CHANGE_FILTER_SLA_TAKEDOWN = "CHANGE_FILTER_SLA_TAKEDOWN",
    CHANGE_FILTER_IDENTIFIED_TAKEDOWN = "CHANGE_FILTER_IDENTIFIED_TAKEDOWN",
    CHANGE_FILTER_TIME_PHASE_TAKEDOWN = "CHANGE_FILTER_TIME_PHASE_TAKEDOWN",
    CHANGE_FILTER_IDENTIFIED_HOURS_TAKEDOWN = "CHANGE_FILTER_IDENTIFIED_HOURS_TAKEDOWN",
    CHANGE_FILTER_TIME_PHASE_HOURS_TAKEDOWN = "CHANGE_FILTER_TIME_PHASE_HOURS_TAKEDOWN",
    CHANGE_FILTER_NOTIFICATIONS_TAKEDOWN = "CHANGE_FILTER_NOTIFICATIONS_TAKEDOWN",

    CHANGE_COMPLAINT_FORM_CHANNEL= "CHANGE_COMPLAINT_FORM_CHANNEL",
    CHANGE_COMPLAINT_FORM_DATE= "CHANGE_COMPLAINT_FORM_DATE",
    CHANGE_COMPLAINT_FORM_REPORTED_TO= "CHANGE_COMPLAINT_FORM_REPORTED_TO",
    CHANGE_COMPLAINT_FORM_REPORT_ID= "CHANGE_COMPLAINT_FORM_REPORT_ID",
    CHANGE_COMPLAINT_FORM_EVIDENCE_LINK= "CHANGE_COMPLAINT_FORM_EVIDENCE_LINK",
    CHANGE_COMPLAINT_FORM_EVIDENCE= "CHANGE_COMPLAINT_FORM_EVIDENCE",

    REGISTER_COMPLAINT= "REGISTER_COMPLAINT",
    ADD_COMPLAINT= "ADD_COMPLAINT",
    FETCH_COMPLAINTS= "FETCH_COMPLAINTS",
    CHANGE_CURRENT_COMPLAINTS="CHANGE_CURRENT_COMPLAINTS",
    UPDATE_COMPLAINT= "UPDATE_COMPLAINT",

    REGISTER_PIX= "REGISTER_PIX",
    ADD_PIX= "ADD_PIX",
    FETCH_PIX= "FETCH_PIX",
    CHANGE_CURRENT_PIX="CHANGE_CURRENT_PIX",
    UPDATE_PIX= "UPDATE_PIX",

    CHANGE_PIX_FORM_EVIDENCE_LINK= "CHANGE_PIX_FORM_EVIDENCE_LINK",
    CHANGE_PIX_FORM_EVIDENCE= "CHANGE_PIX_FORM_EVIDENCE",
    CHANGE_PIX_FORM_CHECKOUT_URL= "CHANGE_PIX_FORM_CHECKOUT_URL",
    CHANGE_PIX_FORM_CPF= "CHANGE_PIX_FORM_CPF",
    CHANGE_PIX_FORM_FINANCIAL_INSTITUTION= "CHANGE_PIX_FORM_FINANCIAL_INSTITUTION",
    CHANGE_PIX_FORM_PAYMENT_GATEWAY= "CHANGE_PIX_FORM_PAYMENT_GATEWAY",
    CHANGE_PIX_FORM_PIX= "CHANGE_PIX_FORM_PIX",

    UPDATE_ASSUME_TAKEDOWN= "UPDATE_ASSUME_TAKEDOWN",
    UPDATE_RENOUNCE_TAKEDOWN= "UPDATE_RENOUNCE_TAKEDOWN",
    UPDATE_ASSUME_TAKEDOWN_FROM_SAGA= "UPDATE_ASSUME_TAKEDOWN_FROM_SAGA",
    CHANGE_REASONING_FINISH_FORM= "CHANGE_REASONING_FINISH_FORM",

    FINISH_TAKEDOWNS= "FINISH_TAKEDOWNS",
    SPECIAL_TREATMENT= "SPECIAL_TREATMENT",
    REOPEN_THREATS= "REOPEN_THREATS",
    ADD_TAKEDOWN = "ADD_TAKEDOWN",

    FETCH_THREAT_DETAILS="FETCH_THREAT_DETAILS",

    CHANGE_CURRENT_THREAT_OCCURRENCES="CHANGE_CURRENT_THREAT_OCCURRENCES",
    ADD_OCCURRENCE_ADS_FORM="ADD_OCCURRENCE_ADS_FORM",
    REMOVE_OCCURRENCE_ADS_FORM="REMOVE_OCCURRENCE_ADS_FORM",
    CHANGE_OCCURRENCE_ADS_FORM="CHANGE_OCCURRENCE_ADS_FORM",
    ADD_OCCURRENCE_CONTENT_FORM="ADD_OCCURRENCE_CONTENT_FORM",
    REMOVE_OCCURRENCE_CONTENT_FORM="REMOVE_OCCURRENCE_CONTENT_FORM",
    CHANGE_OCCURRENCE_CONTENT_FORM="CHANGE_OCCURRENCE_CONTENT_FORM",
    ADD_OCCURRENCE_PROFILE_FORM="ADD_OCCURRENCE_PROFILE_FORM",
    REMOVE_OCCURRENCE_PROFILE_FORM="REMOVE_OCCURRENCE_PROFILE_FORM",
    CHANGE_OCCURRENCE_PROFILE_FORM="CHANGE_OCCURRENCE_PROFILE_FORM",
    ADD_OCCURRENCE_SITE_FORM="ADD_OCCURRENCE_SITE_FORM",
    REMOVE_OCCURRENCE_SITE_FORM="REMOVE_OCCURRENCE_SITE_FORM",
    CHANGE_OCCURRENCE_SITE_FORM="CHANGE_OCCURRENCE_SITE_FORM",

    FETCH_REASONING_LABELS="FETCH_REASONING_LABELS",
    CHANGE_REASONING_LABELS_SAGA="CHANGE_REASONING_LABELS_SAGA",
    REMOVE_FORM_LINK_OCCURRENCE="REMOVE_FORM_LINK_OCCURRENCE",
    LINK_THREAT_OCCURRENCES="LINK_THREAT_OCCURRENCES",

    LINK_THREAT_DATABASES="LINK_THREAT_DATABASES",
    CHANGE_LINKED_DATABASE="CHANGE_LINKED_DATABASE",

    LINK_OCCURRENCE_HOST_DATABASE="LINK_OCCURRENCE_HOST_DATABASE",
    CHANGE_CURRENT_TAKEDOWN_OCCURRENCE_HOST="CHANGE_CURRENT_TAKEDOWN_OCCURRENCE_HOST",

    GET_TAKEDOWN_BY_ID="GET_TAKEDOWN_BY_ID",
    CHANGE_CURRENT_TAKEDOWN_FROM_SAGA="CHANGE_CURRENT_TAKEDOWN_FROM_SAGA",
}