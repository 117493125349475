export enum ModuleEnum {
    frauds = 'Fraudes Digitais',
    brand_bidding = 'Brand Bidding',
    admin = 'Admin',
}

export enum FunctionalityEnum {
    manage_threats = 'Manage Threats',
    manage_takedowns = 'Manage Takedowns',
    registers = 'Cadastros',
    manage_agressors = 'Gerenciar Agressores',
    admin_screen = 'Tela administrativa',
    analytical_report = 'Relatório Analítico',
    panel = 'Painel',
    manage_access = 'Manage Access',
    database_access = 'Database Access',
}

export enum ActionEnum {
    move_threat_to_quarantine = 'move_threat_to_quarantine',
    discard_threat = 'discard_threat',
    edit_threat = 'edit_threat',
    mark_threat_as_resolved = 'mark_threat_as_resolved',
    restore_threat_from_discarded = 'restore_threat_from_discarded',
    cancel_approval_request = 'cancel_approval_request',
    approve_takedown = 'approve_takedown',
    change_threat_url_status = 'change_threat_url_status',
    view_threat_details = 'view_threat_details',
    manually_add_threat = 'manually_add_threat',
    delete_threat = 'delete_threat',
    restore_threat_from_resolved = 'restore_threat_from_resolved',
    request_takedown_approval = 'request_takedown_approval',
    report_link = 'report_link',
    discard_threat_takedowns = 'discard_threat_takedowns',
    mark_threat_as_resolved_takedowns = 'mark_threat_as_resolved_takedowns',
    change_threat_url_status_takedowns = 'change_threat_url_status_takedowns',
    register_report_modal = 'register_report_modal',
    register_report_add = 'register_report_add',
    register_report_pix_evidence = 'register_report_pix_evidence',
    view_report_channels = "view_report_channels",
    crud_report_channels = "crud_report_channels",
    triage = 'triage',
    everything = '*',
    view_permissions = 'view_permissions',
    edit_permissions = 'edit_permissions',
    invite_agents = 'invite_agents',
    invite_customers = 'invite_customers',
    invite_freemium = 'invite_freemium',
    manage_users = 'manage_users',
    act_takedown = 'act_takedown',
    view_takedown = 'view_takedown',
    act_databases = 'act_databases',
}

export const userActionPermissions: any = {
    [ModuleEnum.frauds]: {
        [FunctionalityEnum.manage_threats]: {
            move_threat_to_quarantine: ActionEnum.move_threat_to_quarantine,
            discard_threat: ActionEnum.discard_threat,
            edit_threat: ActionEnum.edit_threat,
            mark_threat_as_resolved: ActionEnum.mark_threat_as_resolved,
            restore_threat_from_discarded: ActionEnum.restore_threat_from_discarded,
            cancel_approval_request: ActionEnum.cancel_approval_request,
            approve_takedown: ActionEnum.approve_takedown,
            change_threat_url_status: ActionEnum.change_threat_url_status,
            view_threat_details: ActionEnum.view_threat_details,
            manually_add_threat: ActionEnum.manually_add_threat,
            delete_threat: ActionEnum.delete_threat,
            restore_threat_from_resolved: ActionEnum.restore_threat_from_resolved,
            request_takedown_approval: ActionEnum.request_takedown_approval,
            report_link: ActionEnum.report_link,
        },
        [FunctionalityEnum.manage_takedowns]: {
            discard_threat_takedowns: ActionEnum.discard_threat_takedowns,
            mark_threat_as_resolved_takedowns: ActionEnum.mark_threat_as_resolved_takedowns,
            change_threat_url_status_takedowns: ActionEnum.change_threat_url_status_takedowns,
            register_report_modal: ActionEnum.register_report_modal,
            register_report_add: ActionEnum.register_report_add,
            register_report_pix_evidence: ActionEnum.register_report_pix_evidence,
            act_takedown: ActionEnum.act_takedown,
            view_takedown: ActionEnum.view_takedown,
        },
        [FunctionalityEnum.registers]: {
            view_report_channels: ActionEnum.view_report_channels,
            crud_report_channels: ActionEnum.crud_report_channels,
        },
    },
    [ModuleEnum.brand_bidding]: {
        [FunctionalityEnum.manage_agressors]: {
            triage: ActionEnum.triage
        },
        [FunctionalityEnum.analytical_report]: {
            '*': ActionEnum.everything
        },
        [FunctionalityEnum.panel]: {
            '*': ActionEnum.everything
        },
    },
    [ModuleEnum.admin]: {
        [FunctionalityEnum.manage_access]: {
            view_permissions: ActionEnum.view_permissions,
            edit_permissions: ActionEnum.edit_permissions,
            invite_agents: ActionEnum.invite_agents,
            invite_customers: ActionEnum.invite_customers,
            invite_freemium: ActionEnum.invite_freemium,
            manage_users: ActionEnum.manage_users,
        },
        [FunctionalityEnum.database_access]: {
            act_databases: ActionEnum.act_databases
        },
    },
}