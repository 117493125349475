import { useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { IStore } from "../../../../interfaces/store.interface";
import Body from "../../../components/Text/Body";

interface IProps {
    isOpen: boolean
    onClose: any
    onConfirm: any
}

export default function ModalRenounceTakedown({ isOpen, onClose, onConfirm }: IProps) {

    const { currentTakedown, currentIds } = useSelector((store: IStore) => store.Threats)

    return (
        <Modal
            title="Renunciar takedown" subtitle={currentIds.length > 1 ? `Selecionadas: ${currentIds.length}` : `Selecionada: ${currentTakedown?.sourceThreat?.searchText}`} isOpen={isOpen} onClose={onClose} width={480}
            footerButtons={[
                { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                { buttonStyle: 'primary', onClick: onConfirm, label: 'Confirmar' },
            ]}
        >
            <div className="d-flex flex-column gap-2 mb-4">
                <Body style="medium">Tem certeza que deseja renunciar esse takedown?</Body>
                {
                    currentIds.length == 0 && !currentTakedown
                        ? null
                        : currentIds.length > 1
                            ? <span>Foram selecionadas {currentIds.length} ameaças. Você removerá o responsável delas.</span>
                            : <span>Foi selecionado a ameaça: {currentTakedown?.sourceThreat?.searchText} e você removerá o responsável dela.</span>
                }
            </div>
        </Modal>
    )
}