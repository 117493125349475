import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { useEffect, useState } from "react";
import { getAllCompaniesToAdmin } from "../../../../store/admin/action";
import { setLoading } from "../../../../store/threats/actions";
import { APIClient } from "../../../../helpers/api_helper";
import showToast from "../../../utils/show-toast.util";
import Modal from "../../../components/Modal";
import Label from "../../../components/Text/Label";
import Input from "../../../components/Input/Input";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";

export default function EditDomainModal({ showModalRegisterDomain, setShowModalRegisterDomain, campaignToEdit }: any) {
    const dispatch = useDispatch()
    const { companiesList } = useSelector((store: IStore) => store.Admin)

    const [formDataDomain, setFormDataDomain] = useState({
        name: '',
        searchType: '',
        companyId: '',
        brandName: '',
        monitoringType: '',
        domainKeywords: { include: '', exclude: '' },
        nextRunAt: '',
        enabledUntil: null,
        intervalHours: '',
        runHour: '',
        isEnabled: false,
    });

    useEffect(() => {
        campaignToEdit && setFormDataDomain({
            ...campaignToEdit,
            nextRunAt: new Date(campaignToEdit.nextRunAt).toISOString().slice(0, 16),
            enabledUntil: campaignToEdit.enabledUntil && new Date(campaignToEdit?.enabledUntil).toISOString().slice(0, 16),
            intervalHours: campaignToEdit.intervalHours.toString(), runHour: String(campaignToEdit.runHour).padStart(2, '0') + ":00"
        })
    }, [campaignToEdit]);

    useEffect(() => {
        dispatch(getAllCompaniesToAdmin())
    }, []);

    const handleSubmitUpdateDomain = async (e: any) => {
        e.preventDefault();
        dispatch(setLoading(true))

        try {

            const api = new APIClient();

            const data: any = await api.put('/campaign/admin-campaigns-domain', { ...formDataDomain })
            showToast({ children: <Label>Campanha atualizada com sucesso!</Label>, type: 'info' })
        } catch (e: any) {
            showToast({ children: <Label>Erro ao atualizar campanha!</Label>, type: 'error' })
        } finally {
            setShowModalRegisterDomain(false)
            dispatch(setLoading(false))
        }
    };

    return (
        <Modal footerButtons={[
            // { label: 'Cancelar', isSubmit: false, buttonStyle: 'primary', type: 'destructive' },
            // { label: 'Salvar', isSubmit: true, buttonStyle: 'primary' },
        ]} width={'90%'} title="Editar campanha de Domínio" isOpen={showModalRegisterDomain} onClose={() => setShowModalRegisterDomain(false)}>
            <form className="pb-5" onSubmit={handleSubmitUpdateDomain} style={{ display: 'flex', gap: '2rem' }}>
                {/* Cadastro */}
                <div className="d-flex flex-column gap-3" style={{ flex: 1 }}>
                    <h3>Cadastro</h3>

                    <div className="d-flex gap-2 flex-column">

                        <Label>Campanha</Label>
                        <Input
                            required
                            type="text"
                            placeholder="Nome da campanha"
                            value={formDataDomain.name}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, name: e.target.value })}
                        />
                    </div>

                    <div className="d-flex gap-2 flex-column">
                        <Label>Tipo</Label>
                        <select required className="p-2 border rounded border-gray"
                            value={formDataDomain.searchType}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, searchType: e.target.value })}>
                            <option selected value="prospect">Pescaria</option>
                            <option value="trial">Trial</option>
                            <option value="customer">Cliente</option>
                        </select>


                    </div>
                    <div className="d-flex gap-2 flex-column">

                        <Label>Marca</Label>
                        <select className="p-2 border rounded border-gray"
                            required
                            value={JSON.stringify({ _id: formDataDomain.companyId, name: formDataDomain.brandName })}
                            onChange={(e: any) => {
                                setFormDataDomain({ ...formDataDomain, brandName: JSON.parse(e.target.value).name, companyId: JSON.parse(e.target.value)._id })
                            }}
                        >
                            <option selected value="">Selecione a marca</option>
                            {companiesList.map((el, index) =>
                                <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                            )}
                        </select>
                    </div>


                    <div className="d-flex gap-2 flex-column">

                        <Label>Tipo de Monitoramento</Label>
                        <select className="p-2 border rounded border-gray"
                            required
                            value={formDataDomain.monitoringType}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, monitoringType: e.target.value })}
                        >
                            <option selected value="default">Padrão</option>
                            <option value="internal">Interno</option>
                            <option value="generic_terms">Termos genéricos</option>
                        </select>
                    </div>

                    <div className="d-flex gap-2 flex-column">

                        <Label>Incluir Palavras-chave</Label>
                        <textarea
                            required
                            className="p-2 border rounded border-gray"
                            placeholder="Palavras-chave"
                            value={formDataDomain.domainKeywords.include}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, domainKeywords: { ...formDataDomain.domainKeywords, include: e.target.value } })}
                        />
                    </div>

                    <div className="d-flex gap-2 flex-column">

                        <Label>Excluir Palavras-chave</Label>
                        <textarea
                            className="p-2 border rounded border-gray"
                            placeholder="Palavras-chave"
                            value={formDataDomain.domainKeywords.exclude}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, domainKeywords: { ...formDataDomain.domainKeywords, exclude: e.target.value } })}
                        />
                    </div>

                </div>

                {/* Configuração */}
                <div className="d-flex flex-column gap-3" style={{ flex: 1 }}>
                    <h3>Configuração</h3>

                    <div className="d-flex gap-2 flex-column">

                        <Label>Período (Desde: Data Selecionada | Até: Hoje)</Label>
                        <Input
                            type="datetime-local"
                            required
                            value={formDataDomain.nextRunAt}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, nextRunAt: e.target.value })}
                        />
                    </div>


                    <div className="d-flex gap-2 flex-column">

                        <Label>Expira em:</Label>
                        <Input
                            type="datetime-local"
                            value={formDataDomain.enabledUntil}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, enabledUntil: e.target.value })}
                        />
                    </div>


                    <div className="d-flex gap-2 flex-column">

                        <Label>Executar diariamente</Label>
                        <select className="p-2 border rounded border-gray"
                            required
                            value={formDataDomain.intervalHours}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, intervalHours: e.target.value })}
                        >
                            <option value="6">A cada 06 horas</option>
                            <option value="12">A cada 12 horas</option>
                            <option value="24">A cada 24 horas</option>
                        </select>
                    </div>


                    <div className="d-flex gap-2 flex-column">

                        <Label>Início monitoração (diária)</Label>
                        <Input
                            type="time"
                            required
                            value={formDataDomain.runHour.slice(0,5)}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, runHour: e.target.value })}
                        />
                    </div>

                    <div className="d-flex gap-2">
                        <Label>Ativa</Label>
                        <input
                            type="checkbox"
                            checked={formDataDomain.isEnabled}
                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, isEnabled: e.target.checked })}
                        />
                    </div>

                    <div>
                        <ButtonPrimary isSubmit label="Salvar" />
                    </div>
                </div>
            </form>
        </Modal>
    )
}