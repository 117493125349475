import { useEffect, useState } from "react";
import Accordion from "../../../components/Accordion";
import Modal from "../../../components/Modal";
import { APIClient } from "../../../../helpers/api_helper";
import Label from "../../../components/Text/Label";
import Icon from "../../../components/Icon";
import { CloseOutlined, CloseRounded, DeleteOutline } from "@mui/icons-material";
import Input from "../../../components/Input/Input";
import { setLoading } from "../../../../store/threats/actions";
import showToast from "../../../utils/show-toast.util";
import { useDispatch } from "react-redux";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";

export default function UpdateModal({ showModalUpdate, setShowModalUpdate, companyData }: any) {

    const dispatch = useDispatch()

    const [parentNameList, setParentNameList] = useState([]);
    const [parentNameText, setParentNameText] = useState('');
    
    const [agencyList, setAgencyList] = useState([]);
    const [agencyText, setAgencyText] = useState('');
    
    const [segmentText, setSegmentText] = useState('');
   
    const [seasonalityList, setSeasonalityList] = useState<{ from: string, until: string, description: string }[]>([]);
    const [seasonality, setSeasonality] = useState({ from: "", until: "", description: "" });

    const [formData, setFormData] = useState({
        "parentId": "",
        "name": "",
        "nationalId": null,
        "category": "pending",
        "isEnabled": true,
        "domain": "",
        "segmentIds": [] as any[],
        "parentName": "",
        "domains": "",
        "whitelist": "",
        "marketingAgenciesIds": [] as any[],
        "seasonalities": [] as any[],
        "company_type": "prospect",
        "marketingAgencies": [] as any[],
        
        "cnpj":"",
        "email":"",
        "phone":"",
        "address":"",
        "socialReason":"",
        "customerEmail":""
    });

    const [formDataProducts, setFormDataProducts] = useState({
        frauds: {
            enabled: false,
            trial: false,
            trialDateEnd: "",
            automaticApprovals: [
                {
                    "product": "fake_site",
                    "isAutomatic": false
                },
                {
                    "product": "fake_profile",
                    "isAutomatic": false
                },
                {
                    "product": "fake_content",
                    "isAutomatic": false
                },
                {
                    "product": "fake_ads",
                    "isAutomatic": false
                }
            ],
            acceptAIRecomendation: false
        },
        brandBidding: {
            enabled: false,
            trial: false,
            trialDateEnd: "",
        }
    })

    useEffect(() => {
        if (companyData){
            let formDataCopy = { ...formData };
    
            formDataCopy.parentId = companyData.parentId || "";
            formDataCopy.name = companyData.name || "";
            formDataCopy.nationalId = companyData.nationalId || null;
            formDataCopy.category = companyData.category || "pending";
            formDataCopy.isEnabled = companyData.isEnabled ?? true;
            formDataCopy.domain = companyData.domain || "";
            formDataCopy.segmentIds = companyData.segmentIds || [];
            formDataCopy.parentName = companyData.parentName || "";
            formDataCopy.domains = (companyData.domains || []).join(";");
            formDataCopy.whitelist = (companyData.whitelist || []).join(";");
            formDataCopy.marketingAgenciesIds = companyData.marketingAgenciesIds || [];
            formDataCopy.seasonalities = companyData.seasonalities || [];
            formDataCopy.company_type = companyData.company_type || "prospect";
            formDataCopy.marketingAgencies = companyData.marketingAgencies || [];
            formDataCopy.cnpj = companyData.cnpj || "";
            formDataCopy.email = companyData.email || "";
            formDataCopy.phone = companyData.phone || "";
            formDataCopy.address = companyData.address || "";
            formDataCopy.socialReason = companyData.socialReason || "";
            formDataCopy.customerEmail = (companyData.customerEmail || []).join(";");
        
            setFormData(formDataCopy);
            if(companyData.parentId){
                searchParentId(companyData.parentId)
            }

            if(companyData.marketingAgenciesIds){
                searchAgencyId(companyData?.marketingAgenciesIds[0])
            }

            if(companyData.seasonalities){
                const seasonalitiesData = (companyData.seasonalities as any[]).map(el => ({ description: el.title, from: el.startDate, until: el.endDate }))
                setSeasonalityList(seasonalitiesData)
            }

            getCompanyProduct(companyData._id)
        }
    }, [companyData]);

    async function searchParentName(text: string) {
        const api = new APIClient()
        const data = await api.get("/company/admin/search-by", { name: text })
        // @ts-ignore
        const filteredData = (data as []).filter(dt => dt.name != formData.parentName);

        setParentNameList(filteredData as [])
    }

    async function searchParentId(parentId: string) {
        const api = new APIClient()
        const data = await api.get("/company/admin/search-by", { parentId: parentId }) as any

        if (data && data.name) {
            setParentNameText(data.name as string);
        }
    }

    useEffect(() => {
        searchParentName(parentNameText)
    }, [parentNameText]);

    async function searchAgency(text: string) {
        const api = new APIClient()
        const data = await api.get('/marketing-agency/search-by', { name: text })
        // @ts-ignore
        const filteredData = (data as []).filter(dt => !(formData.marketingAgencies || []).some(el => el._id === dt._id));

        setAgencyList(filteredData as [])
    }

    async function searchAgencyId(agencyId: string) {
        const api = new APIClient()
        const data = await api.get('/marketing-agency/search-by', { agencyId: agencyId }) as any

        setAgencyText(data.name)
    }

    useEffect(() => {
        searchAgency(agencyText)
    }, [agencyText]);

    async function getCompanyProduct(companyId: string) {
        const api = new APIClient();
        const data = await api.get('/company-products', { companyId: companyId }) as any;
    
        if (!data.products || data.products.length === 0) {
            return;
        }
    
        const brandBidding = data.products.find((el: { productName: string; }) => el.productName === "brand bidding");
        const frauds = data.products.find((el: { productName: string; }) => el.productName === "fraud");
    
        setFormDataProducts(prevState => ({
            ...prevState,
            brandBidding: {
                ...prevState.brandBidding,
                ...brandBidding,
            },
            frauds: {
                ...prevState.frauds,
                ...frauds,
            },
        }));
    }

    async function handleSubmitUpdateData(e: any) {
        e.preventDefault()
        dispatch(setLoading(true))

        try {
            const api = new APIClient();

            const formattedData = {
                ...formData,
                _id:companyData._id,
                domains: formData.domains.split(";"),
                whitelist: formData.whitelist.split(";"),
                segmentIds: formData.segmentIds.map(el => (typeof el === 'string' ? el : el._id)),
                customerEmail: formData.customerEmail.split(";")
            }

            // @ts-ignore
            if (formData.seasonalities.length > 0) formattedData.seasonalities = formData.seasonalities.map(el => ({ title: el.description, startDate: el.from, endDate: el.until }))
            
            // @ts-ignore
            if (formData.marketingAgencies.length > 0) formattedData.marketingAgenciesIds = formData.marketingAgencies.map((el: { _id: string }) => el._id);

            const data: any = await api.create('/company/admin/edit', { ...formattedData })
            showToast({ children: <Label>Dados da empresa atualizados com sucesso!</Label>, type: 'info' })
        } catch (e: any) {
            showToast({ children: <Label>Erro ao atualizar dados da empresa!</Label>, type: 'error' })
        } finally {
            dispatch(setLoading(false))
        }
    }

    async function handleSubmitProducts(e: any) {
        e.preventDefault()
        dispatch(setLoading(true))

        try {
            const api = new APIClient();

            const payload = {
                companyId: companyData._id,
                products: [
                    {
                        ...formDataProducts.brandBidding,
                        productName: "brand bidding"
                    },
                    {
                        ...formDataProducts.frauds,
                        productName: "fraud"
                    }
                ]
            }

            const data: any = await api.create('/company-products', { ...payload })
            showToast({ children: <Label>Produtos atualizados com sucesso!</Label>, type: 'info' })
        } catch (e: any) {
            showToast({ children: <Label>Erro ao atualizar produtos!</Label>, type: 'error' })
        } finally {
            setShowModalUpdate(false)
            dispatch(setLoading(false))
        }
    }

    const handleCheckboxChange = (product: string, isChecked: boolean) => {
        setFormDataProducts(prevState => ({
            ...prevState,
            frauds: {
                ...prevState.frauds,
                automaticApprovals: prevState.frauds.automaticApprovals.map(item =>
                    item.product === product ? { ...item, isAutomatic: isChecked } : item
                )
            }
        }));
    };

    return (
        <Modal width={'90%'} title="Editar empresa" isOpen={showModalUpdate} onClose={() => setShowModalUpdate(false)}>
            <div className="pb-4">

                <Accordion title="Cadastro">
                    <form onSubmit={handleSubmitUpdateData} className="pb-5" >
                        <div className="card-header">
                            <h5>Dados da Empresa</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >Grupo Empresarial</Label>
                                        <div className="d-flex flex-wrap gap-2 mb-2">
                                            {formData.parentName &&
                                                <div role="button"
                                                    className="d-flex gap-2 border-primary border p-2"
                                                    onClick={() => setFormData({ ...formData, parentId: "", parentName: "" })}
                                                >
                                                    <span>{formData.parentName}</span>
                                                    <Icon width={14} height={14} IconComponent={CloseOutlined} />
                                                </div>
                                            }
                                        </div>
                                        <Input
                                            type="text"
                                            placeholder="Localidades"
                                            value={parentNameText}
                                            onChange={(e: any) => setParentNameText(e.target.value)}
                                        />
                                        {(parentNameList.length > 0 && parentNameText) &&
                                            <div
                                                style={{
                                                    top: '100%',
                                                    left: 0,
                                                    maxHeight: 200,
                                                    zIndex: 1000,
                                                    marginTop: '4px',
                                                }}
                                                className="position-absolute overflow-auto w-100 bg-white p-2 border border-gray shadow"
                                            >
                                                <div className="position-absolute end-0 me-2" role="button" onClick={() => setParentNameList([])}>
                                                    <Icon color="red" width={22} height={22} IconComponent={CloseRounded} />
                                                </div>
                                                {parentNameList?.map((el: any, index) =>
                                                    <div className="p-2" key={index} role="button"
                                                        onClick={() => {
                                                            setFormData({ ...formData, parentId: el._id, parentName: el.name })
                                                        }}>
                                                        <Label>{el.name}</Label>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label>Empresa<span className="text-danger"> *</span></Label>
                                        <input required onChange={(e: any) => setFormData({ ...formData, name: e.target.value })} type="text" name="name" className="form-control" id="name" placeholder="Nome" value={formData.name} />
                                    </div>
                                </div>

                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >Domínios  (separe com ponto e vírgula (;)) <span className="text-danger"> *</span></Label>
                                        <input value={formData.domains} required onChange={(e: any) => setFormData({ ...formData, domains: e.target.value })} type="text" name="domains" className="form-control" id="domains-txt" placeholder="Domínios" />
                                    </div>
                                </div>

                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >Categoria <span className="text-danger"> *</span></Label>
                                        <select required onChange={(e: any) => setFormData({ ...formData, category: e.target.value })} name="category" id="category" className="form-select p-2 border">

                                            <option selected value="pending">
                                                Pendente
                                            </option>

                                            <option value="saas">
                                                SAAS
                                            </option>

                                            <option value="gold">
                                                Gold
                                            </option>

                                            <option value="Platinum 1">
                                                Platinum 1
                                            </option>

                                            <option value="Platinum 2">
                                                Platinum 2
                                            </option>

                                            <option value="Black 1">
                                                Black 1
                                            </option>

                                            <option value="Black 2">
                                                Black 2
                                            </option>

                                            <option value="enterprise">
                                                Enterprise
                                            </option>

                                            <option value="Titanium 1">
                                                Titanium 1
                                            </option>

                                            <option value="Titanium 2">
                                                Titanium 2
                                            </option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >Tipo <span className="text-danger"> *</span></Label>
                                        <select required onChange={(e: any) => setFormData({ ...formData, company_type: e.target.value })} name="company_type" id="company_type" className="form-select p-2 border">

                                            <option selected value="customer">
                                                Cliente
                                            </option>

                                            <option value="prospect">
                                                Prospect
                                            </option>

                                            <option value="ADVERTISER">
                                                Anunciante
                                            </option>

                                            <option value="churn">
                                                Churn
                                            </option>

                                        </select>
                                    </div>
                                </div>

                                <div className="form-group mt-4">
                                    <Label >Verticais</Label>
                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex gap-2">
                                            <input value={segmentText} onChange={(e: any) => setSegmentText(e.target.value)} type="text" name="segments" className="form-control" id="segments-txt" placeholder="Verticais de negócios" autoComplete="off" />
                                            <ButtonPrimary state={segmentText ? 'default' : 'disabled'} onClick={() => {
                                                setFormData({ ...formData, segmentIds: [...formData.segmentIds, { _id: segmentText, name: segmentText }] })
                                                setSegmentText('')
                                            }} label="Inserir" />
                                        </div>
                                        {formData.segmentIds.length > 0 &&
                                            <div className="d-flex flex-wrap gap-2">
                                                {formData.segmentIds?.map((el, index) =>
                                                    <div role="button" className="d-flex gap-2 border-primary border p-2" onClick={() => {
                                                        const updatedArr = formData.segmentIds.filter(segment => segment._id != el._id)
                                                        setFormData({ ...formData, segmentIds: updatedArr })
                                                    }} key={index}>
                                                        <span>{el.name || el}</span>
                                                        <Icon width={14} height={14} IconComponent={CloseOutlined} />
                                                    </div>)}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="form-group mt-4">
                                    <Label >Agência</Label>
                                    {formData.marketingAgencies.length > 0 &&
                                        <div className="d-flex flex-wrap gap-2">
                                            {formData.marketingAgencies?.map((el, index) =>
                                                <div role="button" className="d-flex gap-2 border-primary border p-2" onClick={() => {
                                                    const updatedArr = formData.marketingAgencies.filter(geo => geo._id != el._id)
                                                    setFormData({ ...formData, marketingAgencies: updatedArr })
                                                }} key={index}>
                                                    <span>{el.name || el}</span>
                                                    <Icon width={14} height={14} IconComponent={CloseOutlined} />
                                                </div>)}
                                        </div>
                                    }
                                    <Input
                                        type="text"
                                        placeholder="Agência"
                                        value={agencyText}
                                        onChange={(e: any) => {
                                            setAgencyText(e.target.value)
                                        }}
                                    />
                                    {(agencyList.length > 0 && agencyText) &&
                                        <div
                                            style={{
                                                top: '100%',
                                                left: 0,
                                                maxHeight: 200,
                                                zIndex: 1000,
                                                marginTop: '4px',
                                            }}
                                            className="position-absolute overflow-auto w-100 bg-white p-2 border border-gray shadow"
                                        >
                                            <div className="position-absolute end-0 me-2" role="button" onClick={() => setAgencyList([])}>
                                                <Icon color="red" width={22} height={22} IconComponent={CloseRounded} />
                                            </div>
                                            {agencyList?.map((el: any, index) =>
                                                <div className="p-2" key={index} role="button"
                                                    onClick={() => {
                                                        setFormData({ ...formData, marketingAgencies: [...formData.marketingAgencies, { _id: el._id, name: el.name }] })
                                                    }}>
                                                    <Label>{el.name}</Label>
                                                </div>
                                            )}
                                        </div>
                                    }
                                </div>
                                <div className="form-group mt-4 d-flex gap-2 align-items-center">
                                    <input checked={formData.isEnabled} onChange={(e: any) => setFormData({ ...formData, isEnabled: e.target.checked })} name="isEnabled" type="checkbox" className="form-check-input ms" id="isEnabled" placeholder="Nome da Empresa" />
                                    <Label >Ativa</Label>
                                </div>

                                <div className="mt-4">
                                    <Label >Período de Sazonalidade (Ignora-se o ano)</Label>
                                    <div className="input-group mt-2" id="seasonalityPeriod">
                                        <span className="input-group-text">De: </span>
                                        <input onChange={(e: any) => setSeasonality({ ...seasonality, from: e.target.value })} className="form-control input-group-date" name="seasonalityPeriodStart" type="date" value={seasonality.from} />
                                        <span className="input-group-text">Até: </span>
                                        <input onChange={(e: any) => setSeasonality({ ...seasonality, until: e.target.value })} className="form-control input-group-date" name="seasonalityPeriodEnd" type="date" value={seasonality.until} />
                                        <span className="input-group-text">Descrição: </span>
                                        <input onChange={(e: any) => setSeasonality({ ...seasonality, description: e.target.value })} className="form-control" type="text" name="seasonalityPeriodTitle" placeholder="Insira o nome do período. Ex. 'Natal' " value={seasonality.description} />
                                        <button disabled={!(seasonality.from && seasonality.until && seasonality.description)} onClick={() => {
                                            setFormData({ ...formData, seasonalities: [...seasonalityList, seasonality] })
                                            setSeasonality({ from: "", until: "", description: "" })
                                        }} className="btn btn-primary" type="button" id="insertSeasonalityPeriodBtn">Inserir</button>
                                    </div>
                                </div>

                                <h6 className="seasonalityPeriodsDisplay mt-4">Sazonalidades Cadastradas</h6>

                                <div className="bootstrap-table bootstrap4">

                                    <table data-toggle="table" data-search="false" data-show-columns="false" data-locale="pt-BR" className="table-custom table-full seasonalityTable table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th data-field="0">Início</th>
                                                <th data-field="1">Fim</th>
                                                <th data-field="2">Período</th>
                                                <th data-field="3"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                formData?.seasonalities?.map((el, index) =>
                                                    <tr key={index} className="no-records-found">
                                                        <td>{new Date(el.from).toLocaleDateString('pt-BR')}</td>
                                                        <td>{new Date(el.until).toLocaleDateString('pt-BR')}</td>
                                                        <td>{el.description}</td>
                                                        <td>
                                                            <div role="button" onClick={() => {
                                                                const updatedArr = formData.seasonalities.filter(sea => sea.description != el.description)
                                                                setFormData({ ...formData, seasonalities: updatedArr })
                                                            }}>
                                                                <Icon IconComponent={DeleteOutline} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            {formData?.seasonalities?.length == 0 &&
                                                <tr className="no-records-found">
                                                    <td colSpan={4}>Nenhum registro encontrado</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="form-group">
                                <Label >Whitelist (separe com ponto e vírgula (;))</Label>
                                <input value={formData.whitelist} onChange={(e: any) => setFormData({ ...formData, whitelist: e.target.value })} type="text" name="whitelist" className="form-control" id="whitelist-txt" placeholder="Domínios permitidos" />
                            </div>
                        </div>

                        <div className="mt-3">
                            <ButtonPrimary isSubmit label="Salvar" />
                        </div>
                    </form >
                </Accordion >

                <Accordion title="Produtos">
                    <form onSubmit={handleSubmitProducts}>
                        <div className="row">
                            <div className="col-6 form-group">
                                <p className="font-weight-bold text-muted" >Fraudes</p>

                                <div className="form-group form-check">
                                    <input onChange={() => setFormDataProducts({ ...formDataProducts, frauds: { ...formDataProducts.frauds, enabled: true } })} checked={formDataProducts.frauds.enabled == true} type="radio" name="fraudeStatus" id="fraudeAtivo" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="fraudeAtivo">Ativo</label>
                                </div>
                                <div className="form-group form-check">
                                    <input onChange={() => setFormDataProducts({ ...formDataProducts, frauds: { ...formDataProducts.frauds, enabled: false } })} checked={formDataProducts.frauds.enabled == false} type="radio" name="fraudeStatus" id="fraudeInativo" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="fraudeInativo">Inativo</label>
                                </div>
                                <div className="form-group" id="fraudeTrialOptions" >
                                    <input checked={formDataProducts.frauds.trial} onChange={(e: any) => setFormDataProducts({ ...formDataProducts, frauds: { ...formDataProducts.frauds, trial: e.target.checked } })} type="checkbox" name="fraudeTrial" id="fraudeTrial" className="form-check-input" />
                                    <label htmlFor="fraudeTrial">Trial</label>
                                    <div className="input-group" id="trialDate">
                                        <span className="input-group-text">Expiração: </span>
                                        <input onChange={(e: any) => setFormDataProducts({ ...formDataProducts, frauds: { ...formDataProducts.frauds, trialDateEnd: e.target.value } })} className="form-control" name="fraudTrialDateEnd" placeholder="Data de expiração do trial" type="datetime-local" value={formDataProducts.frauds.trialDateEnd} />
                                    </div>
                                </div>

                            </div>

                            <div className="col-6">
                                <p className="font-weight-bold text-muted">Brand Bidding</p>

                                <div className="form-group form-check">
                                    <input onChange={() => setFormDataProducts({ ...formDataProducts, brandBidding: { ...formDataProducts.brandBidding, enabled: true } })} checked={formDataProducts.brandBidding.enabled == true} type="radio" name="bbStatus" id="bbAtivo" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="bbAtivo">Ativo</label>
                                </div>
                                <div className="form-group form-check">
                                    <input onChange={() => setFormDataProducts({ ...formDataProducts, brandBidding: { ...formDataProducts.brandBidding, enabled: false } })} checked={formDataProducts.brandBidding.enabled == false} type="radio" name="bbStatus" id="bbInativo" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="bbInativo">Inativo</label>
                                </div>

                                <div className="form-group" id="brandBiddingTrialOptions" >
                                    <input checked={formDataProducts.brandBidding.trial} onChange={(e: any) => setFormDataProducts({ ...formDataProducts, brandBidding: { ...formDataProducts.brandBidding, trial: e.target.checked } })} type="checkbox" name="bbTrial" id="bbTrial" className="form-check-input" />
                                    <label htmlFor="bbTrial">Trial</label>
                                    <div className="input-group" id="trialDate" >
                                        <span className="input-group-text">Expiração: </span>
                                        <input onChange={(e: any) => setFormDataProducts({ ...formDataProducts, brandBidding: { ...formDataProducts.brandBidding, trialDateEnd: e.target.value } })} className="form-control" name="fraudTrialDateEnd" placeholder="Data de expiração do trial" type="datetime-local" value={formDataProducts.brandBidding.trialDateEnd} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-3 mt-4">
                                <p className="font-weight-bold text-muted" >Aprovações Automáticas</p>

                                <div className="form-group form-check">
                                    <input
                                        onChange={(e: any) => handleCheckboxChange('fake_site', e.target.checked)}
                                        checked={formDataProducts.frauds.automaticApprovals.find(el => el.product === 'fake_site')?.isAutomatic || false}
                                        type="checkbox"
                                        id="siteFalso"
                                        name="automaticApprovalSiteFalso"
                                        className="form-check-input"
                                    />
                                    <label className="form-check-label" htmlFor="siteFalso">Site falso</label>
                                </div>

                                <div className="form-group form-check">
                                    <input
                                        onChange={(e: any) => handleCheckboxChange('fake_ads', e.target.checked)}
                                        checked={formDataProducts.frauds.automaticApprovals.find(el => el.product === 'fake_ads')?.isAutomatic || false}
                                        type="checkbox"
                                        id="perfilFalso"
                                        name="automaticApprovalPerfilFalso"
                                        className="form-check-input"
                                    />
                                    <label className="form-check-label" htmlFor="perfilFalso">Perfil falso</label>
                                </div>

                                <div className="form-group form-check">
                                    <input
                                        onChange={(e: any) => handleCheckboxChange('fake_content', e.target.checked)}
                                        checked={formDataProducts.frauds.automaticApprovals.find(el => el.product === 'fake_content')?.isAutomatic || false}
                                        type="checkbox"
                                        id="conteudoFalso"
                                        name="automaticApprovalConteudoFalso"
                                        className="form-check-input"
                                    />
                                    <label className="form-check-label" htmlFor="conteudoFalso">Conteúdo falso</label>
                                </div>

                                <div className="form-group form-check">
                                    <input
                                        onChange={(e: any) => handleCheckboxChange('fake_profile', e.target.checked)}
                                        checked={formDataProducts.frauds.automaticApprovals.find(el => el.product === 'fake_profile')?.isAutomatic || false}
                                        type="checkbox"
                                        id="anuncioFalso"
                                        name="automaticApprovalAnuncioFalso"
                                        className="form-check-input"
                                    />
                                    <label className="form-check-label" htmlFor="anuncioFalso">Anúncio falso</label>
                                </div>
                            </div>
                            <div className="col-4 mt-4">
                                <div className="d-flex align-items-center gap-2">
                                    <input onChange={(e: any) => setFormDataProducts({ ...formDataProducts, frauds: { ...formDataProducts.frauds, acceptAIRecomendation: e.target.checked } })} checked={formDataProducts.frauds.acceptAIRecomendation} type="checkbox" />
                                    <p className="font-weight-bold text-muted mb-0" >Aceitar automaticamente recomendação da IA</p>
                                </div>

                            </div>
                        </div>
                        <ButtonPrimary isSubmit label="Salvar" />
                    </form>
                </Accordion>

                <Accordion title="Informações complementares">
                    <form onSubmit={handleSubmitUpdateData} className="pb-5" >
                        <div className="card-header">
                            <h5>Dados cadastrais</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >Endereço</Label>
                                        <Input  onChange={(e: any) => setFormData({ ...formData, address: e.target.value })} type="text" placeholder="Insira o endereço da empresa" value={formData.address} />
                                    </div>
                                </div>
                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >Email</Label>
                                        <Input
                                            type="text"
                                            placeholder="Informe o email da empresa"
                                            value={formData.email}
                                            onChange={(e: any) => setFormData({ ...formData, email: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >CNPJ</Label>
                                        <Input
                                            type="text"
                                            placeholder="Informe o CNPJ da empresa"
                                            value={formData.cnpj}
                                            onChange={(e: any) => setFormData({ ...formData, cnpj: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >Telefone</Label>
                                        <Input
                                            type="text"
                                            placeholder="Informe o telefone da empresa"
                                            value={formData.phone}
                                            onChange={(e: any) => setFormData({ ...formData, phone: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-6 mt-4">
                                    <div className="form-group">
                                        <Label >Razão social</Label>
                                        <Input
                                            type="text"
                                            placeholder="Informe a razão social"
                                            value={formData.socialReason}
                                            onChange={(e: any) => setFormData({ ...formData, socialReason: e.target.value })}
                                        />
                                    </div>
                                </div>

                                <h6 className="mt-4">Notificação</h6>

                                <div className="bootstrap-table bootstrap4">

                                    <table data-toggle="table" data-search="false" data-show-columns="false" data-locale="pt-BR" className="table-custom table-full seasonalityTable table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th data-field="0">Nome</th>
                                                <th data-field="1">Email</th>
                                                <th data-field="2">Tipo de contato</th>
                                                <th data-field="3">Output</th>
                                                <th data-field="4">Observação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                        </tbody>
                                    </table>
                                    <p className="text-center" >Nenhum registro encontrado</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <Label >Emails DataStudio (separados por ;)</Label>
                                <input type="text" value={formData.customerEmail} onChange={(e: any) => setFormData({ ...formData, customerEmail: e.target.value })} name="emails-datastudio" className="form-control" id="emails-datastudio-txt" />
                            </div>
                        </div>

                        <div className="mt-3">
                            <ButtonPrimary isSubmit label="Salvar" />
                        </div>
                    </form >
                </Accordion>
            </div>

        </Modal >
    )
}