import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { ActionTypesEnum } from "./types.enum";
import {
  fetchComplaintsHelper,
  fetchPixHelper,
  finishTakedownHelper,
  getFraudsByCompanyId,
  getTakedownsHelper,
  registerComplaintHelper,
  registerFraudHelper,
  registerPixHelper,
  restoreFraudHelper,
  restoreMultipleFraudsHelper,
  updateAssumeTakedownHelper,
  specialTreamentTakedownHelper,
  updateComplaintHelper,
  updateFraudHelper,
  updateMultipleFraudsHelper,
  updatePixHelper,
  updateTakedownHelper,
  reopenThreatsHelper,
  threatDetailsHelper,
  fetchLabelsByTypeHelper,
  linkOccurrencesHelper,
  updateRenounceTakedownHelper,
  linkDatabasesHelper,
  linkHostDatabasesHelper,
  takedownDetailHelper,
} from "../../helpers/backend_helper";
import {
  addComplaint,
  addFraud,
  addPix,
  addTakedown,
  changeCurrentComplaints,
  changeCurrentPix,
  changeCurrentTakedownFromSaga,
  changeCurrentTakedownOccurrenceHost,
  changeCurrentThreatOccurrencesFromSaga,
  changeFraudsFilteredQuantityByState,
  changeFraudsQuantityByState,
  changeFraudsTotalPageByState,
  changeLinkedDatabase,
  changeReasoningLabelsFromSaga,
  changeTakedownsFilteredQuantityByPhase,
  changeTakedownsFromSaga,
  changeTakedownsList,
  changeTakedownsQuantityByPhase,
  changeTakedownsTotalPageByPhase,
  changeThreatsList,
  getTakedowns,
  registerFraudFailure,
  setLoading,
  updateAssumeTakedownFromSaga,
  updateFraudFromSaga,
  updateMultipleFraudsFromSaga,
  updateTakedownFromSaga,
} from "./actions";
import { IThreats } from "../../interfaces/threats.interface";
import { ITakedowns } from "../../interfaces/takedown.interface";
import { ITakedownComplaintForm } from "../../interfaces/takedown-complaint-form.interface";
import { ITakedownPixForm } from "../../interfaces/takedown-pix-form.interface";

function* getFraudsByCompanyByIdSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const getFrauds: {
      threats: any[];
      threatsByState: any;
      totalPagesByState: any;
    } = yield call(getFraudsByCompanyId, {
      companyId: payload.companyId,
      itemsPerPage: payload.itemsPerPage,
      page: payload.page,
      type: payload.type,
      platform: payload.platform,
      status: payload.status,
      startDate: payload.startDate,
      endDate: payload.endDate,
      state: payload.state,
      text: payload.text,
    });
    yield put(changeThreatsList(getFrauds.threats));
    yield put(changeFraudsQuantityByState(getFrauds.threatsByState));
    yield put(changeFraudsFilteredQuantityByState(getFrauds.threatsByState));
    yield put(changeFraudsTotalPageByState(getFrauds.totalPagesByState));
  } catch {
    yield put(changeThreatsList([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* registerFraudSaga({ payload }: any) {
  try {
    const formData = new FormData();

    const occurrencesArray: any = []

    for (const [key, value] of Object.entries(payload)) {
      if (key === "occurrences") {
        const occurrences = value as any[];

        occurrences.forEach((occurrence, index) => {
          let occurrenceItem = { url: '', labels: [], evidence: { files: [], link: '' }, adsQuantity: '', adId: '', searchDate: '' }
          occurrenceItem.url = occurrence.url || ""

          if (occurrence.evidence.files && occurrence.evidence.files.length > 0) {
            occurrence.evidence.files.forEach((file: File, fileIndex: number) => {
              if (file instanceof File) formData.append(`occurrences[${index}][${fileIndex}]`, file);
            });
          } else occurrenceItem.evidence.link = occurrence.evidence.link

          occurrenceItem.labels = occurrence.labels
          occurrenceItem.adsQuantity = occurrence?.adsQuantity
          occurrenceItem.adId = occurrence?.adId
          occurrenceItem.searchDate = occurrence?.searchDate

          occurrencesArray.push(occurrenceItem)
        });
      } else {
        formData.append(key, value as any);
      }
    }
    formData.append('occurrences', JSON.stringify(occurrencesArray))

    const newFraud: IThreats = yield call(registerFraudHelper, formData);
    // @ts-ignore
    if (newFraud.error) yield put(registerFraudFailure(newFraud))
    else yield put(addFraud(newFraud));

    yield put(setLoading(false));
  } catch (error: any) {
    yield put(registerFraudFailure(error));
    yield put(setLoading(false));
  }
}

function* updateFraudSaga({ payload }: any) {
  try {
    const formData = new FormData();

    const occurrencesArray: any = []

    for (const [key, value] of Object.entries(payload)) {
      if (key === "occurrences") {
        const occurrences = value as any[];

        occurrences.forEach((occurrence, index) => {
          let occurrenceItem = { url: '', labels: [], evidence: { files: [] as any, link: '' }, adsQuantity: '', adId: '', searchDate: '' }
          occurrenceItem.url = occurrence.url || ""

          if (occurrence.evidence.files && occurrence.evidence.files.length > 0) {
            occurrence.evidence.files.forEach((file: any, fileIndex: number) => {
              if (file instanceof File) formData.append(`occurrences[${index}][${fileIndex}]`, file);
              else occurrenceItem.evidence.files.push(file)
            });
          } else occurrenceItem.evidence.link = occurrence.evidence.link

          occurrenceItem.labels = occurrence.labels
          occurrenceItem.adsQuantity = occurrence?.adsQuantity
          occurrenceItem.adId = occurrence?.adId
          occurrenceItem.searchDate = occurrence?.searchDate

          occurrencesArray.push(occurrenceItem)
        });
      } else {
        formData.append(key, value as any);
      }
    }
    formData.append('occurrences', JSON.stringify(occurrencesArray))

    const updatedFraud: IThreats = yield call(updateFraudHelper, formData);
    yield put(updateFraudFromSaga(updatedFraud));
    yield put(setLoading(false));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

function* updateTakedownSaga({ payload }: any) {
  try {
    const formData = new FormData();

    for (const [key, value] of Object.entries({
      takedownPhase: payload.takedownPhase,
      isActive: payload.isActive,
      _id: payload._id,
    })) {
      formData.append(key, value as any);
    }

    const updatedTakedown: ITakedowns = yield call(updateTakedownHelper, formData);
    yield put(updateTakedownFromSaga(updatedTakedown));
    yield put(setLoading(false));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

function* restoreFraudSaga({ payload }: any) {
  try {
    const updatedFraud: IThreats = yield call(restoreFraudHelper, {
      currentState: payload.currentState,
      isActive: payload.isActive.toString(),
      _id: payload._id,
    });
    yield put(updateFraudFromSaga(updatedFraud));
    yield put(setLoading(false));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

function* updateMultipleFraudsSaga({ payload }: any) {
  try {
    const updatedFrauds: IThreats[] = yield call(
      updateMultipleFraudsHelper,
      payload
    );
    yield put(updateMultipleFraudsFromSaga(updatedFrauds));
    yield put(setLoading(false));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}
function* restoreMultipleFraudsSaga({ payload }: any) {
  try {
    const updatedFrauds: IThreats[] = yield call(
      restoreMultipleFraudsHelper,
      payload
    );
    yield put(updateMultipleFraudsFromSaga(updatedFrauds));
    yield put(setLoading(false));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

function* getTakedownsSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const getTakedowns: {
      takedowns: ITakedowns[];
      takedownsByPhase: any;
      totalPagesByPhase: any;
    } = yield call(getTakedownsHelper, {
      companyId: payload.companyId,
      itemsPerPage: payload.itemsPerPage,
      page: payload.page,
      type: payload.type,
      platform: payload.platform,
      status: payload.status,
      startDate: payload.startDate,
      endDate: payload.endDate,
      takedownPhase: payload.takedownPhase,
      text: payload.text,
      responsible: payload.responsible,
      notifications: payload.notifications,
      SLA: payload.SLA,
      timeIdentified: payload.timeIdentified,
      timeOnPhase: payload.timeOnPhase,
    });
    yield put(changeTakedownsList(getTakedowns.takedowns));
    yield put(changeTakedownsQuantityByPhase(getTakedowns.takedownsByPhase));
    yield put(changeTakedownsFilteredQuantityByPhase(getTakedowns.takedownsByPhase));
    yield put(changeTakedownsTotalPageByPhase(getTakedowns.totalPagesByPhase));
  } catch {
    yield put(changeTakedownsList([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* registerComplaintSaga({ payload }: any) {
  try {
    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (key === "evidence") {
        // @ts-ignore
        if (value?.link) {
          // @ts-ignore
          formData.append("evidenceLink", value.link);
        } else {
          // @ts-ignore
          for (let file of value?.files as File[]) {
            if (file instanceof File) {
              formData.append("evidence", file);
            }
          }
        }
      } else formData.append(key, value as any);
    }

    const newComplaint: ITakedownComplaintForm = yield call(registerComplaintHelper, formData);
    yield put(addComplaint(newComplaint));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
  }
}

function* fetchComplaintSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const getComplaints: ITakedownComplaintForm[] = yield call(fetchComplaintsHelper, { treatmentId: payload });
    yield put(changeCurrentComplaints(getComplaints))
  } catch {
    yield put(changeCurrentComplaints([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* updateComplaintSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const formData = new FormData();

    for (const [key, value] of Object.entries({
      reportDate: payload.reportDate,
      reportedTo: payload.reportedTo,
      _id: payload._id,
      evidenceLink: payload.evidenceLink,
      evidence: payload.evidence,
      reportId: payload.reportId,
      complaintChannel: payload.complaintChannel,
      treatmentId: payload.treatmentId,
    })) {
      if (key === "evidence") {
        for (let file of value as File[]) {
          if (file instanceof File) {
            formData.append("evidence", file);
          } else formData.append("evidence", value);
        }
      } else formData.append(key, value as any);
    }

    const updatedComplaint: ITakedownComplaintForm[] = yield call(updateComplaintHelper, formData);
    yield put(changeCurrentComplaints(updatedComplaint))
  } catch {
    yield put(changeCurrentComplaints([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* registerPixSaga({ payload }: any) {
  try {
    const formData = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      if (key === "evidence") {
        // @ts-ignore
        if (value?.link) {
          // @ts-ignore
          formData.append("evidenceLink", value.link);
        } else {
          // @ts-ignore
          for (let file of value?.files as File[]) {
            if (file instanceof File) {
              formData.append("evidence", file);
            }
          }
        }
      } else formData.append(key, value as any);
    }

    const newPix: ITakedownPixForm = yield call(registerPixHelper, formData);
    yield put(addPix(newPix));
    yield put(setLoading(false));
  } catch (error: any) {
    yield put(setLoading(false));
  }
}

function* fetchPixSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const getPix: ITakedownPixForm[] = yield call(fetchPixHelper, { threatId: payload });
    yield put(changeCurrentPix(getPix))
  } catch {
    yield put(changeCurrentPix([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* updatePixSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const formData = new FormData();

    for (const [key, value] of Object.entries({
      checkoutUrl: payload.checkoutUrl,
      paymentGateway: payload.paymentGateway,
      financialInstitution: payload.financialInstitution,
      pix: payload.pix,
      cpf: payload.cpf,
      evidenceLink: payload.evidenceLink,
      evidence: payload.evidence,
      treatmentId: payload.treatmentId,
      _id: payload._id,
    })) {
      if (key === "evidence") {
        for (let file of value as File[]) {
          if (file instanceof File) {
            formData.append("evidence", file);
          } else formData.append("evidence", value);
        }
      } else formData.append(key, value as any);
    }

    const updatedPix: ITakedownPixForm[] = yield call(updatePixHelper, formData);
    yield put(changeCurrentPix(updatedPix))
  } catch {
    yield put(changeCurrentPix([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* updateAssumeTakedownSaga({ payload }: any) {
  try {
    const updatedAssumeTakedown: ITakedowns = yield call(updateAssumeTakedownHelper, { takedowns: payload });
    yield put(updateAssumeTakedownFromSaga(updatedAssumeTakedown));
    yield put(setLoading(false));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

function* renounceTakedownSaga({ payload }: any) {
  try {
    const updatedTakedown: ITakedowns = yield call(updateRenounceTakedownHelper, { takedowns: payload });
    // yield put(updateAssumeTakedownFromSaga(updatedTakedown));
    yield put(setLoading(false));
  } catch (error) {
    // yield put(registerFraudFailure(error.message)); // Dispatch failure action
  }
}

function* finishTakedownsSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const updatedTakedowns: ITakedowns[] = yield call(finishTakedownHelper, payload);
    yield put(changeTakedownsFromSaga(updatedTakedowns))
  } catch {
    // yield put(changeCurrentPix([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* specialTreatmentTakedownsSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const updatedTakedowns: ITakedowns[] = yield call(specialTreamentTakedownHelper, { takedowns: payload });
    yield put(changeTakedownsFromSaga(updatedTakedowns))
  } catch {
    // yield put(changeCurrentPix([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* reopenThreatsSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const updatedTakedown: ITakedowns = yield call(reopenThreatsHelper, { takedowns: payload });
    yield put(addTakedown(updatedTakedown))
  } catch {
    // yield put(changeCurrentPix([]));
  } finally {
    yield put(setLoading(false));
  }
}

function* getThreatDetailsSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    // @ts-ignore
    const threatDetails = yield call(threatDetailsHelper, { ticketId: payload });
    yield put(changeCurrentThreatOccurrencesFromSaga(threatDetails))
  } catch {
    console.error('Erro ao resgatar detalhes da ameaça')
  } finally {
    yield put(setLoading(false));
  }
}
function* getTakedownDetailSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    // @ts-ignore
    const takedownDetail = yield call(takedownDetailHelper, { treatmentId: payload });
    yield put(changeCurrentTakedownFromSaga(takedownDetail))
  } catch {
    console.error('Erro ao resgatar detalhes da ameaça')
  } finally {
    yield put(setLoading(false));
  }
}

function* fetchReasoningLabelsSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    const labels: any[] = yield call(fetchLabelsByTypeHelper, { type: payload });
    yield put(changeReasoningLabelsFromSaga(labels))
  } catch {
    console.error('Erro ao resgatar detalhes da ameaça')
  } finally {
    yield put(setLoading(false));
  }
}

function* linkOccurrencesSaga({ payload }: any) {
  yield put(setLoading(true));
  try {

    const formData = new FormData();

    const occurrencesArray: any = []

    for (const [key, value] of Object.entries(payload)) {
      if (key === "occurrences") {
        const occurrences = value as any[];

        occurrences.forEach((occurrence, index) => {
          let occurrenceItem = { url: '', evidence: { files: [], link: '' } }

          occurrenceItem.url = occurrence.url || ""

          if (occurrence.evidence.files && occurrence.evidence.files.length > 0) {
            occurrence.evidence.files.forEach((file: File, fileIndex: number) => {
              if (file instanceof File) formData.append(`occurrences[${index}][${fileIndex}]`, file);
            });
          } else occurrenceItem.evidence.link = occurrence.evidence.link

          occurrencesArray.push(occurrenceItem)
        });
      } else {
        formData.append(key, value as any);
      }
    }

    formData.append('occurrences', JSON.stringify(occurrencesArray))

    yield call(linkOccurrencesHelper, formData);
    // yield put(changeReasoningLabelsFromSaga(reasoning))
  } catch {
    console.error('Erro ao vincular ocorrências.')
  } finally {
    yield put(setLoading(false));
  }
}

function* linkDatabasesSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    // @ts-ignore
    const linkedDatabase = yield call(linkDatabasesHelper, { databases: payload.databases, sourceId: payload.sourceId, occurrenceId: payload.occurrenceId, type: payload.type });
    yield put(changeLinkedDatabase(linkedDatabase))
  } catch {
    console.error('Erro ao vincular database.')
  } finally {
    yield put(setLoading(false));
  }
}

function* linkHostDatabasesSaga({ payload }: any) {
  yield put(setLoading(true));
  try {
    // @ts-ignore
    const linkedDatabase = yield call(linkHostDatabasesHelper, { databases: payload.databases, occurrenceId: payload.occurrenceId });
    yield put(changeCurrentTakedownOccurrenceHost(linkedDatabase))
  } catch {
    console.error('Erro ao vincular database.')
  } finally {
    yield put(setLoading(false));
  }
}

export function* watchAll() {
  yield takeLatest(ActionTypesEnum.GET_FRAUDS_BY_COMPANY, getFraudsByCompanyByIdSaga);
  yield takeLatest(ActionTypesEnum.REGISTER_FRAUD, registerFraudSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_FRAUD, updateFraudSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS, updateMultipleFraudsSaga);
  yield takeLatest(ActionTypesEnum.RESTORE_MULTIPLE_FRAUDS, restoreMultipleFraudsSaga);
  yield takeLatest(ActionTypesEnum.RESTORE_FRAUD, restoreFraudSaga);
  yield takeLatest(ActionTypesEnum.GET_TAKEDOWNS, getTakedownsSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_TAKEDOWN, updateTakedownSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_ASSUME_TAKEDOWN, updateAssumeTakedownSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_RENOUNCE_TAKEDOWN, renounceTakedownSaga);
  yield takeLatest(ActionTypesEnum.REGISTER_COMPLAINT, registerComplaintSaga);
  yield takeLatest(ActionTypesEnum.FETCH_COMPLAINTS, fetchComplaintSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_COMPLAINT, updateComplaintSaga);
  yield takeLatest(ActionTypesEnum.REGISTER_PIX, registerPixSaga);
  yield takeLatest(ActionTypesEnum.FETCH_PIX, fetchPixSaga);
  yield takeLatest(ActionTypesEnum.UPDATE_PIX, updatePixSaga);
  yield takeLatest(ActionTypesEnum.FINISH_TAKEDOWNS, finishTakedownsSaga);
  yield takeLatest(ActionTypesEnum.SPECIAL_TREATMENT, specialTreatmentTakedownsSaga);
  yield takeLatest(ActionTypesEnum.REOPEN_THREATS, reopenThreatsSaga);
  yield takeLatest(ActionTypesEnum.FETCH_THREAT_DETAILS, getThreatDetailsSaga);
  yield takeLatest(ActionTypesEnum.FETCH_REASONING_LABELS, fetchReasoningLabelsSaga);
  yield takeLatest(ActionTypesEnum.LINK_THREAT_OCCURRENCES, linkOccurrencesSaga);
  yield takeLatest(ActionTypesEnum.LINK_THREAT_DATABASES, linkDatabasesSaga);
  yield takeLatest(ActionTypesEnum.LINK_OCCURRENCE_HOST_DATABASE, linkHostDatabasesSaga);
  yield takeLatest(ActionTypesEnum.GET_TAKEDOWN_BY_ID, getTakedownDetailSaga);
}

function* threatSaga() {
  yield all([call(watchAll)]);
}
export default threatSaga;
