import styled from 'styled-components';
import Filter from "./filter";
import Table from "./table";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { useState } from "react";
import RegisterModal from "./register-modal";
import UpdateModal from './update-modal';

const Companies = () => {

    const [showModalRegister, setShowModalRegister] = useState(false);
    const [showModalUpdate, setShowModalUpdate] = useState(false);

    const [companyData, setcompanyData]=useState([])

    return (
        <section className="mt-5">
            <div className="d-flex justify-content-end my-3">
                <ButtonPrimary onClick={() => setShowModalRegister(true)} label="Nova empresa" />
            </div>
            <Filter />
            <Table setShowModalUpdate={setShowModalUpdate} setcompanyData={setcompanyData}  />

            <RegisterModal showModalRegister={showModalRegister} setShowModalRegister={setShowModalRegister} />
            <UpdateModal showModalUpdate={showModalUpdate} setShowModalUpdate={setShowModalUpdate} companyData={companyData} />
        </section>
    );
};

export default Companies;