// src/components/CompanyFilters.jsx
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components';

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const FilterInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FilterSelect = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FilterDomain = () => {
  const navigate = useNavigate();

  const [searchParams, _] = useSearchParams();
  const location = useLocation();

  return (
    <FiltersContainer>
      <FilterInput onChange={(e: any) => {
        setTimeout(() => {
          searchParams.set('text', e.target.value)
          const newSearch = searchParams.toString();
          if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }, 1000);
      }} className="flex-grow-1" type="text" placeholder="Pesquisa por empresa, agência ou grupo" />
      <FilterSelect onChange={(e: any) => {
        searchParams.set('company_type', e.target.value)
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
      }}>
        <option value="">Tipo Empresa</option>
        <option value="customer">Cliente</option>
        <option value="prospect">Prospect</option>
        <option value="ADVERTISER">Anunciante</option>
        <option value="churn">Churn</option>
      </FilterSelect>
      <FilterSelect onChange={(e: any) => {
        searchParams.set('status', e.target.value)
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
      }}>
        <option value="">Status</option>
        <option value={"true"}>Ativas</option>
        <option value={"false"}>Inativas</option>
      </FilterSelect>
      <FilterSelect onChange={(e: any) => {
        searchParams.set('searchType', e.target.value)
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
      }}>
        <option value="">Tipo Campanha</option>
        <option value="customer">Cliente</option>
        <option value="trial">Trial</option>
        <option value="prospect">Pescaria</option>
      </FilterSelect>
      <FilterSelect onChange={(e: any) => {
        searchParams.set('monitoringType', e.target.value)
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
      }}>
        <option value="">Monitoramento</option>
        <option value="default">Padrão</option>
        <option value="internal">Interno</option>
        <option value="generic_terms">Genérico</option>
      </FilterSelect>
    </FiltersContainer>
  );
};

export default FilterDomain;