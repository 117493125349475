import { createPortal } from "react-dom";
import { dsVariables } from "../../../variables";
import styled from "styled-components";
import { Close} from "@mui/icons-material";
import Icon from "../../../components/Icon";
import { IButtonProps } from "../../../components/Button/ButtonPrimary";
import rafiki from "../../../assets/rafiki.svg"
import bgBranddi from "../../../assets/bg-branddi.png"
import Sub6 from "../../../components/Text/Sub6";
import Label from "../../../components/Text/Label";

type TButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'quaternary'

export interface IFooterButton extends IButtonProps {
    buttonStyle: TButtonStyle
}

interface IProps {
    isOpen: boolean
    onRequestDemonstration:any
}

export default function SuccessModalDemonstration({ isOpen, onRequestDemonstration}: IProps){
    if (!isOpen){
        return null
    }

    return createPortal(
        <ModalContainer className="w-100 h-100 top-0 start-0 position-fixed d-flex justify-content-center align-items-center">
            <ModalContent>
                <MaskModal/>
                <ModalHeader className="d-flex flex-column">
                    <Sub6 color={dsVariables.colors.Gray[100]}>Solicitação enviada!</Sub6>

                    <ButtonClose className="position-absolute bg-transparent border-0" onClick={onRequestDemonstration}>
                        <Icon color={dsVariables.colors.Astronaut[900]} IconComponent={Close} />
                    </ButtonClose>
                </ModalHeader>

                <MainContent>
                    <img src={rafiki} alt="rafiki img" />
                    <Label color="white">Nosso time recebeu sua solicitação e entrará em contato em breve!</Label>
                </MainContent>
            </ModalContent>
            
        </ModalContainer>,
        document.getElementById("modal-root") as HTMLElement
    );
}

const MaskModal = styled.div<{ height?: boolean }>`
    background: linear-gradient(89deg, #001D2E 45.37%, #09B6C3 100%), var(--Modal-Modal-BG, #FFF);
    box-shadow: 0 8px 32px rgba(13, 88, 95, 0.2);
    opacity: 0.5;
    width: 900px;
    height: 520px;
    border-radius:10px;
    position:absolute;
`

const MainContent = styled.div<{ height?: boolean }>`
    font-family: ${dsVariables.fontFamilies.Inter};
    gap: 25px;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    height: 100%;
    width:100%;
    position:absolute; 

    p {
        text-align: center;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 400;
    }

    img{
        width:17rem;
    }
`;

const ModalContainer = styled.div`
    background-color: ${dsVariables.colors.Gray[800] + 'dd'};
    z-index: 9999;
`

const ModalHeader = styled.div`
    position: absolute;
    width: 100%;
    padding:15px;
    h5{
        font-family: ${dsVariables.fontFamilies.Inter};
        color: #ffff;
        font-size:1.2rem;
    }
`

const ModalContent = styled.div<{ backgroundColor?: string }>`
    background-image: url(${bgBranddi});
    background-size: cover;
    background-position: center;
    z-index: 9999;
    width: 900px;
    height: 520px;
    border-radius:10px;
    position:relative;
`

const ButtonClose = styled.button`
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 1.5rem;
    z-index: 10; 
`;
