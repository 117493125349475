import styled from "styled-components"
import Icon from "../../Icon"
import { dsVariables } from "../../../variables"
import { SvgIconComponent } from "@mui/icons-material"
import Caption from "../../Text/Caption"

export interface IColumnProps {
    label?: string
    icon?: SvgIconComponent
    showCheckbox?: boolean
    onChangeCheckbox?:any
    checkboxDisabled?:boolean
    checkboxChecked?: boolean
    colSpan?:number
}

export default function Column({ icon, label, showCheckbox,onChangeCheckbox,checkboxDisabled=false,checkboxChecked=false, colSpan }: IColumnProps) {
    return (
        <Th colSpan={colSpan}>
            {showCheckbox && <input checked={checkboxChecked} disabled={checkboxDisabled} type="checkbox" onChange={e => onChangeCheckbox(e.target.checked)} />}
            {icon && <Icon IconComponent={icon} />}
            {label && <Caption style="medium">{label}</Caption>}
        </Th>
    )
}

const Th = styled.th`
    background: ${dsVariables.colors.Gray[100]};
    height: Fixed (40px)px;
    padding: 8px 12px;
    gap: 8px;
    border: 0px 0px 1px 0px;

    input:disabled{
        cursor: not-allowed;
    }
`