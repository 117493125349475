import { createPortal } from "react-dom";
import { dsVariables } from "../../../variables";
import styled from "styled-components";
import { Close } from "@mui/icons-material";
import Icon from "../../../components/Icon";
import { IButtonProps } from "../../../components/Button/ButtonPrimary";
import crown from "../../../assets/crown.svg"
import verified from "../../../assets/verified.svg"
import preemiumTakedown from "../../../assets/preemium-takedown.svg"
import { useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import Label from "../../../components/Text/Label";
import Sub6 from "../../../components/Text/Sub6";
import Body from "../../../components/Text/Body";
import Caption from "../../../components/Text/Caption";

type TButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'quaternary'

export interface IFooterButton extends IButtonProps {
    buttonStyle: TButtonStyle
}

interface IProps {
    isOpen: boolean
    onClose: any
    onRequestDemonstration: any
}

export default function ModalCommercial({ isOpen, onClose, onRequestDemonstration }: IProps) {
    const { user } = useSelector((store: IStore) => ({
        user: store.Profile.user,
    }))

    if (!isOpen) {
        return null
    }

    return createPortal(
        <ModalContainer className="w-100 h-100 top-0 start-0 position-fixed d-flex justify-content-center align-items-center" onClick={onClose}>
            <ModalContent className="position-relative d-flex flex-column" onClick={(e) => e.stopPropagation()}>
                <ModalHeader className="d-flex gap-3">
                    <img width={16} height={16} src={crown} alt="" />
                    <Label>Funcionalidade Premium!</Label>
                </ModalHeader>

                <ModalChildren>
                    <div className="d-flex flex-column gap-2 pb-4 pt-2   ">
                        <Sub6 color={dsVariables.colors.Astronaut[100]}>Libere gratuitamente período de testes</Sub6>
                        <Label color={dsVariables.colors.Astronaut[100]}>Olá, {user.firstName}! Atualmente, a funcionalidade de Aprovação de Takedown está bloqueada para sua conta.</Label>
                    </div>

                    <ButtonClose className="position-absolute bg-transparent border-0 top-0 mt-5" onClick={onClose}>
                        <Icon color={dsVariables.colors.Astronaut[900]} IconComponent={Close} />
                    </ButtonClose>

                    <div className="d-flex gap-4">
                        <img width={419} src={preemiumTakedown} alt="preemium takedown" />

                        <div>
                            <Body color={dsVariables.colors.Astronaut[100]}>
                                <span>
                                    Para desbloquear essa funcionalidade e poder atuar diretamente nas decisões de takedown, entre em contato com o nosso time comercial.
                                </span>
                            </Body>

                            <div className="d-flex flex-column gap-3 my-4 py-1">
                                <div className="d-flex gap-3">
                                    <div style={{
                                        borderRadius: 6,
                                        background: 'rgba(255, 255, 255, 0.09)',
                                        height: 'fit-content'
                                    }}>
                                        <img src={verified} alt="verified" />
                                    </div>
                                    <Label color="white">
                                        Clique no botão abaixo para entrar em contato com nossa equipe;
                                    </Label>
                                </div>
                                <div className="d-flex gap-3">
                                    <div style={{
                                        borderRadius: 6,
                                        background: 'rgba(255, 255, 255, 0.09)',
                                        height: 'fit-content'
                                    }}>
                                        <img src={verified} alt="verified" />
                                    </div>
                                    <Label color="white">
                                        Nossa equipe comercial está pronta para
                                        ajudar você a habilitar essa funcionalidade.</Label>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <DemostrationButton className="d-flex gap-2 pe-3" onClick={() => {
                                    onRequestDemonstration();
                                    onClose();
                                }}>
                                    <div className="icon-circle">
                                        <svg viewBox="0 0 24 24">
                                            <path d="M9 18l6-6-6-6" />
                                        </svg>
                                    </div>
                                    <Body style="medium" >Solicitar Demonstração</Body>
                                </DemostrationButton>
                            </div>
                        </div>

                    </div>

                    <div className="mt-3">
                        <Caption color="white">
                            Importante: Enquanto o acesso estiver bloqueado,
                            você poderá visualizar as informações das ameaças, mas
                            não poderá aprovar ações de takedown diretamente.
                        </Caption>
                    </div>
                </ModalChildren>
            </ModalContent>
        </ModalContainer>,
        document.getElementById("modal-root") as HTMLElement
    );
}

const ModalChildren = styled.div<{ height?: boolean }>`
    height: ${({ height }) => height ? 340 : 'unset'}px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
    font-family: ${dsVariables.fontFamilies.Inter};

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    background: linear-gradient(89deg, #001D2E 45.37%, #09B6C3 100%);

    h3{
        color:#fff;
    }

    .message{
        max-width:770px;
        line-height: 16.8px;
        color:#fff;
        font-size:1.12rem;
        font-weight: 500;
        margin-top:15px;
        margin-bottom:20px;
    }
`

const DemostrationButton = styled.button`
    display: flex;
    align-items: center;
    background-color:#0ACFDE;
    border: none;
    border-radius: 100px;
    padding: 4px;
    cursor: pointer;
    transition: transform 0.2s;
    text-decoration: none;
    box-shadow: 0 2px 8px rgba(0, 191, 179, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.10);

    .icon-circle {
        width: 40px;
        height: 40px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid ${dsVariables.colors.Gray[200]}
    }

    .icon-circle svg {
        width: 20px;
        height: 20px;
        fill: none;
        stroke:#030303;
        stroke-width: 2;
    }

    .button-text {
        color: #030303;
        font-size: 18px;
        font-weight: 500;
        padding-right: 24px;
    }
`

const ModalContainer = styled.div`
    background-color: ${dsVariables.colors.Gray[800] + 'dd'};
    z-index: 9999;
`

const ModalHeader = styled.div`
    background-color: #09B6C3;
    color: white;
    padding: 12px 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    gap: 8px;
    font-size: 14px;
    font-weight: 500;
    
    img{
        widht:1rem;
        height:1rem;
    }

    h5{
        margin:0;
        color:#ffff;
        font-size: 16px;
        word-spacing: 5px
    }
`

const ModalContent = styled.div<{ backgroundColor?: string }>`
    /* background: ${props => props.backgroundColor}; */
    border-radius: ${dsVariables.cornerRadius[8]};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 785px;
    p{
        margin:0;
        padding:0;
    }
`

const ButtonClose = styled.button`
    top:4.5rem;
    right: 15px;
    font-size: 2rem;
`