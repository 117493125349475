import { Table } from "react-bootstrap";
import ButtonPrimary from "../../../../components/Button/ButtonPrimary";
import { AddOutlined, EditOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import Label from "../../../../components/Text/Label";
import Input from "../../../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../../interfaces/store.interface";
import { changeFormDatabaseRegistrar, fetchDatabaseByType, registerRegistrar, updateDatabase } from "../../../../../store/admin/action";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../../../components/Icon";

export default function RegistrarTable() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { forms, registrar } = useSelector((store: IStore) => store.Admin.databases)

    const [showModalRegister, setShowModalRegister] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [registrarToEdit, setRegistrarToEdit] = useState({
        ianaNumber: '',
        entity: '',
        abuseContactPhone: '',
        abuseContactMail: '',
        abuseForm: '',
        entryWebsite: '',
    });

    useEffect(() => {
        dispatch(fetchDatabaseByType('registrar'))
    }, []);

    function onClose() {
        setShowModalRegister(false)
        searchParams.delete('modalOpened')
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    async function onConfirm() {
        dispatch(registerRegistrar({ ...forms.registrar, type: 'registrar' }))
    }

    function handleInsertButtonClick() {
        setShowModalRegister(true)
        searchParams.set('modalOpened', 'true')
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    function handleShowModalEdit(registrarId: string) {
        setRegistrarToEdit(registrar.find(el => el._id == registrarId))
        setShowModalEdit(true)
    }

    function editRegistrar() {
        dispatch(updateDatabase({ ...registrarToEdit, type: 'registrar' }))
    }

    return (
        <section className="d-flex flex-column mt-3">
            {/* <Filter /> */}

            <div className="bg-white d-flex flex-column gap-3 rounded-2">
                <Table className="table">
                    <thead>
                        <tr className="fw-bold">
                            <td>IANA Number</td>
                            <td>Entity</td>
                            <td>Abuse Contact Phone</td>
                            <td>Abuse Contact Email</td>
                            <td>Abuse Form</td>
                            <td>Entry Website</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {registrar?.map((el, index) =>
                            <tr key={index}>
                                <td style={{ maxWidth: 100 }}>{el.ianaNumber}</td>
                                <td style={{ maxWidth: 100 }}>{el.entity}</td>
                                <td style={{ maxWidth: 100 }}>{el.abusePhone}</td>
                                <td style={{ maxWidth: 100 }}>{el.abuseEmail}</td>
                                <td style={{ maxWidth: 100 }}>{el.abuseForm}</td>
                                <td style={{ maxWidth: 100 }}>{el.entryWebsite}</td>
                                <td role="button"><Icon onClick={() => handleShowModalEdit(el._id)} IconComponent={EditOutlined} /></td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* <Paginator /> */}
            </div>

            {/* <Modal
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: onConfirm, label: 'Inserir' },
                ]} title="Novo Registrar" isOpen={showModalRegister} width={'50%'} onClose={onClose}>
                <form className="d-flex flex-column gap-4">
                    <div className="d-flex gap-4">
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>IANA Number</Label>
                            <Input value={forms?.registrar?.ianaNumber} onChange={(e: any) => {
                                dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, ianaNumber: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Entity</Label>
                            <Input value={forms?.registrar?.entity} onChange={(e: any) => {
                                dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, entity: e.target.value }))
                            }} />
                        </div>
                    </div>
                    <div className="d-flex gap-4">
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Abuse Contact Phone</Label>
                            <Input value={forms.registrar?.abuseContactPhone} onChange={(e: any) => {
                                dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, abuseContactPhone: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Abuse Contact Email</Label>
                            <Input value={forms.registrar?.abuseContactMail} onChange={(e: any) => {
                                dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, abuseContactMail: e.target.value }))
                            }} />
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <Label>Abuse Form</Label>
                        <Input value={forms.registrar?.abuseForm} onChange={(e: any) => {
                            dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, abuseForm: e.target.value }))
                        }} />
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <Label>Entry Website</Label>
                        <Input value={forms.registrar?.entryWebsite} onChange={(e: any) => {
                            dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, entryWebsite: e.target.value }))
                        }} />
                    </div>

                </form>
            </Modal> */}
            <Modal
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: () => setShowModalEdit(false), label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: editRegistrar, label: 'Atualizar' },
                ]} title="Editar Registrar" isOpen={showModalEdit} width={'50%'} onClose={() => setShowModalEdit(false)}>
                <form className="d-flex flex-column gap-4">
                    <div className="d-flex gap-4">
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>IANA Number</Label>
                            <Input placeholder="Insira o IANA Number" state="disabled" value={registrarToEdit?.ianaNumber} onChange={(e: any) => {
                                dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, ianaNumber: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Entity</Label>
                            <Input placeholder="Insira o Entity" state="disabled" value={registrarToEdit?.entity} onChange={(e: any) => {
                                dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, entity: e.target.value }))
                            }} />
                        </div>
                    </div>
                    <div className="d-flex gap-4">
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Abuse Contact Phone</Label>
                            <Input placeholder="Insira o Abuse Contact Phone" state="disabled" value={registrarToEdit?.abuseContactPhone} onChange={(e: any) => {
                                dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, abuseContactPhone: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Abuse Contact Email</Label>
                            <Input placeholder="Insira o Abuse Contact Email" state="disabled" value={registrarToEdit?.abuseContactMail} onChange={(e: any) => {
                                dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, abuseContactMail: e.target.value }))
                            }} />
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <Label>Abuse Form</Label>
                        <Input placeholder="Insira o abuse form aqui" value={registrarToEdit?.abuseForm} onChange={(e: any) => {
                            setRegistrarToEdit({ ...registrarToEdit, abuseForm: e.target.value })
                        }} />
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <Label>Entry Website</Label>
                        <Input placeholder="Insira o entry website" state="disabled" value={registrarToEdit?.entryWebsite} onChange={(e: any) => {
                            dispatch(changeFormDatabaseRegistrar({ ...forms.registrar, entryWebsite: e.target.value }))
                        }} />
                    </div>

                </form>
            </Modal>

        </section>
    )
}