import { Container, Row } from "reactstrap";
import SectionTitle from "../../../DesignSystem/components/SectionTitle/SectionTitle";
import ButtonSecondary from "../../../DesignSystem/components/Button/ButtonSecondary";
import { useCallback, useEffect, useRef, useState } from "react";
import Input from "../../../DesignSystem/components/Input/Input";
import Tabs, { ITabs } from "../../../DesignSystem/components/Tabs";
import { useLocation, useNavigate } from "react-router-dom";
import { Tune, Search, WebAssetOff, Warning, ExpandMore, Close, PersonRemoveAlt1Outlined, PersonAddAltOutlined, EmergencyOutlined, FavoriteBorder, CheckCircleOutline } from "@mui/icons-material";
import { dsVariables } from "../../../DesignSystem/variables";
import Modal from "../../../DesignSystem/components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../interfaces/store.interface";
import Snackbar from "../../../DesignSystem/components/Snackbar";
import Label from "../../../DesignSystem/components/Text/Label";
import ButtonPrimary from "../../../DesignSystem/components/Button/ButtonPrimary";
import Loader from "../../../DesignSystem/components/Loader";
import { getCompanyProductsByCompanyId } from "../../../store/companyProducts/action";
import Chip from "../../../DesignSystem/components/Chip";
import Body from "../../../DesignSystem/components/Text/Body";
import useCanPerformAction from "../../../DesignSystem/hooks/CanPerformAction.hook";
import { ActionEnum } from "../../../DesignSystem/constants/user-action-permissions.constant";
import TableTakedowns from "../../components/Table/TableTakedown/TableTakedowns";
import { TakedownPhaseEnum } from "../../../enums/takedownPhase.enum";
import { changeFilterCompanyIdTakedown, changeFilterIdentifiedHoursTakedown, changeFilterIdentifiedTakedown, changeFilterNotificationsTakedown, changeFilterPlatformTakedown, changeFilterSlaTakedown, changeFilterStatusTakedown, changeFilterTextTakedown, changeFilterTimePhaseHoursTakedown, changeFilterTimePhaseTakedown, changeFilterTypeTakedown, changeTakedownsCurrent, changeTakedownsCurrentIds, changeTakedownsFilteredQuantityByPhase, changeTakedownsTable, changeThreatsCurrentIds, finishTakedowns, getTakedowns, setLoading, updateAssumeTakedown, specialTreatmentTakedowns, reopenThreats, renounceTakedown, getTakedownDetailById, } from "../../../store/threats/actions";
import { ThreatOptionsCardEnum } from "../../../enums/threat-options-card.enum";
import { ThreatTypeEnum } from "../../../enums/threat-type.enum";
import { ITakedownsFilter } from "../../../interfaces/takedown-filter.interface";
import { ThreatDaysEnum } from "../../../enums/threat-days.enum";
import { ThreatPlatformEnum } from "../../../enums/threat-platform.enum";
import { ThreatStatusActiveEnum } from "../../../enums/threat-status-active.enum";
import { getThreatTypeText } from "../../utils/get-threat-type-text.util";
import TakedownsFilterSection from "../../components/FilterSection/TakedownsFilterSection";
import { TakedownTextSlaEnum } from "../../../enums/takedown-sla-text.enum";
import { TakedownSlaEnum } from "../../../enums/takedown-sla.enum";
import ModalTakedownDetail from "./Modals/ModalTakedownDetail";
import ModalAssumeTakedown from "./Modals/ModalAssumeTakedown";
import ModalFinish from "./Modals/ModalFinish";
import ModalSpecialTreatment from "./Modals/ModalSpecialTreatment";
import OptionsCard from "../../components/Table/TableTakedown/OptionsCard";
import ModalReopenThreat from "./Modals/ModalReopenThreat";
import ModalRenounceTakedown from "./Modals/ModalRenounceTakedown";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Icon from "../../components/Icon";
import showToast from "../../utils/show-toast.util";
import SelectFilterSection from "../../components/FilterSection/SelectFilterSection";
import InvisibleSelect from "../../components/FilterSection/InvisibleSelect";

export default function ManageTakedowns() {
  document.title = "Branddi.com - Gerenciar Takedowns";

  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tabActive: number = Number(searchParams.get("tab"));
  const tablePage: number = Number(searchParams.get("page"));

  const filterButtonRef = useRef<HTMLDivElement | null>(null);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const buttonActionsRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const {
    takedownsTableData,
    takedownsFilter,
    currentIds,
    current,
    takedownsList,
    selectedCompany,
    isCustomer,
    isLoading,
    takedownsQuantityByPhase,
    takedownsFilteredQuantityByPhase,
    forms,
    user,
  } = useSelector((store: IStore) => ({
    takedownsTableData: store.Threats.takedownsTableData,
    takedownsFilter: store.Threats.takedownsFilter,
    currentIds: store.Threats.currentIds,
    current: store.Threats.current,
    takedownsList: store.Threats.takedownsList,
    selectedCompany: store.Profile.selectedCompany,
    isCustomer: store.Profile.user.isCustomer,
    isLoading: store.Threats.isLoading,
    companyProducts: store.CompanyProducts.products,
    takedownsQuantityByPhase: store.Threats.takedownsQuantityByPhase,
    takedownsFilteredQuantityByPhase:
      store.Threats.takedownsFilteredQuantityByPhase,
    forms: store.Threats.forms,
    user: store.Profile.user,
  }));

  const [openFilter, setOpenFilter] = useState(false);

  const [showModalDetails, setShowModalDetails] = useState<boolean>(false);
  const [showModalTakedownDetail, setShowModalTakedownDetail] = useState<boolean>(false);
  const [showModalFinish, setShowModalFinish] = useState<boolean>(false);
  const [showModalSpecialTreatment, setShowModalSpecialTreatment] = useState<boolean>(false);
  const [showModalAssumeTakedown, setShowModalAssumeTakedown] = useState<boolean>(false);
  const [showModalRenounceTakedown, setShowModalRenounceTakedown] = useState<boolean>(false);
  const [showModalReopenThreat, setShowModalReopenThreat] = useState<boolean>(false);

  const [showModalBBCta, setShowModalBBCta] = useState(false);

  const [showSnackErrorModalFinish, setShowSnackErrorModalFinish] = useState(false);

  const [showOptionsCard, setShowOptionsCard] = useState(false);

  const showActTakedown = useCanPerformAction(ActionEnum.act_takedown);

  const tabs: ITabs[] = [
    {
      label: "Solicitações recebidas",
      onClick: () => tabClick(0),
      iconRight: "badge",
      iconRightLabel:
        takedownsFilteredQuantityByPhase?.request_received > 100
          ? "100+"
          : takedownsFilteredQuantityByPhase?.request_received,
      tooltip: takedownsFilteredQuantityByPhase?.request_received,
    },
    {
      label: "Notificações enviadas",
      onClick: () => tabClick(1),
      iconRight: "badge",
      iconRightLabel:
        takedownsFilteredQuantityByPhase?.complaint_made > 100
          ? "100+"
          : takedownsFilteredQuantityByPhase?.complaint_made,
      tooltip: takedownsFilteredQuantityByPhase?.complaint_made,
    },
    {
      label: "Tratativas especiais",
      onClick: () => tabClick(2),
      iconRight: "badge",
      iconRightLabel:
        takedownsFilteredQuantityByPhase?.special_treatment > 100
          ? "100+"
          : takedownsFilteredQuantityByPhase?.special_treatment,
      tooltip: takedownsFilteredQuantityByPhase?.special_treatment,
    },
    {
      label: "Finalizada",
      onClick: () => tabClick(3),
      iconRight: "badge",
      iconRightLabel:
        takedownsFilteredQuantityByPhase?.finished > 100
          ? "100+"
          : takedownsFilteredQuantityByPhase?.finished,
      tooltip: takedownsFilteredQuantityByPhase?.finished,
    },
  ];

  function tabClick(index: number) {
    searchParams.set("tab", index.toString());
    const newSearch = searchParams.toString();
    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }

  function showDetails(id: string) {
    dispatch(changeTakedownsCurrent(takedownsList?.find((el) => el._id == id)!));
    setShowModalDetails(true);
  }

  function showOptions(id: string, option: ThreatOptionsCardEnum) {
    dispatch(changeTakedownsCurrent(takedownsList?.find((el) => el._id == id)!));
    dispatch(changeTakedownsCurrentIds([takedownsList?.find((el) => el._id == id)!._id as string]));

    switch (option) {
      case ThreatOptionsCardEnum.REGISTER_NOTIFICATION:
        searchParams.set('takedown', takedownsList?.find((el) => el._id == id)?.identifier!)
        var newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        return setShowModalTakedownDetail(true);
      case ThreatOptionsCardEnum.SPECIAL_TREATMENT:
        return setShowModalSpecialTreatment(true)
      case ThreatOptionsCardEnum.FINISH:
        return setShowModalFinish(true);
      case ThreatOptionsCardEnum.ASSUME_TAKEDOWN:
        return setShowModalAssumeTakedown(true);
      case ThreatOptionsCardEnum.RENOUNCE_TAKEDOWN:
        return setShowModalRenounceTakedown(true);
      case ThreatOptionsCardEnum.REOPEN_THREAT:
        return setShowModalReopenThreat(true);
    }
  }

  function finishTakedown() {
    if (!forms.takedownFinishForm.reasoning.text) return showToast({
      children: <div><Label style="medium">Erro!</Label> - Selecione ao menos uma opção.</div>,
      type: 'error'
    })

    dispatch(finishTakedowns({ takedowns: currentIds, reasoning: { text: forms.takedownFinishForm.reasoning.text, id: forms.takedownFinishForm.reasoning.id } }))
    closeModalsAndSnacks()

    return toast.success(
      currentIds.length > 1
        ? <Label style="medium">Tratativas finalizadas!<Label><br />{currentIds.length} finalizadas.</Label></Label>
        : <Label style="medium">Tratativa finalizada!<Label><br />A tratativa foi finalizada.</Label></Label>, {
      position: "bottom-center",
      autoClose: 5000,
      closeOnClick: true,
      transition: Bounce,
      style: {
        backgroundColor: dsVariables.colors.Green[100],
        color: dsVariables.colors.Green[800],
        borderColor: dsVariables.colors.Green[200],
        borderWidth: 1,
        borderStyle: 'solid',
        maxWidth: 480,
        marginLeft: -100,
        zIndex: 123123123123,
      },
      progressStyle: {
        backgroundColor: dsVariables.colors.Green[800],
        height: 2
      },
      icon: () => <Icon IconComponent={CheckCircleOutline} width={16} height={16} color={dsVariables.colors.Green[800]} />
    })
  }

  function handleSpecialTreatmentTakedown() {
    dispatch(specialTreatmentTakedowns(currentIds))
    closeModalsAndSnacks()

    return toast.success(
      currentIds.length > 1
        ? <Label style="medium">Movidas para tratativas especiais! <Label><br />{currentIds.length} tratativas finalizadas.</Label></Label>
        : <Label style="medium">Movida para tratativas especiais! <Label><br />A tratativa foi movida.</Label></Label>, {
      position: "bottom-center",
      autoClose: 5000,
      closeOnClick: true,
      transition: Bounce,
      style: {
        backgroundColor: dsVariables.colors.Blue[100],
        color: dsVariables.colors.Blue[800],
        borderColor: dsVariables.colors.Blue[200],
        borderWidth: 1,
        borderStyle: 'solid',
        maxWidth: 480,
        marginLeft: -100,
        zIndex: 123123123123,
      },
      progressStyle: {
        backgroundColor: dsVariables.colors.Blue[800],
        height: 2
      },
      icon: () => <Icon IconComponent={CheckCircleOutline} width={16} height={16} color={dsVariables.colors.Blue[800]} />
    })

  }

  function handleReopenThreats() {
    dispatch(reopenThreats(currentIds))
    return closeModalsAndSnacks()
  }

  function handleOptionsCardClick(option: ThreatOptionsCardEnum) {
    switch (option) {
      case ThreatOptionsCardEnum.SPECIAL_TREATMENT:
        return setShowModalSpecialTreatment(true)
      case ThreatOptionsCardEnum.FINISH:
        return setShowModalFinish(true);
      case ThreatOptionsCardEnum.REOPEN_THREAT:
        return setShowModalReopenThreat(true);
      case ThreatOptionsCardEnum.ASSUME_TAKEDOWN:
        return setShowModalAssumeTakedown(true);
    }
  }

  function closeModalsAndSnacks() {
    setShowModalDetails(false);
    setShowModalFinish(false);
    setShowModalSpecialTreatment(false);
    setShowOptionsCard(false);
    setShowSnackErrorModalFinish(false);
    setShowModalAssumeTakedown(false);
    setShowModalRenounceTakedown(false);
    setShowModalReopenThreat(false);
    dispatch(changeTakedownsCurrentIds([]));
    setTimeout(() => {
      dispatch(
        getTakedowns({
          SLA: searchParams.get("sla"),
          companyId: searchParams.get("companyId") || "all",
          itemsPerPage: Number(searchParams.get("itemsPerPage")) || 10,
          page: Number(searchParams.get("page")) || 1,
          type: searchParams.get("type"),
          platform: searchParams.get("platform"),
          status: searchParams.get("status"),
          startDate: searchParams.get("startDate"),
          endDate: searchParams.get("endDate"),
          takedownPhase: getPhaseByTabActive(),
          text: searchParams.get("text"),
          responsible: searchParams.get("responsible"),
          notifications: searchParams.get("notifications"),
          timeIdentified: searchParams.get('identified') || searchParams.get('identifiedHours')
            ? JSON.stringify({ days: searchParams.get('identified'), hours: searchParams.get('identifiedHours') })
            : null,
          timeOnPhase: searchParams.get('timePhase') || searchParams.get('timePhaseHours')
            ? JSON.stringify({ days: searchParams.get('timePhase'), hours: searchParams.get('timePhaseHours') })
            : null
        })
      );
    }, 500);
  }

  function filterTableData(
    takedownPhase: TakedownPhaseEnum,
    filter: ITakedownsFilter
  ) {
    const calculateDaysAgoDate = (days: number): Date => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      today.setDate(today.getDate() - days);
      return today;
    };

    const isWithinLastDays = (
      date: Date,
      daysEnum: ThreatDaysEnum
    ): boolean => {
      switch (daysEnum) {
        case ThreatDaysEnum.DAYS_7:
          return date >= calculateDaysAgoDate(7);
        case ThreatDaysEnum.DAYS_14:
          return date >= calculateDaysAgoDate(14);
        case ThreatDaysEnum.DAYS_28:
          return date >= calculateDaysAgoDate(28);
        case ThreatDaysEnum.ALL_DAYS:
        default:
          return true;
      }
    };

    return takedownsList!
      .filter((takedown) => {
        // @ts-ignore
        const matchesState = takedown.phase === takedownPhase;

        const matchesText = takedownsFilter.text
          // @ts-ignore
          ? takedown.sourceThreat?.searchText
            .toLowerCase()
            .includes(takedownsFilter.text.toLowerCase())
          : true;

        const matchesPlatform =
          takedownsFilter.platform !== ThreatPlatformEnum.ALL
            // @ts-ignore
            ? takedown.sourceThreat.platform === takedownsFilter.platform
            : true;

        const matchesType =
          takedownsFilter.type !== ThreatTypeEnum.ALL
            // @ts-ignore
            ? takedown.threat.type === takedownsFilter.type
            : true;

        const matchesDateRangeStartDate = takedownsFilter.dateRange?.startDate
          ? new Date(takedown.createdAt) >= takedownsFilter.dateRange.startDate
          : true;

        const matchesDateRangeEndDate = takedownsFilter.dateRange?.endDate
          ? new Date(takedown.createdAt) <=
          new Date(
            new Date(takedownsFilter.dateRange.endDate).setDate(
              new Date(takedownsFilter.dateRange.endDate).getDate() + 1
            )
          )
          : true;

        const matchesDays =
          takedownsFilter.days &&
            takedownsFilter.days !== ThreatDaysEnum.ALL_DAYS
            ? isWithinLastDays(
              new Date(takedown.createdAt),
              takedownsFilter.days
            )
            : true;

        const matchesStatus =
          searchParams.get("status") != "Todos"
            // @ts-ignore
            ? takedownsFilter.status == takedown.threat.isActive
            : true;

        return (
          matchesState &&
          matchesText &&
          matchesPlatform &&
          matchesType &&
          matchesDateRangeStartDate &&
          matchesDateRangeEndDate &&
          matchesDays &&
          matchesStatus
        );
      })
      .map((el) => ({ ...el, checked: false }));
  }

  async function getTakedownDetail(takedownId: string) {
    dispatch(getTakedownDetailById(takedownId))
  }

  useEffect(() => {
    dispatch(changeThreatsCurrentIds([]));
  }, [tabActive]);
  function changeTabsQuantityByFilter() {

    dispatch(
      changeTakedownsFilteredQuantityByPhase({
        request_received: takedownsQuantityByPhase.request_received,
        complaint_made: takedownsQuantityByPhase.complaint_made,
        special_treatment: takedownsQuantityByPhase.special_treatment,
        finished: takedownsQuantityByPhase.finished,
      })
    );
  }

  const changeTable = useCallback(() => {
    if (!takedownsList) return;
    changeTabsQuantityByFilter();
    switch (tabActive) {
      case 0:
        return dispatch(
          changeTakedownsTable(
            filterTableData(TakedownPhaseEnum.REQUEST_RECEIVED, takedownsFilter)
          )
        );
      case 1:
        return dispatch(
          changeTakedownsTable(
            filterTableData(TakedownPhaseEnum.COMPLAINT_MADE, takedownsFilter)
          )
        );
      case 2:
        return dispatch(
          changeTakedownsTable(
            filterTableData(
              TakedownPhaseEnum.SPECIAL_TREATMENT,
              takedownsFilter
            )
          )
        );
      case 3:
        return dispatch(
          changeTakedownsTable(
            filterTableData(TakedownPhaseEnum.FINISHED, takedownsFilter)
          )
        );
      default:
        return dispatch(changeTakedownsTable(takedownsTableData!));
    }
  }, [tabActive, takedownsFilter, takedownsList, tablePage]);

  useEffect(() => {
    changeTable();
  }, [changeTable]);

  useEffect(() => {
    if (selectedCompany?.value) {
      dispatch(setLoading(true));
      dispatch(
        getTakedowns({
          SLA: searchParams.get("sla"),
          companyId: searchParams.get("companyId") || "all",
          responsible: searchParams.get("responsible"),
          itemsPerPage: Number(searchParams.get("itemsPerPage")) || 10,
          page: Number(searchParams.get("page")) || 1,
          type: searchParams.get("type"),
          platform: searchParams.get("platform"),
          status: searchParams.get("status"),
          startDate: searchParams.get("startDate"),
          endDate: searchParams.get("endDate"),
          takedownPhase: getPhaseByTabActive(),
          text: searchParams.get("text"),
          notifications: searchParams.get("notifications"),
          timeIdentified: searchParams.get('identified') || searchParams.get('identifiedHours')
            ? JSON.stringify({ days: searchParams.get('identified'), hours: searchParams.get('identifiedHours') })
            : null,
          timeOnPhase: searchParams.get('timePhase') || searchParams.get('timePhaseHours')
            ? JSON.stringify({ days: searchParams.get('timePhase'), hours: searchParams.get('timePhaseHours') })
            : null
        })
      );

      dispatch(getCompanyProductsByCompanyId(selectedCompany.value));
    }
  }, [selectedCompany, location]);

  function getPhaseByTabActive() {
    switch (tabActive) {
      case 0:
        return TakedownPhaseEnum.REQUEST_RECEIVED;
      case 1:
        return TakedownPhaseEnum.COMPLAINT_MADE;
      case 2:
        return TakedownPhaseEnum.SPECIAL_TREATMENT;
      case 3:
        return TakedownPhaseEnum.FINISHED;

      default:
        return TakedownPhaseEnum.REQUEST_RECEIVED;
    }
  }

  useEffect(() => {
    if (!searchParams.get("type"))
      searchParams.set("type", takedownsFilter.type);
    else dispatch(changeFilterTypeTakedown(searchParams.get("type") as ThreatTypeEnum));

    if (!searchParams.get("platform"))
      searchParams.set("platform", takedownsFilter.platform);
    else
      dispatch(
        changeFilterPlatformTakedown(searchParams.get("platform") as ThreatPlatformEnum)
      );

    if (!searchParams.get("status")) searchParams.set("status", "Todos");
    else dispatch(changeFilterStatusTakedown(searchParams.get("status") == ThreatStatusActiveEnum.ACTIVE));


    if (!searchParams.get("notifications")) dispatch(changeFilterNotificationsTakedown(null));
    else {
      const notifications = Number(searchParams.get("notifications"));
      dispatch(changeFilterNotificationsTakedown(notifications));
    }

    if (!searchParams.get('responsible')) searchParams.set('responsible', '*')

    if (searchParams.get("sla")) dispatch(changeFilterSlaTakedown(searchParams.get("sla") == TakedownTextSlaEnum.EXPIRED ? TakedownSlaEnum.EXPIRED : TakedownSlaEnum.UP_TO_DATE));

    if (!searchParams.get("identified")) dispatch(changeFilterIdentifiedTakedown(null));
    else
      dispatch(changeFilterIdentifiedTakedown(searchParams.get("identified")!));

    if (!searchParams.get("identifiedHours")) dispatch(changeFilterIdentifiedHoursTakedown(null));
    else
      dispatch(changeFilterIdentifiedHoursTakedown(searchParams.get("identifiedHours")!));

    if (!searchParams.get("timePhase")) dispatch(changeFilterTimePhaseTakedown(null));
    else
      dispatch(changeFilterTimePhaseTakedown(searchParams.get("timePhase")!));

    if (!searchParams.get("timePhaseHours")) dispatch(changeFilterTimePhaseHoursTakedown(null));
    else
      dispatch(changeFilterTimePhaseHoursTakedown(searchParams.get("timePhaseHours")!));

    if (searchParams.get("text"))
      dispatch(changeFilterTextTakedown(searchParams.get("text")!));

    if (searchParams.get("companyId")) dispatch(changeFilterCompanyIdTakedown(searchParams.get("companyId")!));
    else dispatch(changeFilterCompanyIdTakedown("all"))

    if (location.search.includes("showModalBBCta")) setShowModalBBCta(true);
    else setShowModalBBCta(false);

    if (searchParams.get("takedown")) {
      setShowModalTakedownDetail(true)
      getTakedownDetail(searchParams.get("takedown") as string)
    }
    else setShowModalTakedownDetail(false)

    const newSearch = searchParams.toString();

    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }, [location]);

  useEffect(() => {
    if (isCustomer) {
      searchParams.set("tab", "2");
      const newSearch = searchParams.toString();

      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }
  }, []);

  useEffect(() => {
    function closeFilter(event: MouseEvent) {
      if (
        filterRef.current &&
        !filterRef.current.contains(event.target as Node) &&
        filterButtonRef.current &&
        !filterButtonRef.current.contains(event.target as Node)
      ) {
        setOpenFilter(false);
      }
    }

    document.addEventListener("mousedown", closeFilter);

    return () => {
      document.removeEventListener("mousedown", closeFilter);
    };
  }, [openFilter]);

  useEffect(() => {
    function closeFilter(event: MouseEvent) {
      if (
        buttonActionsRef.current &&
        !buttonActionsRef.current.contains(event.target as Node)
      ) {
        setShowOptionsCard(false);
      }
    }

    document.addEventListener("mousedown", closeFilter);

    return () => {
      document.removeEventListener("mousedown", closeFilter);
    };
  }, [showOptionsCard]);

  function getQuantityOfFiltersActive() {
    const companyId = searchParams.get("companyId") ? 1 : 0;
    const type = searchParams.get("type") != "all" ? 1 : 0;
    const platform = searchParams.get("platform") != "Todos" ? 1 : 0;
    const status = searchParams.get("status") != "Todos" ? 1 : 0;
    const SLA = searchParams.get("sla") ? 1 : 0;
    const notification = searchParams.get("notifications") ? 1 : 0;
    const identified = searchParams.get("identified") ? 1 : 0;
    const identifiedHours = searchParams.get("identifiedHours") ? 1 : 0;
    const timePhase = searchParams.get("timePhase") ? 1 : 0;
    const timePhaseHours = searchParams.get("timePhaseHours") ? 1 : 0;
    const responsible = searchParams.get("responsible") != '*' ? 1 : 0;

    return responsible + companyId + type + platform + status + SLA + notification + identified + identifiedHours + timePhase + timePhaseHours;
  }

  function getFilterChips() {
    const companyId = searchParams.get("companyId") ? (
      <Chip
        onClick={() => {
          searchParams.set("companyId", ThreatTypeEnum.ALL);
          const newSearch = searchParams.toString();

          if (location.search !== `?${newSearch}`)
            navigate({ search: newSearch });
        }}
        label={user.companies.find((el: any) => el._id == takedownsFilter.companyId)?.name}
      />
    ) : null;
    const type =
      searchParams.get("type") != ThreatTypeEnum.ALL ? (
        <Chip
          onClick={() => {
            searchParams.set("type", ThreatTypeEnum.ALL);
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`)
              navigate({ search: newSearch });
          }}
          label={getThreatTypeText(takedownsFilter.type)}
        />
      ) : null;
    const platform =
      searchParams.get("platform") != ThreatPlatformEnum.ALL ? (
        <Chip
          onClick={() => {
            searchParams.set("platform", ThreatPlatformEnum.ALL);
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`)
              navigate({ search: newSearch });
          }}
          label={takedownsFilter.platform}
        />
      ) : null;
    const status =
      searchParams.get("status") != "Todos" ? (
        <Chip
          onClick={() => {
            searchParams.set("status", "Todos");
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`)
              navigate({ search: newSearch });
          }}
          label={
            takedownsFilter.status
              ? ThreatStatusActiveEnum.ACTIVE
              : ThreatStatusActiveEnum.INACTIVE
          }
        />
      ) : null;
    const notification = searchParams.get("notifications") ? (
      <Chip
        onClick={() => {
          searchParams.delete("notifications");
          const newSearch = searchParams.toString();

          if (location.search !== `?${newSearch}`)
            navigate({ search: newSearch });
        }}
        label={"Notificações: " + takedownsFilter.notifications}
      />
    ) : null;
    const SLA =
      searchParams.get("sla") ? (
        <Chip
          onClick={() => {
            searchParams.delete("sla");
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`)
              navigate({ search: newSearch });
          }}
          label={takedownsFilter.SLA == TakedownSlaEnum.EXPIRED ? "Prazo SLA: " + TakedownTextSlaEnum.EXPIRED : "Prazo SLA: " + TakedownTextSlaEnum.UP_TO_DATE}
        />
      ) : null;
    const identified = searchParams.get("identified") ? (
      <Chip
        onClick={() => {
          searchParams.delete("identified");
          const newSearch = searchParams.toString();

          if (location.search !== `?${newSearch}`)
            navigate({ search: newSearch });
        }}
        label={"Identificado há: " + takedownsFilter.timeIdentified + " dia(s)"}
      />
    ) : null;
    const identifiedHours = searchParams.get("identifiedHours") ? (
      <Chip
        onClick={() => {
          searchParams.delete("identifiedHours");
          const newSearch = searchParams.toString();

          if (location.search !== `?${newSearch}`)
            navigate({ search: newSearch });
        }}
        label={"Identificado há: " + takedownsFilter.timeIdentifiedHours + " hora(s)"}
      />
    ) : null;
    const timePhase = searchParams.get("timePhase") ? (
      <Chip
        onClick={() => {
          searchParams.delete("timePhase");
          const newSearch = searchParams.toString();

          if (location.search !== `?${newSearch}`)
            navigate({ search: newSearch });
        }}
        label={"Tempo na fase há: " + takedownsFilter.timeOnPhase + " dia(s)"}
      />
    ) : null;
    const timePhaseHours = searchParams.get("timePhaseHours") ? (
      <Chip
        onClick={() => {
          searchParams.delete("timePhaseHours");
          const newSearch = searchParams.toString();

          if (location.search !== `?${newSearch}`)
            navigate({ search: newSearch });
        }}
        label={"Tempo na fase há: " + takedownsFilter.timeOnPhaseHours + " hora(s)"}
      />
    ) : null;
    const responsible = searchParams.get("responsible") != '*' ? (
      <Chip
        onClick={() => {
          searchParams.set("responsible", "*");
          const newSearch = searchParams.toString();

          if (location.search !== `?${newSearch}`)
            navigate({ search: newSearch });
        }}
        label={`Responsável: ${searchParams.get("responsible") == '*' ? 'Todos' : user.fullName}`}
      />
    ) : null;

    return (
      getQuantityOfFiltersActive() > 0 && (
        <>
          {companyId}
          {type}
          {platform}
          {status}
          {notification}
          {SLA}
          {identified}
          {identifiedHours}
          {timePhase}
          {timePhaseHours}
          {responsible}
          <Chip
            type="clean_all"
            onClick={() => {
              searchParams.delete("companyId");
              searchParams.set("type", ThreatTypeEnum.ALL);
              searchParams.set("platform", ThreatPlatformEnum.ALL);
              searchParams.set("status", "Todos");
              searchParams.delete("sla");
              searchParams.delete("notifications");
              searchParams.delete("identified");
              searchParams.delete("identifiedHours");
              searchParams.delete("timePhase");
              searchParams.delete("timePhaseHours");
              searchParams.set("responsible", "*");
              setSelectedFilter("Selecione um filtro salvo");
              const newSearch = searchParams.toString();

              if (location.search !== `?${newSearch}`)
                navigate({ search: newSearch });
            }}
            label="Limpar tudo"
          />
        </>
      )
    );
  }

  function handleTextFilterInput(e: any) {
    dispatch(changeFilterTextTakedown(e.target.value));
    setTimeout(() => {
      searchParams.set("text", e.target.value);
      searchParams.set("page", "1");

      const newSearch = searchParams.toString();

      if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, 1000);
  }

  function clearTextFilter() {
    dispatch(changeFilterTextTakedown(""));

    searchParams.delete("text");
    const newSearch = searchParams.toString();

    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }

  function assumeTakedown() {
    dispatch(setLoading(true))
    dispatch(updateAssumeTakedown(currentIds))
    closeModalsAndSnacks()
    toast.success(
      <Label>Você assumiu a tratativa.</Label>, {
      position: "bottom-center",
      autoClose: 5000,
      closeOnClick: true,
      transition: Bounce,
      style: {
        backgroundColor: dsVariables.colors.Blue[100],
        color: dsVariables.colors.Blue[800],
        borderColor: dsVariables.colors.Blue[200],
        borderWidth: 1,
        borderStyle: 'solid'
      },
      progressStyle: {
        height: 2,
        backgroundColor: dsVariables.colors.Blue[800]
      },
      icon: () => <PersonAddAltOutlined />
    })
  }

  function handleRenounceTakedown() {
    dispatch(setLoading(true))
    dispatch(renounceTakedown(currentIds))
    closeModalsAndSnacks()
    toast.success(
      <Label>Responsável removido da ameaça.</Label>,
      {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        transition: Bounce,
        style: {
          backgroundColor: dsVariables.colors.Green[100],
          color: dsVariables.colors.Green[600],
          borderColor: dsVariables.colors.Green[200],
          borderWidth: 1,
          borderStyle: 'solid'
        },
        icon: () => <PersonRemoveAlt1Outlined />
      })
  }


  function changeSLAFilter(e: any) {
    searchParams.set("sla", TakedownTextSlaEnum.EXPIRED);
    searchParams.set("page", "1");
    const newSearch = searchParams.toString();

    if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
  }

  const [savedFilters, setSavedFilters] = useState<{ [key: string]: string }>({});
  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const toggleOptionsVisibility = () => {
    setIsOptionsVisible((prev) => !prev);
  };

  function handleSelectFavoriteChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedFilterName = event.target.value;
    setSelectedFilter(selectedFilterName);
    loadFilter(selectedFilterName);
  }

  function loadFilter(filterName: string) {
    const filterParams = savedFilters[filterName];
    navigate({ search: filterParams });
  }

  useEffect(() => {
    const filters = JSON.parse(localStorage.getItem("TakedownsFavoriteFilters") || "{}");
    setSavedFilters(filters);
  }, []);

  return (
    <div className="page-content">
      {isLoading && <Loader />}
      <Container fluid>
        <Row className="d-flex flex-column gap-4">
          <SectionTitle title="Gerenciar Takedowns" />

          {/* <div className="d-flex w-100 justify-content-end">
            <ButtonSecondary onClick={changeSLAFilter} label="Filtrar tratativas em atraso" />
          </div> */}

          {getQuantityOfFiltersActive() > 0 && (
            <div className="d-flex flex-column gap-1 w-100 align-items-end">
              <span
                style={{ fontSize: 10, color: dsVariables.colors.Gray[900] }}
              >
                Filtros aplicados
              </span>

              <div className="d-flex gap-2">{getFilterChips()}</div>
            </div>
          )}

          <div className="d-flex flex-column box-content">
            <div className="d-flex flex-wrap row-gap-4 justify-content-between align-items-end px-3">
              {currentIds.length > 0 ? (
                <div ref={buttonActionsRef} className="position-relative">
                  <ButtonPrimary
                    width={370}
                    iconRight={ExpandMore}
                    iconRightColor="white"
                    label={`Ações em massa (${Number(
                      currentIds.length - 1 < 0 ? 0 : currentIds.length
                    )})`}
                    onClick={() => setShowOptionsCard(!showOptionsCard)}
                  />
                  {showOptionsCard && showActTakedown && (
                    <OptionsCard
                      onClick={handleOptionsCardClick}
                      operatorView
                    />
                  )}
                </div>
              ) : (
                <div className="py-3"></div>
              )}
              <div className="d-flex gap-3 flex-wrap">
                <Input
                  onClickIconRight={clearTextFilter}
                  iconRight={takedownsFilter.text ? Close : undefined}
                  width={276}
                  value={takedownsFilter?.text?.trim()}
                  onChange={handleTextFilterInput}
                  iconLeft={Search}
                  placeholder="Busque pela URL da ameaça"
                />
                <div className="position-relative">
                  <div className="d-flex" ref={filterButtonRef}>
                    <ButtonSecondary
                      onClick={() => setOpenFilter(!openFilter)}
                      type="normal"
                      iconLeft={Tune}
                      label="Filtros"
                      iconLeftColor={dsVariables.colors.Astronaut[900]}
                    />
                    <ButtonSecondary
                      iconLeft={FavoriteBorder}
                      iconLeftColor={dsVariables.colors.Astronaut[900]}
                      onClick={toggleOptionsVisibility}
                    />
                    <InvisibleSelect
                      value={selectedFilter}
                      label=""
                      onChange={handleSelectFavoriteChange}
                      options={[Object.keys(savedFilters).length === 0 ? "Salve um filtro" : "Selecione um filtro salvo"].concat(Object.keys(savedFilters).sort((a, b) => a.localeCompare(b)))}
                    />
                  </div>
                  {getQuantityOfFiltersActive() > 0 && (
                    <div
                      className="position-absolute d-flex align-items-center justify-content-center"
                      style={{
                        backgroundColor: dsVariables.colors.Red[400],
                        borderRadius: "50%",
                        width: 16,
                        height: 16,
                        top: -6,
                        right: 36,
                      }}
                    >
                      <span style={{ fontSize: 10, color: "white" }}>
                        {getQuantityOfFiltersActive()}
                      </span>
                    </div>
                  )}
                  {openFilter && (
                    <div ref={filterRef}>
                      <TakedownsFilterSection
                        selectedFilter={selectedFilter}
                        setSavedFilters={setSavedFilters}
                        savedFilters={savedFilters}
                        setSelectedFilter={setSelectedFilter}
                        handleSelectFavoriteChange={handleSelectFavoriteChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column h-100">
              <Tabs tabs={tabs} indexActive={tabActive} />
              <TableTakedowns
                showDetails={showDetails}
                showOptions={showOptions}
              />
            </div>
          </div>
        </Row>
      </Container>

      <Modal
        title="Detalhes da ameaça Index Takedowns"
        // subtitle="deadpooltagarela.com.br"
        isOpen={showModalDetails}
        onClose={() => setShowModalDetails(false)}
        width={980}
        footerButtons={[
          {
            buttonStyle: "secondary",
            // onClick: quarantineThreat,
            label: "Quarentena",
            iconLeft: Warning,
            iconLeftColor: dsVariables.colors.Astronaut[900],
          },
          {
            buttonStyle: "primary",
            // onClick: takedownThreat,
            label: "Solicitar takedown",
            iconLeft: WebAssetOff,
            iconLeftColor: "white",
          },
        ]}
      >
        {takedownsTableData?.filter(takedown => takedown._id === current?._id)
          .map((takedown) => (
            <div key={takedown._id}>
              <div>
                {takedown.sourceThreat?.searchText} + {takedown.isActive == false ? "Inativo" : "Ativo"}
              </div>
            </div>
          ))}
      </Modal>

      <ModalAssumeTakedown onConfirm={assumeTakedown} isOpen={showModalAssumeTakedown} onClose={() => setShowModalAssumeTakedown(false)} />
      <ModalRenounceTakedown onConfirm={handleRenounceTakedown} isOpen={showModalRenounceTakedown} onClose={() => setShowModalRenounceTakedown(false)} />

      <Modal
        width={480}
        isOpen={showModalBBCta}
        onClose={() => navigate("/manage-takedowns")}
        title="Contratar Brand Bidding"
        subtitle="Você não tem acesso ao módulo."
      >
        <br />
        <Body style="medium">
          Tenha controle sobre os Agressores que afetam sua empresa.
        </Body>
        <br />
        <div className="mt-2"></div>
        <Body>
          Contate um dos nossos atendentes o quanto antes, para que você possa
          controlar, monitorar e<Body style="medium">&nbsp;Eliminar&nbsp;</Body>
          os agressores que
          <Body style="medium">&nbsp;roubam&nbsp;</Body>
          seus lucros!
        </Body>
        <br />
        <br />

        <p>
          <Body style="medium">Entre em contato com seu atendimento!</Body>
        </p>
        <br />
      </Modal>

      {showModalTakedownDetail && <ModalTakedownDetail onClose={() => {
        setShowModalTakedownDetail(false)
        searchParams.delete('takedown')
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
      }} />}
      {showModalFinish && <ModalFinish onConfirm={finishTakedown} isOpen={showModalFinish} onClose={() => setShowModalFinish(false)} />}
      {showModalSpecialTreatment && <ModalSpecialTreatment onConfirm={handleSpecialTreatmentTakedown} isOpen={showModalSpecialTreatment} onClose={() => setShowModalSpecialTreatment(false)} />}
      {showModalReopenThreat && <ModalReopenThreat onConfirm={handleReopenThreats} isOpen={showModalReopenThreat} onClose={() => setShowModalReopenThreat(false)} />}

      <ToastContainer />

    </div>
  );
}
