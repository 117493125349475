import { createPortal } from "react-dom";
import { dsVariables } from "../variables";
import styled from "styled-components";
import { ReactNode, ReactElement } from "react";
import Icon from "./Icon";
import { Close, SvgIconComponent } from "@mui/icons-material";
import ButtonPrimary, { IButtonProps, TButtonStates, TButtonTypes } from "./Button/ButtonPrimary";
import ButtonSecondary from "./Button/ButtonSecondary";
import Sub6 from "./Text/Sub6";
import Label from "./Text/Label";

type TButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'quaternary'

export interface IFooterButton extends IButtonProps {
    buttonStyle: TButtonStyle
}

interface IProps {
    width: number | string
    height?: boolean | number
    isOpen: boolean
    onClose: any
    children: ReactNode
    backgroundColor?: string
    title: string
    subtitle?: string | ReactElement
    subtitleChildren?: ReactNode
    footerButtons?: IFooterButton[]
}

export default function Modal({ isOpen, onClose, children, backgroundColor = 'white', subtitle, title, footerButtons, width, height = false, subtitleChildren }: IProps) {
    if (!isOpen) return null;

    function renderButton(style: TButtonStyle, iconLeft: SvgIconComponent, iconLeftColor: string, onClick: any, label: string, type: TButtonTypes, state: TButtonStates, key: number) {
        switch (style) {
            case "primary": return <ButtonPrimary key={key} iconLeft={iconLeft} iconLeftColor={iconLeftColor} onClick={onClick} label={label} type={type} state={state} />
            case "secondary": return <ButtonSecondary key={key} iconLeft={iconLeft} iconLeftColor={iconLeftColor} onClick={onClick} label={label} type={type} state={state} />
            case "tertiary": return <ButtonPrimary key={key} iconLeft={iconLeft} iconLeftColor={iconLeftColor} onClick={onClick} label={label} type={type} state={state} />
            case "quaternary": return <ButtonPrimary key={key} iconLeft={iconLeft} iconLeftColor={iconLeftColor} onClick={onClick} label={label} type={type} state={state} />
        }
    }

    return createPortal(
        <ModalContainer className="w-100 h-100 top-0 start-0 position-fixed d-flex justify-content-center align-items-center" onClick={onClose}>
            <ModalContent width={width} height={typeof height == 'string' || typeof height == 'number' ? height : undefined} backgroundColor={backgroundColor} className="position-relative d-flex flex-column" onClick={(e) => e.stopPropagation()}>
                <ModalHeader className="d-flex flex-column">
                    <Sub6 color={dsVariables.colors.Gray[800]}>{title}</Sub6>
                    <div className="d-flex gap-4">
                        <Label color={dsVariables.colors.Gray[800]}>{subtitle}</Label>
                        {subtitleChildren}
                    </div>
                </ModalHeader>
                <ButtonClose className="position-absolute bg-transparent border-0" onClick={onClose}>
                    <Icon color={dsVariables.colors.Astronaut[900]} IconComponent={Close} />
                </ButtonClose>
                <ModalChildren height={typeof height == 'boolean' ? height : false}>
                    {children}
                </ModalChildren>
                {footerButtons && <ModalFooter className="d-flex justify-content-center position-absolute bottom-0 w-100 py-3">
                    {footerButtons.map((button, index) => renderButton(button.buttonStyle, button.iconLeft!, button.iconLeftColor!, button.onClick, button.label!, button.type!, button.state!, index))}
                </ModalFooter>
                }
            </ModalContent>
        </ModalContainer>,
        document.getElementById("modal-root") as HTMLElement
    );
}

const ModalChildren = styled.div<{ height?: boolean }>`
    height: ${({ height }) => height ? 340 : 'unset'}px;
    max-height: 458px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 16px;
    margin-right: 2px;
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${dsVariables.colors.Astronaut[400]};
    }
    &::-webkit-scrollbar-track {
        background-color: ${dsVariables.colors.Gray[200]};
    }
`

const ModalFooter = styled.footer`
    background-color:white;
    gap: ${dsVariables.spacing[16]};
    border: 1px 0px 0px 0px;
    padding: ${dsVariables.spacing[8]} ${dsVariables.spacing[16]};
    border-radius: ${dsVariables.cornerRadius[8]};
`

const ModalContainer = styled.div`
    background-color: ${dsVariables.colors.Gray[800] + 'dd'};
    z-index: 9999;
`

const ModalHeader = styled.div`
    background: white;
    gap: ${dsVariables.spacing[8]};
    padding: ${dsVariables.spacing[16]};
    border-radius: ${dsVariables.cornerRadius[8]};
`

const ModalContent = styled.div<{ backgroundColor?: string, width: number | string, height?: number | string }>`
    background: ${props => props.backgroundColor};
    width: ${props => `${typeof props.width == 'number' ? props.width + 'px' : props.width}`};
    height: ${props => `${typeof props.height == 'number' ? props.height + 'px' : props.height}`};
    max-width: 1920px;
    min-width: 584px;
    padding-bottom: 70px;
    border-radius: ${dsVariables.cornerRadius[8]};
`

const ButtonClose = styled.button`
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
`