import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Link, useLocation } from 'react-router-dom';
import { Col, Collapse, Row } from 'reactstrap';
import withRouter from '../../Components/Common/withRouter';

// Import Data
import navdata from "../LayoutMenuData";
//i18n
import { withTranslation } from "react-i18next";
import { dsVariables } from '../../DesignSystem/variables';
import { useSelector } from 'react-redux';
import Icon from '../../DesignSystem/components/Icon';
import { KeyboardArrowDown } from '@mui/icons-material';
import useIsEnabledOrTrial from '../../DesignSystem/hooks/IsEnabledOrTrial.hook';
import { RolesEnum } from '../../enums/roles.enum';

const HorizontalLayout = (props) => {
    const location = useLocation()

        const { isFreemium, isCustomer, companyProducts } = useSelector(store => {
        return {
            isFreemium: store.Profile.user.isFreemium,
            isCustomer: store.Profile.user.isCustomer,
            companyProducts: store.CompanyProducts.products,
        }
    })

    const { atLeastOne: isBBEnabledOrTrial } = useIsEnabledOrTrial("brand bidding")
    const { atLeastOne: isFraudEnabledOrTrial } = useIsEnabledOrTrial("fraud")

    const navData = navdata().props.children;
    let menuItems = [];
    let splitMenuItems = [];
    let menuSplitContainer = 6;
    navData.forEach(function (value, key) {
        if (value['isHeader']) {
            menuSplitContainer++;
        }
        if (key >= menuSplitContainer) {
            let val = value;
            val.childItems = value.subItems;
            val.isChildItem = (value.subItems) ? true : false;
            delete val.subItems;
            splitMenuItems.push(val);
        } else {
            menuItems.push(value);
        }
    });
    // menuItems.push({ id: 'more', label: 'More', icon: 'ri-briefcase-2-line', link: "/#", stateVariables: isMoreMenu, subItems: splitMenuItems, click: function (e) { e.preventDefault(); setIsMoreMenu(!isMoreMenu); }, });

    const path = props.router.location.pathname;

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const initMenu = () => {
            const pathName = process.env.PUBLIC_URL + path;
            const ul = document.getElementById("navbar-nav");
            const items = ul.getElementsByTagName("a");
            let itemsArray = [...items]; // converts NodeList to Array
            removeActivation(itemsArray);

            let matchingMenuItem = itemsArray.find((x) => {
                return (x.pathname === pathName.replaceAll('/', '') || x.pathname.includes(pathName.replaceAll('/', '')));
            });
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
    }, [path, props.layoutType]);

    function activateParentDropdown(item) {
        item.classList.add("active");
        let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

        if (parentCollapseDiv) {

            // to set aria expand true remaining
            parentCollapseDiv.classList.add("show");
            parentCollapseDiv.parentElement.children[0].classList.add("active");
            parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true");
            if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show");
                var parentElementDiv = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling;
                if (parentElementDiv)
                    if (parentElementDiv.closest(".collapse"))
                        parentElementDiv.closest(".collapse").classList.add("show");
                parentElementDiv.classList.add("active");
                var parentElementSibling = parentElementDiv.parentElement.parentElement.parentElement.previousElementSibling;
                if (parentElementSibling) {
                    parentElementSibling.classList.add("active");
                }
            }
            return false;
        }
        return false;
    }

    const removeActivation = (items) => {
        let actiItems = items.filter((x) => x.classList.contains("active"));

        actiItems.forEach((item) => {
            if (item.classList.contains("menu-link")) {
                if (!item.classList.contains("active")) {
                    item.setAttribute("aria-expanded", false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
            }
            if (item.classList.contains("nav-link")) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
                item.setAttribute("aria-expanded", false);
            }
            item.classList.remove("active");
        });
    };

    if(location.pathname.includes('admin')) return null

    return (
        <React.Fragment>
            {(!isFreemium) && (menuItems || []).map((item, key) => {
                return (
                    <React.Fragment key={key}>
                        {/* Main Header */}
                        {!item['isHeader'] ?
                            (item.subItems ? (
                                <li className="nav-item">
                                    <Link
                                        style={{ color: isCustomer && !isBBEnabledOrTrial ? '#666666' : '#30437f' }}
                                        className="nav-link menu-link align-items-center gap-1"
                                        onClick={(isBBEnabledOrTrial) && item.click}
                                        to={item.link ? item.link : "/#"}
                                        data-bs-toggle="collapse"
                                    >
                                        <span data-key="t-apps">{props.t(item.label)}</span>
                                        <div className=''>
                                            {(!props.t(item.label).includes('Fraudes') && isCustomer ? isBBEnabledOrTrial : true) && <Icon width={12} height={12} IconComponent={KeyboardArrowDown} />}
                                        </div>
                                        {(!props.t(item.label).includes('Fraudes') && isCustomer && !isBBEnabledOrTrial) && <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.47933 2.25001L5.33333 4.93268L3.18733 2.25001C3.10066 2.14286 2.98296 2.06515 2.85039 2.02752C2.71781 1.98989 2.57684 1.99419 2.4468 2.03982C2.31676 2.08546 2.20401 2.17019 2.12403 2.28242C2.04404 2.39464 2.00071 2.52886 2 2.66667V12.6667C2 13.402 2.598 14 3.33333 14H12.6667C13.402 14 14 13.402 14 12.6667V2.66667C13.9993 2.52886 13.956 2.39464 13.876 2.28242C13.796 2.17019 13.6832 2.08546 13.5532 2.03982C13.4232 1.99419 13.2822 1.98989 13.1496 2.02752C13.017 2.06515 12.8993 2.14286 12.8127 2.25001L10.6667 4.93268L8.52067 2.25001C8.26667 1.93401 7.73333 1.93401 7.47933 2.25001ZM3.33333 12.6667V11.3333H12.6673V12.6667H3.33333ZM10.146 6.41668C10.4 6.73334 10.934 6.73334 11.188 6.41668L12.6667 4.56734L12.6673 10H3.33333V4.56734L4.81267 6.41668C5.06667 6.73334 5.60067 6.73334 5.85467 6.41668L8 3.73401L10.146 6.41668Z" fill="#30437F" />
                                        </svg>
                                        }
                                    </Link>
                                    {item.subItems?.length > 0 && <Collapse
                                        className={item.id === "baseUi" && item.subItems.length > 13 ? "menu-dropdown mega-dropdown-menu" : "menu-dropdown"}
                                        isOpen={item.stateVariables}
                                        id="sidebarApps">
                                        {/* subItms  */}
                                        {item.id === "baseUi" && item.subItems.length > 13 ? (
                                            <React.Fragment>
                                                <Row>
                                                    {item.subItems && ((item.subItems || []).map((subItem, key) => (
                                                        <React.Fragment key={key}>
                                                            <Col lg={4}>
                                                                <ul className="nav nav-sm flex-column">
                                                                    <li className="nav-item">
                                                                        <Link to={item.subItems[key].link} className="nav-link text-decoration-none">{item.subItems[key].label}</Link>
                                                                    </li>
                                                                </ul>
                                                            </Col>
                                                        </React.Fragment>
                                                    ))
                                                    )}
                                                </Row>
                                            </React.Fragment>
                                        ) : (
                                            <ul className="nav nav-sm flex-column test">
                                                {item.subItems && ((item.subItems || []).map((subItem, key) => (
                                                    <React.Fragment key={key}>
                                                        {!subItem.isChildItem ? (
                                                            <li className="nav-item">
                                                                <Link
                                                                    to={subItem.link ? subItem.link : "/#"}
                                                                    className="nav-link border-bottom-0"
                                                                >
                                                                    <Icon IconComponent={subItem.icon} /> {props.t(subItem.label)}
                                                                </Link>
                                                            </li>
                                                        ) : (
                                                            <li className="nav-item">
                                                                <Link
                                                                    onClick={subItem.click}
                                                                    className="nav-link border-bottom-0"
                                                                    to="/#"
                                                                    data-bs-toggle="collapse"
                                                                >
                                                                    {props.t(subItem.label)}
                                                                </Link>
                                                                <Collapse className="menu-dropdown" isOpen={subItem.stateVariables} id="sidebarEcommerce">
                                                                    <ul className="nav nav-sm flex-column">
                                                                        {/* child subItms  */}
                                                                        {subItem.childItems && (
                                                                            (subItem.childItems || []).map((subChildItem, key) => (
                                                                                <React.Fragment key={key}>
                                                                                    {!subChildItem.isChildItem ? (
                                                                                        <li className="nav-item">
                                                                                            <Link
                                                                                                to={subChildItem.link ? subChildItem.link : "/#"}
                                                                                                className="nav-link"
                                                                                            >
                                                                                                {props.t(subChildItem.label)}
                                                                                            </Link>
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li className="nav-item">
                                                                                            <Link
                                                                                                onClick={subChildItem.click}
                                                                                                className="nav-link"
                                                                                                to="/#"
                                                                                                data-bs-toggle="collapse"
                                                                                            > {props.t(subChildItem.label)}
                                                                                            </Link>
                                                                                            <Collapse className="menu-dropdown" isOpen={subChildItem.stateVariables} id="sidebarEcommerce">
                                                                                                <ul className="nav nav-sm flex-column">
                                                                                                    {/* child subItms  */}
                                                                                                    {subChildItem.childItems && (
                                                                                                        (subChildItem.childItems || []).map((subSubChildItem, key) => (
                                                                                                            <li className="nav-item apex" key={key}>
                                                                                                                <Link
                                                                                                                    to={subSubChildItem.link ? subSubChildItem.link : "/#"}
                                                                                                                    className="nav-link"
                                                                                                                >
                                                                                                                    {props.t(subSubChildItem.label)}
                                                                                                                </Link>
                                                                                                            </li>
                                                                                                        ))
                                                                                                    )}
                                                                                                </ul>
                                                                                            </Collapse>
                                                                                        </li>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            ))
                                                                        )}
                                                                    </ul>
                                                                </Collapse>
                                                            </li>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                                )}
                                            </ul>
                                        )}
                                    </Collapse>}
                                </li>
                            ) : (
                                <li className="nav-item">
                                    <Link
                                        style={{ color: isCustomer && !isBBEnabledOrTrial ? '#666666' : '#30437f' }}
                                        className="nav-link menu-link"
                                        to={item.link ? item.link : "/#"}>
                                        {item.icon && <i className={item.icon}></i>}

                                        <span style={{ color: (props.t(item.label).includes('Fraudes') && isCustomer && isFraudEnabledOrTrial) && dsVariables.colors.Gray[400] }}>{props.t(item.label)}</span>
                                        {(props.t(item.label).includes('Fraudes') && isCustomer && isFraudEnabledOrTrial) && <div className='text-decoration-none px-1 py-1 rounded-1 ms-2' style={{ backgroundColor: dsVariables.colors.Astronaut[500], height: 'fit-content', textDecoration: 'none', color: dsVariables.colors.Astronaut[0], fontSize: 10, fontWeight: 600 }}>Novo</div>}
                                        {(props.t(item.label).includes('Fraudes') && isCustomer && !isFraudEnabledOrTrial) && <svg className='ms-2' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">

                                            <path d="M7.47933 2.25001L5.33333 4.93268L3.18733 2.25001C3.10066 2.14286 2.98296 2.06515 2.85039 2.02752C2.71781 1.98989 2.57684 1.99419 2.4468 2.03982C2.31676 2.08546 2.20401 2.17019 2.12403 2.28242C2.04404 2.39464 2.00071 2.52886 2 2.66667V12.6667C2 13.402 2.598 14 3.33333 14H12.6667C13.402 14 14 13.402 14 12.6667V2.66667C13.9993 2.52886 13.956 2.39464 13.876 2.28242C13.796 2.17019 13.6832 2.08546 13.5532 2.03982C13.4232 1.99419 13.2822 1.98989 13.1496 2.02752C13.017 2.06515 12.8993 2.14286 12.8127 2.25001L10.6667 4.93268L8.52067 2.25001C8.26667 1.93401 7.73333 1.93401 7.47933 2.25001ZM3.33333 12.6667V11.3333H12.6673V12.6667H3.33333ZM10.146 6.41668C10.4 6.73334 10.934 6.73334 11.188 6.41668L12.6667 4.56734L12.6673 10H3.33333V4.56734L4.81267 6.41668C5.06667 6.73334 5.60067 6.73334 5.85467 6.41668L8 3.73401L10.146 6.41668Z" fill={dsVariables.colors.Astronaut[500]} />
                                        </svg>
                                        }
                                    </Link>
                                </li>
                            ))
                            : (<li className="menu-title"><span data-key="t-menu">{props.t(item.label)}</span></li>)}
                    </React.Fragment>
                );
            })}
            {/* menu Items */}
        </React.Fragment >
    );
};

HorizontalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(HorizontalLayout));
