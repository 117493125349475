import { useState } from "react";
import styled from "styled-components";
import Label from "./Text/Label";
import { ChevronLeft } from "@mui/icons-material";
import Icon from "./Icon";
import { dsVariables } from "../variables";
import Tag from "./Tag";

type TBorder = 'top' | 'bottom' | 'top-bottom' | any

interface IProps {
  children: React.ReactNode
  title: string
  withBorder?: boolean
  headerIcon?: any
  headerIconOnClick?: any
  headerIconColor?: any
  borderType?: TBorder
  defaultOpened?: boolean
  isChild?: boolean
  headerLabels?: any[]
}

export default function Accordion({
  title, children, withBorder = false, headerIcon, headerIconOnClick,
  headerIconColor, borderType, defaultOpened = false, isChild = false,
  headerLabels
}: IProps) {
  const [isOpen, setIsOpen] = useState(defaultOpened);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContainer className="position-relative" borderType={borderType} withBorder={withBorder}>
      <AccordionHeader isChild={isChild} isOpen={isOpen} onClick={toggleAccordion}>
        <Label style="medium">{title}</Label>

        <div className="d-flex gap-2 align-items-center">
          {headerIcon && <Icon
            onClick={headerIconOnClick} width={16} height={16}
            IconComponent={headerIcon} color={headerIconColor} />}

          {
            headerLabels?.map((el, index) =>
              <Tag key={index} label={el.label} backgroundColor={el.backgroundColor} textColor={el.textColor} />
            )
          }


          <span>{
            isOpen
              ? <div style={{ rotate: '90deg' }}><ChevronLeft width={16} height={16} /></div>
              : <div style={{ rotate: '-90deg' }}><ChevronLeft width={16} height={16} /></div>
          }
          </span>
        </div>
      </AccordionHeader>
      <AccordionContent className="overflow-auto" withBorder={withBorder} isOpen={isOpen}>{children}</AccordionContent>
    </AccordionContainer>
  );
}

const AccordionContainer = styled.div<{ withBorder?: boolean, borderType?: TBorder }>`
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 4px;
  ${({ withBorder }) => withBorder && 'border: 1px solid #E6E6E6'};
  
  ${props => {
    if (props.borderType) {
      if (props.borderType == 'top') return 'border-top-right-radius: 0;border-top-left-radius: 0;'
      else if (props.borderType == 'bottom') return 'border-bottom-right-radius: 0;border-bottom-left-radius: 0;'
      else if (props.borderType == 'top-bottom') return 'border-bottom-right-radius: 0;border-bottom-left-radius: 0;border-top-right-radius: 0;border-top-left-radius: 0;'
    }
  }

  };
`;

const AccordionHeader = styled.div<{ isOpen: boolean, isChild?: boolean }>`
  padding: ${({ isChild }) => isChild ? 8 : 16}px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isOpen }) => isOpen && `background-color: #F7F7F7; color: ${dsVariables.colors.Astronaut[900]}`}
`;

const AccordionContent = styled.div<{ isOpen: boolean, withBorder?: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  padding: ${({ isOpen }) => (isOpen ? "15px" : "0 15px")};
  font-size: 14px;
  ${({ withBorder }) => withBorder && 'border-top: 1px solid #E6E6E6'};
  transition: all 0.3s ease;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  padding-bottom: 20;
  &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${dsVariables.colors.Astronaut[400]};
    }
    &::-webkit-scrollbar-track {
        background-color: ${dsVariables.colors.Gray[200]};
    }
`;