import { Form, FormControl, FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import CompaniesDropdownAdmin from "../companies-dropdown-admin";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { Bounce, toast } from "react-toastify";
import { getAllCompaniesToAdmin, getCompaniesGroup, inviteUser } from "../../../../store/admin/action";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { setLoading } from "../../../../store/threats/actions";
import useCanPerformAction from "../../../hooks/CanPerformAction.hook";
import { ActionEnum } from "../../../constants/user-action-permissions.constant";
import { RolesEnum } from "../../../../enums/roles.enum";
import { permissionCategory } from "../../../constants/permission-category.constant";

export default function InviteForm() {

    const dispatch = useDispatch()

    const { companiesGroup, user, currentUser, users, companies } = useSelector((store: IStore) => ({
        companiesGroup: store.Admin.companiesGroupList,
        user: store.Admin.user,
        currentUser: store.Profile.user,
        users: store.Admin.users,
        companies: store.Admin.companiesList,
    }))

    const [userType, setUserType] = useState<'customer' | 'operator' | 'agency' | 'freemium'>();
    const [companiesSelected, setCompaniesSelected] = useState<string[]>([]);
    const [selectedCompany, setSelectedCompany] = useState('');

    const canInviteOperator = useCanPerformAction(ActionEnum.invite_agents)
    const canInviteCustomer = useCanPerformAction(ActionEnum.invite_customers)
    const canInviteFreemium = useCanPerformAction(ActionEnum.invite_freemium)
    const canEdit = useCanPerformAction(ActionEnum.edit_permissions)

    function handleSubmit(e: any) {
        e.preventDefault()
        const email = e.currentTarget.email.value

        if (currentUser.email == email) return toast('Não é possível alterar o seu próprio usuário', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Bounce,
            type: 'warning'
        })

        if (userType == 'operator' && !(currentUser.email.includes('branddi') || currentUser.email.includes('brandmonitor'))) {
            return toast(`Você não tem permissão para convidar um Agente Branddi`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                transition: Bounce,
                type: 'warning'
            })
        }

        if (userType != 'operator' && companiesSelected.length == 0) return toast('Seleciona ao menos uma empresa', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Bounce,
            type: 'warning'
        })

        const permissions = (() => {
            if (userType === 'operator') {
                return [...e.currentTarget.operator_permission].filter((el: any) => el.checked);
            }
            if (userType === 'freemium') {
                return [{value:RolesEnum.FREEMIUM}];
            }
            return [...e.currentTarget.customer_permission].filter((el: any) => el.checked);
        })();

        if (!permissions.length) return toast('Insira ao menos uma permissão', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            transition: Bounce,
            type: 'warning'
        })

        const categoriesByPermission = permissions.map((el: any) => {
            let category: string;
        
            if (userType === 'operator') {
                // @ts-ignore
                category = document.getElementById(el.value)?.value || '';
            } else if (userType === 'freemium') {
                category = 'freemium';
            } else {
                category = 'customer';
            }
        
            return {
                category,
                permission: el?.value || '',
            };
        });

        const firstName = e.currentTarget.name.value
        const lastName = e.currentTarget.last_name.value
        dispatch(setLoading(true))

        dispatch(inviteUser({
            firstName, lastName, email,
            categoriesByPermission,
            customerCompaniesList: userType == 'operator' ? [] : companiesSelected
        }))
    }

    function handleCompanyGroup(id: string) {
        if (companiesSelected?.find(el => el == id)) setCompaniesSelected(companiesSelected.filter(el => el != id))
        else {
            if (isGroup(id)) setCompaniesSelected([id])
            else if (hasGroupSelected()) {
                const groupId = companiesSelected?.find(el => el == companiesGroup.find(cp => cp?.isGroup)?._id)
                const companiesListUpdated = companiesSelected?.filter(el => el != groupId)
                setCompaniesSelected(companiesListUpdated)
            }
            else setCompaniesSelected([...companiesSelected, id])
        }
    }

    function hasGroupSelected() {
        return Boolean(companiesSelected?.find(el => el == companiesGroup.find(cp => cp?.isGroup)?._id))
    }

    function isGroup(currentCompanyId: string) {
        return currentCompanyId == companiesSelected?.find(el => el == companiesGroup.find(cp => cp?.isGroup)?._id)
    }

    function handleChangeOperator(e: any) {
        const permission = e.target.value
        const { checked } = e.target

        if (!checked) return

        if (permission == RolesEnum.COMMERCIAL || permission == RolesEnum.ADMIN || permission == RolesEnum.MANAGER)
            return document.getElementsByName(e.target.name).forEach((el: any) => {
                if (el.value != permission) el.checked = false
            })

        return document.getElementsByName(e.target.name).forEach((el: any) => {
            if (el.value == RolesEnum.COMMERCIAL || el.value == RolesEnum.ADMIN || el.value == RolesEnum.MANAGER) el.checked = false
        })
    }

    function handleCompany(e: any) {
        setCompaniesSelected([])
        dispatch(getCompaniesGroup(e.target.value))
        setSelectedCompany(e.target.value)
    }

    function updateCompaniesSelected(id: string) {
        if (companiesSelected?.find(el => el == id)) setCompaniesSelected(companiesSelected.filter(el => el != id))
        else setCompaniesSelected([...companiesSelected, id])
    }

    useEffect(() => {
        if (companies.length == 0) dispatch(getAllCompaniesToAdmin())
        else dispatch(getCompaniesGroup(companies[0]._id))
    }, [companies]);

    useEffect(() => {
        if (companiesGroup.length == 1) setCompaniesSelected([...companiesSelected, companiesGroup[0]?._id])
    }, [companiesGroup]);

    useEffect(() => {
        setCompaniesSelected([])
    }, [userType]);

    return (
        <Form onSubmit={handleSubmit} className="d-flex flex-column gap-4 mt-5">
            < FormGroup >
                <FormLabel className="text-black fs-5">Nome</FormLabel>
                <FormControl required name='name' type="text" placeholder="Insira o primeiro nome do usuário" />
            </FormGroup>
            <FormGroup>
                <FormLabel className="text-black fs-5">Último nome</FormLabel>
                <FormControl required name='last_name' type="text" placeholder="Insira o último nome do usuário" />
            </FormGroup>
            <FormGroup>
                <FormLabel className="text-black fs-5">Email</FormLabel>
                <div className="d-flex gap-2">
                    <FormControl required name="email" type="email" placeholder="Insira o email do usuário" />
                </div>
            </FormGroup>
            <FormGroup>
                <FormLabel className="text-black fs-5">Selecionar tipo de usuário</FormLabel>
                <div className="d-flex gap-4">
                    {canInviteCustomer && <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Cliente</FormLabel>
                        <input required onChange={_ => setUserType('customer')} name="user_type" type="radio" />
                    </div>}
                    {canInviteOperator && <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Agente Branddi</FormLabel>
                        <input required onChange={_ => setUserType('operator')} name="user_type" type="radio" />
                    </div>}
                    {canInviteCustomer && <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Agência</FormLabel>
                        <input required onChange={_ => setUserType('agency')} name="user_type" type="radio" />
                    </div>}
                    {canInviteFreemium && <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Freemium</FormLabel>
                        <input required onChange={_ => setUserType('freemium')} name="user_type" type="radio" />
                    </div>}
                </div>
            </FormGroup>
            {userType != 'operator' && <FormGroup>
                <FormLabel className="text-black fs-5">Selecionar permissões do cliente</FormLabel>
                <div className="d-flex gap-4">
                    <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Visualização</FormLabel>
                        <input defaultChecked={user ? user.roles.find((el: any) => el.name == RolesEnum.CUSTOMER_VIEW) : false} required type="radio" name="customer_permission" value={RolesEnum.CUSTOMER_VIEW} />
                    </div>
                    <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Triagem</FormLabel>
                        <input disabled={userType=="freemium"} defaultChecked={user ? user.roles.find((el: any) => el.name == RolesEnum.CUSTOMER_TRIAGE) : false} required type="radio" name="customer_permission" value={RolesEnum.CUSTOMER_TRIAGE} />
                    </div>
                    <div className="d-flex gap-2">
                        <FormLabel className="mb-0">Admin</FormLabel>
                        <input disabled={userType=="freemium"} defaultChecked={user ? user.roles.find((el: any) => el.name == RolesEnum.CUSTOMER_ADMIN) : false} required type="radio" name="customer_permission" value={RolesEnum.CUSTOMER_ADMIN} />
                    </div>
                </div>
            </FormGroup>}
            {userType == 'operator' && <FormGroup>
                <FormLabel className="text-black fs-5">Selecionar permissões do agente interno</FormLabel>
                <div className="d-flex gap-5 flex-wrap">
                    {Object.values(RolesEnum).filter(el => !el.includes('Cliente')).map((el, index) =>
                        <div key={index} className="d-flex gap-2 flex-column">
                            <div className="d-flex gap-2">
                                <FormLabel className="mb-0">{el}</FormLabel>
                                <input defaultChecked={user?.roles.find((role: any) => role.name == el)} onChange={handleChangeOperator} type="checkbox" name="operator_permission" value={el} />
                            </div>
                            <FormSelect defaultValue={user?.roles.find((role: any) => role.name == el && role.category == Object.values(permissionCategory).find(el => el.value == role.category)?.value)?.category} id={el} name="operator_category">
                                {Object.values(permissionCategory).map((el, index) => <option key={index} value={el.value}>{el.text}</option>)}
                            </FormSelect>
                        </div>)}
                </div>
            </FormGroup>}
            {(userType && userType != 'operator') &&
                <FormGroup>
                    <FormLabel className="text-black fs-5">Selecionar empresas</FormLabel>
                    <CompaniesDropdownAdmin companiesSelected={companiesSelected} updateCompaniesSelected={updateCompaniesSelected} userType={userType} selectedCompany={selectedCompany} handleCompany={handleCompany} />
                    {(userType == 'agency' && Boolean(companiesSelected.length)) && <p className="mt-2 fw-semibold"><span className="text-secondary">{companiesSelected.length}</span> empresas selecionadas</p>}
                </FormGroup>
            }
            {(userType && userType == 'customer' && companiesGroup?.length > 0) &&
                <FormGroup>
                    <FormLabel className="text-black fs-5">Selecionar empresas do mesmo grupo</FormLabel>
                    <div className="d-flex flex-column gap-2">
                        {companiesGroup?.map((el, index) =>
                            <div onClick={() => handleCompanyGroup(el._id)}
                                role={(!hasGroupSelected() || isGroup(el._id)) ? "button" : "none"}
                                className={`form-control d-flex gap-2 ${companiesSelected.length > 0 ?
                                    hasGroupSelected()   // Primeira verificação para saber se existe alguma empresa que é 'grupo' nos selecionados
                                        ? isGroup(el._id) // Segunda verificação para saber se a empresa atual é 'grupo'
                                            ? 'bg-primary text-white'
                                            : 'bg-dark text-white pe-none opacity-50'
                                        : companiesSelected.find(cp => cp == el._id) // Terceira verificação para estilizar a empresa selecionada
                                            ? 'bg-primary text-white'
                                            : companiesGroup.find(cp => cp?.isGroup && cp._id == el._id) // Quarta verificação para travar a empresa 'grupo'
                                                ? 'bg-dark text-white pe-none opacity-50'
                                                : ''
                                    : ''
                                    }`} key={index}>
                                <input className="pe-none" checked={Boolean(companiesSelected.find(cp => cp == el._id))} type="checkbox" name="" id="" />
                                <span>{el.name}</span>
                            </div>
                        )}
                    </div>
                </FormGroup>
            }

            {(canInviteFreemium || canInviteCustomer || canInviteOperator || canEdit) && <div className="d-flex">
                <ButtonPrimary isSubmit label="Enviar" />
            </div>}
        </Form>
    )
}