import { Dropdown, Form, Pagination } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IStore } from "../../../../interfaces/store.interface";
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { dsVariables } from "../../../variables";
import { useEffect, useRef, useState } from "react";
import Icon from "../../../components/Icon";

const Paginator = ({totalCount}:any) => {

    
    const navigate = useNavigate();
    const location = useLocation();
    
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const ellipsisRef = useRef<HTMLDivElement | null>(null);
    const selectedItemRef = useRef<any>(null)
    
    const [searchParams, _] = useSearchParams();
    const currentPage = parseInt(searchParams.get('page')!) || 1;
    const tabActive = searchParams.get('tab') || '0'
    
    const resultsPerPage = parseInt(searchParams.get('itemsPerPage')!) || 10
    const totalPages = Math.ceil(totalCount / resultsPerPage)

    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handlePageChange = (pageNumber: any) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            searchParams.set('page', pageNumber);
            const newSearch = searchParams.toString();
            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }
    };

    const handleResultsPerPageChange = (e: any) => {
        const newResultsPerPage = e.target.value;
        searchParams.set('page', '1');
        searchParams.set('itemsPerPage', newResultsPerPage);
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    };


    useEffect(() => {
        searchParams.set('page', '1');
        const newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, [tabActive]);

    useEffect(() => {
        function closeDropdown(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node) &&
                ellipsisRef.current && !ellipsisRef.current.contains(event.target as Node)
            ) {
                setShowDropdown(false);
            }
        }

        document.addEventListener('mousedown', closeDropdown)

        if (showDropdown && selectedItemRef.current) {
            selectedItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        return () => {
            document.removeEventListener('mousedown', closeDropdown)
        }
    }, [showDropdown]);

    function getTabQuantity(){
        return 0
    }

    return (
        <div className="d-flex justify-content-between align-items-center mx-4">
            <div className="d-flex gap-3">
                <span>
                    Exibindo <span className="fw-medium">{resultsPerPage * currentPage - resultsPerPage + 1}</span> a <span className="fw-medium">{totalCount < resultsPerPage * currentPage ? totalCount : resultsPerPage * currentPage}</span> de <span className="fw-medium">{totalCount}</span> Resultados
                </span>

                <Form.Select
                    value={resultsPerPage}
                    onChange={handleResultsPerPageChange}
                    style={{ width: 80, paddingLeft: 10 }}
                >
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </Form.Select>
            </div>

            <Pagination>
                <button className="border-0 bg-transparent" onClick={() => handlePageChange(1)} disabled={currentPage === 1} >
                    <Icon color={currentPage === 1 ? dsVariables.colors.Gray[300] : dsVariables.colors.Astronaut[900]} width={12} height={12} IconComponent={KeyboardDoubleArrowLeft} />
                </button>
                <button className="border-0 bg-transparent" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} >
                    <Icon color={currentPage === 1 ? dsVariables.colors.Gray[300] : dsVariables.colors.Astronaut[900]} width={12} height={12} IconComponent={KeyboardArrowLeft} />
                </button>

                {
                    [...Array(totalPages)].map((_, index) => {
                        const pageNum = index + 1;

                        let startPage = currentPage

                        if (startPage + 4 >= totalPages) {
                            startPage = totalPages - 4;
                        }

                        if (pageNum >= startPage && pageNum < startPage + 4) {
                            return (
                                <Pagination.Item
                                    style={{ borderRadius: 4, overflow: 'hidden', width: 32, height: 32 }}
                                    className="text-center"
                                    key={index}
                                    active={pageNum === currentPage}
                                    onClick={() => handlePageChange(pageNum)}
                                >
                                    {pageNum}
                                </Pagination.Item>
                            );
                        }

                        if (pageNum === startPage + 4 && totalPages > startPage + 4) {
                            return (
                                <Pagination.Ellipsis
                                    ref={ellipsisRef}
                                    key="ellipsis"
                                    onClick={toggleDropdown}
                                />
                            );
                        }

                        if (pageNum === totalPages) {
                            return (
                                <Pagination.Item
                                    style={{ borderRadius: 4, overflow: 'hidden', width: 32, height: 32 }}
                                    className="text-center"
                                    key={index}
                                    active={pageNum === currentPage}
                                    onClick={() => handlePageChange(pageNum)}
                                >
                                    {pageNum}
                                </Pagination.Item>
                            );
                        }

                        return null;
                    })
                }

                {showDropdown && (
                    <div ref={dropdownRef} style={{ position: 'absolute', zIndex: 1000 }}>
                        <Dropdown.Menu show>
                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                {
                                    [...Array(totalPages)].map((_, index) => {
                                        const pageNum = index + 1;
                                        return (
                                            <Dropdown.Item
                                                ref={pageNum === currentPage ? selectedItemRef : null}
                                                key={index}
                                                active={pageNum === currentPage}
                                                onClick={() => {
                                                    handlePageChange(pageNum);
                                                    setShowDropdown(false);
                                                }}
                                            >
                                                {pageNum}
                                            </Dropdown.Item>
                                        );
                                    })
                                }
                            </div>
                        </Dropdown.Menu>
                    </div>
                )}

                <button className="border-0 bg-transparent" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    <Icon color={currentPage === totalPages ? dsVariables.colors.Gray[300] : dsVariables.colors.Astronaut[900]} width={12} height={12} IconComponent={KeyboardArrowRight} />
                </button>
                <button className="border-0 bg-transparent" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                    <Icon color={currentPage === totalPages ? dsVariables.colors.Gray[300] : dsVariables.colors.Astronaut[900]} width={12} height={12} IconComponent={KeyboardDoubleArrowRight} />
                </button>
            </Pagination>
        </div>
    );
};

export default Paginator;