import { useEffect, useState } from "react";
import ButtonSecondary from "../../../components/Button/ButtonSecondary";
import RegistrarTable from "./Tables/registrar-table";
import DomainTable from "./Tables/domain-table";
import HostTable from "./Tables/host-table";
import { useLocation, useNavigate } from "react-router-dom";


export default function Databases() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate()

    const [databaseSelected, setDatabaseSelected] = useState<'registrar' | 'domain' | 'host'>('registrar');

    function renderTable() {
        switch (databaseSelected) {
            case "registrar": return <RegistrarTable />
            case "domain": return <DomainTable />
            case "host": return <HostTable />
        }
    }

    useEffect(() => {
        const searchParamsType = searchParams.get('type')
        if (!searchParamsType) {
            searchParams.set('type', 'registrar')
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        } else if (searchParamsType == 'registrar' || searchParamsType == 'domain' || searchParamsType == 'host') {
            setDatabaseSelected(searchParamsType)
        }
    }, [location]);

    useEffect(() => {
        searchParams.set('type', databaseSelected)
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }, [databaseSelected]);

    return (
        <section className="mt-5">
            <div className="d-flex gap-3">
                <ButtonSecondary state={databaseSelected == 'registrar' ? 'disabled' : 'default'} onClick={() => setDatabaseSelected('registrar')} label="Registrar" />
                <ButtonSecondary state={databaseSelected == 'domain' ? 'disabled' : 'default'} onClick={() => setDatabaseSelected('domain')} label="Domínio" />
                <ButtonSecondary state={databaseSelected == 'host' ? 'disabled' : 'default'} onClick={() => setDatabaseSelected('host')} label="Hospedagem" />
            </div>

            {renderTable()}

        </section>
    )
}