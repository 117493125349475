import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { IStore } from "../../../interfaces/store.interface";
import { useEffect, useState } from "react";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import useCanPerformAction from "../../hooks/CanPerformAction.hook";
import { ActionEnum } from "../../constants/user-action-permissions.constant";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InviteForm from "./forms/invite-form";
import ChangePermissionForm from "./forms/change-permission-form";
import Databases from "./Databases";
import ManageUsers from "./ManageUsers/manage-users";
import Companies from "./Companies";
import Campaigns from "./Campaigns";

export default function Admin() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate()

    const canEdit = useCanPerformAction(ActionEnum.edit_permissions)
    const canView = useCanPerformAction(ActionEnum.view_permissions)
    const canInviteOperator = useCanPerformAction(ActionEnum.invite_agents)
    const canInviteCustomer = useCanPerformAction(ActionEnum.invite_customers)
    const canManageUsers = useCanPerformAction(ActionEnum.manage_users)
    const canActDatabases = useCanPerformAction(ActionEnum.act_databases)

    const canAccessPage = canEdit || canView || canInviteOperator || canInviteCustomer

    if (!canAccessPage) navigate('/')

    const [sectionEnabled, setSectionEnabled] = useState<'invite' | 'permission' | 'manage-users' | 'databases' | 'companies' | 'campaigns'>('invite');

    const { isLoading } = useSelector((store: IStore) => ({
        isLoading: store.Threats.isLoading,
    }))

    function renderSection() {
        switch (sectionEnabled) {
            case "invite": return <InviteForm />
            case "permission": return <ChangePermissionForm />
            case "manage-users": return <ManageUsers />
            case "databases": return <Databases />
            case "companies": return <Companies />
            case "campaigns": return <Campaigns />
        }
    }

    useEffect(() => {
        if (searchParams.get('databases')) setSectionEnabled('databases')

    }, [location]);

    useEffect(() => {
        if (sectionEnabled != 'databases') {
            searchParams.delete('databases')
            searchParams.delete('type')
            searchParams.delete('modalOpened')
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        } else {
            searchParams.set('databases', 'true')
            const newSearch = searchParams.toString();

            if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
        }
    }, [sectionEnabled]);

    return (
        <div className="page-content container-fluid">
            {isLoading && <Loader />}

            <div className="d-flex gap-2 mt-md-5">
                <ButtonPrimary state={sectionEnabled != 'invite' ? 'default' : 'disabled'} label="Convidar" onClick={() => setSectionEnabled('invite')} />
                {canEdit && <ButtonPrimary state={sectionEnabled != 'permission' ? 'default' : 'disabled'} label="Trocar permissão" onClick={() => setSectionEnabled('permission')} />}
                {canManageUsers && <ButtonPrimary state={sectionEnabled != 'manage-users' ? 'default' : 'disabled'} label="Gerenciar usuários" onClick={() => setSectionEnabled('manage-users')} />}
                {canActDatabases && <ButtonPrimary state={sectionEnabled != 'databases' ? 'default' : 'disabled'} label="Databases" onClick={() => setSectionEnabled('databases')} />}
                    <ButtonPrimary state={sectionEnabled != 'companies' ? 'default' : 'disabled'} label="Empresas" onClick={() => setSectionEnabled('companies')} />
                    <ButtonPrimary state={sectionEnabled != 'campaigns' ? 'default' : 'disabled'} label="Monitoramento" onClick={() => setSectionEnabled('campaigns')} />
            </div>

            {renderSection()}
            <ToastContainer />
        </div >
    )
}