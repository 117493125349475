import { ActionTypesEnum } from "./types.enum";

export const getAllCompaniesToAdmin = () => ({
  type: ActionTypesEnum.FETCH_COMPANIES,
});

export const changeAdminCompaniesList = (companies: any[]) => ({
  type: ActionTypesEnum.CHANGE_COMPANIES_LIST,
  payload: companies
});

export const getCompaniesGroup = (companyId: string) => ({
  type: ActionTypesEnum.FETCH_COMPANIES_GROUP,
  payload: companyId
});

export const changeAdminCompaniesGroupList = (companies: any[]) => ({
  type: ActionTypesEnum.CHANGE_COMPANIES_GROUP_LIST,
  payload: companies
});

export const inviteUser = (data: any) => ({
  type: ActionTypesEnum.INVITE_USER,
  payload: data
});

export const changeUserPermissions = (data: any) => ({
  type: ActionTypesEnum.CHANGE_USER_PERMISSIONS,
  payload: data
});

export const getUserToChangePermissions = (email: string) => ({
  type: ActionTypesEnum.FETCH_USER_TO_CHANGE,
  payload: email
});

export const changeUser = (data: any) => ({
  type: ActionTypesEnum.CHANGE_USER,
  payload: data
});

export const changeUsers = (data: any) => ({
  type: ActionTypesEnum.CHANGE_USERS,
  payload: data
});

export const fetchUsers = () => ({
  type: ActionTypesEnum.FETCH_USERS,
});

export const fetchUsersAndInviteds = (data: any) => ({
  type: ActionTypesEnum.FETCH_USERS_AND_INVITEDS,
  payload: data
});

export const changeUsersAndInviteds = (data: any) => ({
  type: ActionTypesEnum.CHANGE_USERS_AND_INVITEDS,
  payload: data
});

export const changeSuccessMessage = (message: string) => ({
  type: ActionTypesEnum.CHANGE_SUCCESS_MESSAGE,
  payload: message
});

export const changeErrorMessage = (message: string) => ({
  type: ActionTypesEnum.CHANGE_ERROR_MESSAGE,
  payload: message
});

export const cancelUserInvite = (email: string) => ({
  type: ActionTypesEnum.CANCEL_USER_INVITE,
  payload: email
});

export const resendUserInvite = (email: string) => ({
  type: ActionTypesEnum.RESEND_USER_INVITE,
  payload: email
});

export const changeUserPassword = (data: any) => ({
  type: ActionTypesEnum.CHANGE_USER_PASSWORD,
  payload: data
});

export const changeUserStatus = (email: string) => ({
  type: ActionTypesEnum.CHANGE_USER_STATUS,
  payload: email
});

export const changeFilterManageUsers = (data: any) => ({
  type: ActionTypesEnum.CHANGE_FILTER,
  payload: data
});

export const changeFormDatabaseRegistrar = (data: any) => ({
  type: ActionTypesEnum.CHANGE_FORM_DATABASE_REGISTRAR,
  payload: data
});

export const changeFormDatabaseDomain = (data: any) => ({
  type: ActionTypesEnum.CHANGE_FORM_DATABASE_DOMAIN,
  payload: data
});

export const changeFormDatabaseHost = (data: any) => ({
  type: ActionTypesEnum.CHANGE_FORM_DATABASE_HOST,
  payload: data
});

export const registerRegistrar = (data: any) => ({
  type: ActionTypesEnum.REGISTER_REGISTRAR,
  payload: data
});

export const updateDatabase = (data: any) => ({
  type: ActionTypesEnum.UPDATE_DATABASE,
  payload: data
});

export const registerDomain = (data: any) => ({
  type: ActionTypesEnum.REGISTER_DOMAIN,
  payload: data
});

export const registerHost = (data: any) => ({
  type: ActionTypesEnum.REGISTER_HOST,
  payload: data
});

export const fetchDatabaseByType = (type: string) => ({
  type: ActionTypesEnum.FETCH_DATABASE_BY_TYPE,
  payload: type
});

export const changeDatabaseByType = (data: any) => ({
  type: ActionTypesEnum.CHANGE_DATABASE_BY_TYPE,
  payload: data
});