import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import FilterDomain from "./filter-domain";
import FilterBB from "./filter-bb";
import TableDomain from "./table-domain";
import TableBB from "./table-bb";
import Label from "../../../components/Text/Label";
import Input from "../../../components/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../../store/threats/actions";
import { APIClient } from "../../../../helpers/api_helper";
import { getAllCompaniesToAdmin } from "../../../../store/admin/action";
import { IStore } from "../../../../interfaces/store.interface";
import showToast from "../../../utils/show-toast.util";
import Icon from "../../../components/Icon";
import { CloseOutlined, CloseRounded, DeleteOutline } from "@mui/icons-material";

const Campaigns = () => {

    const dispatch = useDispatch()

    const { companiesList } = useSelector((store: IStore) => store.Admin)

    const [showModalRegisterDomain, setShowModalRegisterDomain] = useState(false);
    const [showModalRegisterBB, setShowModalRegisterBB] = useState(false);

    const [campaignType, setCampaignType] = useState<'domain' | 'bb'>('domain');

    const [formDataDomain, setFormDataDomain] = useState({
        name: '',
        searchType: 'prospect',
        companyId: '',
        brandName: '',
        monitoringType: 'default',
        domainKeywords: { include: '', exclude: '' },
        lastDateSearch: '',
        enabledUntil: null,
        intervalHours: '6',
        runHour: '',
        isEnabled: false,
    });

    const [formDataBB, setFormDataBB] = useState({
        name: '',
        searchType: 'prospect',
        companyId: '',
        brandName: '',
        monitoringType: 'default',
        keywords: '',
        negativeWords: '',
        enabledUntil: null,
        intervalHours: '6',
        isEnabled: false,
        affiliatesRegex: '',
        isAffiliateSystem: false,
        description: '',
        pages: '1',
        geoLocationGroupIds: [] as { _id: string, groupName: string }[],
        brandScoreConfig: '1',
        intervalMinutes: {
            google: 0,
            bing: 0
        },
        devices: [
            {
                type: 'desktop',
                mobileType: '',
                geoLocationsPercent: 0,
            },
            {
                type: 'mobile',
                mobileType: 'android',
                geoLocationsPercent: 0,
            },
            {
                type: 'mobile',
                mobileType: 'iphone',
                geoLocationsPercent: 0,
            },
        ]
    });

    const handleSubmitRegisterDomain = async (e: any) => {
        e.preventDefault();
        dispatch(setLoading(true))

        try {

            const api = new APIClient();

            const data: any = await api.create('/campaign/admin-campaigns-domain', { ...formDataDomain })
            showToast({ children: <Label>Campanha adicionadae com sucesso!</Label>, type: 'info' })
        } catch (e: any) {
            showToast({ children: <Label>Erro ao adicionar campanha!</Label>, type: 'error' })
        } finally {
            setShowModalRegisterDomain(false)
            dispatch(setLoading(false))
        }
    };

    const handleSubmitRegisterBB = async (e: any) => {
        e.preventDefault();
        dispatch(setLoading(true))

        try {

            const api = new APIClient();

            if (formDataBB.geoLocationGroupIds.length == 0) return showToast({ children: <Label>Adicione uma localidade!</Label>, type: 'error' })
            if (formDataBB.intervalMinutes.google == 0 && formDataBB.intervalMinutes.bing == 0) return showToast({ children: <Label>Adicione ao menos uma execução diária (Google ou Bing)!</Label>, type: 'error' })

            const data: any = await api.create('/campaign/admin-campaigns-bb', { ...formDataBB })
            showToast({ children: <Label>Campanha adicionada com sucesso!</Label>, type: 'info' })
        } catch (e: any) {
            showToast({ children: <Label>Erro ao adicionar campanha!</Label>, type: 'error' })
        } finally {
            setShowModalRegisterBB(false)
            dispatch(setLoading(false))
        }
    };

    const [geoLocationsList, setGeoLocationsList] = useState([]);
    const [geoLocationText, setGeoLocationText] = useState('');

    async function searchLocations(text: string) {
        const api = new APIClient()
        const data = await api.get("/campaign/geo-location-group", { text })
        // @ts-ignore
        const filteredData = (data as []).filter(dt => !(formDataBB.geoLocationGroupIds || []).some(el => el._id === dt._id));

        setGeoLocationsList(filteredData as [])
    }

    useEffect(() => {
        searchLocations(geoLocationText)
    }, [formDataBB.geoLocationGroupIds]);

    useEffect(() => {
        dispatch(getAllCompaniesToAdmin())
    }, []);

    return (
        <section className="my-5">
            <div className="d-flex w-100 justify-content-between">
                <div className="d-flex gap-2">
                    <ButtonPrimary state={campaignType != 'domain' ? 'default' : 'disabled'} label="Domínios" onClick={() => setCampaignType('domain')} />
                    <ButtonPrimary state={campaignType != 'bb' ? 'default' : 'disabled'} label="Search Engines" onClick={() => setCampaignType('bb')} />
                </div>
                <div className="d-flex justify-content-end my-3">
                    <ButtonPrimary onClick={() => campaignType == 'domain' ? setShowModalRegisterDomain(true) : setShowModalRegisterBB(true)} label="Nova campanha" />
                </div>
            </div>
            {
                campaignType == 'domain' ?
                    <>
                        <FilterDomain />
                        <TableDomain />

                        <Modal footerButtons={[
                            // { label: 'Cancelar', isSubmit: false, buttonStyle: 'primary', type: 'destructive' },
                            // { label: 'Salvar', isSubmit: true, buttonStyle: 'primary' },
                        ]} width={'90%'} title="Nova campanha de Domínio" isOpen={showModalRegisterDomain} onClose={() => setShowModalRegisterDomain(false)}>
                            <form className="pb-5" onSubmit={handleSubmitRegisterDomain} style={{ display: 'flex', gap: '2rem' }}>
                                {/* Cadastro */}
                                <div className="d-flex flex-column gap-3" style={{ flex: 1 }}>
                                    <h3>Cadastro</h3>

                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Campanha</Label>
                                        <Input
                                            required
                                            type="text"
                                            placeholder="Nome da campanha"
                                            value={formDataDomain.name}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, name: e.target.value })}
                                        />
                                    </div>

                                    <div className="d-flex gap-2 flex-column">
                                        <Label>Tipo</Label>
                                        <select required className="p-2 border rounded border-gray"
                                            value={formDataDomain.searchType}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, searchType: e.target.value })}>
                                            <option selected value="prospect">Pescaria</option>
                                            <option value="trial">Trial</option>
                                            <option value="customer">Cliente</option>
                                        </select>


                                    </div>
                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Marca</Label>
                                        <select className="p-2 border rounded border-gray"
                                            required
                                            value={JSON.stringify({ _id: formDataDomain.companyId, name: formDataDomain.brandName })}
                                            onChange={(e: any) => {
                                                setFormDataDomain({ ...formDataDomain, brandName: JSON.parse(e.target.value).name, companyId: JSON.parse(e.target.value)._id })
                                            }}
                                        >
                                            <option selected value="">Selecione a marca</option>
                                            {companiesList.map((el, index) =>
                                                <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                            )}
                                        </select>
                                    </div>


                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Tipo de Monitoramento</Label>
                                        <select className="p-2 border rounded border-gray"
                                            required
                                            value={formDataDomain.monitoringType}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, monitoringType: e.target.value })}
                                        >
                                            <option selected value="default">Padrão</option>
                                            <option value="internal">Interno</option>
                                            <option value="generic_terms">Termos genéricos</option>
                                        </select>
                                    </div>

                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Incluir Palavras-chave</Label>
                                        <textarea
                                            required
                                            className="p-2 border rounded border-gray"
                                            placeholder="Palavras-chave"
                                            value={formDataDomain.domainKeywords.include}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, domainKeywords: { ...formDataDomain.domainKeywords, include: e.target.value } })}
                                        />
                                    </div>

                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Excluir Palavras-chave</Label>
                                        <textarea
                                            className="p-2 border rounded border-gray"
                                            placeholder="Palavras-chave"
                                            value={formDataDomain.domainKeywords.exclude}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, domainKeywords: { ...formDataDomain.domainKeywords, exclude: e.target.value } })}
                                        />
                                    </div>

                                </div>

                                {/* Configuração */}
                                <div className="d-flex flex-column gap-3" style={{ flex: 1 }}>
                                    <h3>Configuração</h3>

                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Período (Desde: Data Selecionada | Até: Hoje)</Label>
                                        <Input
                                            type="datetime-local"
                                            required
                                            value={formDataDomain.lastDateSearch}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, lastDateSearch: e.target.value })}
                                        />
                                    </div>


                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Expira em:</Label>
                                        <Input
                                            type="datetime-local"
                                            value={formDataDomain.enabledUntil}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, enabledUntil: e.target.value })}
                                        />
                                    </div>


                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Executar diariamente</Label>
                                        <select className="p-2 border rounded border-gray"
                                            required
                                            value={formDataDomain.intervalHours}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, intervalHours: e.target.value })}
                                        >
                                            <option selected value="6">A cada 06 horas</option>
                                            <option value="12">A cada 12 horas</option>
                                            <option value="12">A cada 24 horas</option>
                                        </select>
                                    </div>


                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Início monitoração (diária)</Label>
                                        <Input
                                            type="time"
                                            required
                                            value={formDataDomain.runHour}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, runHour: e.target.value })}
                                        />
                                    </div>

                                    <div className="d-flex gap-2">
                                        <Label>Ativa</Label>
                                        <input
                                            type="checkbox"
                                            checked={formDataDomain.isEnabled}
                                            onChange={(e: any) => setFormDataDomain({ ...formDataDomain, isEnabled: e.target.checked })}
                                        />
                                    </div>

                                    <div>
                                        <ButtonPrimary isSubmit label="Salvar" />
                                    </div>
                                </div>
                            </form>
                        </Modal>
                    </>
                    :
                    <>
                        <FilterBB />
                        <TableBB />

                        <Modal width={'90%'} title="Nova campanha de BB" isOpen={showModalRegisterBB} onClose={() => setShowModalRegisterBB(false)}>
                            <form className="pb-5" onSubmit={handleSubmitRegisterBB} style={{ display: 'flex', gap: '2rem' }}>
                                <div className="d-flex gap-3 flex-column" style={{ flex: 1 }}>
                                    <h3>Cadastro</h3>
                                    <div className="d-flex gap-2 flex-column">
                                        <Label>Campanha</Label>
                                        <Input
                                            required
                                            type="text"
                                            placeholder="Nome da campanha"
                                            value={formDataBB.name}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, name: e.target.value })}
                                        />
                                    </div>
                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Tipo</Label>
                                        <select required className="p-2 border rounded border-gray"
                                            value={formDataBB.searchType}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, searchType: e.target.value })}>
                                            <option selected value="prospect">Pescaria</option>
                                            <option value="trial">Trial</option>
                                            <option value="customer">Cliente</option>
                                        </select>
                                    </div>
                                    <div className="d-flex gap-2 flex-column">
                                        <Label>Marca</Label>
                                        <select className="p-2 border rounded border-gray"
                                            required
                                            value={JSON.stringify({ _id: formDataBB.companyId, name: formDataBB.brandName })}
                                            onChange={(e: any) => {
                                                setFormDataBB({ ...formDataBB, brandName: JSON.parse(e.target.value).name, companyId: JSON.parse(e.target.value)._id })
                                            }}
                                        >
                                            <option selected value="">Selecione a marca</option>
                                            {companiesList.map((el, index) =>
                                                <option key={index} value={JSON.stringify(el)}>{el.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="d-flex gap-2 flex-column">
                                        <Label>Tipo de Monitoramento</Label>
                                        <select className="p-2 border rounded border-gray"
                                            required
                                            value={formDataBB.monitoringType}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, monitoringType: e.target.value })}
                                        >
                                            <option selected value="default">Padrão</option>
                                            <option value="internal">Interno</option>
                                            <option value="generic_terms">Termos genéricos</option>
                                        </select>
                                    </div>
                                    <div className="d-flex gap-4 align-items-center">
                                        <div className="flex-grow-1">
                                            <Label>RegExp Afiliados</Label>
                                            <Input
                                                type="text"

                                                placeholder="Parâmetro de afiliados"
                                                value={formDataBB.affiliatesRegex}
                                                onChange={(e: any) => setFormDataBB({ ...formDataBB, affiliatesRegex: e.target.value })}
                                            />
                                        </div>
                                        <div className="d-flex gap-2">
                                            <input
                                                type="checkbox"
                                                checked={formDataBB.isAffiliateSystem}
                                                onChange={(e: any) => setFormDataBB({ ...formDataBB, isAffiliateSystem: e.target.checked })}
                                            />
                                            <Label>Sistema de Afiliação</Label>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Palavras-chave (separe com vírgula)</Label>
                                        <textarea
                                            required
                                            className="p-2 border border-gray"
                                            placeholder="Palavras-chave"
                                            value={formDataBB.keywords}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, keywords: e.target.value })}
                                        />
                                    </div>
                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Palavras para negativação (separe com vírgula)</Label>
                                        <textarea
                                            required
                                            className="p-2 border border-gray"
                                            placeholder="Palavras-chave"
                                            value={formDataBB.negativeWords}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, negativeWords: e.target.value })}
                                        />
                                    </div>
                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Descrição</Label>
                                        <textarea

                                            className="p-2 border border-gray"
                                            placeholder="Descrição"
                                            value={formDataBB.description}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, description: e.target.value })}
                                        />
                                    </div>
                                </div>

                                {/* Configuração */}
                                <div className="d-flex gap-3 flex-column" style={{ flex: 1 }}>
                                    <h3>Configuração</h3>
                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Páginas</Label>
                                        <select
                                            className="p-2 border rounded border-gray"
                                            value={formDataBB.pages}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, pages: e.target.value })}
                                            required id="pages">
                                            <option selected value="1">Primeira página</option>
                                            <option value="1,2">1a e 2a página</option>
                                            <option value="2">Somente 2a página</option>
                                        </select>
                                    </div>
                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Executar diariamente (Google)</Label>
                                        <select
                                            className="p-2 border rounded border-gray"
                                            required
                                            value={formDataBB.intervalMinutes.google}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, intervalMinutes: { ...formDataBB.intervalMinutes, google: Number(e.target.value) } })}
                                        >
                                            <option value="">Selecione quantas vezes executar por dia...</option>
                                            <option value="60">24x</option>
                                            <option value="90">16x</option>
                                            <option value="120">12x</option>
                                            <option value="180">8x</option>
                                            <option value="240">6x</option>
                                            <option value="360">4x</option>
                                            <option value="720">2x</option>
                                            <option value="0" selected>Desativado</option>
                                        </select>
                                    </div>
                                    <div className="d-flex gap-2 flex-column">

                                        <Label>Executar diariamente (Bing)</Label>
                                        <select
                                            className="p-2 border rounded border-gray"
                                            required
                                            value={formDataBB.intervalMinutes.bing}
                                            onChange={(e: any) => setFormDataBB({ ...formDataBB, intervalMinutes: { ...formDataBB.intervalMinutes, bing: Number(e.target.value) } })}
                                        >
                                            <option value="">Selecione quantas vezes executar por dia...</option>
                                            <option value="60">24x</option>
                                            <option value="90">16x</option>
                                            <option value="120">12x</option>
                                            <option value="180">8x</option>
                                            <option value="240">6x</option>
                                            <option value="360">4x</option>
                                            <option value="720">2x</option>
                                            <option value="0" selected>Desativado</option>
                                        </select>
                                    </div>
                                    <div className="d-flex gap-2 flex-column position-relative">
                                        <Label>Localidades</Label>
                                        {formDataBB.geoLocationGroupIds.length > 0 &&
                                            <div className="d-flex flex-wrap gap-2">
                                                {formDataBB.geoLocationGroupIds.map((el, index) =>
                                                    <div role="button" className="d-flex gap-2 border-primary border p-2" onClick={() => {
                                                        const updatedArr = formDataBB.geoLocationGroupIds.filter(geo => geo._id != el._id)
                                                        setFormDataBB({ ...formDataBB, geoLocationGroupIds: updatedArr })
                                                    }} key={index}>
                                                        <span>{el.groupName}</span>
                                                        <Icon width={14} height={14} IconComponent={CloseOutlined} />
                                                    </div>)}
                                            </div>
                                        }
                                        <Input
                                            type="text"
                                            placeholder="Localidades"
                                            value={geoLocationText}
                                            onChange={(e: any) => {
                                                setGeoLocationText(e.target.value)
                                                setTimeout(() => {
                                                    searchLocations(e.target.value)
                                                }, 1000);
                                            }}
                                        />
                                        {(geoLocationsList.length > 0 && geoLocationText) &&
                                            <div
                                                style={{
                                                    top: '100%',
                                                    left: 0,
                                                    maxHeight: 200,
                                                    zIndex: 1000,
                                                    marginTop: '4px', // Pequeno espaçamento entre o input e a lista
                                                }}
                                                className="position-absolute overflow-auto w-100 bg-white p-2 border border-gray shadow"
                                            >
                                                <div className="position-absolute end-0 me-2" role="button" onClick={() => setGeoLocationsList([])}>
                                                    <Icon color="red" width={22} height={22} IconComponent={CloseRounded} />
                                                </div>
                                                {geoLocationsList?.map((el: any, index) =>
                                                    <div className="p-2" key={index} role="button"
                                                        onClick={() => {
                                                            setFormDataBB({ ...formDataBB, geoLocationGroupIds: [...formDataBB.geoLocationGroupIds, { _id: el._id, groupName: el.groupName }] })
                                                        }}>
                                                        <Label>{el.groupName}</Label>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <Label >BrandScore (Palavra-Chave)</Label>
                                    </div>
                                    <select
                                        className="p-2 border rounded border-gray"
                                        value={formDataBB.brandScoreConfig}
                                        onChange={(e: any) => setFormDataBB({ ...formDataBB, brandScoreConfig: e.target.value })}
                                        required id="brandScoreConfig">
                                        <option selected value="1,2">Todas</option>
                                        <option value="1">Principal</option>
                                        <option value="2">Secundária</option>
                                    </select>
                                    <div>
                                        <Label>Dispositivos (% de localidade)</Label>
                                        <div className="d-flex flex-column gap-4">
                                            <div className="d-flex align-items-center gap-3">

                                                <Label>Desktop</Label>
                                                <Input required onChange={(e: any) => {
                                                    const updatedArr = formDataBB.devices.map((el, index) => {
                                                        if (index == 0) return { ...el, geoLocationsPercent: Number(e.target.value) }
                                                        return el
                                                    })
                                                    setFormDataBB({ ...formDataBB, devices: updatedArr })
                                                }} type="number" value={formDataBB.devices[0].geoLocationsPercent} placeholder="%" />
                                            </div>

                                            <div className="d-flex align-items-center gap-3">

                                                <Label>Android</Label>
                                                <Input required onChange={(e: any) => {
                                                    const updatedArr = formDataBB.devices.map((el, index) => {
                                                        if (index == 1) return { ...el, geoLocationsPercent: Number(e.target.value) }
                                                        return el
                                                    })
                                                    setFormDataBB({ ...formDataBB, devices: updatedArr })
                                                }} type="number" value={formDataBB.devices[1].geoLocationsPercent} placeholder="%" />
                                            </div>

                                            <div className="d-flex align-items-center gap-3">

                                                <Label>iPhone</Label>
                                                <Input required onChange={(e: any) => {
                                                    const updatedArr = formDataBB.devices.map((el, index) => {
                                                        if (index == 2) return { ...el, geoLocationsPercent: Number(e.target.value) }
                                                        return el
                                                    })
                                                    setFormDataBB({ ...formDataBB, devices: updatedArr })
                                                }} type="number" value={formDataBB.devices[2].geoLocationsPercent} placeholder="%" />
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between mt-3 align-items-center">
                                            <div className="d-flex gap-2 flex-column">
                                                <Label>Expira em:</Label>
                                                <Input
                                                    type="datetime-local"
                                                    value={formDataBB.enabledUntil}
                                                    onChange={(e: any) => setFormDataBB({ ...formDataBB, enabledUntil: e.target.value })}
                                                />
                                            </div>
                                            <div className="d-flex gap-2 me-5">
                                                <input
                                                    type="checkbox"
                                                    checked={formDataBB.isEnabled}
                                                    onChange={(e: any) => setFormDataBB({ ...formDataBB, isEnabled: e.target.checked })}
                                                />
                                                Ativa
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <ButtonPrimary isSubmit label="Salvar" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal >
                    </>
            }

        </section >
    );
};

export default Campaigns;