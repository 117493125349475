import { Table } from "react-bootstrap";
import ButtonPrimary from "../../../../components/Button/ButtonPrimary";
import { AddOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../../interfaces/store.interface";
import { changeFormDatabaseDomain, fetchDatabaseByType, registerDomain } from "../../../../../store/admin/action";
import Label from "../../../../components/Text/Label";
import Input from "../../../../components/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";

export default function DomainTable() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { forms, domain } = useSelector((store: IStore) => store.Admin.databases)

    const [showModalRegister, setShowModalRegister] = useState(false);

    useEffect(() => {
        dispatch(fetchDatabaseByType('domain'))
    }, []);

    function onClose() {
        setShowModalRegister(false)
        searchParams.delete('modalOpened')
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    async function onConfirm() {
        dispatch(registerDomain({ ...forms.domain, type: 'domain' }))
    }

    function handleInsertButtonClick() {
        setShowModalRegister(true)
        searchParams.set('modalOpened', 'true')
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    return (
        <section className="d-flex flex-column mt-3">
            {/* <Filter /> */}

            <div className="bg-white d-flex flex-column gap-3 rounded-2">
                <Table className="table">
                    <thead>
                        <tr className="fw-bold">
                            <td>Domain Name</td>
                            <td>Registry Date UTC</td>
                            <td>Registry Domain ID</td>
                            <td>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        {domain?.map((el, index) =>
                            <tr key={index}>
                                <td style={{ maxWidth: 100 }}>{el.domainName}</td>
                                <td style={{ maxWidth: 100 }}>{el.createddateintime}</td>
                                <td style={{ maxWidth: 100 }}>{el.id}</td>
                                <td style={{ maxWidth: 100 }}>
                                    <div className="d-flex gap-1 flex-column">
                                        {el.status?.map((el: string, index: number) => <span key={index}>{el}</span>)}
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* <Paginator /> */}
            </div>


            <Modal
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: onConfirm, label: 'Inserir' },
                ]} title="Novo Registrar" isOpen={showModalRegister} width={'50%'} onClose={onClose}>
                <form className="d-flex flex-column gap-4">
                    <div className="d-flex gap-4">
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Domain Name</Label>
                            <Input value={forms.domain?.domainName} onChange={(e: any) => {
                                dispatch(changeFormDatabaseDomain({ ...forms.domain, domainName: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Registry Date UTC</Label>
                            <Input value={forms.domain?.registryDateUtc} onChange={(e: any) => {
                                dispatch(changeFormDatabaseDomain({ ...forms.domain, registryDateUtc: e.target.value }))
                            }} />
                        </div>
                    </div>
                    <div className="d-flex gap-4">
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Registry Domain ID</Label>
                            <Input value={forms.domain?.registryDomainId} onChange={(e: any) => {
                                dispatch(changeFormDatabaseDomain({ ...forms.domain, registryDomainId: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Status</Label>
                            <Input value={forms.domain?.status} onChange={(e: any) => {
                                dispatch(changeFormDatabaseDomain({ ...forms.domain, status: e.target.value }))
                            }} />
                        </div>
                    </div>
                </form>
            </Modal>

        </section>
    )
}