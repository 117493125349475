import { IStore } from "../../../../interfaces/store.interface";
import { useDispatch, useSelector } from "react-redux";
import Body from "../../../components/Text/Body";
import Sub6 from "../../../components/Text/Sub6";
import Label from "../../../components/Text/Label";
import { Add, AddOutlined, ArrowOutwardOutlined, CloseOutlined, CopyAllOutlined } from "@mui/icons-material";
import Accordion from "../../../components/Accordion";
import EmptyState from "../../../assets/empty-state-complaints.svg"
import { getThreatTypeText } from "../../../utils/get-threat-type-text.util";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import styled from "styled-components";
import { dsVariables } from "../../../variables";
import { useEffect, useState, useRef } from "react";
import RegisterComplaintForm from "../Forms/RegisterComplaintForm";
import { changeComplaintFormChannel, changeComplaintFormDate, changeComplaintFormEvidence, changeComplaintFormEvidenceLink, changeComplaintFormReportedId, changeComplaintFormReportedTo, changePixFormCheckoutUrl, changePixFormCpf, changePixFormEvidence, changePixFormEvidenceLink, changePixFormFinancialInstitution, changePixFormPaymentGateway, changePixFormPix, fetchComplaints, fetchPix, linkOccurrenceHostDatabases, linkThreatDatabases } from "../../../../store/threats/actions";
import { CompaniesComplaintEnum } from "../../../../enums/companies-complaint.enum";
import RegisterPixForm from "../Forms/RegisterPixForm";
import { FinancialInstitutionEnum } from "../../../../enums/financial-institution.enum";
import { PaymentsGatewayEnum } from "../../../../enums/payments-gateway.enum";
import { ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader";
import Icon from "../../../components/Icon";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useFetcher, useNavigate, useSearchParams } from "react-router-dom";
import ButtonTertiary from "../../../components/Button/ButtonTertiary";
import ButtonSecondary from "../../../components/Button/ButtonSecondary";
import { fetchDatabaseByType } from "../../../../store/admin/action";
import Caption from "../../../components/Text/Caption";
import { ActionEnum } from "../../../constants/user-action-permissions.constant";
import useCanPerformAction from "../../../hooks/CanPerformAction.hook";
import showToast from "../../../utils/show-toast.util";
import { useClickOutside } from "../../../hooks/useClickOutside";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Tag from "../../../components/Tag";
import ModalTreatmensList from "./ModalTreatmentsList";
import { APIClient } from "../../../../helpers/api_helper";


interface IProps {
    onClose: any
}

export default function ModalTakedownDetail({ onClose }: IProps) {

    const searchParams = useSearchParams()[0]
    const navigate = useNavigate()

    const tab = searchParams.get('tab')

    const isDetailView = tab != '0' && tab != '1' && Boolean(tab)

    const { currentTakedown, isLoading, domainDatabase, hostDatabase, registrarDatabase } = useSelector((store: IStore) => {
        return {
            currentTakedown: store.Threats.currentTakedown,
            isLoading: store.Threats.isLoading,
            domainDatabase: store.Admin.databases.domain,
            hostDatabase: store.Admin.databases.host,
            registrarDatabase: store.Admin.databases.registrar,
        }
    })

    const canActTakedown = useCanPerformAction(ActionEnum.act_takedown)

    const [showRegisterComplaint, setShowRegisterComplaint] = useState(false);
    const [showRegisterPix, setShowRegisterPix] = useState(false);
    const [showDomainCard, setShowDomainCard] = useState(false)
    const [showRegistrarCard, setShowRegistrarCard] = useState(false)
    const [showHostCard, setShowHostCard] = useState(false)
    const [occurrenceSelected, setOccurrenceSelected] = useState('');
    const [showTreatmentsModalList, setShowTreatmentsModalList] = useState(false);
    const [treatmentsList, setTreatmentsList] = useState<any[]>([]);

    const dispatch = useDispatch()

    const domainCardRef = useRef<HTMLDivElement>(null);
    const registrarCardRef = useRef<HTMLDivElement>(null);
    const hostCardRef = useRef<HTMLDivElement>(null);

    useClickOutside(domainCardRef, () => setShowDomainCard(false));
    useClickOutside(registrarCardRef, () => setShowRegistrarCard(false));
    useClickOutside(hostCardRef, () => setShowHostCard(false));

    useEffect(() => {
        dispatch(fetchComplaints(currentTakedown?._id!))
        dispatch(fetchPix(currentTakedown?.threat?._id!))
    }, []);

    useEffect(() => {
        showDomainCard && dispatch(fetchDatabaseByType('domain'))
        showRegistrarCard && dispatch(fetchDatabaseByType('registrar'))
        showHostCard && dispatch(fetchDatabaseByType('host'))
    }, [showDomainCard, showRegistrarCard, showHostCard]);

    function handleRegisterDomain() {
        navigate('/admin?databases=true&type=domain&modalOpened=true')
    }
    function handleRegisterRegistrar() {
        navigate('/admin?databases=true&type=registrar&modalOpened=true')
    }
    function handleRegisterHost() {
        navigate('/admin?databases=true&type=host&modalOpened=true')
    }

    function getAccordionBorderType(array: any[], index: number): 'top' | 'bottom' | 'top-bottom' | any {
        if (array.length == 1 || array.length == 0) return

        if (array.length == 2) return index == 0 ? 'bottom' : 'top'
        else {
            if (index == 0) return 'bottom'
            else if (index == array.length - 1) return 'top'
            else return 'top-bottom'
        }
    }

    async function linkDatabaseByType(databaseId: string, type: string) {
        if (occurrenceSelected) dispatch(linkOccurrenceHostDatabases({ databases: [databaseId], occurrenceId: occurrenceSelected }))
        else dispatch(linkThreatDatabases({ databases: [databaseId], sourceId: currentTakedown?.sourceThreat?._id, type }))

        showToast({ children: <Label>Database linkado com sucesso!</Label>, type: 'success' })
        setShowDomainCard(false)
        setShowRegistrarCard(false)
        setShowHostCard(false)
    }

    async function getTreatmentsList() {
        setShowTreatmentsModalList(true)

        const api = new APIClient()

        const data = await api.get('/treatment/same-threat-id', { threatId: currentTakedown?.threat._id,currentIdentifier: currentTakedown?.identifier })

        setTreatmentsList(data as any[])
    }

    return (
        <section className="w-100 h-100 position-fixed overflow-hidden top-0 start-0 pb-5" style={{ zIndex: 1234 }}>
            {isLoading && <Loader />}
            <div onClick={onClose} className="w-100 h-100 position-absolute top-0 start-0" style={{ opacity: 0.4, zIndex: 0, background: 'black' }}></div>
            <section className="w-100 h-100 bg-white mt-5 position-relative pb-5" style={{ borderRadius: 8, padding: 16, overflow: 'auto' }}>
                <div className="d-flex flex-column gap-2 mb-4">
                    <div className="d-flex gap-2 align-items-center">
                        <Sub6>Detalhes da tratativa - #{currentTakedown?.identifier}</Sub6>
                        <Tooltip text="Copiar ID da tratativa">
                            <CopyAllOutlined
                                style={{ color: dsVariables.colors.Astronaut[500], cursor: 'pointer', widows: 20, height: 20 }}
                                onClick={() => {
                                    navigator.clipboard.writeText(currentTakedown?.identifier || '')
                                    showToast({ children: <Label>Copiado para a área de transferência!</Label>, type: 'info' })
                                }} />
                        </Tooltip>
                    </div>
                    <div className="d-flex gap-3">
                        <Label color="#516EE8"><span className="text-decoration-underline">{currentTakedown?.sourceThreat?.searchText}</span></Label>
                        {currentTakedown?.labels?.map((label: any, index: number) => (
                            <Tooltip tooltipPosition="bottom_left" key={index} text={label.type == 'recidivist' ? `Esta ameaça já foi tratada outras vezes, clique para visualizar` : label.label}>
                                <Tag
                                    onClick={() => label.type == 'recidivist' && getTreatmentsList()}
                                    label={label.label || label}
                                    backgroundColor={label.backgroundColor || dsVariables.colors.Gray[100]}
                                    textColor={label.textColor || dsVariables.colors.Gray[700]}
                                />
                            </Tooltip>
                        ))}
                    </div>
                </div>

                <div style={{ gap: 12, display: 'flex' }}>
                    <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ gap: 16, display: 'flex', flexDirection: 'column', borderBottom: '1px solid #E6E6E6', paddingBottom: 8, marginBottom: 16 }}>
                            <Sub6 style="medium">Sobre a ameaça</Sub6>
                            <button disabled={isDetailView} role={isDetailView ? "none" : "button"} onClick={() => setShowRegisterPix(true)} className="bg-transparent border-0 m-0 p-0 text-start">
                                <Label color={isDetailView ? dsVariables.colors.Gray[400] : "#30437F"}><Add /> Add evidência (Pix)</Label>
                            </button>
                            <Body color="#818181" style="medium">Detalhes da ameaça modaltake</Body>
                        </div>
                        {showRegisterPix && <div className="gap-4" style={{ paddingBlock: 16, overflow: 'auto', display: 'flex', flexDirection: 'column', }}>
                            <RegisterPixForm onCancel={() => {
                                setShowRegisterPix(false)
                                dispatch(changePixFormEvidenceLink(''))
                                dispatch(changePixFormEvidence([]))
                                dispatch(changePixFormCheckoutUrl(''))
                                dispatch(changePixFormCpf(''))
                                dispatch(changePixFormFinancialInstitution(FinancialInstitutionEnum.NONE))
                                dispatch(changePixFormPaymentGateway(PaymentsGatewayEnum.NONE))
                                dispatch(changePixFormPix(''))
                            }} />
                        </div>
                        }
                        {(currentTakedown?.pix && currentTakedown?.pix?.length > 0) &&
                            <div className="d-flex flex-column gap-4">
                                {
                                    currentTakedown?.pix?.map(pix =>
                                        <RegisterPixForm onCancel={() => setShowRegisterPix(false)} pix={pix} key={pix._id} editFlow={true} />
                                    )
                                }
                            </div>
                        }
                        <div style={{
                            paddingBlock: 16, overflow: 'auto', border: '1px solid #E6E6E6', display: 'flex',
                            flexDirection: 'column', borderWidth: 1, borderStyle: 'solid',
                            boxShadow: '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)'
                        }}>
                            <div className="d-flex gap-4 border-b-1 border-secondary px-3">
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Tipo de ameaça</Body>
                                        <Body color="#4d4d4d">{getThreatTypeText(currentTakedown?.threat?.type!)}</Body>
                                    </div>
                                    <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Data de identificação</Body>
                                        <Body color="#4d4d4d">{new Date(currentTakedown?.createdAt!).toLocaleDateString('pt-BR')}</Body>
                                    </div>
                                    {currentTakedown?.sourceThreat?.profile && <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Perfil</Body>
                                        <Body color="#4d4d4d">{currentTakedown?.sourceThreat?.profile}</Body>
                                    </div>}
                                </div>
                                <div className="d-flex flex-column gap-2">
                                    <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Status</Body>
                                        <Body color="#4d4d4d">{currentTakedown?.isActive ? 'Ativo' : 'Inativo'}</Body>
                                    </div>
                                    {currentTakedown?.sourceThreat?.platform && <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">Plataforma</Body>
                                        <Body color="#4d4d4d">{currentTakedown?.sourceThreat?.platform}</Body>
                                    </div>}
                                    {currentTakedown?.sourceThreat?.advertiserId && <div className="d-flex flex-column gap-2">
                                        <Body color="#818181" style="medium">ID do anúncio</Body>
                                        <Body color="#4d4d4d">{currentTakedown?.sourceThreat?.advertiserId}</Body>
                                    </div>}
                                </div>
                            </div>
                            {currentTakedown?.threat?.type == ThreatTypeEnum.FAKE_SITE && <div className="d-flex flex-column mt-4 position-relative border-top">
                                {showDomainCard &&
                                    <div ref={domainCardRef} style={{ top: -120, maxWidth: 400, boxShadow: '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)' }} className="z-1 position-absolute end-0 bg-white rounded p-4 border">
                                        <div className="d-flex gap-2">
                                            <Caption>Selecione ou cadastre informações do domínio</Caption>
                                            <ButtonTertiary onClick={handleRegisterDomain} iconLeft={AddOutlined} label="Cadastrar" />
                                        </div>
                                        <div style={{ maxHeight: 400 }} className="overflow-auto d-flex flex-column gap-3 mt-2">
                                            {
                                                domainDatabase?.map((el, index) =>
                                                    <div
                                                        style={{ boxShadow: '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)' }}
                                                        onClick={() => linkDatabaseByType(el._id, 'domain')} role="button" key={index} className="border-bottom p-3 d-flex flex-column gap-2 border">
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Domínio:</Label>
                                                            <Label>{el.domainName}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Registry Date UTC:</Label>
                                                            <Label>{el.registryDateUtc}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Registry Domain ID:</Label>
                                                            <Label>{el.registryDomainId}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Status:</Label>
                                                            <div className="d-flex flex-column">
                                                                {el.status?.map((el: any, index: number) =>
                                                                    <Label key={index}>{el}</Label>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                                {showHostCard &&
                                    <div ref={hostCardRef} style={{ top: -120, maxWidth: 400, boxShadow: '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)' }} className="z-1 position-absolute end-0 bg-white rounded p-4 border">
                                        <div className="d-flex gap-2">
                                            <Caption>Selecione ou cadastre informações do hospedagem</Caption>
                                            <ButtonTertiary onClick={handleRegisterHost} iconLeft={AddOutlined} label="Cadastrar" />
                                        </div>
                                        <div style={{ maxHeight: 400 }} className="overflow-auto d-flex flex-column gap-3 mt-2">
                                            {
                                                hostDatabase?.map((el, index) =>
                                                    <div
                                                        style={{ boxShadow: '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)' }}
                                                        onClick={() => linkDatabaseByType(el._id, 'host')} role="button" key={index} className="border-bottom p-3 border d-flex flex-column gap-2">
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Net Range:</Label>
                                                            <Label>{el.netRange}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Net Name:</Label>
                                                            <Label>{el.netName}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Abuse Contact Email:</Label>
                                                            <Label>{el.abuseEmail}</Label>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                                {showRegistrarCard &&
                                    <div ref={registrarCardRef} style={{ top: -120, maxWidth: 400, boxShadow: '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)' }} className="z-1 position-absolute end-0 bg-white rounded p-4 border">
                                        <div className="d-flex gap-2">
                                            <Caption>Selecione ou cadastre informações do registrar</Caption>
                                            <ButtonTertiary onClick={handleRegisterRegistrar} iconLeft={AddOutlined} label="Cadastrar" />
                                        </div>
                                        <div style={{ maxHeight: 400 }} className="overflow-auto d-flex flex-column gap-3 mt-2">
                                            {
                                                registrarDatabase?.map((el, index) =>
                                                    <div
                                                        style={{ boxShadow: '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)' }}
                                                        onClick={() => linkDatabaseByType(el._id, 'registrar')} role="button" key={index} className="border-bottom p-3 border d-flex flex-column gap-2">
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">IANA Number:</Label>
                                                            <Label>{el.ianaNumber}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Entity:</Label>
                                                            <Label>{el.entity}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Abuse Contact Phone:</Label>
                                                            <Label>{el.abusePhone}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Abuse Contact Email:</Label>
                                                            <Label>{el.abuseEmail}</Label>
                                                        </div>
                                                        <div className="d-flex gap-3">
                                                            <Label style="medium">Entry Website:</Label>
                                                            <Label>{el.entryWebsite}</Label>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                }
                                <Accordion title="Informações do Domínio">
                                    {!currentTakedown?.sourceThreat?.domainDatabase && <ButtonTertiary state={isDetailView ? 'disabled' : 'default'} onClick={() => setShowDomainCard(true)} iconLeft={AddOutlined} label="Adicionar domínio" />}
                                    {currentTakedown?.sourceThreat?.domainDatabase && <div className="d-flex flex-column gap-2 p-3 border border-gray border-2 position-relative">
                                        {canActTakedown && <div onClick={handleRegisterDomain} role="button" className="position-absolute top-0 end-0 m-2">
                                            <Icon IconComponent={ArrowOutwardOutlined} color={dsVariables.colors.Astronaut[500]} />
                                        </div>}
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Domain Name:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.domainDatabase?.domainName}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Registry Date UTC:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.domainDatabase?.registryDateUtc}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Registry Domain ID:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.domainDatabase?.registryDomainId}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Status:</Label>
                                            <div className="d-flex flex-column">
                                                {currentTakedown?.sourceThreat?.domainDatabase?.status?.map((el: any, index: number) =>
                                                    <Label key={index}>{el}</Label>
                                                )}
                                            </div>
                                        </div>
                                    </div>}
                                </Accordion>
                                <Accordion title="Informações do Registrar">

                                    {!currentTakedown?.sourceThreat?.registrarDatabase && <ButtonTertiary state={isDetailView ? 'disabled' : 'default'} onClick={() => setShowRegistrarCard(true)} iconLeft={AddOutlined} label="Adicionar registrar" />}

                                    {currentTakedown?.sourceThreat?.registrarDatabase && <div className="d-flex flex-column gap-2 p-3 border border-gray border-2 position-relative">
                                        {canActTakedown && <div onClick={handleRegisterRegistrar} role="button" className="position-absolute top-0 end-0 m-2">
                                            <Icon IconComponent={ArrowOutwardOutlined} color={dsVariables.colors.Astronaut[500]} />
                                        </div>}
                                        <div className="d-flex gap-2">
                                            <Label style="medium">IANA Number:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.registrarDatabase?.ianaNumber}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Entity:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.registrarDatabase?.entity}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Abuse Contact Phone:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.registrarDatabase?.abusePhone}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Abuse Contact Email:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.registrarDatabase?.abuseEmail}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Entity Website:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.registrarDatabase?.entityWebsite}</Label>
                                        </div>
                                    </div>}

                                </Accordion>
                                <Accordion title="Hospedagens por ocorrência">
                                    {currentTakedown?.occurrences?.map((occurrence: any, index: number) =>
                                        <Accordion key={index}
                                            isChild
                                            borderType={getAccordionBorderType(currentTakedown.occurrences, index)}
                                            withBorder
                                            title={`${index + 1}. ${occurrence.url}`}
                                            headerLabels={occurrence?.labels}
                                        >
                                            {occurrence?.hostDatabase && occurrence?.hostDatabase?.map((el: any, index: number) => <div key={index} className="d-flex flex-column gap-2 p-3 border border-gray border-2 position-relative">
                                                {canActTakedown && <div onClick={handleRegisterHost} role="button" className="position-absolute top-0 end-0 m-2">
                                                    <Icon IconComponent={ArrowOutwardOutlined} color={dsVariables.colors.Astronaut[500]} />
                                                </div>}
                                                <div className="d-flex gap-2">
                                                    <Label style="medium">Net Range:</Label>
                                                    <Label>{el?.netRange}</Label>
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <Label style="medium">Net Name:</Label>
                                                    <Label>{el?.netName}</Label>
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <Label style="medium">Abuse Contact Email:</Label>
                                                    <Label>{el?.abuseEmail}</Label>
                                                </div>
                                            </div>)}
                                            {(!occurrence?.hostDatabase || !occurrence?.hostDatabase?.length) && <ButtonTertiary state={isDetailView ? 'disabled' : 'default'} onClick={() => {
                                                setShowHostCard(true);
                                                setOccurrenceSelected(occurrence._id)
                                            }} iconLeft={AddOutlined} label="Adicionar hospedagem" />}
                                        </Accordion>
                                    )}
                                </Accordion>
                                <Accordion title="Evidências por ocorrência">
                                    {currentTakedown?.occurrences?.map((occurrence: any, index: number) =>
                                        <Accordion key={index}
                                            isChild
                                            borderType={getAccordionBorderType(currentTakedown.occurrences, index)}
                                            withBorder
                                            title={`${index + 1}. ${occurrence.url}`}
                                            headerLabels={occurrence.labels}
                                        >
                                            <div key={index} className="d-flex flex-column gap-2">
                                                <div className="d-flex gap-4">
                                                    <Label>Evidência(s):</Label>
                                                    <div className="flex-grow-1 d-flex gap-1 flex-column">{
                                                        occurrence.evidence?.link
                                                            ? occurrence.evidence?.link
                                                            : occurrence.evidence?.files?.map((file: string, index: number) =>
                                                                <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                    <div className="d-flex gap-2 align-items-center">
                                                                        {/* @ts-ignore */}
                                                                        <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                                        <div className="d-flex flex-column gap-1">
                                                                            <Label>{`Evidência ${++index}`}</Label>
                                                                        </div>
                                                                    </div>
                                                                    <button className="border-0 bg-transparent">
                                                                        <a href={file} target="blank" >
                                                                            <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                                        </a>
                                                                    </button>
                                                                </FilesCard>
                                                            )}
                                                    </div>
                                                    {(!occurrence.evidence?.link && occurrence.evidence?.files?.length == 0) &&
                                                        <Label style="medium">Nenhum evidência cadastrada.</Label>
                                                    }
                                                </div>
                                            </div>
                                        </Accordion>
                                    )}
                                    {
                                        currentTakedown?.pixOccurrences?.map((pixOccurrence: any, index: number) =>
                                            <Accordion key={index}
                                                isChild
                                                borderType={getAccordionBorderType(currentTakedown?.pixOccurrences!, index)}
                                                withBorder
                                                title={`${currentTakedown.occurrences?.length + (index + 1)}. ${pixOccurrence.pix}`}>
                                                <div key={index} className="d-flex flex-column gap-2">
                                                    {/* <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">CPF:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.cpf}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Instituição financeira:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.financialInstitution}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Url de checkout:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.checkoutUrl}</Label>
                                                    </div>

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Gateway de pagamento:</Label>
                                                        </div>
                                                        <Label>{pixOccurrence.paymentGateway}</Label>
                                                    </div> */}

                                                    <div className="d-flex gap-4">
                                                        <div className="w-50">
                                                            <Label style="medium">Evidência(s):</Label>
                                                        </div>
                                                        <div className="flex-grow-1 gap-3 d-flex flex-column">
                                                            {pixOccurrence.evidence?.link
                                                                ? pixOccurrence.evidence?.link
                                                                : pixOccurrence.evidence?.files?.map((file: string, index: number) => {
                                                                    return (
                                                                        <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                                                            <div className="d-flex gap-2 align-items-center">
                                                                                {/* @ts-ignore */}
                                                                                <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                                                                <div className="d-flex flex-column gap-1">
                                                                                    <Label>{`Evidência ${++index}`}</Label>
                                                                                </div>
                                                                            </div>
                                                                            <button className="border-0 bg-transparent">
                                                                                <a href={file} target="blank" >
                                                                                    <Icon IconComponent={OpenInNewIcon} color={dsVariables.colors.Astronaut[700]} />
                                                                                </a>
                                                                            </button>
                                                                        </FilesCard>
                                                                    )
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion>
                                        )
                                    }
                                    {!currentTakedown?.occurrences?.length && 'Nenhuma evidência inserida'}
                                </Accordion>
                            </div>}
                            {currentTakedown?.threat?.type == ThreatTypeEnum.FAKE_AD && <div className="d-flex flex-column mt-4">
                                <Accordion withBorder title="Informações do Anunciante">
                                    <div className="d-flex flex-column gap-2">
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Perfil Anunciante:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.profile}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">ID Perfil Anunciante:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.advertiserId}</Label>
                                        </div>
                                        <div className="d-flex gap-2">
                                            <Label style="medium">Perfil Instagram:</Label>
                                            <Label>{currentTakedown?.sourceThreat?.instagramProfile}</Label>
                                        </div>
                                    </div>
                                </Accordion>
                            </div>}
                        </div>
                    </div>

                    <div className="w-50" style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ gap: 16, display: 'flex', flexDirection: 'column', borderBottom: '1px solid #E6E6E6', paddingBottom: 8 }}>
                            <Sub6 style="medium">Denúncias</Sub6>
                            <button disabled={!canActTakedown || isDetailView} role={!canActTakedown || isDetailView ? "none" : "button"} onClick={() => setShowRegisterComplaint(true)} className="bg-transparent border-0 m-0 p-0 text-start">
                                <Label color={!canActTakedown || isDetailView ? dsVariables.colors.Gray[400] : "#30437F"}><Add /> Registrar denúncia</Label>
                            </button>
                            <Body color="#818181" style="medium">Denúncias feitas</Body>
                        </div>
                        <div className="gap-4" style={{ paddingBlock: 16, overflow: 'auto', display: 'flex', flexDirection: 'column', }}>
                            {showRegisterComplaint && <RegisterComplaintForm onCancel={() => {
                                setShowRegisterComplaint(false)
                                dispatch(changeComplaintFormDate(''))
                                dispatch(changeComplaintFormReportedTo(CompaniesComplaintEnum.NONE))
                                dispatch(changeComplaintFormChannel('Formulário'))
                                dispatch(changeComplaintFormReportedId(''))
                                dispatch(changeComplaintFormEvidenceLink(''))
                                dispatch(changeComplaintFormEvidence([]))
                            }} />}
                            {(!currentTakedown?.complaints?.length) && <img style={{ pointerEvents: 'none' }} className="w-100 h-100" src={EmptyState} alt="nenhuma denúncia" />}
                            {!!currentTakedown?.complaints && currentTakedown?.complaints?.map(complaint =>
                                <RegisterComplaintForm onCancel={() => setShowRegisterComplaint(false)} complaint={complaint} key={complaint._id} editFlow={true} />
                            )}
                        </div>
                    </div>
                </div >


                <CloseOutlined onClick={onClose} className="position-absolute top-0 end-0 m-4" role="button" />


            </section >

            <ModalTreatmensList isOpen={showTreatmentsModalList} onClose={() => setShowTreatmentsModalList(false)} treatmentsList={treatmentsList} />

            <ToastContainer />
        </section >
    )
}


const FilesCard = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
`