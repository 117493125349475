import { APIClient } from "./api_helper";

import * as url from "./url_helper";
import { GET_EVENT } from "./url_helper";
const api = new APIClient();

// Edit profile
export const getUserProfile = () => api.get(url.GET_USER_PROFILE);

// Register Method
export const postRegister = (data) => api.create(url.POST_REGISTER, data);

// Login Method
export const postLogin = (data) => api.create(url.POST_LOGIN, data);

// postForgetPwd
export const postForgetPwd = (data) =>
  api.create(url.POST_PASSWORD_FORGET, data);

// postResetPwd
export const postResetPassword = (data) =>
  api.update(url.POST_PASSWORD_RESET, data);

// getCampaign
export const getUserCampaign = (data) => api.get(url.GET_USER_CAMPAIGN, data);

// getUserCompanies
export const getUserCompanies = () => api.get(url.GET_USER_COMPANIES);

// getTotalAgressorsFoundKPI
export const getTotalAgressorsFound = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_FOUND, data);

// getNewAgressorsKPI
export const getTotalAgressorsNew = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_NEW, data);

// getNotifiedAgressorsKPI
export const getTotalAgressorsNotified = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_NOTIFIED, data);

// getAgressorsInMediationKPI
export const getTotalAgressorsMediation = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_MEDIATION, data);

// getAgressorsEliminatedKPI
export const getTotalAgressorsEliminated = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_ELIMINATED, data);

// getAgressorInactiveKPI
export const getTotalAgressorsInactive = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_INACTIVE, data);

// getAgressorsReappearedKPI
export const getTotalAgressorsReappeared = (data) =>
  api.get(url.GET_TOTAL_AGGRESSOR_REAPPEARED, data);

// getOffenderByRangeDate
export const getOffenderRangeDate = (data) =>
  api.get(url.GET_OFFENDER_RANGE_DATE, data);

// getTotalOccurrences
export const getOffenderOcurrences = (data) =>
  api.get(url.GET_OCURRENCES, data);

// getBrandScoreByCampaign
export const getBrandScoreByCampaign = (data) =>
  api.get(url.GET_BRANDSCORE + data);

// getTotalReportByCampaign
export const getTotalReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_TOTAL_REPORT, data);

// getNewReportByCampaign
export const getNewReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_NEW_REPORT, data);

// getReappearedReportByCampaign
export const getReappearedReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_REAPPEARED_REPORT, data);

// getNewReportByCampaign
export const getNotifiedReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_NOTIFIED_REPORT, data);

// getMediationReportByCampaign
export const getMediationReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_MEDIATION_REPORT, data);

// getMediationReportByCampaign
export const getEliminatedReportByCampaign = (data) =>
  api.get(url.GET_CAMPAIGN_ELIMINATED_REPORT, data);

// getEventById
export const getEventById = (eventId, aggressivenessIndex) => {
  return api.get(GET_EVENT(eventId, aggressivenessIndex));
};

// updateEventMediation
export const updateEventMediation = (eventId, data) =>
  api.update(url.PATCH_EVENT_MEDIATION(eventId), data);

// uploadEventEvidence
export const uploadEventEvidence = (eventId, data) =>
  api.createEvidence(url.POST_EVENT_EVIDENCE_UPLOAD(eventId), data);

//getEventAds
export const getEventAds = (data) => api.get(url.GET_EVENT_ADS, data);

//deleteEventEvidence
export const deleteEventEvidence = (eventId, config) =>
  api.delete(url.DELETE_EVENT_EVIDENCE(eventId, config));

//getDailyScores
export const getDailyScores = (data) => api.get(url.GET_DAILY_SCORES, data);

//getDailyTotalAggression
export const getDailyTotalAggression = (data) =>
  api.get(url.GET_DAILY_TOTAL_AGGRESSION, data);

//getAnalyticalKpis
export const getAnalyticalKpis = (data) =>
  api.get(url.GET_ANALYTICAL_KPIS, data);

//getOffendersGroupByDomain
export const getOffendersGroupByDomain = (data) =>
  api.get(url.GET_OFFENDERS_GROUP_BY_DOMAIN, data);

//getOccurrencesTrend
export const getOccurrencesTrend = (data) =>
  api.get(url.GET_OCCURRENCES_TREND, data);

//getBrandScores
export const getBrandScores = (data) => api.get(url.GET_BRAND_SCORES, data);

//getFrauds
export const getFrauds = () => api.get(url.FRAUDS)

//getFrauds by company
export const getFraudsByCompanyId = (data) => api.get(url.FRAUDS, data)

// register fraud
export const registerFraudHelper = (data) => api.createEvidence(url.FRAUDS, data)

// update fraud
export const updateFraudHelper = (data) => api.updateEvidence(url.FRAUDS, data)

// update multiple frauds
export const updateMultipleFraudsHelper = (data) => api.update(url.PATCH_MULTIPLE_FRAUDS, data)

// restore multiple frauds
export const restoreMultipleFraudsHelper = (data) => api.update(url.RESTORE_MULTIPLE_FRAUDS, data)

// restore fraud
export const restoreFraudHelper = (data) => api.update(url.RESTORE_FRAUD, data)

//getCompanyProducts by company
export const getCompanyProductsByCompanyIdHelper = (data) => api.get(url.COMPANY_PRODUCTS, data)

// get all companies to admin page
export const getAllCompaniesToAdminHelper = (data) => api.get(url.GET_COMPANIES_TO_ADMIN)

// get all companies to admin page
export const getCompaniesGroupHelper = (data) => api.get(`${url.GET_COMPANIES_TO_ADMIN}/${data.companyId}`)

// invite user
export const inviteUserHelper = (data) => api.create(url.SEND_USER_INVITE, data)

// change user permissions
export const changeUserPermissionsHelper = (data) => api.update(url.CHANGE_USER_PERMISSIONS, data)

// get user by email
export const getUserByEmailHelper = (data) => api.get(url.GET_USER_BY_EMAIL, data)

// get all users
export const getAllUsersHelper = (data) => api.get(url.GET_ALL_USERS, data)

// get all users and users inviteds
export const getAllUsersAndInvitedsHelper = (data) => api.get(url.GET_ALL_USERS_AND_INVITEDS, data)

// cancel user invite
export const cancelUserInviteHelper = (data) => api.put(url.CANCEL_USER_INVITE, data)

// resend user invite
export const resendUserInviteHelper = (data) => api.create(url.RESEND_USER_INVITE, data)

// change user status
export const changeUserStatusHelper = (data) => api.put(url.CHANGE_USER_STATUS, data)

// change user passsword from admin
export const changeUserPasswordAdminHelper = (data) => api.put(url.CHANGE_USER_PASSWORD_ADMIN, data)

//getTakedownsHelper
export const getTakedownsHelper = (data) => api.get(url.TAKEDOWNS, data)

// update takedown
export const updateTakedownHelper = (data) => api.updateEvidence(url.TAKEDOWNS, data)

// update assumeTakedown
export const updateAssumeTakedownHelper = (data) => api.update(url.ASSUME_TAKEDOWNS, data)

// update renounceTakedown
export const updateRenounceTakedownHelper = (data) => api.update(url.RENOUNCE_TAKEDOWNS, data)

// register complaint
export const registerComplaintHelper = (data) => api.createEvidence(url.COMPLAINT, data)

// fetch complaints
export const fetchComplaintsHelper = (data) => api.get(url.COMPLAINT, data)

// update complaint
export const updateComplaintHelper = (data) => api.updateEvidence(url.COMPLAINT, data)

// register pix
export const registerPixHelper = (data) => api.createEvidence(url.PIX, data)

// fetch pix
export const fetchPixHelper = (data) => api.get(url.PIX, data)

// update pix
export const updatePixHelper = (data) => api.updateEvidence(url.PIX, data)

// update takedown to finish phase
export const finishTakedownHelper = (data) => api.update(url.FINISH_TAKEDOWNS, data)

// update takedown to special treatment phase
export const specialTreamentTakedownHelper = (data) => api.update(url.SPECIAL_TREATMENT_TAKEDOWNS, data)

// reopen threats
export const reopenThreatsHelper = (data) => api.update(url.REOPEN_THREATS, data)

// threat details
export const threatDetailsHelper = (data) => api.get(url.THREAT_DETAILS, data)

// takedown details
export const takedownDetailHelper = (data) => api.get(url.TAKEDOWN_DETAILS, data)

// fetch labels by type
export const fetchLabelsByTypeHelper = (data) => api.get(url.FETCH_LABELS_BY_TYPE, data)

// link occurrences
export const linkOccurrencesHelper = (data) => api.updateEvidence(url.LINK_OCCURRENCES, data)

// register database in type
export const registerDatabaseInTypeHelper = (data) => api.create(url.REGISTER_DATABASE_IN_TYPE, data)

// update database 
export const updateDatabaseHelper = (data) => api.update(url.ADMIN_DATABASES, data)

// fetch database by type
export const fetchDatabaseByTypeHelper = (data) => api.get(url.FETCH_DATABASE_BY_TYPE, data)

// link databases
export const linkDatabasesHelper = (data) => api.update(url.LINK_DATABASES, data)

// link host databases
export const linkHostDatabasesHelper = (data) => api.update(url.LINK_HOST_DATABASES, data)