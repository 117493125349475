import { IThreats } from '../interfaces/threats.interface';

export const exportThreatsToCSV = (threats: IThreats[], selectedCompany: { value: string, label: string }) => {

    const BOM = '\uFEFF';
    

    const headers = [
        'Id',
        'Empresa',
        'Fase atual',
        'Tipo de ameaça',
        'Plataforma',
        'Ameaça',
        'Criado em',
        'Url da ocorrência'
    ];


    const rows = threats.map(threat => [
        `"${threat._id}"`,
        `"${selectedCompany?.label || threat.companyId}"`,
        `"${threat.currentState}"`,
        `"${threat.type}"`,
        `"${threat.sourceThreat?.platform || ''}"`,
        `"${threat.sourceThreat?.searchText || ''}"`,
        `"${new Date(threat.createdAt).toLocaleDateString('pt-BR')}"`,
        `"${threat.occurrences?.[0]?.url || ''}"`,
    ]);


    const csv = BOM + [headers.join(';'), ...rows.map(row => row.join(';'))].join('\n');
    

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', `ameacas_${new Date().toISOString()}.csv`);
    link.style.visibility = 'hidden';
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}; 