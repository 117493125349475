import { AdminInitialState } from "./state";
import { ActionTypesEnum } from "./types.enum";

const adminReducer = (state = AdminInitialState, action: { type: ActionTypesEnum, payload: any }) => {
    switch (action.type) {
        case ActionTypesEnum.FETCH_COMPANIES:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_COMPANIES_LIST:
            return {
                ...state,
                companiesList: action.payload
            };

        case ActionTypesEnum.FETCH_COMPANIES_GROUP:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_COMPANIES_GROUP_LIST:
            return {
                ...state,
                companiesGroupList: action.payload
            };

        case ActionTypesEnum.INVITE_USER:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER_PERMISSIONS:
            return {
                ...state,
            };

        case ActionTypesEnum.FETCH_USER_TO_CHANGE:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER:
            return {
                ...state,
                user: action.payload
            };

        case ActionTypesEnum.FETCH_USERS:
            return {
                ...state,
            };

        case ActionTypesEnum.FETCH_USERS_AND_INVITEDS:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USERS:
            return {
                ...state,
                users: action.payload
            };

        case ActionTypesEnum.CHANGE_USERS_AND_INVITEDS:
            return {
                ...state,
                usersAndInviteds: {
                    ...state.usersAndInviteds,
                    data: action.payload.data,
                    totalPages: action.payload.totalPages,
                    totalResults: action.payload.totalResults
                }
            };

        case ActionTypesEnum.CHANGE_SUCCESS_MESSAGE:
            return {
                ...state,
                successMessage: action.payload
            };

        case ActionTypesEnum.CHANGE_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload
            };

        case ActionTypesEnum.CANCEL_USER_INVITE:
            return {
                ...state,
            };

        case ActionTypesEnum.RESEND_USER_INVITE:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER_PASSWORD:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_USER_STATUS:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_FILTER:
            return {
                ...state,
                usersAndInviteds: {
                    ...state.usersAndInviteds,
                    filter: action.payload
                }
            };

        case ActionTypesEnum.CHANGE_FORM_DATABASE_REGISTRAR:
            return {
                ...state,
                databases: {
                    ...state.databases,
                    forms: {
                        ...state.databases.forms,
                        registrar: action.payload
                    }
                }
            };

        case ActionTypesEnum.CHANGE_FORM_DATABASE_DOMAIN:
            return {
                ...state,
                databases: {
                    ...state.databases,
                    forms: {
                        ...state.databases.forms,
                        domain: action.payload
                    }
                }
            };

        case ActionTypesEnum.CHANGE_FORM_DATABASE_HOST:
            return {
                ...state,
                databases: {
                    ...state.databases,
                    forms: {
                        ...state.databases.forms,
                        host: action.payload
                    }
                }
            };


        case ActionTypesEnum.REGISTER_REGISTRAR:
            return {
                ...state,
            };
        case ActionTypesEnum.REGISTER_DOMAIN:
            return {
                ...state,
            };
        case ActionTypesEnum.REGISTER_HOST:
            return {
                ...state,
            };
        case ActionTypesEnum.FETCH_DATABASE_BY_TYPE:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_DATABASE_BY_TYPE:
            return {
                ...state,
                databases: {
                    ...state.databases,
                    forms: {
                        ...state.databases.forms,
                    },
                    [action.payload[0].type]: action.payload
                }
            };

        case ActionTypesEnum.UPDATE_DATABASE:
            return {
                ...state,
            };

        default:
            return state;
    }
};
export default adminReducer;