// src/components/CompanyTable.jsx
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from "../../../components/Icon";
import { EditOutlined, InsertDriveFileOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../store/threats/actions";
import { APIClient } from "../../../../helpers/api_helper";
import Paginator from "./paginator";
import { useLocation, useSearchParams } from "react-router-dom";
import EditDomainModal from "./edit-domain-modal";

const TableStyled = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
`;

const TableHead = styled.thead`
  background: #f1f5f9;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f9fafc;
  }
`;

const TableHeader = styled.th`
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ccc;
`;

const Status = styled.span<{ active: boolean }>`
  color: ${(props) => (props.active ? 'green' : 'red')};
  font-weight: bold;
`;


const TableDomain = () => {

    const dispatch = useDispatch()
    const location = useLocation()

    const [list, setList] = useState([]); // Estado para armazenar os dados
    const [searchParams, _] = useSearchParams();
    const [showModalRegisterDomain, setShowModalRegisterDomain] = useState(false);
    const [campaignToEdit, setCampaignToEdit] = useState();
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                dispatch(setLoading(true))

                const api = new APIClient();

                const data: any = await api.get('/campaign/admin-campaigns-domain', {
                    page: searchParams.get('page') || 1,
                    itemsPerPage: searchParams.get('itemsPerPage') || 10,
                    text: searchParams.get('text'),
                    company_type: searchParams.get('company_type'),
                    searchType: searchParams.get('searchType'),
                    status: searchParams.get('status'),
                    monitoringType: searchParams.get('monitoringType'),
                })

                setList(data.campaigns);
                setTotalCount(data.totalCount)
            } catch (err: any) {
                console.error(err.message);
            } finally {
                dispatch(setLoading(false))
            }
        };

        fetchData();
    }, [location]);

    async function editCampaign(_id: string) {
        setCampaignToEdit(list.find((el: any) => el._id == _id))
        setShowModalRegisterDomain(true)
    }

    return (
        <div>

            <TableStyled>
                <TableHead>
                    <TableRow>
                        <TableHeader>Empresa</TableHeader>
                        <TableHeader>Tipo empresa</TableHeader>
                        <TableHeader>Campanha</TableHeader>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Palavras chaves incluídas</TableHeader>
                        <TableHeader>Palavras chaves excluídas</TableHeader>
                        <TableHeader>Vezes ao dia</TableHeader>
                        <TableHeader>Tipo campanha</TableHeader>
                        <TableHeader>Monitoramento</TableHeader>
                        <TableHeader></TableHeader>
                    </TableRow>
                </TableHead>
                <tbody>
                    {list.map((item: any) => (
                        <TableRow key={item._id}>
                            <TableCell>{item.brandName}</TableCell>
                            <TableCell>{item.company.company_type}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>
                                <Status active={item.isEnabled}>{item.isEnabled ? 'Ativo' : 'Inativo'}</Status>
                            </TableCell>
                            <TableCell>{JSON.stringify(item.domainKeywords?.include)}</TableCell>
                            <TableCell>{JSON.stringify(item.domainKeywords?.exclude)}</TableCell>
                            <TableCell>{24 / item.intervalHours}</TableCell>
                            <TableCell>{item.searchType}</TableCell>
                            <TableCell>{item.monitoringType}</TableCell>
                            <TableCell>
                                <div onClick={() => editCampaign(item._id)} className="d-flex gap-2" role="button">
                                    <Icon IconComponent={EditOutlined} />
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </tbody>
            </TableStyled>
            <Paginator totalCount={totalCount} />

            <EditDomainModal campaignToEdit={campaignToEdit} showModalRegisterDomain={showModalRegisterDomain} setShowModalRegisterDomain={setShowModalRegisterDomain} />
        </div>
    );
};

export default TableDomain;