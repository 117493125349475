import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../DesignSystem/components/Modal";
import { IStore } from "../../../../interfaces/store.interface";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import AdsForm from "../Forms/AdsForm";
import ProfileForm from "../Forms/ProfileForm";
import SiteForm from "../Forms/SiteForm";
import ContentForm from "../Forms/ContentForm";
import Snackbar from "../../../../DesignSystem/components/Snackbar";
import { useEffect, useState } from "react";
import { changeAdsForm, changeAdsFormId, changeAdsFormIsActive, changeAdsFormPlatform, changeAdsFormSearchDate, changeAdsFormUrl, changeContentForm, changeProfileForm, changeSiteForm } from "../../../../store/threats/actions";

interface IProps {
    isOpen: boolean
    onClose: any
    onConfirm: any
}

export default function ModalEdit({ isOpen, onClose, onConfirm }: IProps) {

    const dispatch = useDispatch()

    const { adsForm, profileForm, siteForm, contentForm, selectedCompany, formErrorMessage, formError, current } = useSelector((store: IStore) => ({
        adsForm: store.Threats.forms.adsForm,
        profileForm: store.Threats.forms.profileForm,
        siteForm: store.Threats.forms.siteForm,
        contentForm: store.Threats.forms.contentForm,
        threatsList: store.Threats.threatsList,
        selectedCompany: store.Profile.selectedCompany,
        formErrorMessage: store.Threats.forms.formErrorMessage,
        formError: store.Threats.forms.formError,
        current: store.Threats.current,
    }))

    const renderForm = () => {
        switch (current?.type) {
            case ThreatTypeEnum.FAKE_AD: return <AdsForm isInvalidUrl={false} />
            case ThreatTypeEnum.FAKE_PROFILE: return <ProfileForm isInvalidUrl={false} />
            case ThreatTypeEnum.FAKE_SITE: return <SiteForm isInvalidUrl={false} />
            case ThreatTypeEnum.FAKE_CONTENT: return <ContentForm isInvalidUrl={false} />
        }
    }

    const isButtonDisabled = () => {
        switch (current?.type) {
            case ThreatTypeEnum.FAKE_AD: return !(adsForm.searchText && adsForm.platform && adsForm.advertiserId && adsForm.advertiserName && adsForm.occurrences!.every(el => el.url && (el?.evidence?.link || el.evidence?.files?.length > 0)))
            case ThreatTypeEnum.FAKE_SITE: return !(siteForm.searchText && siteForm.occurrences!.every(el => el.url && (el?.evidence?.link || el.evidence?.files?.length > 0)))
            case ThreatTypeEnum.FAKE_CONTENT: return !(contentForm.searchText && contentForm.platform && contentForm.profile && contentForm.occurrences!.every(el => el.url && (el?.evidence?.link || el.evidence?.files?.length > 0)))
            case ThreatTypeEnum.FAKE_PROFILE: return !(profileForm.searchText && profileForm.platform && profileForm.profile && profileForm.occurrences!.every(el => el.url && (el?.evidence?.link || el.evidence?.files?.length > 0)))
        }
    }

    const getEvidenceArray = (evidence: any) => {
        if (!evidence) return []
        if (Object.hasOwn(evidence[0], 'screenshotUrl')) return evidence.map((el: any) => el.screenshotUrl)
        else return evidence
    }

    useEffect(() => {
        switch (current?.type) {
            case ThreatTypeEnum.FAKE_SITE:
                dispatch(changeSiteForm({
                    isActive: current.sourceThreat.isActive,
                    searchText: current.sourceThreat?.searchText,
                    type: current.type,
                    occurrences: current.occurrences
                }))
                break;
            case ThreatTypeEnum.FAKE_AD:
                dispatch(changeAdsForm({
                    isActive: current.sourceThreat.isActive, url: current.url, advertiserId: current.advertiserId!,
                    advertiserName: current.advertiserName!, id: current.id!, instagramProfile: current.instagramProfile!,
                    platform: current.platform, searchDate: current.searchDate!, startDate: current.startDate!,
                    searchText: current.sourceThreat?.searchText, type: current.type,
                    adsQuantity: 0, occurrences: current.occurrences,
                }))
                break;
            case ThreatTypeEnum.FAKE_PROFILE:
                dispatch(changeProfileForm({
                    isActive: current.sourceThreat.isActive,
                    url: current.url,
                    platform: current.sourceThreat.platform,
                    profile: current.sourceThreat.profile!,
                    searchDate: current.searchDate!,
                    type: current.type,
                    occurrences: current.occurrences,
                    searchText: current.sourceThreat?.searchText,
                }))
                break;
            case ThreatTypeEnum.FAKE_CONTENT:
                dispatch(changeContentForm({
                    isActive: current.sourceThreat.isActive,
                    url: current.url,
                    platform: current.sourceThreat.platform,
                    profile: current.sourceThreat.profile!,
                    type: current.type,
                    occurrences: current.occurrences,
                    searchText: current.sourceThreat?.searchText,
                }))
                break;
        }
    }, [current]);

    return (
        <>
            <Modal
                width={728}
                title="Editar ameaça"
                subtitle="Preencha abaixo as informações solicitadas."
                isOpen={isOpen}
                onClose={onClose}
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: onConfirm, label: 'Salvar alterações', state: isButtonDisabled() ? 'disabled' : 'default' },
                ]}
            >
                {renderForm()}
            </Modal>
        </>
    )
}