import { TakedownPhaseEnum } from "../../enums/takedownPhase.enum";
import { ThreatTypeTextEnum } from "../../enums/threat-type-text.enum";
import { ThreatTypeEnum } from "../../enums/threat-type.enum";

export function getThreatTypeText(threatType: ThreatTypeEnum){
    switch(threatType){
        case ThreatTypeEnum.ALL: return ThreatTypeTextEnum.ALL
        case ThreatTypeEnum.FAKE_SITE: return ThreatTypeTextEnum.FAKE_SITE
        case ThreatTypeEnum.FAKE_PROFILE: return ThreatTypeTextEnum.FAKE_PROFILE
        case ThreatTypeEnum.FAKE_AD: return ThreatTypeTextEnum.FAKE_AD
        case ThreatTypeEnum.FAKE_CONTENT: return ThreatTypeTextEnum.FAKE_CONTENT
    }
}
export function getTreatmentPhaseText(phase: TakedownPhaseEnum){
    switch(phase){
        case TakedownPhaseEnum.REQUEST_RECEIVED: return "Solicitações recebidas"
        case TakedownPhaseEnum.COMPLAINT_MADE: return "Notificações enviadas"
        case TakedownPhaseEnum.SPECIAL_TREATMENT: return "Tratativas especiais"
        case TakedownPhaseEnum.FINISHED: return "Finalizada"
    }
}
