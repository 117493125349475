import { useCallback, useEffect, useState } from "react";
import Modal from "../../../../DesignSystem/components/Modal";
import AdsForm from "../Forms/AdsForm";
import { ThreatTypeEnum } from "../../../../enums/threat-type.enum";
import SiteForm from "../Forms/SiteForm";
import ProfileForm from "../Forms/ProfileForm";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { cleanForms, linkThreatOccurrences, registerFraud, removeFormLinkOccurrence, setLoading, addOccurrenceAdsForm, addOccurrenceSiteForm, addOccurrenceContentForm, addOccurrenceProfileForm } from "../../../../store/threats/actions";
import ContentForm from "../Forms/ContentForm";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";
import Label from "../../../components/Text/Label";
import { Bounce, toast } from "react-toastify";
import { CheckCircleOutline } from "@mui/icons-material";
import { dsVariables } from "../../../variables";
import Icon from "../../../components/Icon";
import showToast from "../../../utils/show-toast.util";
import Input from "../../../components/Input/Input";
import InputEvidence from "../../../components/Input/InputEvidence";
import { AddOutlined } from "@mui/icons-material";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import { isValidUrl, isValidEvidence } from "../../../components/Input/InputEvidence";

interface IProps {
    isOpen: boolean
    onClose: any
    setShowSnackNewThreat: any
}

export default function ModalNewThreats({ isOpen, onClose, setShowSnackNewThreat }: IProps) {
    const dispatch = useDispatch()

    const [typeForm, setTypeForm] = useState<ThreatTypeTextEnum>(ThreatTypeTextEnum.FAKE_AD);

    const { adsForm, profileForm, siteForm, contentForm, selectedCompany, formErrorMessage, formError, formLinkOccurrences } = useSelector((store: IStore) => ({
        adsForm: store.Threats.forms.adsForm,
        profileForm: store.Threats.forms.profileForm,
        siteForm: store.Threats.forms.siteForm,
        contentForm: store.Threats.forms.contentForm,
        threatsList: store.Threats.threatsList,
        selectedCompany: store.Profile.selectedCompany,
        formErrorMessage: store.Threats.forms.formErrorMessage,
        formLinkOccurrences: store.Threats.forms.formLinkOccurrences,
        formError: store.Threats.forms.formError,
    }))


    const [showModalLinkOccurrences, setShowModalLinkOccurrences] = useState(false);

    useEffect(() => {
        setShowModalLinkOccurrences(Boolean(formLinkOccurrences?.link))
    }, [formLinkOccurrences]);

    function closeModalLinkOccurrence() {
        setShowModalLinkOccurrences(false)
        dispatch(removeFormLinkOccurrence())
    }

    const isValidUrl = (url: string): boolean => {
        if (!url?.trim()) return false;
        try {
            return url.startsWith('http://') || url.startsWith('https://');
        } catch {
            return false;
        }
    };

    const isValidEvidence = (evidence: any): boolean => {
        return Boolean(
            (evidence?.link && isValidUrl(evidence.link)) || 
            (evidence?.files && evidence.files.length > 0)
        );
    };

    const isValidOccurrence = (occurrence: any): boolean => {
        const hasValidUrl = isValidUrl(occurrence?.url);
        
        const hasEvidence = Boolean(
            (occurrence?.evidence?.link && occurrence.evidence.link.trim()) || 
            (occurrence?.evidence?.files?.length > 0)
        );
        
        return hasValidUrl && hasEvidence;
    };

    const isButtonDisabled = () => {
        const validateOccurrences = (occurrences: any[]) => {
            if (!occurrences?.length) return true; 
            return occurrences.every(isValidOccurrence);
        };

        const hasValidOccurrences = validateOccurrences(
            typeForm === ThreatTypeTextEnum.FAKE_AD ? (adsForm.occurrences || []) :
            typeForm === ThreatTypeTextEnum.FAKE_SITE ? (siteForm.occurrences || []) :
            typeForm === ThreatTypeTextEnum.FAKE_CONTENT ? (contentForm.occurrences || []) :
            typeForm === ThreatTypeTextEnum.FAKE_PROFILE ? (profileForm.occurrences || []) : 
            []
        );

        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD: 
                return !(adsForm.searchText && 
                        adsForm.platform && 
                        adsForm.advertiserId && 
                        adsForm.advertiserName && 
                        hasValidOccurrences);
            
            case ThreatTypeTextEnum.FAKE_SITE: 
                return !(siteForm.searchText && 
                        hasValidOccurrences);
            
            case ThreatTypeTextEnum.FAKE_CONTENT: 
                return !(contentForm.searchText && 
                        contentForm.platform && 
                        contentForm.profile && 
                        hasValidOccurrences);
            
            case ThreatTypeTextEnum.FAKE_PROFILE: 
                return !(profileForm.searchText && 
                        profileForm.platform && 
                        profileForm.profile && 
                        hasValidOccurrences);
        }
    };

    function changeTypeForm(threatTypeText: ThreatTypeTextEnum) {
        dispatch(cleanForms())
        setTypeForm(threatTypeText)
    }

    const renderForm = useCallback(() => {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD: return <AdsForm isInvalidUrl={false} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_PROFILE: return <ProfileForm isInvalidUrl={false} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_SITE: return <SiteForm isInvalidUrl={false} setTypeForm={changeTypeForm} />
            case ThreatTypeTextEnum.FAKE_CONTENT: return <ContentForm isInvalidUrl={false} setTypeForm={changeTypeForm} />
        }
    }, [typeForm])

    async function registerThreat() {
        dispatch(setLoading(true))
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD:

                dispatch(registerFraud({
                    ...adsForm, companyId: selectedCompany?.value!, id: adsForm.id, platform: adsForm.platform,
                    advertiserName: adsForm.advertiserName, advertiserId: adsForm.advertiserId,
                    instagramProfile: adsForm.instagramProfile, searchText: adsForm.advertiserName + adsForm.advertiserId
                }))

                break;
            case ThreatTypeTextEnum.FAKE_PROFILE:
                dispatch(registerFraud({
                    ...profileForm, companyId: selectedCompany?.value!,
                    profile: profileForm.searchText, platform: profileForm.platform,
                    searchDate: profileForm.searchDate
                }))

                break;
            case ThreatTypeTextEnum.FAKE_SITE:
                dispatch(registerFraud({ ...siteForm, companyId: selectedCompany?.value!, domain: siteForm.searchText }))
                setShowSnackNewThreat(true)
                break;
            case ThreatTypeTextEnum.FAKE_CONTENT:
                dispatch(registerFraud({
                    ...contentForm, companyId: selectedCompany?.value!,
                    profile: contentForm.searchText, platform: contentForm.platform,
                    url: contentForm.url,
                }))
                break;
        }
        showToast({ children: <Label>Ameaça incluídda com sucesso!</Label>, type: 'info' })
        onClose()
    }

    function linkOccurrences() {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD:
                dispatch(linkThreatOccurrences({ occurrences: adsForm.occurrences!, threatId: formLinkOccurrences.threatId, type: ThreatTypeEnum.FAKE_AD }))
                break;
            case ThreatTypeTextEnum.FAKE_PROFILE:
                dispatch(linkThreatOccurrences({ occurrences: profileForm.occurrences!, threatId: formLinkOccurrences.threatId, type: ThreatTypeEnum.FAKE_PROFILE }))
                break;
            case ThreatTypeTextEnum.FAKE_SITE:
                dispatch(linkThreatOccurrences({ occurrences: siteForm.occurrences!, threatId: formLinkOccurrences.threatId, type: ThreatTypeEnum.FAKE_SITE }))
                break;
            case ThreatTypeTextEnum.FAKE_CONTENT:
                dispatch(linkThreatOccurrences({ occurrences: contentForm.occurrences!, threatId: formLinkOccurrences.threatId, type: ThreatTypeEnum.FAKE_CONTENT }))
                break;
        }
        closeModalLinkOccurrence()
        toast.success(
            <Label style="medium">Ocorrências vinculadas!</Label>, {
            position: "bottom-center",
            autoClose: 5000,
            closeOnClick: true,
            transition: Bounce,
            style: {
                backgroundColor: dsVariables.colors.Blue[100],
                color: dsVariables.colors.Blue[800],
                borderColor: dsVariables.colors.Blue[200],
                borderWidth: 1,
                borderStyle: 'solid',
                maxWidth: 480,
                marginLeft: -100,
                zIndex: 123123123123,
            },
            progressStyle: {
                backgroundColor: dsVariables.colors.Blue[800],
                height: 2
            },
            icon: () => <Icon IconComponent={CheckCircleOutline} width={16} height={16} color={dsVariables.colors.Blue[800]} />
        })
    }

    const handleAddOccurrence = (e: any) => {
        e.preventDefault();
        
        const currentOccurrences = 
            typeForm === ThreatTypeTextEnum.FAKE_AD ? (adsForm.occurrences || []) :
            typeForm === ThreatTypeTextEnum.FAKE_SITE ? (siteForm.occurrences || []) :
            typeForm === ThreatTypeTextEnum.FAKE_CONTENT ? (contentForm.occurrences || []) :
            typeForm === ThreatTypeTextEnum.FAKE_PROFILE ? (profileForm.occurrences || []) : 
            [];
        
        const lastOccurrence = currentOccurrences[currentOccurrences.length - 1];

        if (!lastOccurrence) {
            dispatchNewOccurrence();
        } else if (isValidUrl(lastOccurrence.url) && isValidEvidence(lastOccurrence.evidence)) {
            dispatchNewOccurrence();
        } else {
            showToast({ 
                children: <Label>
                    Preencha corretamente a URL da ocorrência e adicione pelo menos uma evidência válida
                </Label>,
                type: 'error' 
            });
        }
    };

    const dispatchNewOccurrence = () => {
        switch (typeForm) {
            case ThreatTypeTextEnum.FAKE_AD:
                dispatch(addOccurrenceAdsForm());
                break;
            case ThreatTypeTextEnum.FAKE_SITE:
                dispatch(addOccurrenceSiteForm());
                break;
            case ThreatTypeTextEnum.FAKE_CONTENT:
                dispatch(addOccurrenceContentForm());
                break;
            case ThreatTypeTextEnum.FAKE_PROFILE:
                dispatch(addOccurrenceProfileForm());
                break;
        }
    };

    return (
        <>
            <Modal
                width={728}
                title="Incluir ameaça"
                subtitle="Preencha abaixo as informações solicitadas."
                isOpen={isOpen}
                onClose={onClose}
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: registerThreat, label: 'Inserir', state: isButtonDisabled() ? 'disabled' : 'default' },
                ]}
            >
                {renderForm()}
            </Modal>

            <Modal
                width={300}
                title="Vincular ocorrências"
                subtitle={`Ameaça existente: ${formLinkOccurrences?.sourceThreat?.searchText}`}
                isOpen={showModalLinkOccurrences}
                onClose={closeModalLinkOccurrence}
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: closeModalLinkOccurrence, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: linkOccurrences, label: 'Vincular', state: isButtonDisabled() ? 'disabled' : 'default' },
                ]}>
                <Label style="medium">Deseja vincular as ocorrências à ameaça existente?</Label>
                <Label>A ameaça em questão já existe. Deseja vincular as ocorrências preenchidas à ameaça existente?</Label>
            </Modal>

        </>

    );
}
