import { useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { IStore } from "../../../../interfaces/store.interface";
import Body from "../../../components/Text/Body";

interface IProps {
    isOpen: boolean
    onClose: any
    onConfirm: any
}

export default function ModalSpecialTreatment({ isOpen, onClose, onConfirm }: IProps) {

    const { currentTakedown, currentIds } = useSelector((store: IStore) => store.Threats)

    return (
        <Modal
            title="Tratativas especiais" subtitle={currentIds.length > 1 ? `Selecionadas: ${currentIds.length}` : `Selecionada: ${currentTakedown?.sourceThreat?.searchText}`} isOpen={isOpen} onClose={onClose} width={480}
            footerButtons={[
                { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                { buttonStyle: 'primary', onClick: onConfirm, label: 'Confirmar' },
            ]}
        >
            <div className="d-flex flex-column gap-2 mb-4">
                <Body style="medium">Tem certeza que deseja mover para tratativas especiais?</Body>
                {
                    currentIds.length == 0 && !currentTakedown
                        ? null
                        : currentIds.length > 1
                            ? <span>Foram selecionadas {currentIds.length} tratativas. Elas estarão em tratativas especiais.</span>
                            : <span>Foi selecionado a tratativa: {currentTakedown?.sourceThreat?.searchText} e estará em tratativas especiais.</span>
                }
            </div>
        </Modal>
    )
}