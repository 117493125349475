import { ReactNode } from "react";
import { Bounce, toast } from "react-toastify";
import { dsVariables } from "../variables";
import Icon from "../components/Icon";
import { CheckCircleOutline, ErrorOutline, InfoOutlined, SvgIconComponent, WarningOutlined } from "@mui/icons-material";

type TToastState = 'success' | 'error' | 'info' | 'warning';

interface IProps {
    type: TToastState
    children: ReactNode;
    icon?: SvgIconComponent
}

export default function showToast({ children, type, icon }: IProps) {

    const toastIcon = () => {
        if (icon) return icon
        switch (type) {
            case "success": return CheckCircleOutline
            case "error": return ErrorOutline
            case "info": return InfoOutlined
            case "warning": return WarningOutlined
        }
    }

    const color = () => {
        switch (type) {
            case "success": return dsVariables.colors.Green
            case "error": return dsVariables.colors.Red
            case "info": return dsVariables.colors.Blue
            case "warning": return dsVariables.colors.Orange
            default:
                throw new Error("Invalid toast type");
        }
    }

    toast[type](children, {
        position: "bottom-center",
        autoClose: 5000,
        closeOnClick: true,
        transition: Bounce,
        style: {
            backgroundColor: color()[100],
            color: color()[800],
            borderColor: color()[200],
            borderWidth: 1,
            borderStyle: 'solid',
            maxWidth: 480,
            marginLeft: -100,
            zIndex: 123123123123,
        },
        progressStyle: {
            backgroundColor: color()[800],
            height: 2
        },
        icon: () => <Icon IconComponent={toastIcon()} width={16} height={16} color={color()[800]} />
    })
}