import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Input/Input";
import Label from "../../../components/Text/Label";
import { dsVariables } from "../../../variables";
import Select from "../../../components/Select";
import { CompaniesComplaintEnum } from "../../../../enums/companies-complaint.enum";
import Accordion from "../../../components/Accordion";
import InputEvidence from "../../../components/Input/InputEvidence";
import { IStore } from "../../../../interfaces/store.interface";
import { changeComplaintFormChannel, changeComplaintFormDate, changeComplaintFormEvidence, changeComplaintFormEvidenceLink, changeComplaintFormReportedId, changeComplaintFormReportedTo, registerComplaint, setLoading, updateComplaint } from "../../../../store/threats/actions";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import ButtonSecondary from "../../../components/Button/ButtonSecondary";
import Body from "../../../components/Text/Body";
import styled from "styled-components";
import { useState } from "react";
import { CheckCircleOutline, CheckOutlined, ErrorOutline, ModeEditOutline } from "@mui/icons-material";
import Icon from "../../../components/Icon";
import { Bounce, toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { TakedownPhaseEnum } from "../../../../enums/takedownPhase.enum";

interface IProps {
    onCancel?: any
    editFlow?: boolean
    complaint?: any
}

export default function RegisterComplaintForm({ onCancel, editFlow = false, complaint }: IProps) {

    const { forms: { registerComplaintForm }, currentTakedown } = useSelector((store: IStore) => store.Threats)
    const dispatch = useDispatch()

    const [canEdit, setCanEdit] = useState(editFlow);

    const searchParams = useSearchParams()[0]

    const tab = searchParams.get('tab')

    const isDetailView = tab != '0' && tab != '1'

    async function saveComplaint(e: any) {
        e.preventDefault()
        if (registerComplaintForm.reportedTo == CompaniesComplaintEnum.NONE) {
            return toast.error(<div className="d-flex flex-column">
                <Label style="medium">
                    Erro
                </Label>
                <Label>Reportado a inválido</Label>
            </div>, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                transition: Bounce,
                style: {
                    backgroundColor: dsVariables.colors.Red[100],
                    color: dsVariables.colors.Red[600],
                    borderColor: dsVariables.colors.Red[200],
                    borderWidth: 1,
                    borderStyle: 'solid'
                },
                icon: () => <ErrorOutline />
            })
        }

        const [day, month, year] = registerComplaintForm.reportDate.split('/');
        const validDate = new Date(`${year}-${month}-${day}`);

        if (isNaN(new Date(validDate).getTime())) {
            return toast.error(
                <div className="d-flex flex-column">
                    <Label style="medium">
                        Erro
                    </Label>
                    <Label>Data inválida</Label>
                </div>, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                transition: Bounce,
                style: {
                    backgroundColor: dsVariables.colors.Red[100],
                    color: dsVariables.colors.Red[600],
                    borderColor: dsVariables.colors.Red[200],
                    borderWidth: 1,
                    borderStyle: 'solid'
                },
                icon: () => <ErrorOutline />
            })
        }

        if (!registerComplaintForm?.evidence?.link && !registerComplaintForm?.evidence?.files?.length) {
            return toast.error(<div className="d-flex flex-column">
                <Label style="medium">
                    Erro
                </Label>
                <Label>Insira ao menos uma evidência</Label>
            </div>, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                transition: Bounce,
                style: {
                    backgroundColor: dsVariables.colors.Red[100],
                    color: dsVariables.colors.Red[600],
                    borderColor: dsVariables.colors.Red[200],
                    borderWidth: 1,
                    borderStyle: 'solid'
                },
                icon: () => <ErrorOutline />
            })
        }
        dispatch(setLoading(true))
        if (editFlow) dispatch(updateComplaint({ ...registerComplaintForm, treatmentId: currentTakedown?._id, _id: complaint._id }))
        else dispatch(registerComplaint({ ...registerComplaintForm, treatmentId: currentTakedown?._id }))
        onCancel()

        toast.success(
            <div className="d-flex flex-column">
                <Label style="medium">
                    Denúncia registrada!
                </Label>
            </div>
            , {
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                transition: Bounce,
                style: {
                    backgroundColor: dsVariables.colors.Green[100],
                    color: dsVariables.colors.Green[800],
                    borderColor: dsVariables.colors.Green[200],
                    borderWidth: 1,
                    borderStyle: 'solid'
                },
                progressStyle: {
                    height: 2,
                    backgroundColor: dsVariables.colors.Green[800],
                },
                icon: () => <CheckCircleOutline />
            })

        if (currentTakedown?.phase == TakedownPhaseEnum.REQUEST_RECEIVED) {
            toast.success(
                <div className="d-flex flex-column">
                    <Label style="medium">
                        Tratativa movida para notificações enviadas!
                    </Label>
                </div>
                , {
                    position: "bottom-center",
                    autoClose: 5000,
                    closeOnClick: true,
                    transition: Bounce,
                    style: {
                        backgroundColor: dsVariables.colors.Green[100],
                        color: dsVariables.colors.Green[800],
                        borderColor: dsVariables.colors.Green[200],
                        borderWidth: 1,
                        borderStyle: 'solid'
                    },
                    progressStyle: {
                        height: 2,
                        backgroundColor: dsVariables.colors.Green[800],
                    },
                    icon: () => <CheckCircleOutline />
                })
        }

        return
    }

    function formatDate(date: string) {
        date = date.replace(/\D/g, '');

        if (date.length <= 2) return dispatch(changeComplaintFormDate(date))

        if (date.length <= 4) return dispatch(changeComplaintFormDate(date.slice(0, 2) + '/' + date.slice(2)))

        if (date.length <= 8) return dispatch(changeComplaintFormDate(date.slice(0, 2) + '/' + date.slice(2, 4) + '/' + date.slice(4)))

        return dispatch(changeComplaintFormDate(date.slice(0, 2) + '/' + date.slice(2, 4) + '/' + date.slice(4, 8)))
    }

    return (
        <form onSubmit={saveComplaint}
            style={{
                borderColor: canEdit ? dsVariables.colors.Gray[200] : dsVariables.colors.Blue[500],
                borderWidth: 1, borderStyle: 'solid',
                boxShadow: canEdit ? '0px 0px var(--4, 4px) var(--0, 0px) var(--Colors-Gray-200, #E6E6E6)' : 'unset'
            }}
            className="rounded d-flex flex-column gap-2 position-relative pt-3"
        >
            {(editFlow && !isDetailView && canEdit) && <div role="button" className="position-absolute top-0 end-0 m-3">
                <Icon IconComponent={ModeEditOutline} width={16} height={16} onClick={() => {
                    setCanEdit(!canEdit)
                    dispatch(changeComplaintFormChannel(complaint.complaintChannel))
                    dispatch(changeComplaintFormDate(complaint.reportDate))
                    dispatch(changeComplaintFormReportedTo(complaint.reportedTo))
                    dispatch(changeComplaintFormReportedId(complaint.reportId))
                    dispatch(changeComplaintFormEvidenceLink(complaint?.evidence?.link || ''))
                    dispatch(changeComplaintFormEvidence(complaint?.evidence?.files || []))
                }} />
            </div>}
            <div className="d-flex gap-4 mt-2 px-3">
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column w-50">
                    <Label color={dsVariables.colors.Gray[600]}>Data do Relatório*</Label>
                    {
                        canEdit
                            ? <Body>{complaint.reportDate}</Body>
                            : <Input placeholder="12/07/2024" required={true} value={registerComplaintForm.reportDate} onChange={(e: any) => formatDate(e.target.value)} />
                    }
                </div>
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column w-50">
                    <Label color={dsVariables.colors.Gray[600]}>Reportado a*</Label>
                    {
                        canEdit
                            ? <Body>{complaint.reportedTo}</Body>
                            : <Select required={true} selected={registerComplaintForm.reportedTo} onChange={(e: any) => dispatch(changeComplaintFormReportedTo(e.target.value))} options={Object.values(CompaniesComplaintEnum)} />
                    }
                </div>
            </div>
            <div className="d-flex gap-4 mt-2 px-3 pb-3">
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column w-50">
                    <Label color={dsVariables.colors.Gray[600]}>Canal de Denúncia*</Label>
                    {
                        canEdit
                            ? <Body>{complaint.complaintChannel}</Body>
                            : <Select required={true} selected={registerComplaintForm.complaintChannel} onChange={(e: any) => dispatch(changeComplaintFormChannel(e.target.value))} options={Object.values(['Formulário', 'E-mail'])} />
                    }
                </div>
                <div style={{ gap: canEdit ? 0 : 4 }} className="d-flex flex-column w-50">
                    <Label color={dsVariables.colors.Gray[600]}>ID do relatório*</Label>
                    {
                        canEdit
                            ? <Body>{complaint.reportId}</Body>
                            : <Input placeholder="Insira o ID" required={true} value={registerComplaintForm.reportId} onChange={(e: any) => dispatch(changeComplaintFormReportedId(e.target.value))} />
                    }
                </div>
            </div>
            <Accordion withBorder title="Comprovante de denúncias">
                {canEdit ?
                    complaint?.evidence?.link
                        ? <Body>{complaint?.evidence?.link}</Body>
                        : <div className="d-flex flex-column gap-2">
                            {complaint?.evidence?.files?.map((file: any, index: number) => (
                                <FilesCard key={index} className="d-flex justify-content-between align-items-center px-3 py-2">
                                    <div className="d-flex gap-2 align-items-center">
                                        {/* @ts-ignore */}
                                        <img className="object-fit-contain" width={42} height={42} src={!file?.name ? file : URL.createObjectURL(file.name.includes('svg') ? file : new Blob([file]))} alt={file.name} />
                                        <div className="d-flex flex-column gap-1">
                                            <Label>{file.name || `Evidência ${++index}`}</Label>
                                            {file.size && <Label>{file.size / 1024} Kb</Label>}
                                        </div>
                                    </div>
                                </FilesCard>
                            ))}
                        </div>
                    : <InputEvidence
                        files={registerComplaintForm?.evidence?.files}
                        onChangeLink={(e: any) => dispatch(changeComplaintFormEvidenceLink(e.target.value))}
                        linkValue={registerComplaintForm.evidence?.link}
                        onDropFile={(e: any) => {
                            dispatch(changeComplaintFormEvidence([...registerComplaintForm?.evidence?.files || [], e[0]]))
                        }}
                        onRemoveFile={(file: File) => dispatch(changeComplaintFormEvidence(registerComplaintForm?.evidence?.files.filter((el: any) => el != file)))}
                    />}
            </Accordion>

            {
                !canEdit &&
                <div className="d-flex justify-content-center gap-3 pb-3 pt-2">
                    <ButtonSecondary isSubmit={false} onClick={onCancel} type="destructive" label="Cancelar" />
                    <ButtonPrimary isSubmit={true} label="Salvar" />
                </div>
            }
        </form>
    )
}

const FilesCard = styled.div`
    background-color: ${dsVariables.colors.Gray[50]};
    border-radius: ${dsVariables.cornerRadius[8]};
    border: 1px solid ${dsVariables.colors.Gray[200]};
`