import React, { ReactNode, useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import ArrowTop from './top-tooltip-arrow.svg';
import ArrowBottom from './bottom-tooltip-arrow.svg';
import ArrowLeft from './left-tooltip-arrow.svg';
import ArrowRight from './right-tooltip-arrow.svg';
import { dsVariables } from '../../variables';

export type TTooltipPosition =
    | 'top_center'
    | 'top_left'
    | 'top_right'
    | 'middle_left'
    | 'middle_right'
    | 'bottom_center'
    | 'bottom_left'
    | 'bottom_right';

interface IProps {
    children: ReactNode;
    text: string;
    tooltipPosition?: TTooltipPosition;
    visible?: boolean;
}

export default function Tooltip({ children, text, tooltipPosition = 'bottom_right', visible = true }: IProps) {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [isVisible, setIsVisible] = useState(false);
    const childRef = useRef<HTMLDivElement>(null);

    const calculatePosition = () => {
        if (childRef.current) {
            const rect = childRef.current.getBoundingClientRect();
            const positions = {
                top_center: { top: rect.top - 10, left: rect.left + rect.width / 2 },
                top_left: { top: rect.top - 10, left: rect.left },
                top_right: { top: rect.top - 10, left: rect.right },
                bottom_center: { top: rect.bottom + 10, left: rect.left + rect.width / 2 },
                bottom_left: { top: rect.bottom + 10, left: rect.left },
                bottom_right: { top: rect.bottom + 10, left: rect.right },
                middle_left: { top: rect.top + rect.height / 2, left: rect.left - 10 },
                middle_right: { top: rect.top + rect.height / 2, left: rect.right + 10 },
            };
            setPosition(positions[tooltipPosition] || positions.bottom_right);
        }
    };

    const showTooltip = () => {
        calculatePosition();
        setIsVisible(true);
    };

    const hideTooltip = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        window.addEventListener('resize', calculatePosition);
        window.addEventListener('scroll', calculatePosition);

        return () => {
            window.removeEventListener('resize', calculatePosition);
            window.removeEventListener('scroll', calculatePosition);
        };
    }, []);

    if (!visible) return children;

    return (
        <>
            <TooltipContainer ref={childRef} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
                {children}
            </TooltipContainer>
            {isVisible &&
                ReactDOM.createPortal(
                    <TooltipText style={{ top: `${position.top}px`, left: `${position.left}px` }} tooltipPosition={tooltipPosition}>
                        {text}
                        <Arrow src={getArrowSrc(tooltipPosition)} tooltipPosition={tooltipPosition} />
                    </TooltipText>,
                    document.body
                )}
        </>
    );
}

// Função para obter a seta correta
function getArrowSrc(position: TTooltipPosition) {
    if (position.includes('top')) return ArrowBottom;
    if (position.includes('bottom')) return ArrowTop;
    if (position.includes('left')) return ArrowLeft;
    return ArrowRight;
}

// Styled Components
const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
`;

const TooltipText = styled.div<{ tooltipPosition: TTooltipPosition }>`
    position: absolute;
    z-index: 9999999999;
    background-color: black;
    color: white;
    padding: ${dsVariables.spacing[8]};
    border-radius: ${dsVariables.cornerRadius[4]};
    font-size: 12px;
    white-space: nowrap;
    transform: translateX(-50%);
    pointer-events: none;

    ${({ tooltipPosition }) => {
        switch (tooltipPosition) {
            case 'top_center':
                return `transform: translateX(-50%) translateY(-100%);`;
            case 'top_left':
                return `transform: translateY(-100%);`;
            case 'top_right':
                return `transform: translateY(-100%) translateX(-100%);`;
            case 'bottom_center':
                return `transform: translateX(-50%) translateY(0);`;
            case 'bottom_left':
                return `transform: translateY(0);`;
            case 'bottom_right':
                return `transform: translateX(-100%) translateY(0);`;
            case 'middle_left':
                return `transform: translateY(-50%) translateX(-100%);`;
            case 'middle_right':
                return `transform: translateY(-50%) translateX(0);`;
        }
    }}
`;

const Arrow = styled.img<{ tooltipPosition: TTooltipPosition }>`
    position: absolute;
    width: 12px;
    height: 12px;

    ${({ tooltipPosition }) => {
        switch (tooltipPosition) {
            case 'top_center':
                return `bottom: -6px; left: 50%; transform: translateX(-50%);`;
            case 'top_left':
                return `bottom: -6px; left: 8px;`;
            case 'top_right':
                return `bottom: -6px; right: 8px;`;
            case 'bottom_center':
                return `top: -6px; left: 50%; transform: translateX(-50%);`;
            case 'bottom_left':
                return `top: -6px; left: 8px;`;
            case 'bottom_right':
                return `top: -6px; right: 8px;`;
            case 'middle_left':
                return `top: 50%; right: -6px; transform: translateY(-50%);`;
            case 'middle_right':
                return `top: 50%; left: -6px; transform: translateY(-50%);`;
        }
    }}
`;