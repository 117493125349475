import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import {
  Table,
  Row,
  Col,
  CardBody,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { setPageSizeTable } from "../../store/actions";
import { useLocation, useNavigate } from "react-router-dom";

// Define a default UI for filtering
function GlobalFilter({ globalFilter, setGlobalFilter, SearchPlaceholder }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <CardBody>
        <form>
          <Row className="g-3">
            <Col>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control search /"
                  placeholder={SearchPlaceholder}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

const getPaginationGenerator = (
  currentPageNumber,
  totalPageNumber,
  offset = 4
) => {
  const offsetNumber =
    currentPageNumber <= offset || currentPageNumber > totalPageNumber - offset
      ? offset
      : offset - 1;
  const numbersList = [];
  const numbersListWithDots = [];

  if (totalPageNumber <= 1 || totalPageNumber === undefined) return [1];

  // Create list of numbers:
  numbersList.push(1);
  for (
    let i = currentPageNumber - offsetNumber;
    i <= currentPageNumber + offsetNumber;
    i++
  ) {
    if (i < totalPageNumber && i > 1) {
      numbersList.push(i);
    }
  }
  numbersList.push(totalPageNumber);

  // Add three dots to the list of numbers:
  numbersList.reduce((accumulator, currentValue) => {
    if (accumulator === 1) {
      numbersListWithDots.push(accumulator);
    }
    if (currentValue - accumulator !== 1) {
      numbersListWithDots.push("...");
    }
    numbersListWithDots.push(currentValue);

    return currentValue;
  });

  return numbersListWithDots;
};

const selectStyle = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
  }),
};

const ITEM_LENGTH_OPTIONS = [
  { value: 10, label: "10" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

const TableContainerReactTable = ({
  columns,
  data,
  isPagination,
  isGlobalSearch,
  isGlobalFilter,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  onRowClick,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate()


  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <span>&#8593;</span>
      ) : (
        <span>&#8595;</span>
      )
    ) : (
      ""
    );
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const handlePageSizeChange = (value) => {
    setPageSize(Number(value));
    dispatch(setPageSizeTable(value));
  };

  const startResultIndex = pageIndex === 0 ? 1 : pageIndex * pageSize + 1;
  const endResultIndex =
    pageIndex === 0 ? pageSize : (pageIndex + 1) * pageSize;

  const setGotoPage = (index) => {
    gotoPage(index)
    searchParams.set('paginationIndex', `${index}`);
    const newSearch = searchParams.toString();
    if (location.search !== `?${newSearch}`) {
      navigate({ search: newSearch });
    }
  }

  useEffect(() => {
    gotoPage(Number(searchParams.get('paginationIndex') || '0'));
    // eslint-disable-next-line
  }, [navigate, location.search])

  return (
    <Fragment>
      {(isGlobalSearch || isGlobalFilter) && (
        <Row className="mb-3">
          {isGlobalSearch && (
            <Col md={1}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          )}
          {isGlobalFilter && (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              SearchPlaceholder={SearchPlaceholder}
            />
          )}
        </Row>
      )}

      <div className={divClass}>
        <Table hover {...getTableProps()} className="mb-0">
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={thClass}
                    {...column.getSortByToggleProps()}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr
                    onClick={() => onRowClick(row.original)}
                    className="cursor-pointer"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      {isPagination && (
        <Row className="table-footer">
          <Col col="12" md="6">
            <div className="text-muted me-3">
              Exibindo
              <span className="fw-semibold ms-1">
                {data.length > 0 ? startResultIndex : data.length}
              </span>{" "}
              a
              <span className="fw-semibold ms-1">
                {data.length > endResultIndex ? endResultIndex : data.length}
              </span>{" "}
              de <span className="fw-semibold">{data.length}</span> Resultados
            </div>

            <Select
              placeholder={pageSize || "Selecione"}
              options={ITEM_LENGTH_OPTIONS}
              value={pageSize}
              styles={selectStyle}
              onChange={(value) => handlePageSizeChange(value.value)}
            />
          </Col>

          <Col col="12" md="6">
            <ul className="flex-wrap pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink
                  href="#"
                  first
                  onClick={() => setGotoPage(0)}
                ></PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={!canPreviousPage}>
                <PaginationLink
                  href="#"
                  previous
                  onClick={previousPage}
                ></PaginationLink>
              </PaginationItem>

              {getPaginationGenerator(pageIndex, pageCount).map((item, i) => {
                return (
                  <div key={i}>
                    {isNaN(item) ? (
                      <PaginationItem key={i} disabled>
                        <PaginationLink className="cursor-auto">
                          {item}
                        </PaginationLink>
                      </PaginationItem>
                    ) : (
                      <PaginationItem active={item - 1 === pageIndex}>
                        <PaginationLink
                          onClick={(e) => setGotoPage(item - 1)}
                          href="#"
                          className=""
                        >
                          {item}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                  </div>
                );
                // }
              })}

              <PaginationItem disabled={!canNextPage}>
                <PaginationLink
                  href="#"
                  next
                  onClick={nextPage}
                ></PaginationLink>
              </PaginationItem>
              <PaginationItem disabled={!canNextPage}>
                <PaginationLink
                  href="#"
                  last
                  onClick={() => setGotoPage(pageCount - 1)}
                ></PaginationLink>
              </PaginationItem>
            </ul>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

TableContainerReactTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainerReactTable;
