import { TakedownPhaseEnum } from "../../enums/takedownPhase.enum";
import { ThreatDaysEnum } from "../../enums/threat-days.enum";
import { ThreatInitialState } from "./state";
import { ActionTypesEnum } from "./types.enum";

const threatReducer = (
    state = ThreatInitialState,
    action: { type: ActionTypesEnum; payload: any }
) => {
    switch (action.type) {
        case ActionTypesEnum.SUBMIT_FORM:
            return {
                ...state,
                forms: {
                    formLoading: true,
                    formError: false,
                },
            };

        case ActionTypesEnum.SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                forms: {
                    formLoading: false,
                    formError: false,
                    formErrorMessage: null,
                },
            };

        case ActionTypesEnum.SUBMIT_FORM_ERROR:
            return {
                ...state,
                forms: {
                    formLoading: false,
                    formError: true,
                    formErrorMessage: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_TEXT:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    text: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_STATUS_PLATFORM:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    status: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_TYPE:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    type: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_PLATFORM:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    platform: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_IPF:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ipf: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_DATE_RANGE_START:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    dateRange: {
                        ...state.filter.dateRange,
                        startDate: action.payload,
                    },
                    days: ThreatDaysEnum.ALL_DAYS,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_DATE_RANGE_END:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    dateRange: {
                        ...state.filter.dateRange,
                        endDate: action.payload,
                    },
                    days: ThreatDaysEnum.ALL_DAYS,
                },
            };

        case ActionTypesEnum.CHANGE_TAKEDOWN_FILTER_DATE_RANGE_START:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    dateRange: {
                        ...state.takedownsFilter.dateRange,
                        startDate: action.payload,
                    },
                    days: ThreatDaysEnum.ALL_DAYS,
                },
            };

        case ActionTypesEnum.CHANGE_TAKEDOWN_FILTER_DATE_RANGE_END:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    dateRange: {
                        ...state.takedownsFilter.dateRange,
                        endDate: action.payload,
                    },
                    days: ThreatDaysEnum.ALL_DAYS,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_DAYS:
            return {
                ...state,
                filter: {
                    ...state.filter,
                    days: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_TAKEDOWNS_FILTER_DAYS:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    days: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_LIST:
            return {
                ...state,
                threatsList: action.payload,
            };

        case ActionTypesEnum.CHANGE_TAKEDOWN_LIST:
            return {
                ...state,
                takedownsList: action.payload,
            };

        case ActionTypesEnum.CHANGE_TABLE_TAKEDOWNS:
            return {
                ...state,
                takedownsTableData: action.payload,
            };

        case ActionTypesEnum.CHANGE_CURRENT_IDS_TAKEDOWNS:
            return {
                ...state,
                currentIds: action.payload,
            };

        case ActionTypesEnum.CHANGE_CURRENT_TAKEDOWN:
            return {
                ...state,
                currentTakedown: action.payload,
            };

        case ActionTypesEnum.CHANGE_TABLE:
            return {
                ...state,
                threatsTableData: action.payload,
            };

        case ActionTypesEnum.CHANGE_CURRENT_IDS:
            return {
                ...state,
                currentIds: action.payload,
            };

        case ActionTypesEnum.CHANGE_CURRENT:
            return {
                ...state,
                current: action.payload,
            };

        case ActionTypesEnum.CHANGE_SITE_FORM_TYPE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        threatType: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_SITE_FORM_STATUS:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        status: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_SITE_FORM_URL:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        url: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_SITE_FORM_SEARCH_DATE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        searchDate: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_SITE_FORM_EVIDENCE_LINK:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        evidenceLink: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_SITE_FORM_EVIDENCE_FILES:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        evidence: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_SITE_FORM_IS_ACTIVE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        isActive: action.payload,
                    },
                },
            };
        // Profile Form Cases
        case ActionTypesEnum.CHANGE_PROFILE_FORM_EVIDENCE_LINK:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        evidenceLink: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_PROFILE_FORM_EVIDENCE_FILES:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        evidence: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_PROFILE_FORM_TYPE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        threatType: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_PROFILE_FORM_STATUS:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        status: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_PROFILE_FORM_URL:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        url: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_PROFILE_FORM_SEARCH_DATE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        searchDate: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_PROFILE_FORM_PROFILE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        profile: action.payload,
                        searchText: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_PROFILE_FORM_PLATFORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        platform: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_PROFILE_FORM_IS_ACTIVE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        isActive: action.payload,
                    },
                },
            };

        // Content Form Cases
        case ActionTypesEnum.CHANGE_CONTENT_FORM_EVIDENCE_LINK:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        evidenceLink: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_CONTENT_FORM_EVIDENCE_FILES:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        evidence: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_CONTENT_FORM_TYPE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        threatType: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_CONTENT_FORM_STATUS:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        status: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_CONTENT_FORM_URL:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        url: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_CONTENT_FORM_CONTENT:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        profile: action.payload,
                        searchText: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_CONTENT_FORM_PLATFORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        platform: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_CONTENT_FORM_IS_ACTIVE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        isActive: action.payload,
                    },
                },
            };

        // Ads Form Cases
        case ActionTypesEnum.CHANGE_ADS_FORM_TYPE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        threatType: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_ADS_FORM_EVIDENCE_LINK:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        evidenceLink: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_ADS_FORM_EVIDENCE_FILES:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        evidence: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_STATUS:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        status: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_START_DATE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        startDate: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_SEARCH_DATE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        searchDate: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_ID:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        id: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_PLATFORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        platform: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_URL:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        url: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_INSTAGRAM_PROFILE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        instagramProfile: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_ADVERTISER_NAME:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        advertiserName: action.payload,
                        searchText: action.payload + state.forms.adsForm.advertiserId,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_ADVERTISER_ID:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        advertiserId: action.payload,
                    },
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM_IS_ACTIVE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        isActive: action.payload,
                    },
                },
            };

        case ActionTypesEnum.GET_FRAUDS_BY_COMPANY:
            return {
                ...state,
            };

        case ActionTypesEnum.REGISTER_FRAUD:
            return {
                ...state,
            };

        case ActionTypesEnum.ADD_FRAUD:
            return {
                ...state,
                threatsList: [action.payload, ...state.threatsList],
                forms: { ...ThreatInitialState.forms, formError: false },
            };

        case ActionTypesEnum.UPDATE_FRAUD:
            return {
                ...state,
            };

        case ActionTypesEnum.UPDATE_TAKEDOWN:
            return {
                ...state,
            };

        case ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS:
            return {
                ...state,
            };

        case ActionTypesEnum.RESTORE_FRAUD:
            return {
                ...state,
            };

        case ActionTypesEnum.RESTORE_MULTIPLE_FRAUDS:
            return {
                ...state,
            };

        case ActionTypesEnum.UPDATE_FRAUD_FROM_SAGA:
            var newState = action.payload.currentState;
            var oldState = state.threatsList.find(
                (el) => el._id == action.payload._id
            )!.currentState;
            
            return {
                ...state,
                threatsList: state.threatsList.map((el) => {
                    if (el._id == action.payload._id) return {...action.payload, searchText: action.payload?.sourceThreat?.searchText};
                    else return el;
                }),
                fraudsQuantityByState: {
                    ...state.fraudsQuantityByState,
                    [newState]:
                        (state.fraudsQuantityByState as any)[newState] + 1,
                    [oldState]:
                        (state.fraudsQuantityByState as any)[oldState] - 1 < 0
                            ? 0
                            : (state.fraudsQuantityByState as any)[oldState] -
                            1,
                },
            };

        case ActionTypesEnum.UPDATE_TAKEDOWN_FROM_SAGA:
            var newPhase = action.payload.phase;
            var oldPhase = state.takedownsList.find(
                (el) => el._id == action.payload._id
            )!.phase;

            return {
                ...state,
                takedownsList: state.takedownsList.map((el) => {
                    if (el._id == action.payload._id) return action.payload;
                    else return el;
                }),
                takedownsQuantityByPhase: {
                    ...state.takedownsQuantityByPhase,
                    [newPhase]:
                        (state.takedownsQuantityByPhase as any)[newPhase] + 1,
                    [oldPhase]:
                        (state.takedownsQuantityByPhase as any)[oldPhase] - 1 <
                            0
                            ? 0
                            : (state.takedownsQuantityByPhase as any)[
                            oldPhase
                            ] - 1,
                },
            };

        case ActionTypesEnum.UPDATE_MULTIPLE_FRAUDS_FROM_SAGA:
            var newState = action.payload[0].currentState;
            var oldState = state.threatsList.find(
                (el) => el._id == action.payload[0]._id
            )!.currentState;

            return {
                ...state,
                threatsList: state.threatsList.map((threat) => {
                    if (action.payload.find((el: any) => el._id == threat._id))
                        return action.payload.find(
                            (el: any) => el._id == threat._id
                        );
                    else return threat;
                }),
                fraudsQuantityByState: {
                    ...state.fraudsQuantityByState,
                    [newState]:
                        (state.fraudsQuantityByState as any)[newState] +
                        action.payload.length,
                    [oldState]:
                        (state.fraudsQuantityByState as any)[oldState] -
                            action.payload.length <
                            0
                            ? 0
                            : (state.fraudsQuantityByState as any)[oldState] -
                            action.payload.length,
                },
            };

        case ActionTypesEnum.CLEAN_FORMS:
            return {
                ...state,
                forms: ThreatInitialState.forms,
            };
        case ActionTypesEnum.IS_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            };
        case ActionTypesEnum.REGISTER_FRAUD_FAILURE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    formErrorMessage: action.payload.message,
                    formError: true,
                    formLinkOccurrences: {
                        link: action.payload?.linkOccurrences?.link,
                        threatId: action.payload?.linkOccurrences?.threatId,
                        sourceThreat: action.payload?.linkOccurrences?.sourceThreat,
                    }
                },
            };
        case ActionTypesEnum.REMOVE_FORM_LINK_OCCURRENCE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    formLinkOccurrences: false
                },
            };
        case ActionTypesEnum.CHANGE_FRAUDS_QUANTITY_BY_STATE:
            return {
                ...state,
                fraudsQuantityByState: action.payload,
            };

        case ActionTypesEnum.CHANGE_TAKEDOWNS_QUANTITY_BY_PHASE:
            return {
                ...state,
                takedownsQuantityByPhase: action.payload,
            };

        case ActionTypesEnum.CHANGE_TANKEDOWNS_TOTAL_PAGES_BY_PHASE:
            return {
                ...state,
                takedownsTotalPagesByPhase: action.payload,
            };

        case ActionTypesEnum.CHANGE_FRAUDS_FILTERED_QUANTITY_BY_STATE:
            return {
                ...state,
                fraudsFilteredQuantityByState: action.payload,
            };

        case ActionTypesEnum.CHANGE_TAKEDOWNS_FILTERED_QUANTITY_BY_STATE:
            return {
                ...state,
                takedownsFilteredQuantityByPhase: action.payload,
            };

        case ActionTypesEnum.CHANGE_TAKEDOWNS_FILTER:
            return {
                ...state,
                takedownsFilter: action.payload,
            };

        case ActionTypesEnum.CHANGE_FRAUDS_TOTAL_PAGES_BY_STATE:
            return {
                ...state,
                fraudsTotalPagesByState: action.payload,
            };

        case ActionTypesEnum.CHANGE_FORM_ERROR:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    formError: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_SITE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_ADS_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: action.payload,
                },
            };
        case ActionTypesEnum.CHANGE_PROFILE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_CONTENT_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: action.payload,
                },
            };

        case ActionTypesEnum.GET_TAKEDOWNS:
            return {
                ...state,
            };

        case ActionTypesEnum.CHANGE_FILTER_TEXT_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    text: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_COMPANY_ID_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    companyId: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_TYPE_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    type: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_PLATFORM_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    platform: action.payload,
                },
            };

        case ActionTypesEnum.CHANGE_FILTER_STATUS_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    status: action.payload,
                },
            };
        case ActionTypesEnum.CHANGE_FILTER_SLA_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    SLA: action.payload,
                },
            };
        case ActionTypesEnum.CHANGE_FILTER_IDENTIFIED_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    timeIdentified: action.payload,
                },
            };
        case ActionTypesEnum.CHANGE_FILTER_TIME_PHASE_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    timeOnPhase: action.payload,
                },
            };
        case ActionTypesEnum.CHANGE_FILTER_IDENTIFIED_HOURS_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    timeIdentifiedHours: action.payload,
                },
            };
        case ActionTypesEnum.CHANGE_FILTER_TIME_PHASE_HOURS_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    timeOnPhaseHours: action.payload,
                },
            };
        case ActionTypesEnum.CHANGE_FILTER_NOTIFICATIONS_TAKEDOWN:
            return {
                ...state,
                takedownsFilter: {
                    ...state.takedownsFilter,
                    notifications: action.payload,
                },
            };
        default:
            return state;

        case ActionTypesEnum.CHANGE_COMPLAINT_FORM_EVIDENCE_LINK:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerComplaintForm: {
                        ...state.forms.registerComplaintForm,
                        evidence: {
                            ...state.forms.registerComplaintForm.evidence,
                            link: action.payload
                        }
                    }
                },
            };
        case ActionTypesEnum.CHANGE_COMPLAINT_FORM_EVIDENCE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerComplaintForm: {
                        ...state.forms.registerComplaintForm,
                        evidence: {
                            ...state.forms.registerComplaintForm.evidence,
                            files: action.payload
                        }
                    }
                },
            };
        case ActionTypesEnum.CHANGE_COMPLAINT_FORM_CHANNEL:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerComplaintForm: {
                        ...state.forms.registerComplaintForm,
                        complaintChannel: action.payload,
                    },
                },
            };



        case ActionTypesEnum.CHANGE_COMPLAINT_FORM_DATE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerComplaintForm: {
                        ...state.forms.registerComplaintForm,
                        reportDate: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_COMPLAINT_FORM_REPORTED_TO:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerComplaintForm: {
                        ...state.forms.registerComplaintForm,
                        reportedTo: action.payload,
                    },
                },
            };
        case ActionTypesEnum.CHANGE_COMPLAINT_FORM_REPORT_ID:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerComplaintForm: {
                        ...state.forms.registerComplaintForm,
                        reportId: action.payload,
                    },
                },
            };
        case ActionTypesEnum.REGISTER_COMPLAINT:
            return {
                ...state,
            };
        case ActionTypesEnum.UPDATE_COMPLAINT:
            return {
                ...state,
            };
        case ActionTypesEnum.FETCH_COMPLAINTS:
            return {
                ...state,
            };
        case ActionTypesEnum.ADD_COMPLAINT:
            var takedown = state.takedownsList.find(el => el._id == action.payload.treatmentId)

            var newPhase: any = TakedownPhaseEnum.COMPLAINT_MADE
            var oldPhase = state.takedownsList.find(
                (el) => el._id == takedown!._id
            )!.phase;

            return {
                ...state,
                currentTakedown: {
                    ...state.currentTakedown,
                    complaints:
                        state.currentTakedown
                            ? [action.payload, ...(state.currentTakedown.complaints || [])]
                            : [action.payload]
                },
                takedownsList: state.takedownsList.map((el) => {
                    if (el._id == takedown!._id) return { ...takedown, phase: TakedownPhaseEnum.COMPLAINT_MADE };
                    else return el;
                }),
                takedownsQuantityByPhase: {
                    ...state.takedownsQuantityByPhase,
                    [newPhase]:
                        (state.takedownsQuantityByPhase as any)[newPhase] + 1,
                    [oldPhase]:
                        (state.takedownsQuantityByPhase as any)[oldPhase] - 1 <
                            0
                            ? 0
                            : (state.takedownsQuantityByPhase as any)[
                            oldPhase
                            ] - 1,
                },
            };
        case ActionTypesEnum.CHANGE_CURRENT_COMPLAINTS:
            return {
                ...state,
                currentTakedown: {
                    ...state.currentTakedown,
                    complaints: action.payload
                }
            };

        case ActionTypesEnum.ADD_PIX:
            return {
                ...state,
                currentTakedown: {
                    ...state.currentTakedown,
                    pix:
                        state.currentTakedown
                            ? [action.payload, ...(state.currentTakedown.pix || [])]
                            : [action.payload]
                }
            }
        case ActionTypesEnum.FETCH_PIX:
            return {
                ...state,
            }
        case ActionTypesEnum.CHANGE_CURRENT_PIX:
            return {
                ...state,
                currentTakedown: {
                    ...state.currentTakedown,
                    pix: action.payload
                }
            }
        case ActionTypesEnum.UPDATE_PIX:
            return {
                ...state,
            }
        case ActionTypesEnum.CHANGE_PIX_FORM_EVIDENCE_LINK:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerPixForm: {
                        ...state.forms.registerPixForm,
                        evidence: {
                            ...state.forms.registerPixForm.evidence,
                            link: action.payload
                        }
                    }
                }
            }
        case ActionTypesEnum.CHANGE_PIX_FORM_EVIDENCE:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerPixForm: {
                        ...state.forms.registerPixForm,
                        evidence: {
                            ...state.forms.registerPixForm.evidence,
                            files: action.payload
                        }
                    }
                }
            }
        case ActionTypesEnum.CHANGE_PIX_FORM_CHECKOUT_URL:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerPixForm: {
                        ...state.forms.registerPixForm,
                        checkoutUrl: action.payload
                    }
                }
            }
        case ActionTypesEnum.CHANGE_PIX_FORM_CPF:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerPixForm: {
                        ...state.forms.registerPixForm,
                        cpf: action.payload
                    }
                }
            }
        case ActionTypesEnum.CHANGE_PIX_FORM_FINANCIAL_INSTITUTION:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerPixForm: {
                        ...state.forms.registerPixForm,
                        financialInstitution: action.payload
                    }
                }
            }
        case ActionTypesEnum.CHANGE_PIX_FORM_PAYMENT_GATEWAY:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerPixForm: {
                        ...state.forms.registerPixForm,
                        paymentGateway: action.payload
                    }
                }
            }
        case ActionTypesEnum.CHANGE_PIX_FORM_PIX:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    registerPixForm: {
                        ...state.forms.registerPixForm,
                        pix: action.payload
                    }
                }
            }
        case ActionTypesEnum.REGISTER_PIX:
            return {
                ...state,
            }
        case ActionTypesEnum.UPDATE_ASSUME_TAKEDOWN_FROM_SAGA:
            return {
                ...state,
            }
        case ActionTypesEnum.UPDATE_ASSUME_TAKEDOWN:
            return {
                ...state,
            }

        case ActionTypesEnum.CHANGE_REASONING_FINISH_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    takedownFinishForm: {
                        ...state.forms.takedownFinishForm,
                        reasoning: action.payload
                    }
                }
            }

        case ActionTypesEnum.FINISH_TAKEDOWNS:
            return {
                ...state,
            }

        case ActionTypesEnum.SPECIAL_TREATMENT:
            return {
                ...state,
            }

        case ActionTypesEnum.REOPEN_THREATS:
            return {
                ...state,
            }

        case ActionTypesEnum.CHANGE_TAKEDOWNS_FROM_SAGA:
            var newPhase = action.payload[0].phase;
            var oldPhase = state.takedownsList.find(
                (el) => el._id == action.payload[0]._id
            )!.phase;

            return {
                ...state,
                takedownsList: state.takedownsList.map((takedown) => {
                    if (action.payload.find((el: any) => el._id == takedown._id))
                        return action.payload.find(
                            (el: any) => el._id == takedown._id
                        );
                    else return takedown;
                }),
                takedownsQuantityByState: {
                    ...state.takedownsQuantityByPhase,
                    [newPhase]:
                        (state.takedownsQuantityByPhase as any)[newPhase] +
                        action.payload.length,
                    [oldPhase]:
                        (state.takedownsQuantityByPhase as any)[oldPhase] -
                            action.payload.length <
                            0
                            ? 0
                            : (state.takedownsQuantityByPhase as any)[oldPhase] -
                            action.payload.length,
                },
            };

        case ActionTypesEnum.ADD_TAKEDOWN:
            return {
                ...state,
                takedowsList: [action.payload, ...state.takedownsList],
                forms: { ...ThreatInitialState.forms, formError: false },
            };

        case ActionTypesEnum.FETCH_THREAT_DETAILS:
            return {
                ...state
            }

        case ActionTypesEnum.CHANGE_CURRENT_THREAT_OCCURRENCES:
            return {
                ...state,
                current: {
                    ...state.current,
                    occurrence: action.payload.occurrence,
                    pixOccurrences: action.payload.pixOccurrences,
                    sourceThreat: {
                        ...state.current?.sourceThreat,
                        registrarDatabase: action.payload.sourceThreat?.registrarDatabase,
                        domainDatabase: action.payload.sourceThreat?.domainDatabase,
                    }
                }
            }

        case ActionTypesEnum.ADD_OCCURRENCE_ADS_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        occurrences: [
                            ...(state.forms.adsForm.occurrences || []),
                            { url: '', evidence: { files: [], link: '' }, adId: '' },
                        ],
                    },
                },
            };

        case ActionTypesEnum.REMOVE_OCCURRENCE_ADS_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        occurrences: (state.forms.adsForm.occurrences || []).filter(
                            (_, idx) => idx !== action.payload
                        ),
                    },
                },
            };

        case ActionTypesEnum.CHANGE_OCCURRENCE_ADS_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    adsForm: {
                        ...state.forms.adsForm,
                        occurrences: state.forms.adsForm.occurrences!.map((occurrence, index) => {
                            if (index === action.payload.index) {
                                return action.payload.updatedOccurrence
                            }
                            return occurrence
                        })
                    },
                },
            };

        case ActionTypesEnum.ADD_OCCURRENCE_CONTENT_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        occurrences: [
                            ...(state.forms.contentForm.occurrences || []),
                            { url: '', evidence: { files: [], link: '' }, adId: '' },
                        ],
                    },
                },
            };

        case ActionTypesEnum.REMOVE_OCCURRENCE_CONTENT_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        occurrences: (state.forms.contentForm.occurrences || []).filter(
                            (_, idx) => idx !== action.payload
                        ),
                    },
                },
            };

        case ActionTypesEnum.CHANGE_OCCURRENCE_CONTENT_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    contentForm: {
                        ...state.forms.contentForm,
                        occurrences: state.forms.contentForm.occurrences!.map((occurrence, index) => {
                            if (index === action.payload.index) {
                                return action.payload.updatedOccurrence
                            }
                            return occurrence
                        })
                    },
                },
            };

        case ActionTypesEnum.ADD_OCCURRENCE_PROFILE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        occurrences: [
                            ...(state.forms.profileForm.occurrences || []),
                            { url: '', evidence: { files: [], link: '' }, adId: '' },
                        ],
                    },
                },
            };

        case ActionTypesEnum.REMOVE_OCCURRENCE_PROFILE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        occurrences: (state.forms.profileForm.occurrences || []).filter(
                            (_, idx) => idx !== action.payload
                        ),
                    },
                },
            };

        case ActionTypesEnum.CHANGE_OCCURRENCE_PROFILE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    profileForm: {
                        ...state.forms.profileForm,
                        occurrences: state.forms.profileForm.occurrences!.map((occurrence, index) => {
                            if (index === action.payload.index) {
                                return action.payload.updatedOccurrence
                            }
                            return occurrence
                        })
                    },
                },
            };

        case ActionTypesEnum.ADD_OCCURRENCE_SITE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        occurrences: [
                            ...(state.forms.siteForm.occurrences || []),
                            { url: '', evidence: { files: [], link: '' }, adId: '' },
                        ],
                    },
                },
            };

        case ActionTypesEnum.REMOVE_OCCURRENCE_SITE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        occurrences: (state.forms.siteForm.occurrences || []).filter(
                            (_, idx) => idx !== action.payload
                        ),
                    },
                },
            };

        case ActionTypesEnum.CHANGE_OCCURRENCE_SITE_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        occurrences: state.forms.siteForm.occurrences!.map((occurrence, index) => {
                            if (index === action.payload.index) {
                                return action.payload.updatedOccurrence
                            }
                            return occurrence
                        })
                    },
                },
            };
        case ActionTypesEnum.FETCH_REASONING_LABELS:
            return {
                ...state,
            }

        case ActionTypesEnum.CHANGE_REASONING_LABELS_SAGA:
            return {
                ...state,
                labels: action.payload
            }

        case ActionTypesEnum.CHANGE_SITE_FORM_DOMAIN:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    siteForm: {
                        ...state.forms.siteForm,
                        searchText: action.payload
                    }
                }
            }

        case ActionTypesEnum.LINK_THREAT_OCCURRENCES:
            return {
                ...state,
            }

        case ActionTypesEnum.LINK_THREAT_DATABASES:
            return {
                ...state,
            }

        case ActionTypesEnum.LINK_OCCURRENCE_HOST_DATABASE:
            return {
                ...state,
            }

        case ActionTypesEnum.CHANGE_LINKED_DATABASE:
            if (action.payload.type == 'host') return { ...state }
            return {
                ...state,
                currentTakedown: {
                    ...state.currentTakedown,
                    [action.payload.type + 'Database']: action.payload
                },
            }

        case ActionTypesEnum.CHANGE_CURRENT_TAKEDOWN_OCCURRENCE_HOST:
            var occurrencesUpdated = state.currentTakedown?.occurrences.map((el: any) => {
                if (el._id == action.payload._id) return action.payload

                return el
            })


            return {
                ...state,
                currentTakedown: {
                    ...state.currentTakedown,
                    occurrences: occurrencesUpdated,
                }
            }

        case ActionTypesEnum.GET_TAKEDOWN_BY_ID:
            return {
                ...state,
            }

        case ActionTypesEnum.CHANGE_CURRENT_TAKEDOWN_FROM_SAGA:
            console.log(action.payload)
            return {
                ...state,
                currentTakedown: action.payload
            }
    }
};

export default threatReducer;
