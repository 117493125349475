export enum ActionTypesEnum {
    FETCH_COMPANIES = "FETCH_COMPANIES",
    CHANGE_COMPANIES_LIST = "CHANGE_COMPANIES_LIST",
    FETCH_COMPANIES_GROUP = "FETCH_COMPANIES_GROUP",
    CHANGE_COMPANIES_GROUP_LIST = "CHANGE_COMPANIES_GROUP_LIST",
    INVITE_USER = "INVITE_USER",
    CHANGE_USER_PERMISSIONS = "CHANGE_USER_PERMISSIONS",
    FETCH_USER_TO_CHANGE = "FETCH_USER_TO_CHANGE",
    CHANGE_USER = "CHANGE_USER",
    FETCH_USERS = "FETCH_USERS",
    CHANGE_USERS = "CHANGE_USERS",
    CHANGE_USERS_AND_INVITEDS = "CHANGE_USERS_AND_INVITEDS",
    FETCH_USERS_AND_INVITEDS = "FETCH_USERS_AND_INVITEDS",
    CHANGE_SUCCESS_MESSAGE = "CHANGE_SUCCESS_MESSAGE",
    CHANGE_ERROR_MESSAGE = "CHANGE_ERROR_MESSAGE",
    CANCEL_USER_INVITE = "CANCEL_USER_INVITE",
    RESEND_USER_INVITE = "RESEND_USER_INVITE",
    CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD",
    CHANGE_USER_STATUS = "CHANGE_USER_STATUS",
    CHANGE_FILTER = "CHANGE_FILTER",
    CHANGE_FORM_DATABASE_REGISTRAR = "CHANGE_FORM_DATABASE_REGISTRAR",
    CHANGE_FORM_DATABASE_DOMAIN = "CHANGE_FORM_DATABASE_DOMAIN",
    CHANGE_FORM_DATABASE_HOST = "CHANGE_FORM_DATABASE_HOST",
    REGISTER_REGISTRAR = "REGISTER_REGISTRAR",
    UPDATE_DATABASE = "UPDATE_DATABASE",
    REGISTER_DOMAIN = "REGISTER_DOMAIN",
    UPDATE_DOMAIN = "UPDATE_DOMAIN",
    REGISTER_HOST = "REGISTER_HOST",
    FETCH_DATABASE_BY_TYPE = "FETCH_DATABASE_BY_TYPE",
    CHANGE_DATABASE_BY_TYPE = "CHANGE_DATABASE_BY_TYPE",
}