export interface IAdminInitialState {
    companiesList: any[]
    companiesGroupList: any[]
    user: any
    users: any[]
    usersAndInviteds: {
        data: any[],
        totalPages: number
        totalResults: number
        filter: {
            userStatus: string
            registered: string
            text: string
        }
    }
    successMessage: string
    errorMessage: string
    databases: {
        forms: {
            registrar: any
            domain: any
            host: any
        },
        registrar: any[]
        domain: any[]
        host: any[]
    }
}


export const AdminInitialState: IAdminInitialState = {
    companiesList: [],
    companiesGroupList: [],
    user: null,
    users: [],
    usersAndInviteds: {
        data: [],
        totalPages: 1,
        totalResults: 1,
        filter: {
            userStatus: 'Todos',
            registered: 'Todos',
            text: '',
        }
    },
    successMessage: '',
    errorMessage: '',
    databases: {
        forms: {
            domain: null,
            registrar: null,
            host: null
        },
        domain: [],
        registrar: [],
        host: []
    }
}