import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { changeReasoningFinishForm, fetchReasoningLabels } from "../../../../store/threats/actions";
import Body from "../../../components/Text/Body";
import { dsVariables } from "../../../variables";
import { useEffect } from "react";
import { IStore } from "../../../../interfaces/store.interface";

interface IProps {
    onClose: any
    onConfirm: any
    isOpen: boolean
}

export default function ModalFinish({ onClose, onConfirm, isOpen }: IProps) {

    const dispatch = useDispatch()

    const { labels } = useSelector((store: IStore) => store.Threats)

    useEffect(() => {
        dispatch(fetchReasoningLabels("reasoning"))
    }, []);

    return (
        <Modal
            title="Motivo da finalização" subtitle="Selecione abaixo qual o motivo para finalizar." isOpen={isOpen} onClose={onClose} width={480}
            footerButtons={[
                { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                { buttonStyle: 'primary', onClick: onConfirm, label: 'Finalizar' },
            ]}
        >
            <div className="d-flex flex-column gap-2 mb-4">
                {labels?.map((label, index) =>
                    <div key={index} className="d-flex gap-2 align-items-center">
                        <input role="button" onChange={() => dispatch(changeReasoningFinishForm({text: label.label, id:label._id}))} type="radio" name="reasoning" id={`reasoning${index + 1}`} />
                        <label role="button" className="mb-0" htmlFor={`reasoning${index + 1}`}><Body color={dsVariables.colors.Gray[800]}>{label.label}</Body></label>
                    </div>
                )}
            </div>
        </Modal>
    )
}