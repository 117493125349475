import Input from "../../../../DesignSystem/components/Input/Input";
import Label from "../../../../DesignSystem/components/Text/Label";
import Body from "../../../../DesignSystem/components/Text/Body";
import { ThreatPlatformEnum } from "../../../../enums/threat-platform.enum";
import ButtonPrimary from "../../../../DesignSystem/components/Button/ButtonPrimary";
import { AddOutlined, CalendarMonth, DeleteOutlined } from "@mui/icons-material";
import Select from "../../../../DesignSystem/components/Select";
import { ThreatStatusActiveEnum } from "../../../../enums/threat-status-active.enum";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../interfaces/store.interface";
import { addOccurrenceAdsForm, changeAdsForm, changeAdsFormAdvertiserId, changeAdsFormAdvertiserName, changeAdsFormEvidenceFiles, changeAdsFormEvidenceLink, changeAdsFormId, changeAdsFormInstagramProfile, changeAdsFormIsActive, changeAdsFormOccurence, changeAdsFormPlatform, changeAdsFormSearchDate, changeAdsFormStartDate, changeAdsFormUrl, fetchReasoningLabels, removeOccurrenceAdsForm } from "../../../../store/threats/actions";
import InputEvidence from "../../../../DesignSystem/components/Input/InputEvidence";
import InputDate from "../../../../DesignSystem/components/Input/InputDate";
import { ThreatTypeTextEnum } from "../../../../enums/threat-type-text.enum";
import { dsVariables } from "../../../../DesignSystem/variables";
import Accordion from "../../../components/Accordion";
import { useEffect } from "react";

interface IProps {
    setTypeForm?: any
    isInvalidUrl: boolean
}

export default function AdsForm({ setTypeForm, isInvalidUrl }: IProps) {

    const dispatch = useDispatch()

    const { adsForm } = useSelector((store: IStore) => store.Threats.forms)

    const occurrences = useSelector((state: IStore) => state.Threats.forms.adsForm?.occurrences || []);
    const { labels } = useSelector((store: IStore) => store.Threats)

    useEffect(() => {
        dispatch(fetchReasoningLabels("occurrence"))
    }, []);

    function getAccordionBorderType(index: number): 'top' | 'bottom' | 'top-bottom' | any {
        if (occurrences.length == 1 || occurrences.length == 0) return

        if (occurrences.length == 2) return index == 0 ? 'bottom' : 'top'
        else {
            if (index == 0) return 'bottom'
            else if (index == occurrences.length - 1) return 'top'
            else return 'top-bottom'
        }
    }

    return (
        <form className="mt-2" >
            <div className="mb-3 d-flex gap-3">
                <div className="d-flex flex-column gap-2" >
                    <Label color={dsVariables.colors.Gray[800]}>Tipo de ameaça*</Label>
                    <Select state={setTypeForm ? 'default' : 'disabled'} width={221} selected={ThreatTypeTextEnum.FAKE_AD} required onChange={(e: any) => setTypeForm(e.target.value)} options={Object.values(ThreatTypeTextEnum).filter(el => el != ThreatTypeTextEnum.ALL)} />
                </div>
                <div className="d-flex flex-column gap-2 " >
                    <Label color={dsVariables.colors.Gray[800]}>Plataforma*</Label>
                    <Select state={setTypeForm ? 'default' : 'disabled'} width={221} required onChange={(e: any) => dispatch(changeAdsFormPlatform(e.target.value))} options={Object.values(ThreatPlatformEnum).filter(el => el == ThreatPlatformEnum.METAADS)} />
                </div>
                <div className="d-flex flex-column gap-2 " >
                    <Label color={dsVariables.colors.Gray[800]}>Status*</Label>
                    <Select selected={adsForm.isActive ? ThreatStatusActiveEnum.ACTIVE : ThreatStatusActiveEnum.INACTIVE} width={221} required onChange={(e: any) => dispatch(changeAdsFormIsActive(e.target.value == ThreatStatusActiveEnum.ACTIVE))} options={Object.values(ThreatStatusActiveEnum)} />
                </div>
            </div>

            <Body style="medium">Informações do anunciante</Body>
            <div className="mb-3 d-flex gap-3 mt-3">
                <div className="d-flex flex-column gap-2 " >
                    <Label color={dsVariables.colors.Gray[800]}>Anunciante*</Label>
                    <Input state={setTypeForm ? 'default' : 'disabled'} width={221} required placeholder="Insira o nome" value={adsForm.advertiserName} onChange={(e: any) => dispatch(changeAdsFormAdvertiserName(e.target.value))} />
                </div>
                <div className="d-flex flex-column gap-2 " >
                    <Label color={dsVariables.colors.Gray[800]}>ID perfil anunciante*</Label>
                    <Input state={setTypeForm ? 'default' : 'disabled'} width={221} required placeholder="Ex: 423342680239491" value={adsForm.advertiserId} onChange={(e: any) => dispatch(changeAdsFormAdvertiserId(e.target.value))} />
                </div>
                <div className="d-flex flex-column gap-2 " >
                    <Label color={dsVariables.colors.Gray[800]}>Perfil instagram</Label>
                    <Input state={setTypeForm ? 'default' : 'disabled'} width={221} required placeholder="Ex: @wadewilson" value={adsForm.instagramProfile} onChange={(e: any) => dispatch(changeAdsFormInstagramProfile(e.target.value))} />
                </div>
            </div>

            <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column gap-1">
                    <Body style="medium">Ocorrências</Body>
                    <Label color={dsVariables.colors.Gray[600]}>* As urls de cada ocorrência devem pertencer ao mesmo domínio.</Label>
                </div>
                <div className="">
                    <ButtonPrimary onClick={(e: any) => dispatch(addOccurrenceAdsForm())} iconLeft={AddOutlined} iconLeftColor="white" label="Adicionar ocorrência" />
                </div>
            </div>

            <div className="d-flex flex-column mt-3">
                {
                    occurrences.map((occurrence: any, index: number) =>
                        <Accordion
                            defaultOpened={occurrences.length == 1 && true}
                            borderType={getAccordionBorderType(index)}
                            withBorder
                            headerIcon={occurrences.length > 1 ? DeleteOutlined : null} headerIconColor={dsVariables.colors.Red[500]}
                            headerIconOnClick={() => dispatch(removeOccurrenceAdsForm(index))} key={index}
                            title={`${index + 1}. Ocorrência`}>
                            <div key={index} className="d-flex gap-2 align-items-center">
                                <div className="d-flex flex-column gap-4">
                                    <div className="d-flex gap-4">
                                        <div className="d-flex flex-column gap-2 ">
                                            <Label color={dsVariables.colors.Gray[800]}>URL da ocorrência*</Label>
                                            <Input state={setTypeForm ? 'default' : 'disabled'} error={isInvalidUrl} width={656} required placeholder="Insira a URL" value={occurrence?.url}
                                                onChange={(e: any) => {
                                                    const url = (e.target.value)

                                                    let adId = ''

                                                    if (url.includes('?id=')) adId = url.split('?id=').slice(-1)

                                                    const updatedOccurrence = { ...occurrence, url, adId }
                                                    dispatch(changeAdsFormOccurence({ updatedOccurrence, index }))
                                                }} />
                                        </div>
                                    </div>

                                    <div className="mb-3 d-flex gap-3 ">
                                        <div className="d-flex flex-column gap-2 " >
                                            <Label color={dsVariables.colors.Gray[800]}>ID do anúncio*</Label>
                                            <Input state={'disabled'} width={187}
                                                required placeholder="Insira o ID"
                                                value={occurrence.adId}
                                                onChange={(e: any) => {
                                                    const adId = (e.target.value)
                                                    const updatedOccurrence = { ...occurrence, adId }
                                                    dispatch(changeAdsFormOccurence({ updatedOccurrence, index }))
                                                }} />
                                        </div>
                                        <div className="d-flex flex-column gap-2 " >
                                            <Label color={dsVariables.colors.Gray[800]}>Quantidade de anúncios*</Label>
                                            <Input type="number" width={187} required placeholder="Insira a quantidade" value={occurrence?.adsQuantity || ''}
                                                onChange={(e: any) => {
                                                    const adsQuantity = (e.target.value)
                                                    const updatedOccurrence = { ...occurrence, adsQuantity: adsQuantity < 0 ? 0 : adsQuantity.replace(/[^0-9]/g, '') }
                                                    dispatch(changeAdsFormOccurence({ updatedOccurrence, index }))
                                                }}
                                            />
                                        </div>

                                        <div className="d-flex flex-column gap-2" >
                                            <Label color={dsVariables.colors.Gray[800]}>Veiculação iniciada em*</Label>
                                            <div style={{ pointerEvents: setTypeForm ? 'auto' : 'none' }} className="d-flex align-items-stretch  position-relative">
                                                <InputDate state={setTypeForm ? 'default' : 'disabled'} width={221} value={adsForm?.searchDate} onChange={date => dispatch(changeAdsFormSearchDate(date[0]))} />
                                                <div className="position-absolute pe-none end-0 top-0">
                                                    <ButtonPrimary onClick={() => null} iconLeft={CalendarMonth} iconLeftColor="white" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="d-flex gap-5 ">
                                        {
                                            labels?.map((el, labelIndex) =>
                                                <div key={labelIndex} className="d-flex gap-1">
                                                    <Label>{el.label}</Label>
                                                    <input disabled={!setTypeForm} onChange={(e: any) => {
                                                        const alreadyChecked = occurrence?.labels?.find((el: any) => el == e.target.value)

                                                        if (!alreadyChecked) {
                                                            const updatedOccurrence = { ...occurrence, labels: [...occurrence.labels, e.target.value] }
                                                            dispatch(changeAdsFormOccurence({ updatedOccurrence, index }))
                                                        } else {
                                                            const updatedLabels = occurrence?.labels?.filter((el: any) => el != e.target.value)
                                                            const updatedOccurrence = { ...occurrence, labels: updatedLabels }
                                                            dispatch(changeAdsFormOccurence({ updatedOccurrence, index }))
                                                        }
                                                    }}
                                                        value={el._id} type="checkbox" name="" id="" />
                                                </div>
                                            )
                                        }
                                    </div>
                                    <InputEvidence
                                        files={occurrence?.evidence.files}
                                        onChangeLink={(e: any) => {
                                            const link = (e.target.value)
                                            const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, link } }
                                            dispatch(changeAdsFormOccurence({ updatedOccurrence, index }))
                                        }}
                                        linkValue={occurrence?.evidence.link}
                                        onDropFile={(e: any) => {
                                            const newEvidence = e[0]
                                            const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, files: [...occurrence.evidence.files, newEvidence] } }
                                            dispatch(changeAdsFormOccurence({ updatedOccurrence, index }))
                                        }}
                                        onRemoveFile={(file: File) => {
                                            const updatedOccurrenceFiles = occurrence?.evidence?.files.filter((el: any) => el != file)
                                            const updatedOccurrence = { ...occurrence, evidence: { ...occurrence.evidence, files: updatedOccurrenceFiles } }
                                            dispatch(changeAdsFormOccurence({ updatedOccurrence, index }))
                                        }}
                                    />
                                </div>
                            </div>
                        </Accordion>)
                }
            </div>
        </form>
    )
}