import { Table } from "react-bootstrap";
import ButtonPrimary from "../../../../components/Button/ButtonPrimary";
import { AddOutlined, EditOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Modal from "../../../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../../../../interfaces/store.interface";
import { changeFormDatabaseHost, fetchDatabaseByType, registerHost, updateDatabase } from "../../../../../store/admin/action";
import Label from "../../../../components/Text/Label";
import Input from "../../../../components/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../../../components/Icon";

export default function HostTable() {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { forms, host } = useSelector((store: IStore) => store.Admin.databases)

    const [showModalRegister, setShowModalRegister] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [hostToEdit, setHostToEdit] = useState({
        organization: '',
        netRange: '',
        netName: '',
        abuseEmail: '',
        abuseForm: '',
    });

    useEffect(() => {
        dispatch(fetchDatabaseByType('host'))
    }, []);

    function onClose() {
        setShowModalRegister(false)
        searchParams.delete('modalOpened')
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    async function onConfirm() {
        dispatch(registerHost({ ...forms.host, type: 'host' }))
    }

    function handleInsertButtonClick() {
        setShowModalRegister(true)
        searchParams.set('modalOpened', 'true')
        const newSearch = searchParams.toString();

        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }

    function handleShowModalEdit(hostId: string) {
        setHostToEdit(host.find(el => el._id == hostId))
        setShowModalEdit(true)
    }

    function editHost() {
        dispatch(updateDatabase({ ...hostToEdit, type: 'host' }))
    }

    return (
        <section className="d-flex flex-column mt-3">
            {/* <Filter /> */}

            <div className="bg-white d-flex flex-column gap-3 rounded-2">
                <Table className="table">
                    <thead>
                        <tr className="fw-bold">
                            <td>Organization</td>
                            <td>Net Range</td>
                            <td>Net Name</td>
                            <td>Abuse Contact Email</td>
                            <td>Abuse Form</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {host?.map((el, index) =>
                            <tr key={index}>
                                <td style={{ maxWidth: 100 }}>{el.organization}</td>
                                <td style={{ maxWidth: 100 }}>{el.netRange}</td>
                                <td style={{ maxWidth: 100 }}>{el.netName}</td>
                                <td style={{ maxWidth: 100 }}>{el.abuseEmail}</td>
                                <td style={{ maxWidth: 100 }}>{el.abuseForm}</td>
                                <td role="button"><Icon onClick={() => handleShowModalEdit(el._id)} IconComponent={EditOutlined} /></td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {/* <Paginator /> */}
            </div>

            {/* <Modal
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: onClose, label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: onConfirm, label: 'Inserir' },
                ]} title="Novo Registrar" isOpen={showModalRegister} width={'50%'} onClose={onClose}>
                <form className="d-flex flex-column gap-4">
                    <div className="d-flex gap-4">
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Organization</Label>
                            <Input value={forms.host?.organization} onChange={(e: any) => {
                                dispatch(changeFormDatabaseHost({ ...forms.host, organization: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Net Range</Label>
                            <Input value={forms.host?.netRange} onChange={(e: any) => {
                                dispatch(changeFormDatabaseHost({ ...forms.host, netRange: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Net Name</Label>
                            <Input value={forms.host?.netName} onChange={(e: any) => {
                                dispatch(changeFormDatabaseHost({ ...forms.host, netName: e.target.value }))
                            }} />
                        </div>
                    </div>

                    <div className="d-flex flex-column gap-2">
                        <Label>Abuse Contact Email</Label>
                        <Input value={forms.host?.abuseEmail} onChange={(e: any) => {
                            dispatch(changeFormDatabaseHost({ ...forms.host, abuseEmail: e.target.value }))
                        }} />
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <Label>Abuse Form</Label>
                        <Input value={forms.host?.abuseForm} onChange={(e: any) => {
                            dispatch(changeFormDatabaseHost({ ...forms.host, abuseForm: e.target.value }))
                        }} />
                    </div>

                </form>
            </Modal> */}
            <Modal
                footerButtons={[
                    { buttonStyle: 'secondary', onClick: () => setShowModalEdit(false), label: 'Cancelar', type: 'destructive' },
                    { buttonStyle: 'primary', onClick: editHost, label: 'Atualizar' },
                ]} title="Novo Registrar" isOpen={showModalEdit} width={'50%'} onClose={() => setShowModalEdit(false)}>
                <form className="d-flex flex-column gap-4">
                    <div className="d-flex gap-4">
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Organization</Label>
                            <Input state="disabled" value={hostToEdit?.organization} onChange={(e: any) => {
                                dispatch(changeFormDatabaseHost({ ...forms.host, organization: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Net Range</Label>
                            <Input state="disabled" value={hostToEdit?.netRange} onChange={(e: any) => {
                                dispatch(changeFormDatabaseHost({ ...forms.host, netRange: e.target.value }))
                            }} />
                        </div>
                        <div className="d-flex flex-column gap-2 flex-grow-1">
                            <Label>Net Name</Label>
                            <Input state="disabled" value={hostToEdit?.netName} onChange={(e: any) => {
                                dispatch(changeFormDatabaseHost({ ...forms.host, netName: e.target.value }))
                            }} />
                        </div>
                    </div>

                    <div className="d-flex flex-column gap-2">
                        <Label>Abuse Contact Email</Label>
                        <Input state="disabled" value={hostToEdit?.abuseEmail} onChange={(e: any) => {
                            dispatch(changeFormDatabaseHost({ ...forms.host, abuseEmail: e.target.value }))
                        }} />
                    </div>
                    <div className="d-flex flex-column gap-2">
                        <Label>Abuse Form</Label>
                        <Input value={hostToEdit?.abuseForm} onChange={(e: any) => {
                            setHostToEdit({ ...hostToEdit, abuseForm: e.target.value })
                        }} />
                    </div>

                </form>
            </Modal>

        </section>
    )
}