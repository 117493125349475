import styled from "styled-components"
import { dsVariables } from "../../variables"
import Icon from "../Icon"
import { SvgIconComponent } from "@mui/icons-material"
import { HTMLInputTypeAttribute } from "react"

interface IProps {
    state?: "default" | "disabled"
    value?: any
    onChange: any
    iconLeft?: SvgIconComponent
    iconRight?: SvgIconComponent
    placeholder?: string
    required?: boolean
    width?: number
    minwidth?: number
    onClickIconRight?: any
    error?: boolean
    type?: HTMLInputTypeAttribute
}

export default function Input({ state = 'default', iconLeft, iconRight, placeholder, value = '', onChange, required = false, width, onClickIconRight, error = false, type = 'text' }: IProps) {
    return (
        <Div state={state} error={error} onClick={e => e.stopPropagation()} width={width} iconLeft={iconLeft} className={`position-relative d-flex align-items-center`}>
            <input step={type == 'time' ? 60 : 1} min={0} disabled={state == 'disabled'} required={required} onChange={onChange} className="border-0 w-100 " value={value} type={type} placeholder={placeholder} />
            {iconLeft && <div className="position-absolute start-0 ms-2 pe-none"><Icon width={16} height={16} IconComponent={iconLeft} color={dsVariables.colors.Gray[600]} /></div>}
            {iconRight && <div onClick={onClickIconRight} role="button" className="position-absolute end-0 me-2"> <Icon width={16} height={16} IconComponent={iconRight} color={dsVariables.colors.Gray[600]} /></div>}
        </Div>
    )
}

const Div = styled.div<{ iconLeft?: SvgIconComponent, minwidth?: number, width?: number, error?: boolean, state?: "default" | "disabled" }>`
    height: ${dsVariables.spacing[36]};
    gap: ${dsVariables.spacing[8]};
    align-self: stretch;
    border-radius: ${dsVariables.cornerRadius[4]};
    border: 1px solid ${props =>
        props.error ? dsVariables.colors.Red[400]
            : props.state == 'disabled'
                ? dsVariables.colors.Gray[100]
                : dsVariables.colors.Gray[400]
    } !important;


    ${props => props.width && `
        width: ${props.width}px !important;
    `}

    input{
        /* min-width: 190px; */
        ${props => props.minwidth && `
            minwidth: ${props.minwidth}px !important;
        `}
        padding: ${dsVariables.spacing[4]} ${props => props.iconLeft ? dsVariables.spacing[32] : dsVariables.spacing[8]};
        color: ${props => props.state == 'disabled' ? dsVariables.colors.Gray[300] : dsVariables.colors.Gray[700]};

        &:disabled, &::placeholder {
            background-color:transparent;
            color: ${dsVariables.colors.Gray[700]};
        }
    }
`