import { OpenInFull } from "@mui/icons-material";
import Accordion from "../../../components/Accordion";
import ButtonPrimary from "../../../components/Button/ButtonPrimary";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Label from "../../../components/Text/Label";
import Sub6 from "../../../components/Text/Sub6";
import { getTreatmentPhaseText } from "../../../utils/get-threat-type-text.util";
import { dsVariables } from "../../../variables";
import { useLocation, useNavigate } from "react-router-dom";

function ModalTreatmensList({ isOpen, onClose, treatmentsList }: any) {

      const location = useLocation();
      const navigate = useNavigate();
      const searchParams = new URLSearchParams(location.search);
    
    function selectTreatment(identifier: string){
        onClose()
        searchParams.set('takedown', identifier)
        var newSearch = searchParams.toString();
        if (location.search !== `?${newSearch}`) navigate({ search: newSearch });
    }
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} width={'90%'} title="Tratativas em comum">
            <div className="d-flex gap-4 flex-wrap">
                {
                    treatmentsList?.map((el: any, index: number) =>
                        <div className="d-flex flex-column gap-2 p-4 border rounded position-relative" key={index}>
                            <div role="button" className="position-absolute top-0 end-0 m-2" onClick={() => selectTreatment(el.identifier)}>
                                <Icon color={dsVariables.colors.Astronaut[600]} width={16} height={16} IconComponent={OpenInFull} />    
                            </div>
                            <Sub6 style="medium">#{el.identifier}</Sub6>
                            <div className="d-flex gap-2">
                                <div style={{ width: 160 }}>
                                    <Label style="medium">Fase:</Label>
                                </div>
                                <Label>{getTreatmentPhaseText(el.phase)}</Label>
                            </div>
                            <div className="d-flex gap-2">
                                <div style={{ width: 160 }}>
                                    <Label style="medium">Pix:</Label>
                                </div>
                                <Label>{el.pix?.length}</Label>
                            </div>
                            <div className="d-flex gap-2">
                                <div style={{ width: 160 }}>
                                    <Label style="medium">Denúncias:</Label>
                                </div>
                                <Label>{el.complaint?.length}</Label>
                            </div>
                            <div className="d-flex gap-2">
                                <div style={{ width: 160 }}>
                                    <Label style="medium">Última movimentação:</Label>
                                </div>
                                <Label>{new Date(el.lastMovimentationDate).toLocaleDateString('pt-BR')}</Label>
                            </div>
                            <div className="d-flex gap-2">
                                <div style={{ width: 160 }}>
                                    <Label style="medium">Criado em:</Label>
                                </div>
                                <Label>{new Date(el.createdAt).toLocaleDateString('pt-BR')}</Label>
                            </div>
                        </div>
                    )
                }
            </div>
        </Modal>
    );
}

export default ModalTreatmensList;